import GoodsGroups from '~/modules/goods/models/GoodsGroups'
import contentDialog from '~/mixins/dialogs/contentDialog'
import Suppliers from '~/modules/goods/models/Suppliers'

const actions = {
  mixins: [contentDialog],
  methods: {
    async addGroup () {
      return await this.contentDialog.open({
        title: 'New group',
        width: '700px',
        component: 'm-form-block',
        componentProps: {
          fields: GoodsGroups.ormFields,
          buttonText: 'Add group',
          onSubmit: async (data) => {
            const groupResponse = await GoodsGroups.api().create({
              ...data,
              parent_id: this._.get(data, 'parent_id.id', null),
              description: 'description' // TODO: REMOVE THIS
            })
            const group = this._.get(groupResponse, 'entities.goodsgroups[0]', null)
            this.$notification.success(this.$t('Group added successfully'))
            this.contentDialog.close(group)
          }
        }
      })
    },
    async addProvider () {
      return await this.contentDialog.open({
        title: 'New provider',
        width: '700px',
        component: 'm-form-block',
        componentProps: {
          fields: Suppliers.ormFields,
          buttonText: 'Add provider',
          onSubmit: async (data) => {
            const supplierRequest = await Suppliers.api().create(data)
            const supplier = this._.get(supplierRequest, 'entities.suppliers[0]', null)
            this.$notification.success(this.$t('Provider successfully added'))
            await this.contentDialog.close(supplier)
          }
        }
      })
    }
  }
}

export default actions
