<template lang="pug">
  div(class="dialog")
    div(
      v-if="loading"
      class="dialog__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    div(
      class="dialog__content"
      v-else
    )
      form-cash-register(
        v-if="checkboxOutletsExists"
        @dialog:close="close"
      )
      form-outlet(
        v-else-if="showOutletsForm"
        @dialog:close="close"
        is-dialog-part
      )
      div(v-else-if="onlyTaxOutletsExists")
        p(class="dialog__text dialog__text--small mt-9 mb-3") {{ $t('The point of sale is still registered in the tax') }}.
        p(class="dialog__text dialog__text--small") {{ $t('After 2-3 minutes, click "Update data"') }}
        e-button-main(
          @click="close"
          :enableIcon="false"
          class="d-block mx-auto mt-8 mb-6"
        ) {{ $t('Okay') }}
      div(v-else)
        v-card-title(class="dialog__text") {{ $t('Before you add a cash register, add an outlet') }}
        e-button-main(
          @click="addOutlet"
          :enableIcon="false"
          class="d-block mx-auto mt-4 mb-6"
        ) {{ $t('Add outlet') }}
</template>

<script>
import FormCashRegister from '~/modules/cashRegisters/views/components/form-cash-register.vue'
import FormOutlet from '~/modules/outlets/views/components/creating-form/form-outlet.vue'
import Outlets from '~/modules/outlets/models/Outlets'
import EButtonMain from '~/components/elements/buttons/e-button-main.vue'
import OutletOrder from '~/modules/outlets/models/OutletOrder'
import authUser from '~/modules/auth/authUser'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  components: {
    EProgressCircular,
    FormCashRegister,
    FormOutlet,
    EButtonMain
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    checkboxOutletsExists: false,
    onlyTaxOutletsExists: false,
    showOutletsForm: false
  }),
  computed: {
    model () {
      return CashRegisters
    }
  },
  async created () {
    await this.checkOutlets()
  },
  methods: {
    addOutlet () {
      this.showOutletsForm = true
    },
    close () {
      this.closeModal()
    },
    async checkOutlets () {
      // info: this checks because this role doesn't have access for outlets and user sees error message
      const isEmergencyCashier = authUser.get() && authUser.get().isEmergencyCashier

      if (!this.$Organization || isEmergencyCashier) {
        return
      }
      try {
        this.loading = true
        const checkboxOutlets = await Outlets.api().filter({ mode: Outlets.itemModes.checkbox.mode, limit: 1, offset: 1 }).all(null, {
          save: false
        })
        const taxOutlets = await OutletOrder.api().filter({ type: OutletOrder.orderTypes['100'].value, limit: 1, offset: 1 }).all(null, {
          save: false
        })
        this.checkboxOutletsExists = Boolean(this._.get(checkboxOutlets, 'response.data.meta.totalItems', 0))
        this.onlyTaxOutletsExists = Boolean(this._.get(taxOutlets, 'response.data.meta.totalItems', 0)) && !this.checkboxOutletsExists
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  &__loader {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    word-break: normal;
    font-size: 18px;
    text-align: center;
    color: #000;
    line-height: 1.5;
    padding-top: 40px;

    &--small {
      padding: 0;
      font-size: 16px;
      margin: 0;
      text-align: left;
    }
  }
}
</style>
