import auth from '~/modules/auth/auth'
import routes from '~/const/routes'

export default ({ $axios, redirect, app }) => {
  let refreshing = false
  const CancelToken = $axios.CancelToken
  const delegatedError = (response) => {
    const err = new Error()
    err.response = response
    return err
  }

  const isRefreshing = () => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        const token = auth.getAuthToken()
        if (!token || !refreshing) {
          clearInterval(interval)
          resolve(true)
        }
      }, 300)
    })
  }
  const logOut = async () => {
    if (auth.getToken()) {
      await auth.logout()
      redirect('/auth/login')
      throw new Error('Auth token is outdated. Renew Please')
    }
  }
  const isPublicRoute = (url) => {
    const isResetPassword = (url = '') => {
      const urlArr = url.split('/')
      return url === routes.auth.apply_reset_password(urlArr.pop())
    }
    return [
      ...Object.values(routes.auth).map(item => item()),
      routes.login(),
      routes.monobank.auth(),
      routes.monobank.temporaryLogin(),
      routes.monobank.authCheck(),
      routes.monobank.deepLink()
    ].includes(url) || isResetPassword(url)
  }

  $axios.defaults.validateStatus = (status) => {
    return [200, 201, 202, 203, 204].includes(status)
  }

  $axios.onRequest(async (request) => {
    const source = CancelToken.source()
    const token = auth.getAuthToken()
    request.cancelToken = source.token
    if (!isPublicRoute(request.url)) {
      if (token && !refreshing && (token.expiredAt - Math.floor(Date.now() / 1000) < 2)) {
        try {
          refreshing = true
          await auth.refreshToken()
        } catch (e) {
          await logOut()
        } finally {
          refreshing = false
        }
      }
      await isRefreshing()
      if (!auth.getToken()) {
        source.cancel('Auth token is outdated. Renew Please')
      }
    }
    request.headers['Accept-Language'] = app.i18n.locale
    request.headers.Authorization = auth.getBearer()
    request.headers['X-ORGANIZATION'] = auth.getCurrentOrganizationId()
    request.headers['X-CLIENT-NAME'] = 'checkbox-by-mono-portal-frontend'
    return request
  })

  $axios.onError((data) => {
    const response = data.response
    if (response) {
      if (!isPublicRoute(response.config.url) && response.config.url !== '/token/refresh' && response.status === 401) {
        // TODO hotfix
        logOut()
        response.data.message = 'Auth token is outdated. Renew Please'
      }
      if (response.data) {
        throw delegatedError(response)
      }
    }
  })

  $axios.onResponseError(({ response }) => {
    // Executes after onError.
  })

  $axios.onResponse((response) => {
    return response
  })
}
