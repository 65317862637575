import { get } from 'lodash'
import AuthToken from '~/modules/auth/Models/AuthToken'
import Organization from '~/models/directories/Organization'
import CurrentUser from '~/modules/auth/Models/CurrentUser'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'
import AuthenticatedCashier from '~/modules/receipt/models/AuthenticatedCashier'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'
import OutletsSearch from '~/modules/outlets/models/OutletsSearch'
import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'
import InvoicesSearch from '~/models/billing/search/InvoicesSearch'
import ActsSearch from '~/models/billing/search/ActsSearch'
import GoodsGroupsSearch from '~/modules/goods/models/GoodsGroupsSearch'
import SuppliersSearch from '~/modules/goods/models/SuppliersSearch'
import AccountingGoodsSearch from '~/modules/goods/models/AccountingGoodsSearch'
import OrganizationSearch from '~/models/directories/search/OrganizationSearch'
import TagsSearch from '~/models/directories/search/TagsSearch'
import ProvidersSearch from '~/modules/acquiring/models/ProvidersSearch'
import MccCodesSearch from '~/models/directories/search/MccCodesSearch'

export default {
  login (login, password) {
    return AuthToken.api().getToken(login.toLowerCase(), password)
  },
  loginById (userId) {
    return AuthToken.api().getTokenForUser(userId)
  },

  /**
   * This used for login by other user from super admin user
   */
  async loginAsOtherUser (userId) {
    if (!this.getAuthToken()) { return }
    const superAdminToken = this.getAuthToken()
    await this.loginById(userId)
    superAdminToken.userType = AuthToken.userTypes.SUPER_ADMIN
    await superAdminToken.$save()
    await Promise.all(this.deleteEntities())
    // await superAdminToken.
  },

  /**
   * This used for super admin user
   */
  async applyAdminToken () {
    if (this.getSuperAdminAuthToken()) {
      const adminToken = this.getSuperAdminAuthToken()
      await this.logout()
      adminToken.userType = AuthToken.userTypes.USER
      await adminToken.$save()
      await currentOrganizationService.setCurrentOrganization(null)
    }
  },

  logout () {
    localStorage.removeItem('monobankBusinessId')

    return Promise.all([
      ...this.deleteEntities(),
      AuthToken.deleteAll()
    ])
  },

  refreshToken () {
    return AuthToken.api().refresh(this.getRefreshToken())
  },

  getAuthToken () {
    return AuthToken.query().where('userType', AuthToken.userTypes.USER).first()
  },

  getSuperAdminAuthToken () {
    return AuthToken.query().where('userType', AuthToken.userTypes.SUPER_ADMIN).first()
  },

  getToken () {
    const authToken = this.getAuthToken()
    return get(authToken, 'token', null)
  },

  getBearer () {
    const token = this.getToken()
    return token ? 'Bearer ' + token : null
  },

  getCurrentOrganizationId () {
    const currentOrganization = currentOrganizationService.getCurrentOrganization()
    return get(currentOrganization, 'id', null)
  },

  getRefreshToken () {
    const authToken = this.getAuthToken()
    return get(authToken, 'refresh_token', null)
  },

  isAuthenticated () {
    return !!this.getAuthToken()
  },

  deleteEntities () {
    return [
      MccCodesSearch.deleteAll(),
      ProvidersSearch.deleteAll(),
      TagsSearch.deleteAll(),
      OrganizationSearch.deleteAll(),
      AccountingGoodsSearch.deleteAll(),
      GoodsGroupsSearch.deleteAll(),
      SuppliersSearch.deleteAll(),
      ActsSearch.deleteAll(),
      InvoicesSearch.deleteAll(),
      AuthenticatedCashier.deleteAll(),
      CashRegistersSearch.deleteAll(),
      OutletsSearch.deleteAll(),
      EmployeesSearch.deleteAll(),
      CurrentUser.deleteAll(),
      Organization.deleteAll()
    ]
  }
}
