import { Model } from '@vuex-orm/core'
import Search from '~/models/abstracts/Search'

export class CashRegistersSearch extends Search {
  static entity = 'cashregisterssearch'

  static fields () {
    return {
      id: this.attr(null),
      number: this.attr(null),
      fiscalNumber: this.attr(null),
      outlet: this.attr(null),
      acquiringSettings: this.attr(null),
      ettnSettings: this.attr(null),
      mode: this.attr(null),
      address: this.attr(null)
    }
  }

  toString () {
    let address = this._.get(this.outlet, 'address', '')

    if (address && address.toString().toLowerCase().startsWith('україна')) {
      address = address.replace('УКРАЇНА, ', '')
    }

    return `Каса №${this.number} (${this.fiscalNumber}) ` + (address ? `\n${address}` : '')
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.listMultiAccount = function (payload, options = {}) {
        return this.post(Model.$routes.cashregisterssearch.listMultiAccount(), payload, { dataKey: 'data', ...options })
      }
      return configActions
    }
  }
}

export default CashRegistersSearch
