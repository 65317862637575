<template lang="pug">
  v-col(cols="12" :class="['py-0', noGutters || xsDevice ? 'px-0' : '']")
    v-row
      v-col(cols="12" :class="['py-0', noGutters ? 'px-0' : ''].concat(classes)")
        template(v-for="(item, index) in items")
          div(v-if="isAvailInCtx(item) ? isVisible(item) : false" :class="[item.fullWidth ? 'd-block w-100 mr-2' : 'd-inline-block', item.grow ? 'flex-grow-1': '', ]")
            v-tooltip(v-if="item.tooltip" v-bind="_.get(item, 'tooltip.position')" :top="!_.get(item, 'tooltip.position')")
              template(v-slot:activator="{ on, attrs }")
                component(
                  :is="getComponent(item)"
                  :key="index"
                  v-bind="item.attrs"
                  v-on="on"
                  :loading="item.loading"
                  :class="_.get(item, 'attrs.class', '')"
                  :disabled="isDisabled(item)"
                  @click.stop="item.call && item.call(undefined, item.dictionaryFirst, item.parentCtx)"
                )
                  e-svg-icon(v-if="item.svgIcon" :size="_.get(item, 'svgIcon.size')" :class="[item.text && 'mr-2']" style="vertical-align: -2px") {{ _.get(item, 'svgIcon.name') }}
                  | {{ $t(item.text) }}
                  component(v-if="item.afterText" :is="item.afterText.component" v-bind="item.afterText.attrs") {{ item.afterText.text }}
                div(v-if="item.hint" class="hint") {{ $t(item.hint) }}
              span {{ $t(_.get(item, 'tooltip.text')) }}
            v-menu(
              v-else-if="item.menu"
              v-bind="_.get(item, 'menu.attrs', {})"
            )
              template(v-slot:activator="{ on, attrs }")
                component(
                  :is="getComponent(item)"
                  :key="index"
                  v-bind="item.attrs"
                  v-on="on"
                  :loading="item.loading"
                  :class="_.get(item, 'attrs.class', '')"
                  :disabled="isDisabled(item)"
                )
                  e-svg-icon(v-if="item.svgIcon" :size="_.get(item, 'svgIcon.size')" :class="[item.text && 'mr-2']" style="vertical-align: -2px") {{ _.get(item, 'svgIcon.name') }}
                  | {{ $t(item.text) }}
                  component(v-if="item.afterText" :is="item.afterText.component" v-bind="item.afterText.attrs") {{ item.afterText.text }}
                div(v-if="item.hint" class="hint") {{ $t(item.hint) }}
              v-list()
                v-list-item(
                  v-for="(action, index) in item.actions"
                  :key="index"
                  v-if="action.visible === undefined || action.visible"
                  v-bind="action.attrs"
                  @click="action.call && action.call(item)"
                )
                  v-list-item-icon(v-if="action.icon")
                    e-svg-icon(
                      v-if="action.icon"
                      v-bind="_.get(action, 'icon.attrs', {})"
                    ) {{ _.get(action, 'icon.name', '') }}
                  v-list-item-content
                    v-list-item-title {{ $t(action.text) }}
            template(v-else)
              component(
                :is="getComponent(item)"
                :key="index"
                v-bind="item.attrs"
                :loading="item.loading"
                :class="_.get(item, 'attrs.class', '')"
                :disabled="isDisabled(item)"
                @click.stop="item.call && item.call(undefined, item.dictionaryFirst, item.parentCtx)"
              )
                e-svg-icon(v-if="item.svgIcon" :size="_.get(item, 'svgIcon.size')" :class="[item.text && 'mr-2']" style="vertical-align: -2px") {{ _.get(item, 'svgIcon.name') }}
                | {{ $t(item.text) }}
                component(v-if="item.afterText" :is="_.get(item, 'afterText.component', '')" v-bind="_.get(item, 'afterText.attrs', {})") {{ _.get(item, 'afterText.text', '') }}
              div(v-if="item.hint" class="hint") {{ $t(item.hint) }}
</template>

<script>
import {
  VBtn,
  VIcon
} from 'vuetify/lib'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import contexts from '~/mixins/props/contexts'
import EButtonHelp from '~/components/elements/buttons/e-button-help'
import responsive from '~/mixins/pages/responsive'

export default {
  components: {
    VBtn,
    VIcon,
    ESvgIcon,
    EButtonHelp
  },
  mixins: [contexts, responsive],
  props: {
    items: {
      type: Array,
      required: true
    },
    noGutters: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array,
      default: () => ([
        'text-right'
      ])
    }
  },
  methods: {
    getComponent (item) {
      return this._.get(item, 'component', 'v-btn')
    },
    isVisible (item) {
      return this._.isFunction(item.visible) ? item.visible(item) : true
    },
    isDisabled (item) {
      const rules = [
        this._.isFunction(item.disabled) ? item.disabled(this.target) : false
      ]

      return this._.compact(rules).length === rules.length
    },
    isAvailInCtx (button) {
      if ('contexts' in button) {
        return this._.get(button, 'contexts', []).includes(this.context)
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.hint {
  font-size: 11px;
  line-height: 1.2;
  margin-top: 8px;
}
</style>
