<template lang="pug">
  div(class="a-bank-information-check")
    p(class="a-bank-information-check__icon") {{ icon }}
    h3(class="a-bank-information-check__title") {{ $t(title) }}
    v-btn(
      v-if="error"
      class="secondary-button a-bank-information-check__button"
      @click="checkInformation"
      dark
    ) {{ $t('Repeat') }}
    v-progress-linear(
      class="a-bank-information-check__progress"
      v-else
      indeterminate
    )
</template>

<script>
export default {
  name: 'BlockABankInformationCheck',
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    changeStep: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    steps: {
      type: Object,
      default: () => {}
    },
    model: {
      type: null,
      required: true
    }
  },
  data: () => ({
    error: false
  }),
  computed: {
    icon () {
      if (this.error) {
        return '😔'
      }
      return '🕵'
    },
    title () {
      if (this.error) {
        return 'We could not get confirmation from the bank about the availability of the card'
      }
      return 'Checking the information'
    }
  },
  created () {
    this.changeTitle('')
    this.toggleCloseBtn(false)
    this.checkInformation()
  },
  methods: {
    showError () {
      this.error = true
      this.toggleCloseBtn(true)
    },
    async checkInformation () {
      try {
        this.error = false
        this.toggleCloseBtn(false)

        const clientStatus = this._.get(await this.model.api().getClientStatus(), 'response.data.clientType')

        if (clientStatus === this.model.CLIENT_STATUSES.notClient || clientStatus === this.model.CLIENT_STATUSES.redPhysClient) {
          this.showError()
        } else {
          this.changeStep(this.steps.accountOpening)
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.showError()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.a-bank-information-check {
  text-align: center;

  &__icon {
    font-size: 48px;
    margin-bottom: 40px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 18px;
    }
  }

  &__progress {
    margin-top: 40px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-top: 30px;
    }
  }

  &__button {
    margin-top: 32px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-top: 24px;
    }
  }
}
</style>
