import auth from '~/modules/auth/auth'

const faqChildren = [
  {
    text: 'How to start',
    icon: {
      type: 'e-svg-icon',
      text: 'compass',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isOwnerWithoutOrganization
    },
    href: '/dashboard/onboarding'
  },
  {
    text: 'Instructions ',
    icon: {
      type: 'e-svg-icon',
      text: 'book',
      options: {
        size: 'md'
      }
    },
    href: '/dashboard/instructions'
  },
  {
    text: 'Rules of work with cash register',
    icon: {
      type: 'e-svg-icon',
      text: 'faq',
      options: {
        size: 'sm'
      }
    },
    href: '/dashboard/faq'
  },
  {
    text: 'Як протестувати',
    color: '#ff8a00',
    icon: {
      type: 'e-svg-icon',
      text: 'integrations-orange',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isOwnerWithoutOrganization
    },
    link: 'https://wiki.checkbox.ua/uk/portal/test-data'
  }
]

export const menuItems = [
  {
    text: 'Answers to questions',
    visible: (user) => {
      let show = user.isOwner || user.isEmployee || user.isOwnerWithoutOrganization
      const dateCreated = user?.dateCreated
      if (dateCreated) {
        if (show) {
          // Two weeks
          show = (new Date().getTime() - new Date(dateCreated).getTime()) < 12096e5
        }
      }
      return !user?.globalAllOrganizationsSelected && show
    },
    highlight: () => true,
    children: faqChildren
  },
  {
    text: 'My cash register',
    icon: {
      type: 'e-svg-icon',
      text: 'cheque',
      options: {
        color: '#a5a4bf',
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isAdmin || user.isEmployee || user.isHeadCashier || user.isOwnerWithoutOrganization || user.isAccountant
    },
    children: [
      {
        text: 'Creating a deposit',
        icon: {
          type: 'e-svg-icon',
          text: 'deposit-2',
          options: {
            color: '#a5a4bf',
            size: 'xmd'
          }
        },
        visible: user => !user?.globalAllOrganizationsSelected && user.isPawnshop,
        href: '/dashboard/createdeposit'
      },
      {
        text: 'Redeem deposit',
        icon: {
          type: 'e-svg-icon',
          text: 'deposit',
          options: {
            color: '#a5a4bf',
            size: 'xmd'
          }
        },
        visible: user => !user?.globalAllOrganizationsSelected && user.isPawnshop,
        href: '/dashboard/redeemdeposit'
      },
      {
        text: 'Selling',
        icon: {
          type: 'e-svg-icon',
          text: 'sell',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: user => !user?.globalAllOrganizationsSelected && !user.isAccountant,
        href: '/dashboard/createreceipts'
      },
      {
        text: 'Return',
        icon: {
          type: 'e-svg-icon',
          text: 'selling',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: user => !user?.globalAllOrganizationsSelected && !user.isAccountant,
        href: '/dashboard/refundreceipt'
      },
      {
        text: 'Reports',
        icon: {
          type: 'e-svg-icon',
          text: 'seo-report',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/reports'
      }
    ]
  },
  {
    text: 'Cash management',
    icon: {
      type: 'e-svg-icon',
      text: 'cashier',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isAdmin || user.isHeadCashier || user.isEmergencyCashier || user.isEmployee || user.isOwnerWithoutOrganization || user.isAccountant
    },
    children: [
      {
        text: 'Employees',
        icon: {
          type: 'e-svg-icon',
          text: 'paymaster',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/employees',
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization)
        }
      },
      {
        text: 'Cash registers',
        icon: {
          type: 'e-svg-icon',
          text: 'cash-register',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/cashregisters',
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isEmergencyCashier || user.isOwnerWithoutOrganization)
        }
      },
      {
        text: 'Acquiring',
        icon: {
          type: 'e-svg-icon',
          text: 'banking-2',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/acquiring',
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization)
        }
      },
      {
        text: 'Outlets',
        icon: {
          type: 'e-svg-icon',
          text: 'shop',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization || user.isAccountant)
        },
        href: '/dashboard/outlets'
      },
      {
        text: 'Receipts archive',
        icon: {
          type: 'e-svg-icon',
          text: 'price',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/receipts',
        visible: (user) => {
          return user.isOwner || user.isEmployee || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization || user.isAccountant
        }
      },
      {
        text: 'Work shifts',
        icon: {
          type: 'e-svg-icon',
          text: 'change',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user) => {
          return user.isOwner || user.isEmployee || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization || user.isAccountant
        },
        href: '/dashboard/shifts'
      },
      {
        text: 'Goods',
        icon: {
          type: 'e-svg-icon',
          text: 'shopping-cart',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (user.isOwner || user.isAdmin || user.isHeadCashier || user.isOwnerWithoutOrganization)
        },
        href: '/dashboard/goods'
      }
    ]
  },
  {
    text: 'Organizations',
    icon: {
      type: 'e-flat-icon',
      text: 'flaticon-connection',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/organizations'
  },
  {
    text: 'Users',
    icon: {
      type: 'e-svg-icon',
      text: 'users',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/users'
  },
  {
    text: 'Payments',
    icon: {
      type: 'e-svg-icon',
      text: 'cashflow',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/payments'
  },
  {
    text: 'Tax Bills',
    icon: {
      type: 'e-svg-icon',
      text: 'cashflow',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/taxbills'
  },
  {
    text: 'Invoices',
    icon: {
      type: 'e-svg-icon',
      text: 'invoice',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/invoices'
  },
  {
    text: 'Billings',
    icon: {
      type: 'e-svg-icon',
      text: 'billing',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/billings'
  },
  {
    text: 'Client balances',
    icon: {
      type: 'e-svg-icon',
      text: 'billing',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/clientbalances'
  },
  {
    text: 'Acts',
    icon: {
      type: 'e-svg-icon',
      text: 'act',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/acts'
  },
  {
    text: 'My organization',
    icon: {
      type: 'e-svg-icon',
      text: 'organization',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isOwner || user.isAdmin || user.isOwnerWithoutOrganization
    },
    children: [
      {
        text: 'Organization data',
        icon: {
          type: 'e-svg-icon',
          text: 'network',
          options: {
            color: '#a5a4bf',
            size: 'md'
          }
        },
        href: '/dashboard/myorganization'
      },
      {
        text: 'Tax rates',
        icon: {
          type: 'e-svg-icon',
          text: 'taxes',
          options: {
            color: '#a5a4bf',
            size: 'sm'
          }
        },
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && (auth.getSuperAdminAuthToken() || user.isOwner || user.isAdmin || user.isOwnerWithoutOrganization)
        },
        href: '/dashboard/taxrates'
      },
      {
        text: 'Settings',
        icon: {
          type: 'e-svg-icon',
          text: 'settings',
          options: {
            color: '#a5a4bf',
            size: 'xmd'
          }
        },
        visible: (user) => {
          return !user?.globalAllOrganizationsSelected && user.isOwner
        },
        href: '/profile'
      }
    ]
  },
  {
    text: 'Answers to questions',
    visible: (user) => {
      let show = user.isOwner || user.isEmployee || user.isOwnerWithoutOrganization
      const dateCreated = user?.dateCreated
      if (dateCreated) {
        if (show) {
          // Two weeks
          show = (new Date().getTime() - new Date(dateCreated).getTime()) > 12096e5
        }
      }
      return !user?.globalAllOrganizationsSelected && show
    },
    highlight: () => false,
    children: faqChildren
  },
  {
    text: 'Reconciliation Checkbox act',
    icon: {
      type: 'e-svg-icon',
      text: 'reconciliation-act',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/reconciliationact'
  },
  {
    text: 'Payment transfers',
    icon: {
      type: 'e-svg-icon',
      text: 'money-transaction',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/transferrequests'
  },
  {
    text: 'Notification',
    icon: {
      type: 'e-svg-icon',
      text: 'notification-2',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isClientsManager
    },
    href: '/dashboard/notifications'
  },
  {
    text: 'Turnover settings',
    icon: {
      type: 'e-svg-icon',
      text: 'cashflow',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/turnoversettings'
  },
  {
    text: 'Reviews',
    icon: {
      type: 'e-svg-icon',
      text: 'review-2',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/reviews'
  },
  {
    text: 'Refunds',
    icon: {
      type: 'e-svg-icon',
      text: 'selling',
      options: {
        size: 'md'
      }
    },
    visible: (user) => {
      return user.isSuperAdmin
    },
    href: '/dashboard/refunds'
  }
]
