import Employees from '~/modules/employees/models/Employees'

const processingCheckAgent = {
  methods: {
    async checkSignAgent (employee) {
      const token = this._.get(await Employees.api().processingSignin(employee), 'response.data.access_token', null)
      const signAgentLaunched = this._.get(await Employees.api().processingCheckAgent(token), 'response.data.online', false)
      return { signAgentLaunched, token }
    }
  }

}

export default processingCheckAgent
