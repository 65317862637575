import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import GoodsGroupsSearch from '~/modules/goods/models/GoodsGroupsSearch'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'

export class GoodsGroups extends PersistAble(OrmModel) {
  static entity = 'goodsgroups'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'name'
  static defaultSortOrder = true

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      parent_id: this.attr(null)
    }
  }

  static ormHeaders = [
    { text: 'Code', value: 'code', sortable: true, filterable: false },
    { text: 'Name', value: 'name', sortable: true, filterable: true },
    { text: 'Barcodes', value: 'barcodesString', sortable: true, filterable: false },
    { text: 'Price, ₴', value: 'priceString', sortable: true, align: 'center', width: '105', filterable: false, sortQuery: 'price' },
    { text: 'Group taxes', value: 'taxesString', sortable: false, filterable: false },
    { text: 'Uktzed', value: 'uktzed', sortable: true, filterable: true },
    { text: 'Supply ', value: 'countString', sortable: true, filterable: true },
    { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '72' }
  ]

  static ormColsComponents = {
    name: {
      component: 'e-text-with-icon',
      forceCall: false,
      attrs: {
        icon: {
          name: 'folder',
          attrs: {
            class: 'mr-2'
          }
        },
        textClass: 'font-weight-bold'
      }
    }
  }

  static ormFields = [
    {
      model: 'name',
      component: 'v-text-field',
      provider: {
        vid: 'name',
        name: 'Group name',
        rules: 'required|max:150'
      },
      attrs: {
        type: 'text',
        label: 'Group name',
        outlined: true
      },
      default: (ctx, item) => (ctx === 'update' && item.name) || null
    },
    new AutocompleteBuilder({
      model: 'parent_id',
      label: 'If this is a subgroup, select the parent group',
      query: model => model.api(),
      default: (ctx, item) => (ctx === 'update' && item.parent_id) || null
    }, GoodsGroupsSearch).build()
  ]

  static ormActions = [
    {
      name: 'edit',
      visible: true,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          title: 'Edit group',
          width: '700px',
          component: 'm-form-block',
          componentProps: {
            context: 'update',
            fields: [
              {
                model: 'name',
                component: 'v-text-field',
                provider: {
                  vid: 'name',
                  name: 'Group name',
                  rules: 'required|max:150'
                },
                attrs: {
                  type: 'text',
                  label: 'Group name',
                  outlined: true
                },
                default: (ctx, item) => (ctx === 'update' && item.name) || null
              }
            ],
            item,
            buttonText: 'Save',
            onSubmit: async (data) => {
              await GoodsGroups.api().update(item, data)
              AppNotifications.success('Group edited successfully')
              await dialog.close()
            }
          }
        })
      }
    },
    {
      name: 'delete',
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          component: 'm-block-goods-removal',
          width: '550px',
          componentProps: {
            item
          }
        })
      }
    }
  ]

  static ormDialogsConfig = {
    edit: {
      config: {
        context: 'update'
      }
    },
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      notification: item => ({ type: 'order ', name: item.name })
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getActive = function () {
        GoodsGroups.deleteAll()
        return this.all()
      }
      configActions.moveGoods = function (id, payload) {
        return this.put(Model.$routes.goodsgroups.moveGoods(id), payload, { save: false })
      }
      return configActions
    }
  }
}

export default GoodsGroups
