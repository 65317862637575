import Base64js from 'base64-js'
import Dialog from '~/models/system/Dialog'
import { TaxReportService } from '~/services/Tax/Report/TaxReportService'
import AppNotifications from '~/services/Notifications/AppNotifications'
import TaxAuthService from '~/services/Tax/Auth/TaxAuthService'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import Organization from '~/models/directories/Organization'
import TaxReports from '~/modules/reports/models/TaxReports'

export default class SignPluginService {
  static async synchronizeOrganization ({ organization, ctx }) {
    await Dialog.query().where('type', 'signPluginForOrganization').first().use()
    const signPluginDialog = Dialog.query().where('type', 'signPluginForOrganization').first()
    try {
      const sign = ctx._.get(signPluginDialog, 'interface.sign')
      const keyData = ctx._.get(signPluginDialog, 'interface.keyData', null)
      const edsKeyData = new EdsKeyData(keyData)
      const EDRPOUCode = ctx._.get(edsKeyData, 'edrpouCode')
      const DRFOCode = ctx._.get(edsKeyData, 'drfoCode')
      if (!keyData) {
        return await signPluginDialog.close()
      } else if (edsKeyData.isSeal()) {
        AppNotifications.error('Please use the EDS key instead of seal')
        return await signPluginDialog.close()
      } else if (edsKeyData.identity !== ctx._.get(ctx.$Organization, 'edrpou', null)) {
        AppNotifications.error('The USREOU codes do not match. Please choose another')
        return await signPluginDialog.close()
      }
      const signedData = typeof sign === 'function' && await sign(ctx._.get(ctx.$Organization, 'edrpou', null) || EDRPOUCode || DRFOCode, {
        fallbackMethod: () => SignPluginService.synchronizeOrganization({ organization, ctx })
      })
      if (!signedData) {
        return null
      }
      const payload = {
        EDRPOUCode,
        DRFOCode,
        sign: signedData
      }
      let afterSyncData = null
      if (payload.sign) {
        afterSyncData = await ctx.$store._actions.synchronizeOrganization[0]({
          id: organization.id,
          data: {
            sign: payload.sign,
            EDRPOUCode,
            DRFOCode
          }
        })
        await Organization.api().within(ctx.$User, '/organization', { dataKey: null })
      }
      await signPluginDialog.hide()
      return afterSyncData
    } catch (e) {
      AppNotifications.error(e)
      await signPluginDialog.close()
    }
  }

  static async synchronizeTaxReports ({ ctx }) {
    await Dialog.query().where('type', 'signPluginForOrganization').first().use()
    const signPluginDialog = Dialog.query().where('type', 'signPluginForOrganization').first()
    try {
      const sign = ctx._.get(signPluginDialog, 'interface.sign')
      const keyData = ctx._.get(signPluginDialog, 'interface.keyData', null)
      const edsKeyData = new EdsKeyData(keyData)
      const EDRPOUCode = ctx._.get(edsKeyData, 'edrpouCode')
      const DRFOCode = ctx._.get(edsKeyData, 'drfoCode')
      if (!keyData) {
        return await signPluginDialog.close()
      } else if (edsKeyData.isSeal()) {
        AppNotifications.error('Please use the EDS key instead of seal')
        return await signPluginDialog.close()
      } else if (edsKeyData.identity !== ctx._.get(ctx.$Organization, 'edrpou', null)) {
        AppNotifications.error('The USREOU codes do not match. Please choose another')
        return await signPluginDialog.close()
      }
      const signedData = typeof sign === 'function' && await sign(ctx._.get(ctx.$Organization, 'edrpou', null) || EDRPOUCode || DRFOCode, {
        fallbackMethod: () => SignPluginService.synchronizeTaxReports({ ctx })
      })
      if (!signedData) {
        return null
      }
      const payload = {
        EDRPOUCode,
        DRFOCode,
        sign: signedData
      }
      let afterSyncData = null
      if (payload.sign) {
        afterSyncData = await TaxReports.api().synchronization({
          sign: payload.sign,
          EDRPOUCode: EDRPOUCode || DRFOCode, // It's only for INDIVIDUALS
          DRFOCode: DRFOCode || EDRPOUCode // It's only for INDIVIDUALS
        })
      }
      await signPluginDialog.hide()
      return afterSyncData
    } catch (e) {
      AppNotifications.error(e)
      await signPluginDialog.close()
    }
  }

  static async sendEntityTaxOrder ({ entity, type, data, orderModel, model, ctx, organization, showSuccessMessage = true, signPluginParams = {} }) {
    try {
      await Dialog.query().where('type', 'signPluginForOrganization').first().use(signPluginParams)
      const signPluginDialog = Dialog.query().where('type', 'signPluginForOrganization').first()
      const keyData = ctx._.get(signPluginDialog, 'interface.keyData', null)
      const edsKeyData = new EdsKeyData(keyData)

      if (!keyData) {
        return signPluginDialog.close()
      } else if (edsKeyData.isSeal()) {
        AppNotifications.error('Please use the EDS key instead of seal')
        await signPluginDialog.close()
        return SignPluginService.sendEntityTaxOrder({ entity, type, data, orderModel, model, ctx, organization })
      } else if (edsKeyData.identity !== organization.edrpou) {
        AppNotifications.error('The USREOU codes do not match. Please choose another')
        await signPluginDialog.close()
        return SignPluginService.sendEntityTaxOrder({ entity, type, data, orderModel, model, ctx, organization })
      }

      try {
        await TaxAuthService.sendAuthData(keyData, signPluginDialog.interface.sign)
      } catch (e) {
        ctx.$handlers.error(e, ctx, true)
      }

      const entityId = ctx._.get(entity, 'id')
      const reportService = new TaxReportService(signPluginDialog.interface)

      let createdOrder, successMessage
      const commonData = {
        HKBOS: reportService.getHKBOSField({
          type,
          entity: ctx._.get(orderModel, 'entity'),
          ctx
        })
      }

      const re = /([0-9]{10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}|[0-9]{9})/

      if (!re.test(commonData.HKBOS)) {
        this.$handlers.error(this.$t('It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents'), this)
        return
      }

      if (!commonData.HKBOS) {
        ctx.$handlers.error(ctx.$t('We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key'), ctx)
        return
      }

      if (type === 'removal') {
        createdOrder = ctx._.get(await orderModel.api().removeFromTax({
          ...commonData,
          ...data
        }), `entities.${orderModel.entity}[0]`, null)
        successMessage = `entityActions.${type}.${orderModel.entity}`
      } else if (type === 'modify') {
        createdOrder = ctx._.get(await orderModel.api().modifyInTax({
          ...commonData,
          ...data
        }), `entities.${orderModel.entity}[0]`, null)
        successMessage = `entityActions.${type}.${orderModel.entity}`
      } else if (type === 'keyReplacement') {
        createdOrder = ctx._.get(await orderModel.api().keyReplacement({
          ...commonData,
          ...data
        }), `entities.${orderModel.entity}[0]`, null)
        successMessage = `entityActions.${type}.${orderModel.entity}`
      }

      const xmlResponse = await orderModel.api().xmlDoc(createdOrder)
      const xml = Base64js.toByteArray(ctx._.get(xmlResponse, 'response.data.xml', null))
      const fname = ctx._.get(xmlResponse, 'response.data.fname', null)
      const signData = await reportService.getEnvelopedData(xml)

      if (!signData) {
        return null
      }

      const taxId = ctx._.get(await orderModel.api().taxRegister(createdOrder, { signData, fname }), 'response.data.response.taxId', null)
      const encodedTaxId = new TextEncoder().encode(String(taxId))
      const signedTaxId = await reportService.getEnvelopedData(encodedTaxId)

      if (!signedTaxId) {
        return null
      }

      await orderModel.api().signTaxId(createdOrder, { tax_id: signedTaxId })

      await model.api().read(entityId)
      if (showSuccessMessage) {
        AppNotifications.success(successMessage)
      }
      await signPluginDialog.hide()
      return createdOrder
    } catch (e) {
      AppNotifications.error(e)
    }
  }
}
