<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('License key') }}
    div(
      v-if="loading"
      class="dialog__loader"
    )
      e-progress-circular(
        size="xxl"
        width="4"
      )
    qrcode-vue(
      v-else-if="qrLink"
      :value="qrLink"
      level="H"
      :size="xsDevice ? 230 : 250"
      class="text-center pt-3 pb-5"
    )
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockAcquiringCashRegisterQr',
  components: {
    QrcodeVue,
    EProgressCircular
  },
  mixins: [responsive],
  props: {
    item: {
      type: Object,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: true,
    qrLink: null
  }),
  created () {
    this.getCashRegister()
  },
  methods: {
    async getCashRegister () {
      try {
        this.loading = true
        const id = this._.get(this.item, 'integrationDetails.kassaId')
        this.qrLink = this._.get(await CashRegisters.api().read(id), 'response.data.licenseKey')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    height: 235px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
