import { Model } from '@vuex-orm/core'
import DOMPurify from 'dompurify'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import FilterAble from '~/models/mixins/FilterAble'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import Taxes from '~/modules/tax-rates/models/Taxes'
import GoodsGroups from '~/modules/goods/models/GoodsGroups'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'

export class AccountingGoods extends ChainInheritance(Directory, [FilterAble, PersistAble, TimestampAble, AssignAble]) {
  static entity = 'accountinggoods'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'name'
  static dblClickAction = 'read'
  static persistOptions = {
    insertOrUpdate: ['goodsgroups']
  }

  static TYPES_MAP = {
    good: 'good',
    service: 'service'
  }

  static ormRelationMap = [
    'taxes',
    'children'
  ]

  static fields () {
    return {
      group_id: this.attr(null),
      tax_ids: this.attr(null),

      id: this.attr(null),
      name: this.attr(null),
      code: this.attr(null),
      barcode: this.attr(null),
      related_barcodes: this.attr(null),
      price: this.attr(null),
      uktzed: this.attr(null),
      is_weight: this.attr(null),
      group: this.belongsTo(GoodsGroups, 'group_id'),
      type: this.attr(null),
      count: this.attr(null),
      position: this.attr(null),
      // relations
      children: this.attr(null, value => this._.isArray(value)
        ? value.map(item => new AccountingGoods(item))
        : []),
      taxes: this.attr(null),
      branches: this.attr(null),
      branches_info: this.attr(null)
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormTrans = {
    single: 'Good',
    multy: 'Goods',
    notificationSingle: 'Good',
    subTitle: 'Good subtitle'
  }

  get priceString () {
    return (this.price / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get countString () {
    if (this.type === AccountingGoods.TYPES_MAP.service) {
      return '-'
    } else if (this.is_weight) {
      return ((this.count || 0) / 1000).toFixed(3)
    } else {
      return ((this.count || 0) / 1000).toFixed(0)
    }
  }

  get taxesString () {
    if (this.taxes && this.taxes.length) {
      const taxes = Taxes.query().whereId(this.taxes).get()
      return taxes.map(x => x.label).join(', ')
    } else { return '' }
  }

  get barcodesString () {
    return this.related_barcodes || this.barcode
  }

  static ormHeaders = [
    { text: 'Code', value: 'code', sortable: true, filterable: false },
    { text: 'Name', value: 'name', sortable: true, filterable: true },
    { text: 'Barcodes', value: 'barcodesString', sortable: true, filterable: false },
    { text: 'Price, ₴', value: 'priceString', sortable: true, align: 'center', width: '105', filterable: false, sortQuery: 'price' },
    { text: 'Group taxes', value: 'taxesString', sortable: false, filterable: false },
    { text: 'Uktzed', value: 'uktzed', sortable: true, filterable: true },
    { text: 'Supply ', value: 'countString', sortable: true, filterable: false, sortQuery: 'count' },
    { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '72' }
  ]

  static ormFields = []

  static ormActions = [
    {
      name: 'read',
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-product-view',
          width: '900px',
          componentProps: {
            item
          }
        })
      }
    },
    {
      name: 'addSupplyToProduct'
    },
    {
      name: 'addWriteOffToProduct'
    },
    {
      name: 'edit'
    },
    {
      name: 'delete',
      call: (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        confirmationDialog.open({
          title: 'Product removal',
          text: (ctx) => {
            return ctx.$t('Are you sure to delete product?', { name: DOMPurify.sanitize(this._.get(item, 'name')) })
          },
          hint: {
            visible: this._.get(item, 'count', 0),
            icon: 'exclamation-warning',
            text: 'Warning! The product has leftovers that will also be removed'
          },
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await AccountingGoods.api().del(item)
              AppNotifications.success('Product successfully deleted')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    }
  ]

  static ormDialogs = {
    default: 'm-orm-goods-form-dialog',
    addSupplyToProduct: 'm-orm-supplies-form-dialog',
    addWriteOffToProduct: 'm-orm-supplies-form-dialog',
    edit: 'm-orm-goods-form-dialog',
    delete: 'm-orm-delete-dialog'
  }

  static ormDialogsConfig = {
    default: {
      config: {
        context: 'create',
        modalName: 'Add goods'
      },
      title: '|'
    },
    addSupplyToProduct: {
      config: {
        context: 'create'
      }
    },
    addWriteOffToProduct: {
      config: {
        context: 'create'
      }
    },
    edit: {
      config: {
        context: 'update'
      }
    },
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      notification: item => ({ type: this.ormTrans.single, name: item.name })
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.addSupplyToProduct = function (id, payload) {
        return this.post(Model.$routes.accountinggoods.addSupplyToProduct(id), payload, { dataKey: null })
      }
      configActions.addWriteOffToMultipleProducts = function (payload) {
        return this.post(Model.$routes.accountinggoods.writeOff(), payload)
      }
      configActions.uploadExcel = function (file, params) {
        const formData = new FormData()
        formData.append('file', file)
        return this.post(Model.$routes.accountinggoods.uploadExcel(), formData, {
          save: false,
          responseType: 'json',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          params
        })
      }
      configActions.uploadCsv = function (file, params) {
        const formData = new FormData()
        formData.append('file', file)
        return this.post(Model.$routes.accountinggoods.uploadCsv(), formData, {
          save: false,
          responseType: 'json',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          params
        })
      }
      configActions.checkUploadStatus = function (id) {
        return this.get(Model.$routes.accountinggoods.checkUploadStatus(id), {
          save: false
        })
      }
      configActions.generateBarcode = function () {
        return this.get(Model.$routes.accountinggoods.generateBarcode(), {
          save: false
        })
      }
      configActions.approveFile = function (id) {
        return this.post(Model.$routes.accountinggoods.checkUploadStatus(id), {}, {
          save: false
        })
      }
      configActions.getUploadExample = function (type) {
        return this.get(Model.$routes.accountinggoods.getUploadExample(type), {
          save: false,
          responseType: 'blob'
        })
      }
      configActions.exportExcel = function () {
        return this.post(Model.$routes.accountinggoods.exportExcel(), {}, {
          save: false
        })
      }
      configActions.exportCsv = function () {
        return this.post(Model.$routes.accountinggoods.exportCsv(), {}, {
          save: false
        })
      }
      configActions.checkExportStatus = function (id) {
        return this.get(Model.$routes.accountinggoods.checkExportStatus(id), {
          save: false
        })
      }
      configActions.exportFile = function (id, payload = {}) {
        return this.post(Model.$routes.accountinggoods.exportFile(id), payload, {
          save: false,
          responseType: 'blob'
        })
      }
      configActions.deleteAll = function () {
        return this.delete(Model.$routes.accountinggoods.list(), { save: false })
      }
      configActions.deleteAllStatus = function (id) {
        return this.get(Model.$routes.accountinggoods.deleteAllStatus(id), {
          save: false
        })
      }
      configActions.deleteAllInGroup = function (params) {
        return this.delete(Model.$routes.accountinggoods.list(), { save: false, params })
      }
      return configActions
    }
  }
}

export default AccountingGoods
