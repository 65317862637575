import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import signPluginCommonDialog from '~/mixins/dialogs/signPluginCommonDialog'
import Organization from '~/models/directories/Organization'

const organization = {
  mixins: [signPluginCommonDialog],
  methods: {
    checkPersonType (code = '') {
      // for old passport type (8 symbols)
      if (isNaN(parseInt(code))) {
        return 'individual'
      }
      // edrpou always 8 symbols
      return code.toString().length === 8 ? 'juridical' : 'individual'
    },
    async getEdsKeyData ({ fallback } = {}) {
      const keyData = await this.signPluginCommonDialog.open()
      if (!keyData) {
        await this.signPluginCommonDialog.close()
        return null
      }
      const edsKeyData = new EdsKeyData(keyData)

      if (edsKeyData.isSeal()) {
        this.$handlers.error('Please use the EDS key instead of seal', this)
        await this.signPluginCommonDialog.close()
        return fallback()
      }

      const sign = await this.signPluginCommonDialog.interface.sign(edsKeyData.identity)
      await this.signPluginCommonDialog.close()

      return {
        edsKeyData,
        sign
      }
    },
    async getDataFromSfs ({ edsKeyData, sign } = {}) {
      return await this.$store.dispatch('user/getOrganizationFromTax', {
        EDRPOUCode: edsKeyData.edrpouCode,
        DRFOCode: edsKeyData.drfoCode,
        sign
      }) || {}
    },
    getOrganizationData ({ dataFromSfs, edsKeyData } = {}) {
      const name = this._.get(dataFromSfs, 'identify.FULL_NAME', '')
      const shortName = this._.get(dataFromSfs, 'identify.NAME', '') || name
      const edrpou = edsKeyData.identity || this._.get(dataFromSfs, 'identify.TIN', '')
      const taxNumber = this._.get(dataFromSfs, 'vat.KOD_PDV', '') || edsKeyData.identity
      const personType = this.checkPersonType(edsKeyData.identity)
      const personShowType = personType === 'juridical' ? 'Legal entity' : 'Individual'
      const isVAT = Boolean(this._.get(dataFromSfs, 'vat', null)) && !this._.get(dataFromSfs, 'vat.DAT_ANUL', null)
      const isSingleTax = Boolean(this._.get(dataFromSfs, 'singleTax', null)) && !this._.get(dataFromSfs, 'singleTax.DAT_ANUL', null)
      const dataFromSfsCopy = JSON.parse(JSON.stringify(dataFromSfs))
      delete dataFromSfsCopy.exists
      delete dataFromSfsCopy.token

      return {
        organizationData: {
          name,
          shortName,
          edrpou,
          taxNumber,
          personType,
          isVAT,
          isSingleTax,
          taxInfo: dataFromSfsCopy
        },
        token: this._.get(dataFromSfs, 'token'),
        personShowType
      }
    },
    async addOrganization ({ organizationData, token, isMultiUser }) {
      if (isMultiUser) {
        return await Organization.api().multiAccount(organizationData, token)
      }
      return await Organization.api().putDraftOrganization(this._.get(this.$User, 'ownedOrganization.id', ''), organizationData, token)
    }
  }
}

export default organization
