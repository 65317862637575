<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ $t(title) }}
    div(class="mb-4")
      p(class="mb-0") {{ $t(text) }}
      e-link(
        v-if="!error"
        @click="openAgreement"
        class="mt-4"
      )
        e-svg-icon(class="mr-1") file-text
        span {{ $t('DepositSign Service Agreement') }}
    div(class="dialog__actions")
      v-btn(
        @click="handleClick"
        :loading="loading"
        class="d-block mx-auto"
        dark
      ) {{ $t(btnText) }}
</template>

<script>
import Base64js from 'base64-js'
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import TaxAuthService from '~/services/Tax/Auth/TaxAuthService'
import signPluginForOrganization from '~/mixins/dialogs/signPluginForOrganizationDialog'

export default {
  name: 'BlockSignDsPdf',
  components: {
    ESvgIcon,
    ELink
  },
  mixins: [signPluginForOrganization],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    depositSignRequest: {
      type: null,
      default: null
    },
    item: {
      type: null,
      default: null
    }
  },
  data: () => ({
    loading: false,
    error: false
  }),
  computed: {
    model () {
      return EmployeeOrder
    },
    title () {
      if (this.error) {
        return 'The document was not signed'
      }
      return 'Acceptance of DepositSign Terms'
    },
    text () {
      if (this.error) {
        return 'Something went wrong and the document could not be signed. Please try again'
      }
      return 'Review and confirm the DepositSign Terms of Service. After approval, we will generate and add to the system the EDS key created through our DepositSign partners to your account'
    },
    btnText () {
      if (this.error) {
        return 'Sign again'
      }
      return 'Agree and sign'
    }
  },
  methods: {
    openAgreement () {
      const link = `data:application/pdf;base64,${this._.get(this.depositSignRequest, 'pdf')}`
      const downloadLink = document.createElement('a')
      downloadLink.href = link
      downloadLink.download = 'DepositSign_Service_Agreement.pdf'
      downloadLink.click()
    },
    async handleClick () {
      if (this.error) {
        this.error = false
      } else {
        await this.signAndSend()
      }
    },
    async signAndSend () {
      try {
        this.loading = true
        await this.signPluginForOrganization.use()
        const keyData = this._.get(this.signPluginForOrganization, 'interface.keyData', null)
        const sign = this._.get(this.signPluginForOrganization, 'interface.sign')
        const edsKeyData = new EdsKeyData(keyData)

        if (!keyData) {
          return this.signPluginForOrganization.close()
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          await this.signPluginForOrganization.close()
          return this.signAndSend()
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          await this.signPluginForOrganization.close()
          return this.signAndSend()
        }

        try {
          const res = await TaxAuthService.sendAuthData(keyData, data => sign(data, { fallbackMethod: this.signAndSend }))
          if (!res) {
            return null
          }
        } catch (e) {
          this.$handlers.error(e, this, true)
        }

        const pdf = Base64js.toByteArray(this._.get(this.depositSignRequest, 'pdf', null))
        const signData = await this.signPluginForOrganization.interface.sign(pdf, { fallbackMethod: this.signAndSend })

        if (!signData) {
          this.error = true
          return null
        }

        await this.model.api().depositSignRequest(this._.get(this.depositSignRequest, 'id'), {
          signedApplicationBase64: signData
        })
        await this.model.api().read(this._.get(this.item, 'id'))
        await this.signPluginForOrganization.hide()
        this.closeModal()
      } catch (e) {
        this.error = true
        this.$handlers.error(e, this)
        await this.signPluginForOrganization.hide()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
