import { Model } from '@vuex-orm/core'
import { get, map } from 'lodash'
import Employees from '~/modules/employees/models/Employees'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import FilterAble from '~/models/mixins/FilterAble'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import Dialog from '~/models/system/Dialog'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import Outlets from '~/modules/outlets/models/Outlets'
import OutletsSearch from '~/modules/outlets/models/OutletsSearch'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'
import { formats } from '~/const/global'

export class Shifts extends ChainInheritance(Directory, [
  FilterAble,
  PersistAble,
  TimestampAble,
  AssignAble]) {
  static entity = 'shifts'
  static paginated = true
  static ormLoadWithRelations = true
  static multiSort = true
  static defaultSortParam = ['status', 'openedAtString']
  static defaultSortOrder = [true, true]
  static persistOptions = {
    insertOrUpdate: [Employees.entity, CashRegisters.entity]
  }

  static processingStatuses = {
    CREATED: 'CREATED',
    OPENING: 'OPENING',
    OPENED: 'OPENED',
    CLOSING: 'CLOSING',
    CLOSED: 'CLOSED'
  }

  static statusesValues = {
    OPENED: true,
    CLOSED: true,
    OPENING: true,
    CLOSING: true
  }

  static statusesColors = {
    OPENED: 'success',
    CLOSED: 'grey',
    OPENING: 'yellow',
    CLOSING: 'grey'
  }

  static statusesTooltips = {
    OPENED: '{value}',
    CLOSED: '{value}',
    OPENING: '{value}...',
    CLOSING: '{value}...'
  }

  get openedAtString () {
    return this.getDateTimeProcessing(this.openedAt)
  }

  get closedAtString () {
    return this.getDateTimeProcessing(this.closedAt)
  }

  static fields () {
    return {
      employee_id: this.attr(null),
      cash_register_id: this.attr(null),

      id: this.attr(null),
      openedAt: this.attr(null),
      closedAt: this.attr(null),
      serial: this.attr(null),
      status: this.attr(null),
      receiptCount: this.attr(null),
      initialTransaction: this.attr(null),
      closingTransaction: this.attr(null),
      cashier: this.belongsTo(Employees, 'employee_id'),
      cashRegister: this.belongsTo(CashRegisters, 'cash_register_id')
      // outlet: this.attr(null)
    }
  }

  static ormRelationMap = [
    // 'group',
    'employee',
    'cashRegister'
  ]

  static ormTrans = {
    single: 'Shift',
    multy: 'Shifts',
    notificationSingle: 'Shift ',
    pageTitle: 'Work shifts'
  }

  get outlet () {
    return this._.get(this.cashRegister, 'outlet', null)
  }

  get outletColumn () {
    if (this.cashRegister && this.cashRegister.isTest) {
      return 'Тестова зміна'
    } else {
      return this._.get(this.cashRegister, 'outlet.name', null)
    }
  }

  get fiscalNumber () {
    return this._.get(this.cashRegister, 'fiscalNumber', null)
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', sortable: true, filterable: true },
    { text: 'outlet', value: 'outletColumn', sortable: true, filterable: true, sortQuery: 'cashRegister.outlet.name' },
    { text: 'Address', value: 'outlet.address', sortable: false, filterable: true },
    { text: 'Cashier', value: 'cashier.name', sortable: true, filterable: true, sortQuery: 'cashier.name' },
    { text: 'Shift number', value: 'serial', sortable: true, filterable: true },
    { text: 'Cash register ', value: 'fiscalNumber', sortable: true, filterable: true },
    { text: 'Opening', value: 'openedAtString', sortable: true, sortQuery: 'openedAt' },
    { text: 'Closing', value: 'closedAtString', sortable: true, sortQuery: 'closedAt' },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormMobileTitle = 'fiscalNumber'

  static ormColsComponents = {
    status: {
      component: 'e-radio-status',
      attrs: {
        valueMap: this.statusesValues,
        colorMap: this.statusesColors,
        tooltipsMap: this.statusesTooltips
      }
    }
  }

  static ormColsComponentsMobile = {
    status: {
      component: 'e-chip-status',
      attrs: {
        colors: this.statusesColors
      }
    }
  }

  static ormRowsConfig = {
    disabled: scopedItem => 'status' in scopedItem.item ? scopedItem.item.status === 'CLOSED' : false
  }

  static ormFilters = [
    {
      model: 'organizations',
      component: 'e-input-organizations-select',
      attrs: {
        visible: (e, ctx) => ctx?.$User?.globalAllOrganizationsSelected,
        hideDetails: true
      }
    },
    {
      model: 'openedAt',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      default: () => {
        const date = new Date()
        date.setMonth(date.getMonth() - 1)
        return [this.$moment(date).format(formats.dateISO8601), this.$moment(new Date()).format(formats.dateISO8601)]
      },
      attrs: {
        closeOnClick: false,
        clearable: false,
        emmitOnlyOnSelectClicked: true,
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        label: 'Opening date',
        min: (dates) => {
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() - 2)
            return this.$moment(date).format(formats.dateISO8601)
          }
        },
        max: (dates) => {
          const today = new Date()
          const formattedToday = this.$moment(today).format(formats.dateISO8601)
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() + 2)
            if (date.getTime() > today.getTime()) {
              return formattedToday
            }
            return this.$moment(date).format(formats.dateISO8601)
          }
          return formattedToday
        }
      }
    },
    new AutocompleteBuilder({
      model: 'cashRegister',
      label: 'Cash register ',
      emmitEmptyObject: true,
      autoWidth: true,
      emitSearch: false,
      itemClass: 'ws-pre',
      hideDetails: true,
      watchProps: ['ctx.attrs.organizations'],
      onWatchProps: async (ctx, val, prevVal) => {
        if (JSON.stringify(val) !== JSON.stringify(prevVal)) {
          await ctx.requesting()
        }
      },
      customQuery: ctx => ctx?.$User?.globalAllOrganizationsSelected,
      query: (model, ctx, config, user) => {
        const filter = { 'mode[in]': `${CashRegisters.itemModes.checkbox.mode},${CashRegisters.itemModes.deactivated.mode},${CashRegisters.itemModes.alert.mode},${CashRegisters.itemModes.deactivatedByUser.mode}` }
        if (!user?.globalAllOrganizationsSelected) {
          return model.api().filter(filter)
        } else {
          let organizations = get(ctx, 'attrs.organizations')
          if (get(organizations, '[0].allOrganizationsItem')) {
            organizations = get(organizations, '[0].value')
          } else {
            organizations = map(organizations, i => i?.id)
          }
          return model.api().listMultiAccount({ organizations }, {
            ...config,
            params: {
              ...config.params,
              ...filter
            }
          })
        }
      }
    }, CashRegistersSearch).build(),
    new AutocompleteBuilder({
      model: 'cashRegister.outlet',
      label: 'Outlet  ',
      emmitEmptyObject: true,
      autoWidth: true,
      emitSearch: false,
      hideDetails: true,
      watchProps: ['ctx.attrs.organizations'],
      onWatchProps: async (ctx, val, prevVal) => {
        if (JSON.stringify(val) !== JSON.stringify(prevVal)) {
          await ctx.requesting()
        }
      },
      customQuery: ctx => ctx?.$User?.globalAllOrganizationsSelected,
      query: (model, ctx, config, user) => {
        const filter = { mode: Outlets.itemModes.checkbox.mode }
        if (!user?.globalAllOrganizationsSelected) {
          return model.api().filter(filter)
        } else {
          let organizations = get(ctx, 'attrs.organizations')
          if (get(organizations, '[0].allOrganizationsItem')) {
            organizations = get(organizations, '[0].value')
          } else {
            organizations = map(organizations, i => i?.id)
          }
          return model.api().listMultiAccount({ organizations }, {
            ...config,
            params: {
              ...config.params,
              ...filter
            }
          })
        }
      }
    }, OutletsSearch).build(),
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        hideDetails: true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify',
        visible: (e, ctx) => !ctx.isResponsive
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersMobile = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        hideDetails: true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: (user) => {
      const isMultiAccount = user?.globalAllOrganizationsSelected
      const grid = [
        {
          component: 'v-row',
          attrs: {
            justify: isMultiAccount ? 'end' : 'start'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: isMultiAccount ? 4 : 3,
                sm: 6
              },
              fields: [
                'openedAt'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: isMultiAccount ? 4 : 3,
                sm: 6
              },
              fields: [
                'cashRegister'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: isMultiAccount ? 4 : 3,
                sm: 6
              },
              fields: [
                'cashRegister.outlet'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: isMultiAccount ? 4 : 3,
                sm: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
      if (isMultiAccount) {
        grid[0].nodes = [
          {
            component: 'v-col',
            attrs: {
              cols: 12,
              md: isMultiAccount ? 4 : 3,
              sm: 6
            },
            fields: [
              'organizations'
            ]
          },
          ...grid[0].nodes
        ]
      }
      return { grid }
    }
  }

  static ormFields = [
    //
  ]

  static ormActions = [
    {
      name: 'closeShift',
      text: 'Close shift',
      visible: (item, ctx) => item.status === Shifts.processingStatuses.OPENED && !ctx.$User.isAccountant,
      loading: true,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        const zReport = await dialog.open({
          component: 'm-block-shift-close',
          width: '500px',
          componentProps: {
            shift: item,
            type: 'current_cashier'
          }
        })
        if (zReport) {
          await dialog.open({
            title: 'Z-report',
            component: 'block-report-z-view',
            width: '460px',
            componentProps: {
              preview: zReport
            }
          })
        }
      }
    },
    {
      name: 'closeShiftByHead',
      text: 'Close shift by head cashier',
      visible: (item, ctx) => item.status === Shifts.processingStatuses.OPENED && !ctx.$User.isAccountant,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        const zReport = await dialog.open({
          component: 'm-block-shift-close',
          width: '600px',
          componentProps: {
            shift: item,
            type: 'head_cashier'
          }
        })
        if (zReport) {
          await dialog.open({
            title: 'Z-report',
            component: 'block-report-z-view',
            width: '460px',
            componentProps: {
              preview: zReport
            }
          })
        }
      }
    },
    {
      name: 'openReceiptView',
      text: 'View z-report',
      isPermitted: (item) => {
        return (item.status === Shifts.processingStatuses.CLOSED)
      }
    }
  ];

  static ormDialogs = {
    openReceiptView: 'm-orm-receipt-view-dialog'
  };

  static ormDialogsConfig = {
    openReceiptView: {
      config: {
        disableQr: true,
        width: '460px',
        view: 'text',
        download: 'text',
        downloadPrefix: 'z-report',
        type: 'z-report'
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.processingCreateCurrent = function (authenticatedCashier) {
        return this.post(Model.$routes.shifts.processingCurrent(), { cashRegister: authenticatedCashier.cashRegister.id }, { headers: { 'X-Processing-Authorization': authenticatedCashier.accessToken }, save: false })
      }
      configActions.processingCloseCurrent = function (token, skipClientNameCheck) {
        if (skipClientNameCheck) {
          return this.put(Model.$routes.shifts.processingCloseCurrent(), { skip_client_name_check: true }, { headers: { 'X-Processing-Authorization': token }, save: false })
        } else {
          return this.put(Model.$routes.shifts.processingCloseCurrent(), {}, { headers: { 'X-Processing-Authorization': token }, save: false })
        }
      }
      configActions.processingCloseById = function (token, cashRegister, id, skipClientNameCheck) {
        if (skipClientNameCheck) {
          return this.post(Model.$routes.shifts.processingCloseById(id), { cashRegister, skip_client_name_check: true }, { headers: { 'X-Processing-Authorization': token }, save: false })
        } else {
          return this.post(Model.$routes.shifts.processingCloseById(id), { cashRegister }, { headers: { 'X-Processing-Authorization': token }, save: false })
        }
      }
      configActions.processingReadCurrent = function (authenticatedCashier) {
        return this.get(Model.$routes.shifts.processingCurrent(), { headers: { 'X-Processing-Authorization': authenticatedCashier.accessToken }, save: false })
      }
      configActions.processingRead = function (shiftId, token) {
        return this.get(Model.$routes.shifts.processingGetShift(shiftId), { headers: { 'X-Processing-Authorization': token }, save: false })
      }
      // workaround for backward capabilities
      configActions.processingReadText = function (id) {
        return this.get(Model.$routes.shifts.readZReport(id), {
          save: false
        })
      }
      configActions.readText = function (id) {
        return this.get(Model.$routes.shifts.readZReport(id), {
          save: false
        })
      }
      configActions.listMultiAccount = function (payload, options = {}) {
        return this.post(Model.$routes.shifts.listMultiAccount(), payload, { dataKey: 'data', ...options })
      }
      return configActions
    }
  }
}

export default Shifts
