<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title")
      v-btn(
        class="back-arrow"
        @click="$emit('back')"
        icon
      )
        e-svg-icon(size="sm") arrow-next
      | Взяття на облік в податковій
    ValidationObserver(
      ref="form"
      slim
    )
      v-form(@submit.prevent.stop="nextStep")
        div(class="dialog__content")
          div(
            v-if="hasSeparatePlace"
            class="mb-6"
          )
            p(class="dialog__text mb-3") Податкова, що обслуговує район, в якому знаходиться торгова точка відрізняється від тієї у якому зареєстровано ваш ФОП
            p(class="dialog__text") Тому вам потрібно підтвердити взяття на облік за місцем розташування торгової точки
          div(
            class="mb-6"
            v-else
          )
            t-orm-label(:item="{ label: { text: 'Адреса реєстрації ФОП' } }" class="mb-3")
            div(
              v-if="regAddressLoading"
              class="dialog__loader"
            )
              e-progress-circular(
                size="sm"
                width="1.5"
              )
            p(
              class="dialog__text dialog__text--bold"
              v-else
            ) {{ shownRegAddress }}
          t-orm-fields(
            v-bind.sync="$attrs.value"
            v-on="$listeners"
            :items="fields"
          )
        div(class="dialog__actions")
          v-btn(
            type="submit"
            class="main-button"
            :loading="isLoading"
          ) {{ $t('Confirm') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields.vue'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import TaxCodesDictionary from '~/models/directories/dictionaries/TaxCodesDictionary'
import KatottgDictionary from '~/models/directories/dictionaries/KatottgDictionary'
import Organization from '~/models/directories/Organization'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import EProgressCircular from '~/components/elements/progress/e-progress-circular.vue'

export default {
  components: {
    EProgressCircular,
    TOrmFields,
    ESvgIcon,
    TOrmLabel
  },
  props: {
    hasSeparatePlace: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    regAddressLoading: false,
    localLoading: false,
    shownRegAddress: null
  }),
  computed: {
    isLoading () {
      return this.loading || this.localLoading || this.regAddressLoading
    },
    fields () {
      return [
        {
          model: 'HSTI',
          label: {
            text: 'Tax service of the point of sale'
          },
          component: 'e-dialog-input',
          tooltip: 'Tax code',
          provider: {
            vid: 'HSTI',
            name: 'Tax service of the point of sale',
            rules: 'required'
          },
          requestParams: ['$User'],
          asyncDefault: (items, response) => {
            return response
          },
          request: async () => {
            this.localLoading = true
            const region = this._.get(this.$Organization, 'region')
            const district = this._.get(this.$Organization, 'district')
            const taxRegion = this._.get(await TaxCodesDictionary.api().exists(['parent'], false).filter({ cReg: region }).all(), 'entities.taxcodesdictionary[0]', null)
            if (taxRegion) {
              this.localLoading = false
              return this._.get(await TaxCodesDictionary.api().parent({ parent: taxRegion }).filter({ cRaj: district }).all(), 'entities.taxcodesdictionary[0]', null)
            }
            this.localLoading = false
            return null
          },
          cols: {
            cols: 12
          },
          attrs: {
            valuePattern: '{nameSti}',
            requestItems: 'onlyRootLevel',
            modalAttrs: {
              component: 'm-orm-dialog-tree-select',
              title: 'Select dpi',
              model: TaxCodesDictionary
            },
            canChange: this.hasSeparatePlace
          }
        }
      ]
    }
  },
  created () {
    if (!this.hasSeparatePlace) {
      this.getRegAddress()
    }
  },
  methods: {
    async getRegAddress () {
      try {
        this.regAddressLoading = true
        const katottg = this._.get(this.$Organization, 'regKatottg')
        if (katottg) {
          const address = this._.get(await KatottgDictionary.api().getAddress(katottg), 'response.data.address')
          const street = this._.get(await Organization.api().getRegStreet(this._.get(this.$Organization, 'id')), 'response.data.regStreet')
          let shownAddress = address
          if (street) {
            shownAddress = address ? `${address}, ${street}` : street
          }
          this.shownRegAddress = shownAddress || '–'

          this.$emit('propertyChange', {
            key: 'T1RXXXXG8S',
            value: street
          })
          this.$emit('propertyChange', {
            key: 'T1RXXXXG6S',
            value: katottg
          })
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.regAddressLoading = false
      }
    },
    async nextStep () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      this.$emit('nextStep', {
        hasSeparatePlace: this.hasSeparatePlace,
        showTaxAddressForm: true,
        send: true
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__content {
    background: rgba(0, 0, 0, 0.03);
    padding: 24px 24px 7px;
    border-radius: 24px;
  }

  &__loader {
    width: 42%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 32px 0 20px;
  }

  &__text {
    color: #7C7C7C;
    font-size: 14px;
    font-weight: 300;
    margin: 0;

    &--bold {
      font-weight: 700;
      color: #000;
    }
  }
}

.back-arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}
</style>
