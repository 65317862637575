<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    v-on="listeners"
    :max-width="width"
    content-class="receipt-view-dialog"
  )
    template(#content)
      iframe(
        ref="receiptDoc"
        class="d-none"
      )
      div(:class="{ 'receipt-view-dialog__frame': true, 'receipt-view-dialog__frame--html': viewDataType === 'html' }")
        v-overlay(
          :value="viewLoading"
          absolute
        )
          e-progress-circular
        div(
          v-dompurify-html="view"
          v-if="viewDataType === 'html' && !notFound"
        )
        pre(v-if="viewDataType === 'text' && !notFound") {{ view }}
        div(
          v-if="notFound"
          class="receipt-view-dialog__text"
        )  {{ $t('Requested preview not exist. Please contact with manager.') }}
    template(#actions)
      v-row(v-if="view")
        v-col(cols="12")
          div(class="receipt__buttons")
            e-link(@click="print" :loading="printLoading")
              e-svg-icon(class="mr-2") printer-3
              | {{ $t('Print') }}
            e-link(v-if="canSendSms" @click="sendToSms")
              e-svg-icon(class="mr-2") receipt-sms
              | {{ $t('Send to sms') }}
            e-link(v-if="isReceipt" @click="sendToEmail")
              e-svg-icon(class="mr-2") receipt-email
              | {{ $t('Sent to email') }}
            e-link(v-if="isReceipt" @click="copyToClipboard")
              e-svg-icon(class="mr-2") receipt-link
              | {{ $t('Copy link') }}
            e-link(@click="download" :loading="downloadLoading")
              e-svg-icon(class="mr-2") receipt-download
              | {{ $t('Download') }}
            e-link(v-if="isSellOrReturnReceipt" @click="openReceiptInDps")
              e-svg-icon(class="mr-2") read-2
              | {{ $t('View in DPS') }}
</template>

<script>
import Download from 'js-file-download'
import TDialog from '~/components/templates/t-dialog'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import converters from '~/mixins/methods/converters'
import clipboard from '~/mixins/global/_clipboard'
import ELink from '~/components/elements/links/e-link'
import Employees from '~/modules/employees/models/Employees'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import Receipts from '~/modules/receipt/models/Receipts'
import AppNotifications from '~/services/Notifications/AppNotifications'
import SmsService from '~/services/Receipt/SmsService'
import contentDialog from '~/mixins/dialogs/contentDialog'

const extensionsMap = {
  html: 'html',
  pdf: 'pdf',
  text: 'txt'
}
export default {
  components: {
    EProgressCircular,
    TDialog,
    TOrmButtons,
    TOrmFields,
    ESvgIcon,
    ELink
  },
  mixins: [converters, clipboard, contentDialog],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    item: null,
    downloadLoading: false,
    printLoading: false,
    viewLoading: false,
    view: null,
    downloaded: null,
    notFound: false,
    imgUrl: ''
  }),
  computed: {
    ormModel () {
      return this._.get(this.dialogConfig, 'config.model', null) || this.model
    },
    isReceipt () {
      return this._.get(this.dialogConfig, 'config.type', '') === 'receipt'
    },
    isSellOrReturnReceipt () {
      const receiptType = this._.get(this.item, 'type')
      return this.isReceipt && (receiptType === Receipts.TYPES.SELL || receiptType === Receipts.TYPES.RETURN)
    },
    disableQr () {
      return !!this.dialogConfig.config.disableQr
    },
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.type)
    },
    width () {
      return this.dialogConfig.config.width || '470px'
    },
    downloadedFileName () {
      const name = this.dialogConfig.config.downloadNameField
        ? this._.get(this.item, this.dialogConfig.config.downloadNameField) + `_${this.dialogConfig.config.downloadPrefix}`
        : Date.now() + `_${this.dialogConfig.config.downloadPrefix}`
      const ext = extensionsMap[this.downloadDataType]
      return name + '.' + ext
    },
    viewDataType () {
      return this.dialogConfig.config.view
    },
    downloadDataType () {
      return this.dialogConfig.config.download
    },
    downloadAction () {
      return this.ormModel.api()['processingRead' + this._.upperFirst(this.downloadDataType)]
    },
    viewAction () {
      return this.ormModel.api()['processingRead' + this._.upperFirst(this.viewDataType)]
    },
    receiptId () {
      const requestField = this._.get(this.dialogConfig, 'config.requestField', null)
      return requestField ? this.item[requestField] : this.item.id
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    ormButtons () {
      return [
        {
          text: 'Download',
          attrs: {
            color: 'primary',
            text: true,
            depressed: true,
            class: ['mr-2']
          },
          call: this.download
        }
      ]
    },
    canSendSms () {
      return Boolean(this.isReceipt && this.$Organization.enableSmsModule)
    }
  },
  methods: {
    genHexString (len) {
      const hex = '0123456789abcdef'
      let output = ''
      for (let i = 0; i < len; ++i) {
        output += hex.charAt(Math.floor(Math.random() * hex.length))
      }
      return output
    },
    openReceiptInDps () {
      const id = this._.get(this.item, 'fiscalCode')
      const sm = (this._.get(this.item, 'totalSum', 0) / 100).toFixed(2)
      const fn = this._.get(this.item, 'cashRegister.fiscalNumber')
      const mac = this.genHexString(43)
      const fiscalDateArr = this._.get(this.item, 'fiscalDate', '').split('T')
      const date = this._.get(fiscalDateArr, '[0]', '').replaceAll('-', '')
      const timeArr = this._.get(fiscalDateArr, '[1]', '').split('+')
      const offset = parseInt(this._.get(timeArr, '[1]', ''))
      let time = this._.get(timeArr, '[0]', '').split(':')
      time[0] = parseInt(time[0]) + offset
      time = encodeURIComponent(time.join(':'))
      const queryParams = `id=${id}&date=${date}&time=${time}&fn=${fn}&sm=${sm}&mac=${mac}`
      const link = `https://cabinet.tax.gov.ua/cashregs/check?${queryParams}`
      window.open(link, '_blank')
    },
    downloadRequest (itemId) {
      return this.downloadAction(itemId)
    },
    viewRequest (itemId) {
      return this.viewAction(itemId)
    },
    async download () {
      try {
        this.downloadLoading = true
        const data = this._.get(await this.downloadRequest(this.receiptId), 'response.data', '')
        Download(data, this.downloadedFileName)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.downloadLoading = false
      }
    },
    async print () {
      if (this.isReceipt) {
        await this.printImg()
      } else {
        await this.printText()
      }
    },
    async printText () {
      try {
        this.printLoading = true
        const res = this._.get(await this.ormModel.api().processingReadText(this.receiptId), 'response.data', '')
        const cw = this.$refs.receiptDoc.contentWindow

        cw.document.open()
        cw.document.write('<pre>')
        cw.document.write(res)
        cw.document.write('</pre>')

        if (!this.disableQr) {
          const qrCode = this._.get(await this.ormModel.api().processingReadQrCode(this.receiptId), 'response.data', '')
          const qrCodeUrl = await this.blobToBase64(qrCode)
          const wrapperWidth = this._.get(this.$Organization, 'receiptSetting.width', 32) * 8
          cw.document.write('<div style="width:' + wrapperWidth + 'px; text-align: center; margin: 0; padding: 0;">')
          cw.document.write(`<img src="${qrCodeUrl}" style="width: 100%; max-width: 250px; display: block; margin: 0 auto;">`)
          cw.document.write('</div>')
        }

        cw.document.close()
        setTimeout(() => {
          cw.print()
        })
      } catch (e) {
        let err = e
        if (this._.get(e, 'response.status') === 404) {
          err = this.$t('No text representation for check printing, please try again later or contact technical support')
        }
        this.$handlers.error(err, this)
      } finally {
        this.printLoading = false
      }
    },
    async printImg () {
      try {
        this.printLoading = true
        const res = this._.get(await this.ormModel.api().processingReadPng(this.receiptId), 'response.data', '')
        const receiptUrl = await this.blobToBase64(res)
        const cw = this.$refs.receiptDoc.contentWindow
        const paperWidth = this._.get(this.$Organization, 'receiptSetting.paperWidth') || 58
        const imgWidth = (paperWidth * 3.78).toFixed(2) + 'px'
        cw.document.open()
        cw.document.write(`<img src="${receiptUrl}" alt="Receipt" style="width: ${imgWidth};">`)
        cw.document.close()
        setTimeout(() => {
          cw.print()
        })
      } catch (e) {
        let err = e
        if (this._.get(e, 'response.status') === 404) {
          err = this.$t('No check view was found, please try again later or contact technical support')
        }
        this.$handlers.error(err, this)
      } finally {
        this.printLoading = false
      }
    },
    clear () {
      this.view = ''
      this.notFound = false
      this.viewLoading = false
      this.downloadLoading = false
    },
    async fill (item) {
      this.item = item
      try {
        this.viewLoading = true
        this.view = this._.get(await this.viewRequest(this.receiptId), 'response.data', '')
      } catch (e) {
        if (e.response && e.response.status === 404) {
          this.notFound = true
        } else {
          this.$handlers.error(e, this)
        }
      } finally {
        this.viewLoading = false
      }
    },
    copyToClipboard () {
      try {
        const id = this.receiptId
        this.copy('https://check.checkbox.ua/' + id)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    sendToEmail () {
      this.contentDialog.open({
        title: 'Sent to email',
        width: '500px',
        component: 'm-form-block',
        componentProps: {
          buttonText: 'Send ',
          fields: [
            {
              model: 'email',
              component: 'v-text-field',
              provider: {
                vid: 'email',
                name: 'E-mail',
                rules: 'required|email'
              },
              attrs: {
                outlined: true,
                type: 'email',
                placeholder: 'E-mail'
              },
              cast: val => val && val.toLowerCase()
            }
          ],
          onSubmit: async (data) => {
            try {
              await Promise.all([
                Employees.api().filter({ mode: Employees.itemModes.checkbox.mode, limit: 1 }).all(),
                CashRegisters.api().filter({ mode: CashRegisters.itemModes.checkbox.mode, limit: 1 }).all()
              ])
              const employee = Employees.query().where('mode', Employees.itemModes.checkbox.mode).first()
              const token = this._.get(await Employees.api().processingSignin(employee), 'response.data.access_token')
              await Receipts.api().sendToEmail([data.email], this.receiptId, token)
              AppNotifications.success('The check was successfully sent')
              this.contentDialog.close()
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        }
      })
    },
    async sendToSms () {
      await SmsService.openSmsModal(this.receiptId)
    }
  }
}
</script>

<style lang="scss">
.receipt-view-dialog {
  margin: 0 15px;

  &__frame {
    min-height: 200px;

    &--html {
      max-width: 290px;

      @media (min-width: map-get($breakpoints, 'sm')) {
        min-width: 370px;
      }
    }
  }

  &__text {
    text-align: center;
    margin-top: 50px;
  }

  .e-link {
    font-size: 12px !important;
  }

  .v-card {
    padding-top: 30px;

    .v-card {
      &__actions {
        padding: 0;
      }
    }
  }
}
</style>
