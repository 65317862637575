<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title")
      v-btn(
        class="back-arrow"
        @click="$emit('back')"
        icon
      )
        e-svg-icon(size="sm") arrow-next
      | Як обрати тип каси
    div(class="dialog__content")
      ul
        li
          h4(class="dialog__list-title") Стаціонарна
          p(class="dialog__text") Підходить тим, у кого бізнес завжди розташовано на одному місці, як кав’ярня чи магазин
        li
          h4(class="dialog__list-title") Інтернет-каса
          p(class="dialog__text") Для всіх, хто продає товари чи послуги онлайн
        li
          h4(class="dialog__list-title") Каса самообслуговування
          p(class="dialog__text") Використовують її у великих супермаркетах, ресторанах швидкого харчування або на точках самообслуговування
        li
          h4(class="dialog__list-title") Переносна
          p(class="dialog__text") Реєструється на конкретну адресу, але використовувати можна по всій країні. Підходять для доставок, мобільних кав’ярень тощо
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon.vue'

export default {
  name: 'BlockCashRegisterTypeInstructions',
  components: {
    ESvgIcon
  }
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 0 0 20px;

  &__content {
    background: rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    padding: 24px;
  }

  ul {
    color: #000;

    li:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__list-title {
    font-size: 14px;
    color: #000;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__text {
    font-size: 12px;
    color: rgba(124, 124, 124, 1);
    font-weight: 300;
    margin: 0;
  }
}

.back-arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}
</style>
