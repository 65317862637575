<template lang="pug">
  ValidationObserver(ref="form" slim)
    v-form(@submit.prevent.stop="submit")
      v-row
        v-col(cols="12")
          t-orm-fields(v-bind.sync="editedItem" :items="fields")
        v-col(cols="12" v-if="!hideButton" :class="buttonsContainerClasses")
          v-btn(class="secondary-button color-error mb-2 mr-4" :class="cancelButtonClasses" @click="cancel" :loading="loading" v-if="cancelBtn") {{ $t(cancelBtnText) }}
          v-btn(class="main-button mb-2" :class="buttonClasses" :loading="loading" type="submit") {{ $t(buttonText) }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'

export default {
  components: {
    TOrmFields
  },
  props: {
    context: {
      type: String,
      default: 'create',
      validator: (value) => {
        return ['create', 'update'].includes(value)
      }
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    buttonText: {
      type: String,
      default: 'Download'
    },
    cancelBtnText: {
      type: String,
      default: 'Cancel'
    },
    successMessage: {
      type: String,
      default: ''
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    cancelBtn: {
      type: Boolean,
      default: false
    },
    item: {
      type: null,
      default: null
    },
    buttonsContainerClasses: {
      type: Array,
      default: () => ['text-right']
    },
    buttonClasses: {
      type: Array,
      default: () => []
    },
    cancelButtonClasses: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    editedItem: {},
    loading: false
  }),
  created () {
    this._.forEach(this.fields, (field) => {
      if (field.model) {
        this.editedItem[field.model] = (field.default && this._.isFunction(field.default) ? field.default(this.context, this.item) : field.default) || null
      }
    })
  },
  methods: {
    async cancel () {
      if (this._.isFunction(this.onCancel)) {
        await this.onCancel(this.editedItem, this)
      }
      this._.forEach(this.editedItem, field => this.$set(this.editedItem, field, null))
    },
    async submit () {
      const valid = await this.$refs.form.validate()

      if (valid && typeof this.onSubmit === 'function') {
        try {
          this.loading = true
          await this.onSubmit(this.editedItem, this)

          if (this.successMessage) {
            this.$notification.success(this.$t(this.successMessage))
          }
        } catch (e) {
          this.$handlers.error(e, this.$refs.form)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
