import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class ABank extends PersistAble(OrmModel) {
  static entity = 'abank'
  static paginated = false
  static defaultSortOrder = true

  static CLIENT_STATUSES = {
    notClient: 'NOT_BANK_CLIENT',
    redPhysClient: 'RED_PHYS_BANK_CLIENT',
    greenPhysClient: 'GREEN_PHYS_BANK_CLIENT',
    fopWithAccount: 'FOP_WITH_BANK_ACCOUNT',
    fopWithAcquiring: 'FOP_WITH_ACQUIRING_BANK_CLIENT'
  }

  static fields () {
    return {
      id: this.uid(),
      clientType: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getClientStatus = function () {
        return this.get(Model.$routes.abank.getClientStatus(), {
          save: false
        })
      }
      return configActions
    }
  }
}

export default ABank
