import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'

import helpers from '~/plugins/vuex-orm/plugins/helpers'
import ormTable from '~/plugins/vuex-orm/plugins/orm-table'
import queries from '~/plugins/vuex-orm/plugins/queries'
import VuexORMAxiosConfig from '~/plugins/vuex-orm/plugins/vuex-orm-axios-config'

import { actions } from '~/const/global'

// TODO: create automatic import of models.
import Organization from '~/models/directories/Organization'
import User from '~/models/administration/User'
import Role from '~/models/administration/Role'
import Payment from '~/modules/payments/models/Payment'
import Permission from '~/models/auth/Permission'
import Group from '~/models/auth/Group'
import MediaObject from '~/models/system/MediaObject'
import Outlets from '~/modules/outlets/models/Outlets'
import Employees from '~/modules/employees/models/Employees'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'
import OutletsSearch from '~/modules/outlets/models/OutletsSearch'
import Uktzeds from '~/models/directories/Uktzed'
import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import Taxes from '~/modules/tax-rates/models/Taxes'
import Shifts from '~/models/directories/Shifts'
import Receipts from '~/modules/receipt/models/Receipts'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import TaxTypeOutlets from '~/models/classifiers/TaxTypeOutlets'
import UktzedDictionary from '~/models/directories/dictionaries/UktzedDictionary'
import KoatuuDictionary from '~/models/directories/dictionaries/KoatuuDictionary'
import TaxCodesDictionary from '~/models/directories/dictionaries/TaxCodesDictionary'
import AuthenticatedCashier from '~/modules/receipt/models/AuthenticatedCashier'
import Reports from '~/modules/reports/models/Reports'
import Invoices from '~/models/billing/Invoices'
import Billings from '~/models/billing/Billings'
import Dialog from '~/models/system/Dialog'
import OrganizationManagers from '~/models/directories/pivot/OrganizationManagers'
import ProcessingTransactions from '~/models/Processing/ProcessingTransactions'
import TaxBills from '~/models/billing/TaxBills'
import InvoicesSearch from '~/models/billing/search/InvoicesSearch'
import ActsSearch from '~/models/billing/search/ActsSearch'
import Acts from '~/models/billing/Acts'
import PromoCodes from '~/models/billing/PromoCodes'
import AuthToken from '~/modules/auth/Models/AuthToken'
import CurrentUser from '~/modules/auth/Models/CurrentUser'
import CashRegisterOrder from '~/modules/cashRegisters/models/CashRegisterOrder'
import ReceiptPaymentMethods from '~/modules/receipt/models/ReceiptPaymentMethods'
import Tags from '~/models/directories/Tags'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import OutletOrder from '~/modules/outlets/models/OutletOrder'
import PromoCodeOrganization from '~/models/billing/PromoCodeOrganizations'
import AdTag from '~/modules/advertising/models/AdTag'
import Referrals from '~/modules/referrals/models/Referrals'
import GoodsGroupsSearch from '~/modules/goods/models/GoodsGroupsSearch'
import GoodsGroups from '~/modules/goods/models/GoodsGroups'
import SuppliersSearch from '~/modules/goods/models/SuppliersSearch'
import Suppliers from '~/modules/goods/models/Suppliers'
import Supplies from '~/modules/goods/models/Supplies'
import AcquiringInvoices from '~/modules/acquiring/models/AcquiringInvoices'
import EttnOrders from '~/modules/ettn/models/EttnOrders'
import AccountingGoodsSearch from '~/modules/goods/models/AccountingGoodsSearch'
import CurrentOrganization from '~/modules/auth/Models/CurrentOrganization'
import KatottgDictionary from '~/models/directories/dictionaries/KatottgDictionary'
import Sidebar from '~/modules/sidebar/models/Sidebar'
import FinancialReport from '~/modules/reports/models/FinancialReport'
import Notifications from '~/modules/notifications/models/Notifications'
import OrganizationSearch from '~/models/directories/search/OrganizationSearch'
import TransferRequests from '~/modules/payments/models/TransferRequests'
import WeAreWorking from '~/modules/we-are-working/models/WeAreWorking'
import TaxReports from '~/modules/reports/models/TaxReports'
import TaxInfo from '~/modules/reports/models/TaxInfo'
import SmsAct from '~/models/sms/SmsAct'
import SmsBilling from '~/models/sms/SmsBilling'
import SmsInvoice from '~/models/sms/SmsInvoice'
import AccountingGoodsSettings from '~/modules/goods/models/AccountingGoodsSettings'
import TurnoverSettings from '~/modules/turnover-settings/models/TurnoverSettings'
import Reviews from '~/modules/reviews/models/Reviews'
import OfflineStatistic from '~/modules/dashboard/models/OfflineStatistic'
import Restorations from '~/models/directories/Restorations'
import AdminAuthLog from '~/models/directories/AdminAuthLog'
import ManagerUsers from '~/models/administration/ManagerUsers'
import UserManagerCashRegisters from '~/models/directories/pivot/UserManagerCashRegisters'
import TagsSearch from '~/models/directories/search/TagsSearch'
import Acquiring from '~/modules/acquiring/models/Acquiring'
import Providers from '~/modules/acquiring/models/Providers'
import ProvidersSearch from '~/modules/acquiring/models/ProvidersSearch'
import Merchants from '~/modules/acquiring/models/Merchants'
import PosTerminals from '~/modules/acquiring/models/PosTerminals'
import AccountOrder from '~/modules/acquiring/models/AccountOrder'
import AcquiringOrder from '~/modules/acquiring/models/AcquiringOrder'
import ABank from '~/modules/acquiring/models/ABank'
import MccCodesSearch from '~/models/directories/search/MccCodesSearch'
import ClientBalances from '~/models/billing/ClientBalances'
import Oschadbank from '~/modules/acquiring/models/Oschadbank'
import Refunds from '~/modules/refunds/models/Refunds'
import LabelsSearch from '~/modules/goods/models/LabelsSearch'
import Labels from '~/models/directories/Labels'
import OfflineStatus from '~/modules/offline-status/models/OfflineStatus'
import MonobankAuth from '~/models/auth/MonobankAuth'
import AcquiringMono from '~/modules/acquiring/models/AcquiringMono'
import AcquiringSettings from '~/modules/acquiring/models/AcquiringSettings'

export const models = {
  CashRegistersSearch,
  OutletsSearch,
  EmployeesSearch,
  AccountingGoodsSearch,
  Taxes,
  Uktzeds,
  User,
  Role,
  Payments: Payment,
  Permission,
  Group,
  MediaObject,
  Organization,
  Outlets,
  Employees,
  CashRegisters,
  Shifts,
  Receipts,
  AccountingGoods,
  TaxTypeOutlets,
  UktzedDictionary,
  KoatuuDictionary,
  TaxCodesDictionary,
  AuthenticatedCashier,
  Reports,
  Payment: Invoices,
  Billings,
  Dialog,
  OrganizationManagers,
  ProcessingTransactions,
  TaxBills,
  InvoicesSearch,
  ActsSearch,
  Acts,
  PromoCodes,
  AuthToken,
  CurrentUser,
  CashRegisterOrder,
  ReceiptPaymentMethods,
  Tags,
  EmployeeOrder,
  OutletOrder,
  PromoCodeOrganization,
  AdTag,
  Referrals,
  GoodsGroupsSearch,
  GoodsGroups,
  SuppliersSearch,
  Suppliers,
  Supplies,
  AcquiringInvoices,
  EttnOrders,
  CurrentOrganization,
  KatottgDictionary,
  Sidebar,
  FinancialReport,
  Notifications,
  OrganizationSearch,
  TransferRequests,
  WeAreWorking,
  TaxReports,
  TaxInfo,
  SmsAct,
  SmsBilling,
  SmsInvoice,
  AccountingGoodsSettings,
  TurnoverSettings,
  Reviews,
  OfflineStatistic,
  Restorations,
  ManagerUsers,
  UserManagerCashRegisters,
  AdminAuthLog,
  TagsSearch,
  Acquiring,
  Providers,
  ProvidersSearch,
  Merchants,
  PosTerminals,
  AccountOrder,
  AcquiringOrder,
  ABank,
  MccCodesSearch,
  ClientBalances,
  Oschadbank,
  Refunds,
  LabelsSearch,
  Labels,
  OfflineStatus,
  MonobankAuth,
  AcquiringMono,
  AcquiringSettings
}

export const mapping = {
  User,
  Role,
  Permission,
  Group,
  MediaObject,
  Organization,
  Outlets,
  Tax: Taxes,
  Employee: Employees,
  CashRegister: CashRegisters,
  Shift: Shifts,
  Receipt: Receipts,
  AccountingGoods,
  Invoices,
  Payment,
  Billings,
  Acts,
  PromoCodes,
  Tags,
  AcquiringInvoices,
  Acquiring
}

function forEach (callback) {
  for (const index in models) {
    const concrete = models[index]

    callback(concrete)
  }
}

export function mapAbilities (rules, config, mock = true) {
  if (mock) {
    return mockAbilities(config)
  }

  return rules
    ? rules.map((item) => {
      if (!(item.subject in mapping)) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(['You need create mapping for:', item.subject].join(' ').trim(), 'entity!')
        }

        return {}
      }

      return Object.assign({}, item, { subject: mapping[item.subject][config.modelKey] })
    })
    : []
}

export function mockAbilities (config) {
  const abilities = []

  forEach((model) => {
    abilities.push({ actions: Object.values(actions), subject: model[config.modelKey] })
  })

  return abilities
}

export function vuexORMPlugin (store) {
  VuexORM.use(VuexORMAxios) // <- No axios option.
  VuexORM.use(VuexORMAxiosConfig)
  VuexORM.use(helpers)
  VuexORM.use(ormTable)
  VuexORM.use(queries)

  const database = new VuexORM.Database()

  forEach(model => database.register(model))

  return VuexORM.install(database)
}
