<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('Add outlet') }}
    ValidationObserver(
      ref="form"
      slim
    )
      v-form(@submit.prevent.stop="nextStep")
        t-orm-fields(
          v-bind.sync="$attrs.value"
          v-on="$listeners"
          :items="fields"
        )
        e-input-switch-tooltip(
          v-model="separatePlace"
          label="There is a separate place for business"
          tooltip="Separate place hint"
        )
        div(class="dialog__actions")
          v-btn(
            type="submit"
            class="main-button"
          ) {{ $t('Continue') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields.vue'
import OutletOrder from '~/modules/outlets/models/OutletOrder'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import TaxTypeOutlets from '~/models/classifiers/TaxTypeOutlets'
import EInputSwitchTooltip from '~/components/elements/inputs/e-input-switch-tooltip'

export default {
  components: {
    EInputSwitchTooltip,
    TOrmFields
  },
  props: {
    hasSeparatePlace: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    separatePlace: true
  }),
  computed: {
    model () {
      return OutletOrder
    },
    fields () {
      return [
        new AutocompleteBuilder({
          model: 'T1RXXXXG3',
          label: 'Type of activity',
          rules: 'required',
          query: model => model.api().rangeFilter({ position: [null, 1001] }),
          loadMoreQuery: model => model.api(),
          returnObject: false,
          fieldVal: ctx => this._.get(ctx.attrs, ctx.field.model)
        }, TaxTypeOutlets).build(),
        {
          model: 'T1RXXXXG4S',
          component: 'v-text-field',
          provider: {
            vid: 'T1RXXXXG4S',
            name: 'Business name',
            rules: 'required|max:50'
          },
          attrs: {
            label: 'Business name',
            outlined: true
          }
        }
      ]
    }
  },
  created () {
    this.separatePlace = this.hasSeparatePlace
  },
  methods: {
    async nextStep () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }

      this.$emit('nextStep', {
        showTaxAddressForm: !this.separatePlace,
        showAddressForm: this.separatePlace,
        hasSeparatePlace: this.separatePlace
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0;
  }
}
</style>
