<template lang="pug">
  div(class="tap-to-phone-upload-documents")
    div(class="tap-to-phone-upload-documents__content")
      block-tap-to-phone-upload-documents-item(
        v-for="(item, idx) in uploadItems"
        :key="idx"
        :item="item"
        :ref="item.name"
        v-bind.sync="files"
        class="py-5"
      )
        template(#subtitle)
          template(v-if="item.key === 'survey'")
            v-btn(
              class="tap-to-phone-upload-documents__item-link"
              :loading="downloadLoading"
              @click="downloadSurvey"
            ) {{ $t('Download survey') }}
          template(v-else-if="item.key === 'passport'")
            span {{ $t('What is confirmed by the authority of the person who will conclude the contract on behalf of:') }}
            ul
              li {{ $t('juridical person type (minutes of the higher management body of the legal entity or the order on the election/appointment of the head of the Partner, power of attorney)') }}
              li {{ $t('registration number of the account') }}
              li {{ $t('taxpayer cards (if available)') }}
          template(v-else) {{ item.text }}
    div(class="dialog__actions d-flex justify-end pt-8")
      v-btn(
        class="main-button"
        :loading="loading"
        @click="btnClick"
      ) {{ $t('Send') }}
</template>

<script>
import Download from 'js-file-download'
import BlockTapToPhoneUploadDocumentsItem from '~/modules/acquiring/views/_old/tap-to-phone/block-tap-to-phone-upload-documents-item.vue'
import Oschadbank from '~/modules/acquiring/models/Oschadbank'

export default {
  name: 'BlockTapToPhoneUploadDocuments',
  components: {
    BlockTapToPhoneUploadDocumentsItem
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      downloadLoading: false,
      files: {
        questionnaire: [],
        passport: [],
        certificate: []
      }
    }
  },
  computed: {
    personType () {
      return this.$Organization.personType
    },
    uploadItems () {
      return this.personType === 'juridical' ? this.uploadItemsJuridical : this.uploadItemsIndividual
    },
    uploadItemsIndividual () {
      return [
        {
          name: 'questionnaire',
          icon: 'questionnaire',
          title: 'Анкета-опитувальник',
          key: 'survey'
        },
        {
          name: 'passport',
          icon: 'file-text-dark',
          title: 'Паспорт громадянина України / Іноземної держави',
          text: 'Документ, що посвідчує особу'
        },
        {
          name: 'certificate',
          icon: 'passport',
          title: 'Довідка податкового органу',
          text: 'Про присвоєння реєстраційного номеру облікової картки платника податків'
        }
      ]
    },
    uploadItemsJuridical () {
      return [
        {
          name: 'questionnaire',
          icon: 'questionnaire',
          title: 'Анкета-опитувальник',
          key: 'survey'
        },
        {
          name: 'certificate',
          icon: 'file-text-dark',
          title: 'Виписка з Єдиного державного реєстру',
          text: 'Для юридичних та фізичних осіб'
        },
        {
          name: 'passport',
          icon: 'passport',
          title: 'Паспорти/документи',
          key: 'passport'
        }
      ]
    }
  },
  created () {
    this.$emit('changeTitle', 'Upload document')
  },
  methods: {
    async downloadSurvey () {
      try {
        this.downloadLoading = true

        const survey = this._.get(await Oschadbank.api().downloadQuestionnaire(), 'response.data')
        Download(survey, 'анкета-опитувальник.docx')
        this.$gtm.push({
          event: 'download_tap_to_phone_survey',
          organization: this._.get(this.$Organization, 'owner.email')
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.downloadLoading = false
      }
    },
    async btnClick () {
      this.loading = true

      const { valid: isQuestionnaireValid } = await this.$refs.questionnaire[0].$refs.fileInput.validate()
      const { valid: isPassportValid } = await this.$refs.passport[0].$refs.fileInput.validate()
      const { valid: isCertificateValid } = await this.$refs.certificate[0].$refs.fileInput.validate()

      if (isQuestionnaireValid && isPassportValid && isCertificateValid) {
        const allDocuments = this._.flatten(Object.values(this.files))

        this.$emit('documents', allDocuments)
        this.$emit('showTapToPhoneContactForm', true)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tap-to-phone-upload-documents {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__item-link {
    color: $focus-color;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    background: none !important;
    height: 20px !important;
    padding: 0 !important;
  }
}
</style>
