const routePrefix = '/dashboard/reports'

export const reports = {
  periodicReport: {
    title: 'Periodic report',
    type: 'periodicReport',
    icon: 'calendar',
    route: `${routePrefix}/periodicreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  receiptsReport: {
    title: 'Receipts report',
    type: 'receiptsReport',
    icon: 'receipt-report',
    route: `${routePrefix}/receiptsreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  extendedReports: {
    title: 'Extended reports',
    type: 'extendedReports',
    icon: 'presentation-chart',
    route: `${routePrefix}/extendedreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  zReport: {
    title: 'Reports z-report',
    type: 'zReport',
    icon: 'z-report',
    route: `${routePrefix}/zreport`,
    visible: () => true
  },
  technicalReturn: {
    title: 'Technical return report',
    type: 'technicalReturn',
    icon: 'selling-2',
    route: `${routePrefix}/technicalreturnreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  goods: {
    title: 'Goods report',
    type: 'goods',
    icon: 'shopping-cart-3',
    route: `${routePrefix}/goodsreport`,
    visible: () => true
  },
  excises: {
    title: 'Excises report',
    type: 'excises',
    icon: 'tax-report',
    route: `${routePrefix}/excisesreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  financial: {
    title: 'Financial report',
    type: 'financial',
    icon: 'finances-report',
    route: `${routePrefix}/financialreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  },
  shifts: {
    title: 'Shifts report',
    type: 'shifts',
    icon: 'change-2',
    iconSize: 'bg',
    route: `${routePrefix}/shiftsreport`,
    visible: user => !user?.globalAllOrganizationsSelected
  }
}
