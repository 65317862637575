<template lang="pug">
  div(class="dialog")
    div(class="dialog__title mb-2") {{ $t('Enter the password from DepositSign') }}
    ValidationObserver(ref="validator" slim)
      v-form(@submit.prevent.stop="submit")
        ValidationProvider(
          vid="password"
          :name="$t('Deposit sign key password')"
          mode="passive"
          rules="required|max:32"
          v-slot="{ errors }"
        )
          v-text-field(
            v-model="password"
            :label="$t('Deposit sign key password')"
            :error-messages="errors"
            type="password"
            outlined
          )
        v-btn(
          class="main-button mb-3 d-block mx-auto"
          :loading="loading"
          type="submit"
        ) {{ $t('Send') }}
</template>

<script>
import Base64js from 'base64-js'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import TaxAuthService from '~/services/Tax/Auth/TaxAuthService'
import { TaxReportService } from '~/services/Tax/Report/TaxReportService'
import signPluginForOrganization from '~/mixins/dialogs/signPluginForOrganizationDialog'

export default {
  name: 'FormDepositsignPassword',
  mixins: [signPluginForOrganization],
  props: {
    item: {
      type: Object,
      default: null
    },
    model: {
      type: null,
      default: null
    },
    token: {
      type: String,
      default: null
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false,
    localAxios: null,
    password: null
  }),
  computed: {},
  created () {
    this.localAxios = this.$axios.create()
  },
  methods: {
    async checkPassword () {
      const blob = new Blob([this.token])
      const signData = await new Response(blob).arrayBuffer()
      try {
        const { data } = await this.localAxios.post('https://depositsign.com/api/v1/checkbox/sign/file',
          {
            FileData: Base64js.fromByteArray(new Uint8Array(signData)),
            AppendData: true,
            Password: this.password
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.token
            }
          })
        return !!data.SignedData
      } catch (e) {
        return false
      }
    },
    async validateData () {
      const localValidation = await this.$refs.validator.validate()
      if (!localValidation) {
        return false
      }
      const passwordValid = await this.checkPassword()
      if (passwordValid === false) {
        this.$refs.validator.setErrors({
          password: this.$t('This password is not valid. Enter another')
        })
      }
      return passwordValid
    },
    async sendToTax () {
      try {
        await this.signPluginForOrganization.use()
        const keyData = this._.get(this.signPluginForOrganization, 'interface.keyData', null)
        const sign = this._.get(this.signPluginForOrganization, 'interface.sign')
        const edsKeyData = new EdsKeyData(keyData)

        if (!keyData) {
          return this.signPluginForOrganization.close()
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          await this.signPluginForOrganization.close()
          return this.sendToTax()
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          await this.signPluginForOrganization.close()
          return this.sendToTax()
        }

        try {
          const res = await TaxAuthService.sendAuthData(keyData, data => sign(data, { fallbackMethod: this.sendToTax }))
          if (!res) {
            return null
          }
        } catch (e) {
          this.$handlers.error(e, this, true)
        }

        const reportService = new TaxReportService(this.signPluginForOrganization.interface, this.sendToTax)
        const xmlResponse = await this.model.api().xmlDoc(this.item)
        const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
        const fname = this._.get(xmlResponse, 'response.data.fname', null)
        const signData = await reportService.getEnvelopedData(xml)

        if (!signData) {
          return null
        }

        const taxId = this._.get(await this.model.api().taxRegister(this.item, { signData, fname }), 'response.data.response.taxId', null)
        const encodedTaxId = new TextEncoder().encode(String(taxId))
        const signedTaxId = await reportService.getEnvelopedData(encodedTaxId)

        if (!signedTaxId) {
          return null
        }

        await this.model.api().signTaxId(this.item, { tax_id: signedTaxId })

        this.$notification.success('entityActions.create.employeeorder')
        await this.signPluginForOrganization.hide()
      } catch (e) {
        this.$handlers.error(e, this)
        await this.signPluginForOrganization.hide()
      }
    },
    async submit () {
      try {
        this.loading = true
        const valid = await this.validateData()
        if (!valid) {
          return
        }
        // Updating model password
        await this.model.api().changeDsPassword(this._.get(this.item, 'id'), { dsPass: this.password })
        // Sending to tax
        await this.sendToTax()
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
