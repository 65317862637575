<template lang="pug">
  div(
    class="pos-terminal-settings-form"
    :class="{ dialog: isDialogPart }"
  )
    div(
      v-if="isDialogPart"
      class="dialog__title pos-terminal-settings-form__title mb-3"
    )
      span {{ $t(title) }}
      div(
        v-if="!isEditing"
        class="pos-terminal-settings-form__link"
      )
        e-link(href="https://wiki.checkbox.ua/uk/app/pc/portal_acquiring") {{ $t('Instructions') }} &#x2192;
    v-form(@submit.prevent.stop="submit")
      div()
        ValidationObserver(ref="form" slim)
          t-orm-fields(
            v-bind.sync="form"
            :items="fields"
          )
      div(class="pos-terminal-settings-form__actions")
        v-btn(
          class="main-button"
          :loading="loading"
          type="submit"
        ) {{ $t(buttonText) }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields.vue'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'
import commonData from '~/modules/receipt/mixins/getters/commonData'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import PosTerminals from '~/modules/acquiring/models/PosTerminals'
import ELink from '~/components/elements/links/e-link.vue'

export default {
  name: 'BlockPosTerminalSettingsForm',
  components: {
    TOrmFields,
    ELink
  },
  mixins: [commonData],
  props: {
    item: {
      type: null,
      default: null
    },
    isDialogPart: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    posTerminals: null,
    form: {
      ipAddress: null,
      port: null,
      terminalName: null,
      cashRegister: null,
      usage: true
    }
  }),
  computed: {
    model () {
      return PosTerminals
    },
    title () {
      if (this.isEditing) {
        return 'POS-terminal editing'
      }
      return 'Add POS-terminal'
    },
    buttonText () {
      if (this.isEditing) {
        return 'Save'
      }
      return 'Add'
    },
    fields () {
      return [
        {
          model: 'ipAddress',
          component: 'v-text-field',
          provider: {
            vid: 'ipAddress',
            name: 'IP address',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'IP address'
          },
          fieldVal: () => this._.get(this.item, 'ipAddress') || '127.0.0.1'
        },
        {
          model: 'port',
          component: 'v-text-field',
          provider: {
            vid: 'port',
            name: 'Port',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Port'
          },
          fieldVal: () => this._.get(this.item, 'port') || '9020'
        },
        {
          model: 'terminalName',
          component: 'v-text-field',
          provider: {
            vid: 'terminalName',
            name: 'Terminal name',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Terminal name'
          }
        },
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Select cash register',
          returnObject: true,
          itemClass: 'ws-pre',
          rules: 'required',
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (!this._.get(this.item, 'cashRegister', null) && items && items.length === 1) {
              return items[0]
            }
          },
          fieldVal: () => this._.get(this.item, 'cashRegister', null),
          itemValue: item => this._.get(item, 'id', null)
        }, CashRegistersSearch).build(),
        {
          model: 'usage',
          component: 'v-switch',
          provider: {
            vid: 'usage',
            name: 'Connect'
          },
          attrs: {
            label: 'Connect'
          },
          fieldVal: () => this._.get(this.item, 'usage', true)
        }
      ]
    }
  },
  created () {
    this.$gtm.push({
      event: 'connect_pos_terminal_to_cashregister_dialog',
      organization: this._.get(this.$Organization, 'owner.email')
    })
  },
  methods: {
    async submit () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        const payload = {
          ...this.form,
          cashRegister: this._.get(this.form, 'cashRegister') ? `cash-registers/${this._.get(this.form, 'cashRegister.id', null)}` : null
        }
        if (this.isEditing) {
          await this.model.api().update(this.item, payload)
          this.$notification.success(this.$t('Bank terminal settings have been successfully edited'))
        } else {
          await this.model.api().create(payload)
          this.$notification.success(this.$t('Bank terminal settings have been successfully created'))
        }

        this.$gtm.push({
          event: 'submit_pos_terminal_to_cashregister',
          organization: this._.get(this.$Organization, 'owner.email'),
          payload: JSON.stringify(payload)
        })

        await this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this.$refs.form)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pos-terminal-settings-form {
  &__actions {
    display: flex;
    justify-content: center;
    padding: 5px 0 20px;
    width: 100%;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__link {
    font-size: 14px;
    font-weight: 700;

    &::v-deep {
      .e-link {
        font-weight: 700;
      }
    }
  }
}
</style>
