<template lang="pug">
  v-autocomplete(
    :class="classes"
    :items="listOrganizations"
    item-text="name"
    :value="currentOrganization"
    :loading="loading"
    @change="setOrganization"
    background-color="transparent"
    :no-data-text="$t('Organization not found ')"
    :menu-props="{ 'offset-y': true }"
    :label="currentOrganization.name"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    dense
    solo
    flat
    return-object
    hide-details
  )
    template(v-slot:selection="{ item }")
      div(
        class="e-input-organization-change__selection"
        :title="item.name"
      ) {{ item.name }}
</template>

<script>
import AuthenticatedCashier from '~/modules/receipt/models/AuthenticatedCashier'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'
import taxAuth from '~/mixins/pages/tax-auth'
import multiAccountUsers from '~/mixins/organization/multiAccountUsers'

export default {
  mixins: [taxAuth, multiAccountUsers],
  data () {
    return {
      showInput: false,
      loading: false,
      current: this.currentOrganization
    }
  },
  computed: {
    classes () {
      return [
        'mt-0', 'e-input-organization-change',
        {
          'e-input-organization-change--disabled': this.disabled
        }
      ]
    },
    disabled () {
      return this.listOrganizations.length <= 1
    },
    managedOrganizations () {
      return this.$User.managedOrganizations
    },
    ownedOrganization () {
      return this.$User.ownedOrganization
    },
    allOrganizationsItem () {
      return {
        name: this.$t('All organizations'),
        value: 'globalAllOrganizationsSelected'
      }
    },
    listOrganizations () {
      const arr = []
      if (this.isMultiAccountUser) {
        arr.push(this.allOrganizationsItem)
      }
      if (this.ownedOrganization) {
        arr.push(this.ownedOrganization)
      }
      return arr.concat(this.managedOrganizations).sort((a, b) => a.name.localeCompare(b.name))
    },
    currentOrganization () {
      if (this._.get(this.$User, 'globalAllOrganizationsSelected')) {
        return this.allOrganizationsItem
      }
      return this.$Organization
    }
  },
  methods: {
    setGlobalAllOrganizationsSelected (val) {
      this.$setLocalStorageClientData({ globalAllOrganizationsSelected: val })
      location.reload()
    },
    async setOrganization (organization) {
      if (!organization || (organization.id === this.currentOrganization.id)) {
        return
      }
      if (this._.get(organization, 'value') === 'globalAllOrganizationsSelected') {
        this.setGlobalAllOrganizationsSelected(true)
      } else {
        await currentOrganizationService.setCurrentOrganization(organization)
        if (this._.get(this.$localStorageClientData(), 'globalAllOrganizationsSelected')) {
          this.setGlobalAllOrganizationsSelected(false)
        }
      }
      await AuthenticatedCashier.deleteAll()
      this.destroyIitGlobalDialog()
      this.$emit('change', this.currentOrganization)
    }
  }
}
</script>

<style scoped lang="scss">
.e-input-organization-change {
  font-size: 12px !important;
  padding: 8px !important;
  background: #2D323B;
  border-radius: 8px;
  position: relative;
  z-index: 2;

  &__selection {
    max-width: 100%;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 11px;
    max-height: 38px;
    font-weight: 400;
  }

  &::v-deep {
    .v-input {
      &__slot {
        padding: 0 !important;
      }

      &__control {
        margin: 0;
      }
    }

    .v-select {
      &__slot {
        font-size: 10px;
        cursor: pointer;

        input {
          text-overflow: ellipsis;
        }

        .v-label {
          font-size: 10px !important;
          color: #fff !important;
        }
      }
    }
  }

  &--disabled {
    pointer-events: none;

    ::v-deep .v-input__append-inner {
      display: none;
    }
  }
}
</style>
