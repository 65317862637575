<template lang="pug">
  div(class="e-dialog-input")
    component(:is="modalAttrs.component" v-model="modal" v-bind="modalAttrs" :selectedVal.sync="selectedVal" ref="modal")
    v-row(v-if="isInputField" align="center" no-gutters)
      v-col(cols="12")
        v-text-field(v-model="selectedVal" :label="label" outlined hide-details)
        e-link(:loading="isLoading" @click="openModal") + {{ btnText }}
    v-row(class="e-dialog-input--field mt-2" no-gutters v-else)
      v-col(cols="12" align-self="center" v-if="visibleValue")
        span(class="visible-value w-100") {{visibleValue}}
        e-link(
          v-if="canChange"
          class="d-block mt-2"
          text-bold
          :loading="isLoading"
          @click="openModal"
        )
          e-svg-icon(
            class="mr-1"
            size="xsm"
          ) edit-2
          | {{ btnText }}
      v-col(cols="6" class="mt-2" v-else)
        e-link(:loading="isLoading" @click="openModal") + {{ btnText }}
    div(class="e-dialog-input--messages" v-if="showMessages")
      | {{errorMessages[0]}}
</template>

<script>
import MOrmDialogTreeSelect from '~/components/modules/dialogs/orm/m-orm-dialog-tree-select'
import MOrmSelectDialog from '~/components/modules/dialogs/orm/m-orm-select-dialog'
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon.vue'

export default {
  components: {
    ESvgIcon,
    MOrmDialogTreeSelect,
    MOrmSelectDialog,
    ELink
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    value: {
      //
    },
    isInputField: {
      type: Boolean,
      default: false
    },
    modalAttrs: {
      type: Object,
      default: () => ({})
    },
    valuePattern: {
      type: String,
      default: '{name}'
    },
    requestItems: {
      type: String,
      default: 'all'
    },
    showMessages: {
      type: Boolean,
      default: true
    },
    canChange: {
      type: Boolean,
      default: true
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableOpenModalRequest: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      inputValue: '',
      dataLoading: false,
      modal: false,
      selectedVal: null
    }
  },
  computed: {
    isLoading () {
      return this.dataLoading || this.loading
    },
    visibleValue () {
      if (this._.isObject(this.value)) {
        return this.valuePattern.replace(/\{(\w*)\}/g, (match, v, v1) => {
          return this.value[v] || ''
        })
      } else {
        return this.value
      }
    },
    btnText () {
      return this.visibleValue ? this.$t('Change') : this.$t('Select from classifier')
    }
  },
  watch: {
    selectedVal (to, prev) {
      this.$emit('input', to)
    }
  },
  created () {
    this.selectedVal = this.value
  },
  methods: {
    async openModal () {
      if (!this.disableOpenModalRequest) {
        this.dataLoading = true
        this.modalAttrs.model.deleteAll()
        await this.modalAttrs.model.api()[this.requestItems]()
        this.dataLoading = false
      }
      this.$refs.modal.fill(this.modalAttrs.title)
      this.modal = true
    }
  }

}
</script>

<style scoped lang="scss">
  .e-dialog-input{
    &--messages {
      color: red;
      min-height: 14px;
      margin-bottom: 3px;
      font-size: 12px;
    }
  }
  .visible-value {
    font-weight: 700;
    color: #000;
    font-size: 14px;
  }
</style>
