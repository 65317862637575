import { intersectionBy } from 'lodash/array'
import { get } from 'lodash/object'
import CurrentOrganization from '~/modules/auth/Models/CurrentOrganization'
import authUser from '~/modules/auth/authUser'
import Organization from '~/models/directories/Organization'

export default {

  refresh () {
    const organization = Organization.query().whereId(get(this.getCurrentOrganization(), 'id', null)).first()
    this.setCurrentOrganization(organization)
  },

  getCurrentOrganization () {
    return CurrentOrganization.query().withAll().first()
  },

  setCurrentOrganization (organization) {
    if (!organization) {
      return CurrentOrganization.deleteAll()
    }
    return CurrentOrganization.create({
      data: organization
    })
  },

  updateCurrentOrganization () {
    const user = authUser.get()
    const currentOrganization = this.getCurrentOrganization()
    if (!currentOrganization ||
      !intersectionBy([user.ownedOrganization, ...user.managedOrganizations], [currentOrganization], 'id').length) {
      this.setCurrentOrganization(this.getDefaultOrganization(user))
    } else {
      this.refresh()
    }
  },

  getDefaultOrganization (user) {
    if (user.isOwner) {
      return user.ownedOrganization
    } else if (user.isOrganizationUser) {
      return user.managedOrganizations[0]
    }
    return null
  }

}
