<template lang="pug">
  component(
    v-if="isVisible"
    v-slot="{ errors }"
    v-bind="wrapperAttrs"
  )
    component(
      v-model.trim="input"
      v-on="listeners"
      v-bind="attributes"
      :is="item.component"
      :class="classes"
      :disabled="isDisabled"
      :error-messages="errors"
      :no-data-text="$t('No items were found')"
      :style="item.style")

      template(v-slot:append v-if="'info' in morphedAttrs" )
        div(class="inner-info-tooltip")
          e-info-tooltip {{$t(morphedAttrs['info'])}}

      template(v-slot:append-outer v-if="tooltipHint")
        e-info-tooltip {{ $t(tooltipHint.text) }}

    div(
      v-if="hint && !(errors && errors.length)"
      class="input-hint"
    )
      span {{ $t(hint.text) }}
      e-link(
        v-if="hint.linkText"
        :href="hint.link"
        class="input-hint__link"
        underline
      ) {{ $t(hint.linkText) }}
</template>

<script>
import { VSelect, VTextField, VSwitch, VCheckbox, VAutocomplete, VTextarea, VFileInput, VCombobox } from 'vuetify/lib'
import { ValidationProvider } from 'vee-validate'
import ctx from '~/mixins/props/ctx'
import mda from '~/mixins/computed/morphedAttrs'

import EDialogInput from '~/components/elements/inputs/e-dialog-input'
import EInputPassword from '~/components/elements/inputs/e-input-password'
import EInputMask from '~/components/elements/inputs/e-input-mask'
import ETreeSelect from '~/components/elements/selections/e-tree-select'
import EInputImage from '~/components/elements/inputs/e-input-image'
import EInputDatetime from '~/components/elements/inputs/e-input-datetime'
import EInputDatetimeRange from '~/components/elements/inputs/e-input-datetime-range'
import EOrmStaticFieldContent from '~/components/elements/orm/e-orm-static-field-content'
import ELinkPath from '~/components/elements/links/e-link-path'
import EFieldGroupCrumbs from '~/components/elements/fields/e-field-group-crumbs'
import EInfoTooltip from '~/components/elements/tooltips/e-info-tooltip'
import EAutocomplete from '~/components/elements/inputs/e-autocomplete'
import EInputWithButton from '~/components/elements/inputs/e-input-with-button'
import EInputTreeSelect from '~/components/elements/inputs/e-input-tree-select'
import ELink from '~/components/elements/links/e-link'
import TEmptyWrapper from '~/components/templates/wrappers/t-emty-wrapper'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content'
import EInputScanner from '~/components/elements/inputs/e-input-scanner'
import EInputSwitchTooltip from '~/components/elements/inputs/e-input-switch-tooltip'
import EInputPaymentType from '~/components/elements/inputs/e-input-payment-type'
import EInputOrganizationsSelect from '~/components/elements/inputs/concrete/e-input-organizations-select'

export default {
  components: {
    ELink,
    EInfoTooltip,
    EFieldGroupCrumbs,
    ELinkPath,
    EDialogInput,
    EOrmStaticFieldContent,
    ValidationProvider,
    VTextField,
    VSelect,
    VSwitch,
    VCheckbox,
    VAutocomplete,
    VCombobox,
    EInputPassword,
    EInputMask,
    EInputWithButton,
    VTextarea,
    VFileInput,
    ETreeSelect,
    EInputImage,
    EInputDatetime,
    EInputDatetimeRange,
    EAutocomplete,
    EInputTreeSelect,
    TEmptyWrapper,
    EInputFileContent,
    EInputScanner,
    EInputSwitchTooltip,
    EInputPaymentType,
    EInputOrganizationsSelect
  },
  mixins: [ctx, mda],
  props: {
    item: {
      type: Object,
      required: true
    },
    dynamic: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    input: null,
    loading: false,
    requestItems: null
  }),
  computed: {
    wrapperAttrs () {
      if (this._.get(this.item, 'provider', null)) {
        return {
          is: 'ValidationProvider',
          ...this.provider,
          mode: this._.get(this.provider, 'mode', '') || 'passive',
          name: this.name
        }
      }
      return {
        is: 't-empty-wrapper'
      }
    },
    hint () {
      const hint = this._.get(this.item, 'hint', null)
      return hint &&
          (typeof hint === 'object' && !Array.isArray(hint)
            ? hint
            : { text: hint.toString() }
          )
    },
    tooltipHint () {
      const hint = this._.get(this.item, 'tooltipHint', null)
      return hint &&
          (typeof hint === 'object' && !Array.isArray(hint)
            ? hint
            : { text: hint.toString() }
          )
    },
    fieldVal () {
      if (!this.isAsyncField) {
        return this._.isFunction(this.item.fieldVal)
          ? this.item.fieldVal(this.ctx)
          : this._.get(this.ctx, 'attrs.' + this.item.model)
      }

      // TODO: add debounce for all async callbacks.
      return this._.isFunction(this.item.fieldVal)
        ? this.item.fieldVal(this.ctx)
        : this._.get(this.ctx, 'attrs.' + this.item.model)
    },

    isRequired () {
      return this._.get(this.provider, 'rules', '').includes('required')
    },

    isDisabled () {
      return this._.get(this.morphedAttrs, 'disabled', false) || this.loading
    },

    isVisible () {
      return this._.get(this.morphedAttrs, 'visible', true)
    },

    isSelectable () {
      return ['v-switch', 'v-checkbox'].includes(this.item.component)
    },

    listeners () {
      const customListeners = {}
      const itemListeners = this._.get(this.item, 'listeners', null)

      if (itemListeners) {
        for (const key in itemListeners) {
          if (typeof itemListeners[key] === 'function') {
            customListeners[key] = val => this.$emit(key, itemListeners[key](val, this.ctx))
          }
        }
      }

      return Object.assign({},
        this.$listeners,
        {
          [this.isSelectable ? 'change' : 'input']: this.getInputEvent(),
          'update:search-input': this.getInputEvent()
        },
        customListeners
      )
    },

    selectItems () {
      if (this._.isFunction(this.item.items)) {
        return this.item.items(this.ctx)
      }

      if (this.item.items) {
        return this._.map(this.item.items, item => (Object.assign({}, {
          ...item
        }, {
          text: (item.prefixText) ? `${item.prefixText} ${this.$t(item.text)}` : this.$t(item.text)
        })))
      }
      if (this.requestItems) {
        return this.requestItems
      }

      return []
    },

    classes () {
      return this._.compact([
        'e-input-wrapper',
        this.isSelectable ? 'mt-1' : null,
        this.isRequired ? 'e-input-wrapper__required' : null,
        ...this._.get(this.item, 'classes', [])
      ])
    },

    name () {
      const name = this._.get(this.item.provider, 'name', '')
      const splitName = name.split('|')

      return (splitName.length >= 2)
        ? `"${this._.upperFirst(this.$tc(splitName[0], splitName[1]))}"`
        : `"${this._.upperFirst(this.$tc(name, 1))}"`
    },

    provider () {
      const provider = Object.assign({}, this.item.provider)

      for (const key in provider) {
        if (this._.isFunction(provider[key])) {
          provider[key] = provider[key](this.ctx)
        }
      }

      return provider
    },

    isTextField () {
      return ['v-text-field', 'v-textarea', 'e-input-mask', 'e-input-password'].includes(this.item.component)
    },

    isAsyncField () {
      return ['e-autocomplete'].includes(this.item.component)
    },

    attributes () {
      if (this.item.moneyFlag) {
        this.inputEvent(this.input)
      }
      return this.isAsyncField
        ? {
          item: this.item,
          ctx: this.ctx,
          ...this.morphedAttrs
        } : {
          // FIXME: May create some new performance issues.
          ctx: this.ctx,
          items: this.selectItems,
          loading: this.loading,
          ...this.morphedAttrs
        }
    },
    requestParams () {
      const params = this._.get(this.item, 'requestParams', [])
      const injected = []
      params.forEach((item) => {
        switch (item) {
          case '$User': {
            injected.push(this.$User)
            break
          }
        }
      })
      return injected
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  watch: {
    value (cur) {
      // FIXME: here may be some global problems!.
      if (this.dynamic) {
        this.input = cur
      }
    },
    '$attrs.value' (cur) {
      // FIXME: here may be some global problems!.
      if (this.dynamic) {
        this.input = cur
      }
    },
    // FIXME: delete this?
    checked (cur) {
      this.input = cur
    }
  },
  async created () {
    // TODO: this is temporary intermediate solution.
    if (!this.isAsyncField && (this.isVisible || this.item.forcedRequest)) {
      let response

      if (this._.isFunction(this.item.request)) {
        try {
          this.loading = true
          response = await this.item.request(this.ctx, ...this.requestParams)
          this.requestItems = response
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }

      if (this._.isFunction(this.item.asyncDefault) && !this.fieldVal) {
        this.input = this.item.asyncDefault(this.selectItems, response, { queryString: this.$route.query })
      } else {
        this.input = this.fieldVal
      }
    } else {
      this.input = this.fieldVal
    }

    // TODO: may create additional update of all fields.
    this.$emit('input', this.input)

    if (this.item.cast) {
      this.inputEvent(this.input)
    }
  },
  mounted () {
    // Activate custom listeners for editedItem
    for (const key in this.listeners) {
      const customListener = this._.get(this.item, `listeners.${key}`, null)
      if (customListener && typeof customListener === 'function') {
        this.$emit(key, customListener(this.input, this.ctx))
      }
    }
  },
  methods: {
    getInputEvent () {
      return this.inputEvent
    },

    inputEvent (event) {
      let data = event

      if (this._.isFunction(this.item.cast)) {
        data = this.item.cast(data)
      }

      // console.log('e-input-wrapper', data)
      this.$emit('input', data)
    }
  }
}
</script>

<style lang="scss">
  .v-input__append-inner{
    .inner-info-tooltip{
      margin-top: -$text-field-enclosed-prepend-append-margin-top;
    }
  }

  .v-input {
    .v-input__append-outer {
      margin: 0 0 0 5px;
    }
  }

  .v-input__slot {
    margin-bottom: 5px;
  }

  .v-input__control {
    margin-bottom: 5px;
  }

  .filled-input {
    border-radius: 12px;

    .v-input__control {
      max-height: 45px;

      .v-text-field__details{
        position: absolute;
        bottom: -20px;
      }

      .v-input__icon i {
        font-size: 19px;
      }
    }

    .v-input__slot{
      background-color: #F0F1F2 !important;
    }

    &:not(.v-input--is-focused) {
      fieldset {
        border: none;
      }
    }

    &--outlined {
      fieldset {
        border-width: 0;
        border-color: rgba(22, 27, 37, 0.2)!important;
      }
      .v-input__prepend-inner {
        .v-icon{
          color: #161B25!important;
          opacity: 0.5;
        }
      }
      .v-input__append-inner {
        .v-icon {
          color: #161B25!important;
          opacity: 0.2;
          font-size: 21px;
        }
      }
      &.v-input--is-focused {
        .v-input__slot {
          background-color: transparent !important;
        }
        fieldset {
          border: 2px solid rgba(22, 27, 37, 0.2)
        }
        .v-input__prepend-inner {
          .v-icon {
            color: #161B25!important;
            opacity: 1;
          }
        }
      }
    }
  }
</style>
