<template lang="pug">
  div(class="a-bank-acquiring-order-info")
    p(class="a-bank-acquiring-order-info__icon") {{ icon }}
    h3(class="a-bank-acquiring-order-info__title") {{ $t(title) }}
</template>

<script>
export default {
  name: 'BlockABankAcquiringOrderInfo',
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    error () {
      return this._.get(this.info, 'error', false)
    },
    icon () {
      if (this.error) {
        return '❌'
      }
      return '✅'
    },
    title () {
      const infoText = this._.get(this.info, 'text', null)
      return infoText || 'The application for the use of acquiring has been successfully signed and sent'
    }
  },
  created () {
    this.changeTitle('')
    this.toggleCloseBtn(true)
  }
}
</script>

<style scoped lang="scss">
.a-bank-acquiring-order-info {
  text-align: center;

  &__icon {
    font-size: 48px;
    margin-bottom: 40px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 18px;
    }
  }

  //&__text {
  //  font-size: 14px;
  //  font-weight: 300;
  //  color: #000;
  //  margin: 0;
  //}
}
</style>
