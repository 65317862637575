<template lang="pug">
  v-col(cols="12" :class="classList")
    e-overlay-block(
      v-if="loading"
      :loading="loading"
      progress-width="6"
      progress-size="xxl"
      color="#161b25"
    )
    block-notification-alert(v-if="notificationAlert")
    v-card(class="white elevation-0 t-orm-page__toolbar-card pt-0 mb-0")
      template
        v-row(no-gutters justify="space-between" align="center" class="no-print")
          v-col(cols="auto" class="t-orm-page__toolbar-heading flex-grow-0 flex-shrink-0")
            h1(class="mr-8 t-orm-page__title" v-if="$slots.title")
              slot(name="title")
          v-col(cols="auto" v-if="$slots['title:append-right']")
            slot(name="title:append-right")
          v-col(cols="12" sm="auto" class="flex-grow-1 flex-shrink-1")
            v-row(no-gutters align="center" justify-sm="end" style="gap: 5px 0;")
              v-col(cols="auto" class="flex-grow-1 flex-shrink-1 order-1 order-md-0" v-if="'title:append' in slots && !smDevice" )
                slot(name="title:append")
              v-col(v-if="!xsDevice && supportButtons && !_.get($User, 'isSuperAdmin', null)" cols="auto" class="ml-0 ml-md-7")
                m-support-buttons
          v-col(cols="12" md="auto" class="mt-2" v-if="'title:append' in slots && smDevice" )
            slot(name="title:append")

        v-row(no-gutters v-if="'subtitle' in slots" class="no-print")
          v-col(cols="12")
            span(class="t-orm-page__toolbar-subtitle")
              slot(name="subtitle")

        v-row(v-if="'toolbar' in slots" class="no-print")
          v-col(colsd="12" class="py-0 t-orm-page__toolbar-slot")
            slot(name="toolbar")

    slot(v-if="!loading")

    m-info-block(v-if="'info' in slots && !loading" type='warning' class="mt-4")
      slot(name="info")
</template>

<script>
import { mapGetters } from 'vuex'
import MInfoBlock from '~/components/modules/blocks/m-info-block'
import EButtonHelp from '~/components/elements/buttons/e-button-help'
import Invoices from '~/models/billing/Invoices'
import { formats } from '~/const/global'
import billingBlocked from '~/mixins/billingBlocked'
import responsive from '~/mixins/pages/responsive'
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block'
import MSupportButtons from '~/components/modules/m-support-buttons'
import BlockNotificationAlert from '~/modules/notifications/views/components/block-notification-alert'

export default {
  components: {
    MSupportButtons,
    EOverlayBlock,
    MInfoBlock,
    EButtonHelp,
    BlockNotificationAlert
  },
  mixins: [billingBlocked, responsive],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    supportButtons: {
      type: Boolean,
      default: true
    },
    notificationAlert: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'profile'
    ]),
    classList () {
      return {
        't-orm-page': true,
        white: true,
        mobile: this.xsDevice
      }
    },
    slots () {
      return this.$slots
    },
    paymentDate () {
      const endDate = this._.get(Invoices.query().first(), 'period.startDate', '')
      const date = new Date(endDate)
      date.setDate(date.getDate() + 5)
      if (!endDate || !date) {
        return false
      }
      return this.$moment(date).format(formats.date)
    }
  }
}
</script>

<style lang="scss">
  .t-orm-page {
    padding: 25px 20px;
    height: 100%;

    &.mobile {
      padding-top: 15px;
      margin-bottom: 55px;
    }

    @media (min-width: map-get($breakpoints, 'sm')) {
      padding: 16px 30px;
    }

    &__toolbar {
      &-card {
        margin-bottom: 10px;
        padding-top: 40px;

        @media (min-width: map-get($breakpoints, 'md')) {
          padding-bottom: 12px;
        }
      }

      &-slot {
        @media (min-width: map-get($breakpoints, 'md')) {
          margin-top: 16px;
        }
      }

      &-heading {
        display: flex;
        font-family: $page-title-font-family;
        justify-content: space-between;
        align-items: center;
        min-height: 48px;
        flex-wrap: wrap;
        padding: 10px 0 !important;

        @media (min-width: map-get($breakpoints, 'sm')) {
          padding: 0 !important;
        }
      }
      &-subtitle {
        display: block;
        font-size: 0.8rem;
        width: 100%;
        margin-top: 15px;
        color: rgba(22, 27, 37, 0.5);
      }
    }

    &__payment-reminder {
      font-family: 'e-Ukraine-light', sans-serif;
      font-size: 0.69rem;
      color: #FF002B;
      border-radius: 10px;
      font-weight: 700;
      padding: 5px;
      width: 100%;
      display: inline;

      @media (min-width: map-get($breakpoints, 'xs')) {
        border: 2px solid #FF002B;
      }
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 500;

      @media (min-width: map-get($breakpoints, 'md')) {
        font-size: 1.75rem;
      }
    }
  }
</style>
