<template lang="pug">
  div(class="dialog")
    div(class="dialog__title pb-0 mb-8") {{ $t('New refund') }}
    v-form(@submit.prevent.stop="addRefund")
      ValidationObserver(
        ref="form"
        slim
      )
        t-orm-fields(
          v-bind.sync="data"
          :items="fields"
          class="mt-3"
        )
      div(class="dialog__actions")
        v-row(justify="center")
          v-col(
            cols="auto"
            class="pa-0"
          )
            v-btn(
              class="main-button"
              :loading="loading"
              type="submit"
            ) {{ $t('Create') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import OrganizationSearch from '~/models/directories/search/OrganizationSearch'
import Refunds from '~/modules/refunds/models/Refunds'

export default {
  name: 'BlockAddRefund',
  components: {
    TOrmFields
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    data: {
      organization: null,
      sum: null,
      comment: null
    }
  }),
  computed: {
    model () {
      return Refunds
    },
    fields () {
      return [
        new AutocompleteBuilder({
          model: 'organization',
          label: 'organization',
          returnObject: true,
          itemClass: 'ws-pre',
          cacheItems: true,
          useDefaultSearchFilter: true,
          rules: 'required',
          chipText: item => this._.get(item, 'name', '-'),
          itemValue: item => this._.get(item, 'id', null),
          query: model => model.api(),
          visible: () => !this.isFileUpload,
          asyncDefault: () => this._.get(this.item, 'organization', [])
        }, OrganizationSearch).build(),
        {
          model: 'sum',
          component: 'v-text-field',
          cast: val => Math.round((parseFloat(val) || 0) * 100),
          provider: {
            vid: 'sum',
            name: 'Refund sum',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Refund sum'
          }
        },
        {
          model: 'comment',
          component: 'v-text-field',
          provider: {
            vid: 'comment',
            name: 'Comment',
            rules: 'required'
          },
          attrs: {
            label: 'Comment',
            outlined: true
          }
        }
      ]
    }
  },
  methods: {
    async addRefund () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        const payload = {
          ...this.data,
          organization: `organizations/${this._.get(this.data, 'organization.id')}`
        }
        await this.model.api().create(payload)
        this.$notification.success(this.$t('Refund application successfully created'))
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this.$refs.form)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
