import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class MonobankAuth extends OrmModel {
  static entity = 'monobank'

  static fields () {
    return {
      id: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Monobank',
    multy: 'Monobank'
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.auth = function () {
        return this.post(Model.$routes.monobank.auth(), {}, { save: false })
      }
      configActions.temporaryLogin = function (payload) {
        return this.post(Model.$routes.monobank.temporaryLogin(), payload, { save: false })
      }
      configActions.authCheck = function (params) {
        return this.get(Model.$routes.monobank.authCheck(), { save: false, params })
      }
      configActions.deepLink = function () {
        return this.get(Model.$routes.monobank.deepLink(), { save: false })
      }
      return configActions
    }
  }
}

export default MonobankAuth
