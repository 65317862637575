const routes = {
  // TODO: create dynamic generation of routes.
  login: _ => '/login',
  acskProviders: 'https://cabinet.tax.gov.ua/ws/api/crypto/public_sign/data/CAs.json',
  payLinkDevices: (ip, port) => `http://${ip}:${port}/api/devices`,
  auth: {
    refresh_token: _ => '/token/refresh',
    send_reset_password: _ => '/public/users/forgot-password',
    apply_reset_password: token => `public/users/new-password/${token}`,
    organizationDataFromTax: _ => ['/registration', '/organizations', '/get-from-tax'].join('').trim(),
    registration: _ => '/registration'
  },
  users: {
    list: _ => '/users',
    authorizeInSfs: _ => ['/users', '/', 'tax-refresh-token'].join('').trim(),
    concrete: id => ['/users', '/', id].join('').trim(),
    changePassword: id => ['/users', '/', id, '/change-password'].join('').trim(),
    profile: _ => ['/users', '/profile'].join('').trim(),
    rules: _ => ['/users', '/rules'].join('').trim(),
    addManagedOrganization: id => ['/users', '/', id, '/add-managed-organization'].join('').trim(),
    deleteManagedOrganization: (id, orgId) => ['/users', '/', id, '/managed-organizations', '/', orgId].join('').trim(),
    getAuthToken: id => ['/users', '/', id, '/get-auth'].join('').trim(),
    updatePartnerCode: id => ['/users', '/', id, '/partner-code'].join('').trim()
  },
  currentuser: {
    concrete: id => ['/users', '/', id].join('').trim()
  },
  roles: {
    list: _ => '/roles',
    concrete: id => ['/roles', '/', id].join('').trim()
  },
  get taxsearch () {
    return this.taxes
  },
  employee: {
    list: _ => '/employees',
    concrete: id => ['/employees', '/', id].join('').trim(),
    resetPincode: id => ['/employees', '/', id, '/', 'reset-pincode'].join('').trim(),
    taxRegister: id => ['/employees', '/', id, '/', 'reg-tax'].join('').trim(),
    xmlDoc: id => ['/employees', '/', id, '/', 'xml-doc'].join('').trim(),
    synchronize: _ => ['/employees', '/', 'sync'].join('').trim(),
    // processingLogin: id => ['/employees', '/', id, '/', 'processing', '/', 'login'].join('').trim(),
    processingSignin: id => ['/employees', '/', id, '/', 'processing', '/', 'sign-in'].join('').trim(),
    processingLoginSignature: _ => ['/employees', '/', 'processing', '/', 'login', '/', 'signature'].join('').trim(),
    processingCheckAgent: _ => ['/employees', '/', 'processing', '/', 'check-signature'].join('').trim(),
    processingGetTransactions: _ => ['/transactions', '/processing'].join('').trim(),
    processingGetPendingTransactions: _ => ['/transactions', '/processing', '/pending'].join('').trim(),
    processingGetTransaction: id => ['/transactions', '/processing', '/', id].join('').trim(),
    error: id => ['/employees', '/', id, '/', 'error'].join('').trim(),
    updateLocal: id => ['/employees', '/', id, '/', 'edit-local'].join('').trim(),
    setEdsKey: id => ['/employees', '/', id, '/', 'set-eds-key'].join('').trim(),
    removeEdsKey: id => ['/employees', '/', id, '/', 'remove-eds-key'].join('').trim(),
    exportExcel: _ => ['/employees', '/export-excel'].join('').trim()
  },
  employeeorder: {
    list: _ => '/employee-tax-orders',
    concrete: id => ['/employee-tax-orders', '/', id].join('').trim(),
    xmlDoc: id => ['/employee-tax-orders', '/', id, '/', 'xml-doc'].join('').trim(),
    taxRegister: id => ['/employee-tax-orders', '/', id, '/', 'send-to-tax'].join('').trim(),
    signTaxId: id => ['/employee-tax-orders', '/', id, '/signed-tax-id'].join('').trim(),
    error: id => ['/employee-tax-orders', '/', id, '/', 'error'].join('').trim(),
    changeDsPassword: id => ['/employee-tax-orders', '/', id, '/', 'change-ds-password'].join('').trim(),
    modifyInTax: _ => ['/employee-tax-orders', '/modify-in-tax'].join('').trim(),
    keyReplacement: _ => ['/employee-tax-orders', '/replacement'].join('').trim(),
    allowedDsRequest: _ => ['/employee-tax-orders', '/allowed-ds-request'].join('').trim(),
    validateReplacementKey: id => ['/employees', '/', id, '/', 'validate-replacement-key'].join('').trim(),
    checkLogin: _ => ['/employee-tax-orders', '/check-login'].join('').trim(),
    depositSignRequest: id => ['/deposit-sign-request', '/', id].join('').trim(),
    depositSignRequests: id => ['/deposit-sign-requests', '/', id].join('').trim()
  },
  get employeessearch () {
    return this.employee
  },
  get goodssearch () {
    return this.goods
  },
  get accountinggoodssearch () {
    return this.accountinggoods
  },
  get goodsgroupssearch () {
    return this.goodsgroups
  },
  get supplierssearch () {
    return this.suppliers
  },
  get labelssearch () {
    return this.labels
  },
  get organizationsearch () {
    return this.organization
  },
  suppliers: {
    list: _ => '/accounting/suppliers',
    concrete: id => ['/accounting/suppliers', '/', id].join('').trim()
  },
  labels: {
    list: _ => '/labels',
    concrete: id => ['/labels', '/', id].join('').trim()
  },
  supplies: {
    list: _ => '/accounting/supplies',
    addSupplyToProduct: id => ['/accounting/supplies', '/', id].join('').trim(),
    correctSupply: id => ['/accounting/supplies', '/', id, '/correct'].join('').trim()
  },
  goods: {
    list: _ => '/goods',
    concrete: id => ['/goods', '/', id].join('').trim(),
    uploadCsvFile: () => ['/goods', '/import-file'].join(''),
    downloadCsvFile: () => ['/goods', '/export-file'].join(''),
    deleteAll: () => ['/goods', '/delete-all'].join('').trim()
  },
  accountinggoods: {
    list: _ => '/accounting/goods',
    writeOff: () => ['/accounting/goods', '/write-off'].join('').trim(),
    concrete: id => ['/accounting/goods', '/', id].join('').trim(),
    addSupplyToProduct: id => ['/accounting/goods', '/', id, '/supplies'].join('').trim(),
    exportExcel: _ => '/accounting/export/goods/excel',
    exportCsv: _ => '/accounting/export/goods/csv',
    checkExportStatus: id => ['/accounting/export/goods', '/', id].join('').trim(),
    deleteAllStatus: id => ['/accounting/goods/check-status', '/', id].join('').trim(),
    exportFile: id => ['/accounting/export/goods/file', '/', id].join('').trim(),
    uploadExcel: _ => '/accounting/upload/goods/excel',
    uploadCsv: _ => '/accounting/upload/goods/csv',
    checkUploadStatus: id => ['/accounting/upload/goods', '/', id].join('').trim(),
    getUploadExample: type => ['/accounting/upload/goods/example', '/', type].join('').trim(),
    generateBarcode: _ => '/accounting/barcode'
  },
  accountinggoodssettings: {
    list: _ => '/accounting/settings',
    concrete: id => ['/accounting/settings', '/', id].join('').trim()
  },
  goodsgroups: {
    list: _ => '/accounting/goods-groups',
    concrete: id => ['/accounting/goods-groups', '/', id].join('').trim(),
    moveGoods: id => ['/accounting/goods-groups', '/', id, '/move-goods'].join('').trim()
  },
  permission: {
    list: _ => '/permissions',
    concrete: id => ['/permissions', '/', id].join('').trim()
  },
  outlet: {
    list: _ => '/outlets',
    listMultiAccount: _ => '/multi_account/outlets',
    randomCode: _ => ['/outlets', '/', 'rand-sequence'].join('').trim(),
    concrete: id => ['/outlets', '/', id].join('').trim(),
    taxRegister: id => ['/outlets', '/', id, '/', 'reg-tax'].join('').trim(),
    xmlDoc: id => ['/outlets', '/', id, '/', 'xml-doc'].join('').trim(),
    synchronize: _ => ['/outlets', '/', 'sync'].join('').trim(),
    error: id => ['/outlets', '/', id, '/', 'error'].join('').trim(),
    getCoordinates: id => ['/outlets', '/', id, '/', 'coordinates'].join('').trim(),
    getShort: _ => ['outlets', '/', 'get-short'].join('').trim()
  },
  outletorder: {
    list: _ => '/outlet-tax-orders',
    concrete: id => ['/outlet-tax-orders', '/', id].join('').trim(),
    xmlDoc: id => ['/outlet-tax-orders', '/', id, '/', 'xml-doc'].join('').trim(),
    taxRegister: id => ['/outlet-tax-orders', '/', id, '/', 'send-to-tax'].join('').trim(),
    signTaxId: id => ['/outlet-tax-orders', '/', id, '/signed-tax-id'].join('').trim(),
    error: id => ['/outlet-tax-orders', '/', id, '/', 'error'].join('').trim(),
    modifyInTax: _ => ['/outlet-tax-orders', '/modify-in-tax'].join('').trim()
  },
  get outletssearch () {
    return this.outlet
  },
  profilepermission: {
    list: _ => '/profile-permissions',
    concrete: id => ['/profile-permissions', '/', id].join('').trim()
  },
  mediaobject: {
    list: _ => '/media-objects',
    concrete: id => ['/media-objects', '/', id].join('').trim()
  },
  promocodeorganizations: {
    list: _ => '/promo-code-organizations'
  },
  organization: {
    list: _ => '/organizations',
    draft: id => ['/organizations', '/', id, '/draft'].join('').trim(),
    multiAccount: _ => '/organizations/multi-account',
    multiAccountArchivedOrganizations: _ => '/multi_account/organization/archived',
    multiAccountDelete: id => ['/multi_account/organization', '/', id].join('').trim(),
    concrete: id => ['/organizations', '/', id].join('').trim(),
    within (model, property = null) {
      return [routes[model.constructor.entity].concrete(model[model.constructor.primaryKey]), property].join('').trim()
    },
    synchronize: id => ['/organizations', '/', id, '/synchronization'].join('').trim(),
    cashRegisters: id => ['/organizations', '/', id, '/cash-registers'].join('').trim(),
    shifts: id => ['/organizations', '/', id, '/shifts'].join('').trim(),
    invoices: id => ['/organizations', '/', id, '/invoices'].join('').trim(),
    applyPromo: id => ['/organization', '/', id, '/apply-promo'].join('').trim(),
    sendTaxAuthData: _ => ['/tax', '/update-token'].join(''),
    generateTestData: id => ['/organizations', '/', id, '/generate-test-data'].join('').trim(),
    promoCodeOrganizations: id => ['/organizations', '/', id, '/promo-code-organizations'].join('').trim(),
    generateTelegramBotLink: id => ['/organizations', '/', id, '/generate-link'].join('').trim(),
    activationLogsOrganization: _ => '/call-center-activation-logs',
    adminAuthLogs: _ => '/admin-auth-logs',
    vip: id => ['/organizations', '/', id, '/set-vip'].join('').trim(),
    getRegStreet: id => ['/organizations', '/', id, '/reg-street'].join('').trim(),
    transitionToCheckbox: id => ['/organization', '/', id, '/transition-to-checkbox'].join('').trim()
  },
  tags: {
    list: _ => '/tags',
    concrete: id => ['/tags', '/', id].join('').trim()
  },
  get tagssearch () {
    return this.tags
  },
  cashregisters: {
    list: _ => '/cash-registers',
    listMultiAccount: _ => '/multi_account/cash-registers',
    concrete: id => ['/cash-registers', '/', id].join('').trim(),
    taxRegister: id => ['/cash-registers', '/', id, '/', 'reg-tax'].join('').trim(),
    xmlDoc: id => ['/cash-registers', '/', id, '/', 'xml-doc'].join('').trim(),
    activate: id => ['/cash-registers', '/', id, '/', 'activate'].join('').trim(),
    deactivate: id => ['/cash-registers', '/', id, '/', 'deactivate'].join('').trim(),
    updateSettings: id => ['/cash-registers', '/', id, '/', 'settings'].join('').trim(),
    activatePayable: id => ['/cash-registers', '/', id, '/', 'activate-payable'].join('').trim(),
    lastPrimaryKey: () => ['/cash-registers', '/', 'max-number'].join('').trim(),
    synchronize: _ => ['/cash-registers', '/', 'sync'].join('').trim(),
    error: id => ['/cash-registers', '/', id, '/', 'error'].join('').trim(),
    exportExcel: _ => ['/cash-registers', '/export-excel'].join('').trim(),
    checkOfflineTime: _ => ['/cash-registers', '/processing', '/check-offline-time'].join('').trim(),
    activateNotActive: id => ['/cash-registers', '/', id, '/activate-not-active'].join('').trim(),
    processingEmergencySetup: _ => ['/cash-registers', '/processing', '/emergency-setup'].join('')
  },
  cashregisterorder: {
    list: _ => '/cash-register-tax-orders',
    concrete: id => ['/cash-register-tax-orders', '/', id].join('').trim(),
    xmlDoc: id => ['/cash-register-tax-orders', '/', id, '/', 'xml-doc'].join('').trim(),
    taxRegister: id => ['/cash-register-tax-orders', '/', id, '/', 'send-to-tax'].join('').trim(),
    signTaxId: id => ['/cash-register-tax-orders', '/', id, '/signed-tax-id'].join('').trim(),
    error: id => ['/cash-register-tax-orders', '/', id, '/', 'error'].join('').trim(),
    removeFromTax: _ => ['/cash-register-tax-orders', '/remove-from-tax'].join('').trim(),
    modifyInTax: _ => ['/cash-register-tax-orders', '/modify-in-tax'].join('').trim()
  },
  get cashregisterssearch () {
    return this.cashregisters
  },
  shifts: {
    list: _ => '/shifts',
    listMultiAccount: _ => '/shifts/multi-account',
    concrete: id => ['/shifts', '/', id].join('').trim(),
    processingCurrent: _ => ['/shift', '/', 'processing'].join('').trim(),
    processingCloseCurrent: _ => ['/shift', '/processing', '/close'].join('').trim(),
    processingGetShift: id => ['/shift', '/', id, '/', 'processing'].join('').trim(),
    processingCloseById: id => ['/shift', '/', id, '/', 'processing', '/', 'close'].join('').trim(),
    readZReport: id => ['/shifts', '/', id, '/z-report', '/text'].join('').trim()
  },
  taxes: {
    list: _ => '/taxes',
    concrete: id => ['/taxes', '/', id].join('').trim(),
    lastPrimaryKey: () => ['/tax', '/', 'free-code'].join('').trim()
  },
  acquiringinvoices: {
    list: _ => '/acquiring-invoices',
    concrete: id => ['/acquiring-invoices', '/', id].join('').trim(),
    createReceipt: id => ['/acquiring-invoices', '/', id, '/create-receipt'].join('').trim(),
    resetQr: id => ['/acquiring-invoices', '/', id, '/reset-qr'].join('').trim(),
    checkStatus: id => ['/acquiring-invoice', '/', id, '/status'].join('').trim()
  },
  ettnOrders: {
    list: _ => '/ettn-orders',
    concrete: id => ['/ettn-orders', '/', id].join('').trim(),
    createReceipt: id => ['/ettn-orders', '/', id, '/create-receipt'].join('').trim(),
    validate: _ => ['/ettn-orders', '/validate'].join('').trim()
  },
  receipts: {
    list: _ => '/receipts',
    listMultiAccount: _ => '/receipts/multi-account',
    concrete: id => ['/receipts', '/', id].join('').trim(),
    chart: _ => ['/receipts', '/get-chart'].join('').trim(),
    totalSum: _ => ['/receipts', '/get-total-sum'].join('').trim(),
    totalSumYear: _ => ['/receipt', '/get-total-sum-year'].join('').trim(),
    totalCountForCurrentYear: _ => ['/receipts', '/get-count'].join(''),
    processingRead: id => ['/receipt', '/processing', '/', id].join(''),
    processingReadPng: id => ['/receipt', '/processing', '/', id, '/png'].join(''),
    processingReadText: id => ['/receipt', '/processing', '/', id, '/text'].join(''),
    processingReadHtml: id => ['/receipt', '/processing', '/', id, '/html'].join(''),
    processingReadPdf: id => ['/receipt', '/processing', '/', id, '/pdf'].join(''),
    processingReadQrCode: id => ['/receipt', '/processing', '/', id, '/qrcode'].join(''),
    processingCreate: _ => ['/receipt', '/processing'].join(''),
    processingCreateService: _ => ['/receipt', '/processing', '/service'].join(''),
    processingExport: _ => ['/receipt', '/processing', '/export'].join(''),
    processingExportStatus: id => ['/receipt', '/processing', '/export-status', '/', id].join(''),
    processingExportGetReceipts: id => ['/receipt', '/processing', '/export', '/', id].join(''),
    readText: id => ['/receipts', '/', id, '/text'].join(''),
    readHtml: id => ['/receipts', '/', id, '/html'].join(''),
    readPdf: id => ['/receipts', '/', id, '/pdf'].join(''),
    sendToEmail: id => ['/receipt', '/processing', '/', id, '/email'].join(''),
    settings: _ => '/receipt-settings',
    returnReceipt: _ => '/return-receipts',
    returnReceiptValidate: _ => '/return-receipts/validate',
    returnReceiptReset: _ => '/return-receipts/reset',
    sendToSms: id => ['/receipt', '/processing', '/', id, '/sms'].join(''),
    salesChart: _ => ['/sales-chart'].join(''),
    salesChartInfo: _ => ['/sales-chart', '/info'].join('')
  },
  receiptgoods: {
    list: _ => '/receipt-goods'
  },
  taxtypeoutlets: {
    list: _ => '/type-tax-objects'
  },
  uktzeds: {
    list: _ => '/uktzeds'
  },
  uktzeddictionary: {
    list: _ => '/uktzeds',
    onlyRootLevel: _ => '/uktzeds?exists[parent]=false',
    children: id => ['/uktzeds', '/', id, '/childrens'].join('').trim()
  },
  koatuudictionary: {
    list: _ => '/koatuus',
    onlyRootLevel: _ => '/koatuus?exists[parent]=false',
    children: id => ['/koatuus', '/', id, '/childrens'].join('').trim()
  },
  katottgdictionary: {
    list: _ => '/katottgs',
    onlyRootLevel: _ => '/katottgs?exists[parent]=false',
    children: id => ['/katottgs', '/', id, '/childrens'].join('').trim(),
    getAddress: id => ['/katottg/to-address', '/', id].join('').trim()
  },
  taxcodesdictionary: {
    list: _ => '/stis',
    onlyRootLevel: _ => '/stis?exists[parent]=false',
    children: id => ['/stis', '/', id, '/childrens'].join('').trim()
  },
  onBoardings: {
    list: _ => '/on-boardings',
    concrete: id => ['/on-boardings', '/', id].join('').trim(),
    concreteSteps: id => ['/on-boardings', '/', id, '/steps'].join('').trim(),
    steps: _ => '/on-boarding-steps',
    concreteStep: id => ['/on-boarding-steps', '/', id].join('').trim()
  },
  reports: {
    processingCreateXReport: _ => ['/report/processing'].join('').trim(),
    processingRead: id => ['/report', '/processing', '/', id],
    processingReadAsText: id => ['/report', '/processing', '/', id, '/text'].join('').trim(),
    periodicReport: _ => ['/report', '/processing', '/periodical'].join('').trim(),
    report: _ => ['/report', '/processing', '/report'].join('').trim(),
    checkStatus: _ => ['/report', '/processing', '/check-status'].join('').trim(),
    excel: _ => ['/report', '/processing', '/get-excel'].join('').trim(),
    goods: _ => ['/report', '/processing', '/goods'].join('').trim(),
    shifts: _ => ['/report', '/processing', '/shift'].join('').trim(),
    groups: _ => '/report-groups',
    concreteGroup: id => ['/report-groups', '/', id].join('').trim(),
    multiAccountGoods: _ => '/multi_account/report/goods',
    multiAccountZReport: _ => '/multi_account/report/z'
  },
  taxinfo: {
    list: _ => '/report/tax-info'
  },
  financialreport: {
    list: _ => '/report/goods-financial',
    downloadCsvFile: _ => '/report/goods-financial/csv',
    downloadExcelFile: _ => '/report/goods-financial/excel'
  },
  invoices: {
    list: _ => '/invoices',
    concrete: id => ['/invoices', '/', id].join('').trim(),
    cashRegisterPeriods: id => ['/invoices', '/', id, '/cash-register-periods'].join('').trim(),
    readPdf: _ => ['/invoices', '/report', '/pdf'].join('').trim(),
    getWaybillXml: id => ['/invoices', '/', id, '/get-xml'].join('').trim(),
    sendSignedXml: id => ['/invoices', '/', id, '/send-xml '].join('').trim(),
    regenerate: id => ['/invoices', '/', id, '/regenerate '].join('').trim(),
    getWaybillXmlParams: id => ['/invoices', '/', id, '/get-xml-params'].join('').trim(),
    setFree: id => ['/invoices', '/', id, '/set-free'].join('').trim(),
    getFile: id => ['/invoices', '/', id, '/get-file'].join('').trim(),
    paymentLink: _ => ['/ipay', '/link'].join('').trim(),
    googlePayPayment: _ => ['/ipay', '/google-pay-payment'].join('').trim(),
    applePayValidation: _ => ['/payment', '/apple-pay-merchant-validate'].join('').trim(),
    applePayPayment: _ => ['/ipay', '/apple-pay-payment'].join('').trim(),
    paymentInfo: _ => ['/payment', '/info'].join('').trim(),
    billingPeriods: _ => ['/periods'].join('').trim(),
    getExcelList: tagId => ['/invoice', '/get-excel-list', '?tag=', tagId].join('').trim()
  },
  get invoicessearch () {
    return this.invoices
  },
  billings: {
    list: _ => '/billings',
    organizationBilling: id => ['/organizations', '/', id, '/billing'].join('').trim(),
    ccActive: id => ['/billings', '/', id, '/cc-active'].join('').trim(),
    generateInvoice: id => ['/billings', '/', id, '/generate-invoice'].join('').trim(),
    concrete: id => ['/billings', '/', id].join('').trim(),
    unsetAutoPayment: id => ['/billings', '/', id, '/', 'disable-auto-payment'].join('').trim(),
    reconciliationAct: id => ['/billings', '/', id, '/', 'generate-reconciliation'].join('').trim(),
    reconciliationActOnAllOrganizations: _ => ['/billings', '/', 'generate-reconciliation'].join('').trim(),
    changeBillingDate: id => ['/billings', '/', id, '/', 'change-billing-date'].join(''),
    activationCount: id => ['/billings', '/', id, '/', 'activation-count'].join(''),
    ssiSetting: id => ['/billings', '/', id, '/', 'ssi'].join(''),
    getPrices: id => ['/billings', '/', id, '/', 'prices'].join('')
  },
  smsAct: {
    list: _ => ['/sms', '/acts'].join('').trim(),
    getPdf: id => ['/sms', '/acts', '/', id, '/pdf'].join('').trim()
  },
  smsBilling: {
    list: _ => ['/sms', '/billings'].join('').trim(),
    generatePaymentLink: _ => ['/sms', '/payments', '/generate-link'].join('').trim()
  },
  smsInvoice: {
    list: _ => ['/sms', '/invoices'].join('').trim(),
    getPdf: id => ['/sms', '/invoices', '/', id, '/pdf'].join('').trim(),
    generateInvoice: _ => ['/sms', '/invoices'].join('').trim()
  },
  payment: {
    list: _ => '/payments',
    concrete: id => ['/payments', '/', id].join('').trim()
  },
  taxbills: {
    list: _ => '/tax-bills',
    concrete: id => ['/tax-bills', '/', id].join('').trim(),
    taxRegister: id => ['/tax-bills', '/', id, '/', 'reg-tax'].join('').trim(),
    xmlDoc: id => ['/tax-bills', '/', id, '/', 'xml-doc'].join('').trim()
  },
  acts: {
    list: _ => '/acts',
    concrete: id => ['/acts', '/', id].join('').trim(),
    getFile: id => ['/acts', '/', id, '/get-file'].join('').trim(),
    changeActSignatory: id => ['/acts', '/', id, '/signatory-name'].join('').trim()
  },
  get actssearch () {
    return this.acts
  },
  promocodes: {
    list: _ => '/promo-codes'
  },
  receiptpaymentmethod: {
    list: _ => '/receipt-payment-methods',
    concrete: id => ['/receipt-payment-methods', '/', id].join('').trim()
  },
  edsKey: {
    check: _ => '/eds-keys/check'
  },
  receiptconfig: {
    config: _ => '/receipt-config/processing',
    logo: _ => '/receipt-config/processing/receipt-logo',
    logoText: _ => '/receipt-config/processing/receipt-text-logo',
    receiptHtml: _ => '/receipt-config/processing/render-html',
    receiptSafeHtml: _ => '/receipt-config/processing/render-safe-html',
    receiptPng: _ => '/receipt-config/processing/render-png'
  },
  referrals: {
    list: _ => '/organization-bonus'
  },
  notifications: {
    list: _ => '/notifications',
    userList: _ => '/notification',
    concrete: id => ['/notifications', '/', id].join('').trim(),
    createFormFile: _ => '/notification/from-file',
    checkNew: _ => '/notification/check-new',
    readAll: _ => '/notification/mark-as-read'
  },
  transferrequests: {
    list: _ => '/transfer-requests',
    concrete: id => ['/transfer-requests', '/', id].join('').trim(),
    execute: id => ['/transfer-requests', '/', id, '/execute'].join('').trim(),
    decline: id => ['/transfer-requests', '/', id, '/decline'].join('').trim()
  },
  weareworking: {
    list: _ => '/lun-uas',
    concrete: id => ['/lun-uas', '/', id].join('').trim()
  },
  taxreports: {
    list: _ => '/tax/reports',
    synchronization: _ => '/report/synchronization',
    reportPdf: id => ['/report', '/', id, '/doc'].join('').trim(),
    reportHtml: id => ['/report', '/', id, '/kvt-html'].join('').trim(),
    report: _ => '/report-tax-orders',
    getReportInfo: _ => '/report-tax-orders/info',
    reportXmlDoc: id => ['/report-tax-orders', '/', id, '/xml-doc'].join('').trim(),
    reportSendToTax: id => ['/report-tax-orders', '/', id, '/send-to-tax'].join('').trim(),
    reportTaxId: id => ['/report-tax-orders', '/', id, '/signed-tax-id'].join('').trim(),
    getReportHtml: id => ['/report-tax-orders', '/', id, '/html'].join('').trim(),
    getReportDraftHtml: _ => ['/report-tax-orders', '/draft-html'].join('').trim(),
    temporaryReport: _ => '/temporary-report-tax-orders',
    getTemporaryReportInfo: _ => '/temporary-report-tax-orders/info',
    temporaryReportXmlDoc: id => ['/temporary-report-tax-orders', '/', id, '/xml-doc'].join('').trim(),
    temporaryReportSendToTax: id => ['/temporary-report-tax-orders', '/', id, '/send-to-tax'].join('').trim(),
    temporaryReportTaxId: id => ['/temporary-report-tax-orders', '/', id, '/signed-tax-id'].join('').trim(),
    getTemporaryReportHtml: id => ['/temporary-report-tax-orders', '/', id, '/html'].join('').trim(),
    getTemporaryReportDraftHtml: _ => ['/temporary-report-tax-orders', '/draft-html'].join('').trim(),
    annualReport: _ => '/annual-report-tax-orders',
    getAnnualReportInfo: _ => '/annual-report-tax-orders/info',
    annualReportXmlDoc: id => ['/annual-report-tax-orders', '/', id, '/xml-doc'].join('').trim(),
    annualReportSendToTax: id => ['/annual-report-tax-orders', '/', id, '/send-to-tax'].join('').trim(),
    annualReportTaxId: id => ['/annual-report-tax-orders', '/', id, '/signed-tax-id'].join('').trim(),
    getAnnualReportHtml: id => ['/annual-report-tax-orders', '/', id, '/html'].join('').trim(),
    getAnnualReportDraftHtml: _ => ['/annual-report-tax-orders', '/draft-html'].join('').trim()
  },
  turnoversettings: {
    list: _ => '/turnover-export-settings',
    concrete: id => ['/turnover-export-settings', '/', id].join('').trim(),
    makeExport: id => ['/turnover-export-settings', '/', id, '/make-export-file'].join('').trim(),
    sentToEmail: id => ['/turnover-export-settings', '/', id, '/send'].join('').trim()
  },
  reviews: {
    list: _ => '/feedback',
    concrete: id => ['/feedback', '/', id].join('').trim(),
    process: id => ['/feedback', '/', id, '/process'].join('').trim(),
    close: id => ['/feedback', '/', id, '/close'].join('').trim()
  },
  offlinestatistic: {
    list: _ => '/offline-statistic'
  },
  managerusers: {
    list: _ => '/manager-users',
    concrete: id => ['manager-users', '/', id].join('')
  },
  acquiring: {
    list: _ => '/terminals',
    monoTerminals: _ => '/terminals/mono',
    concrete: id => ['/terminals', '/', id].join(''),
    oschad: _ => ['/terminals', '/oschad'].join(''),
    oschadUnlink: _ => ['/terminals', '/oschad', '/unlink'].join('')
  },
  posterminals: {
    list: _ => '/pos-terminals',
    concrete: id => ['/pos-terminals', '/', id].join('')
  },
  merchants: {
    list: _ => '/merchants',
    concrete: id => ['/merchants', '/', id].join(''),
    oschad: _ => ['/merchants', '/oschad'].join('')
  },
  providers: {
    list: _ => '/providers',
    concrete: id => ['/providers', '/', id].join('')
  },
  abank: {
    getClientStatus: _ => '/acquiring/pos/onboarding/abank/check-client'
  },
  oschadbank: {
    createOrder: _ => '/oschadbank-acquiring-orders',
    downloadQuestionnaire: _ => '/acquiring/pos/onboarding/oschadbank/questionnaire'
  },
  accountorder: {
    list: _ => '/account-orders',
    concrete: id => ['/account-orders', '/', id].join(''),
    getDoc: id => ['/account-orders', '/', id, '/get-doc'].join('').trim(),
    sendSigned: id => ['/account-orders', '/', id, '/send-signed'].join('').trim()
  },
  acquiringorder: {
    list: _ => '/acquiring-orders',
    concrete: id => ['/acquiring-orders', '/', id].join(''),
    getDoc: id => ['/acquiring-orders', '/', id, '/get-doc'].join('').trim(),
    sendSigned: id => ['/acquiring-orders', '/', id, '/send-signed'].join('').trim()
  },
  get providerssearch () {
    return this.providers
  },
  mcccodessearch: {
    list: _ => '/mccs'
    // list: _ => '/acquiring/pos/onboarding/abank/mcc/get-active'
  },
  clientbalances: {
    list: _ => '/client-balances',
    excel: _ => '/client-balances/excel'
  },
  refunds: {
    list: _ => 'refund-requests',
    concrete: id => ['refund-requests', '/', id].join(''),
    status: _ => 'refund-requests/status',
    getFile: id => ['refund-requests', '/', id, '/get-file'].join(''),
    getSignedFile: id => ['refund-requests', '/', id, '/get-signed'].join(''),
    sendFile: id => ['refund-requests', '/', id, '/signed-file'].join(''),
    approveRequest: id => ['refund-requests', '/', id, '/approve'].join(''),
    declineRequest: id => ['refund-requests', '/', id, '/decline'].join('')
  },
  offlinestatus: {
    list: _ => '/processing/offline/status'
  },
  monobank: {
    auth: _ => '/monobank/auth/request',
    temporaryLogin: _ => '/monobank/auth/temporary-login',
    authCheck: _ => '/monobank/auth/check',
    deepLink: _ => '/monobank/support/deep-link'
  },
  acquiringmono: {
    list: _ => '/monobank/terminal',
    terminalRegistration: _ => '/monobank/terminal/registration',
    terminalBind: _ => '/monobank/terminal/bind',
    tapToMonoCheckboxBind: _ => '/terminals/tap-to-mono-bind',
    unbind: _ => '/monobank/terminal/unbind',
    rebind: _ => '/monobank/terminal/rebind'
  },
  acquiringsettings: {
    list: _ => '/acquiring-settings',
    concrete: id => ['/acquiring-settings', '/', id].join('').trim()
  }
}

export default routes
