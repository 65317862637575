<template lang="pug">
  v-card(:class="{ 'org-form': true, 'org-form--registration': isRegistration }")
    v-row(justify="center" align="center" no-gutters)
      v-col(cols="12")
        h2(:class="['org-form__title', title && !orgExists && 'mb-8']") {{ title }}

        div(v-if="showOrgData")
          v-row(
            v-if="showOrgData[key]"
            v-for="(value, key, index) in showOrgData"
            :key="index"
          )
            v-col(cols="6")
              span {{ $t(key) }}:
            v-col(cols="6")
              span {{ $t(value) }}

        div(v-else-if="!orgExists")
          div(v-if="isPhysicalKey")
            p(class="my-0") {{ $t('Physical key error') }}
          div(v-else-if="notEnoughData")
            p(class="my-0") {{ $t('Something wrong with key') }}
          div(v-else-if="notIeOrLlc")
            h3(class="my-0 text-center") {{ $t('According to this key, no FOP or LLC is registered in the tax office') }}
          div(v-else)
            p(class="mt-0 mb-6") {{ $t('not to enter information manually') }}
            p(class="my-0") {{ $t('confirm by the digital signature') }}

        div(v-if="showManuallyData")
          p(class="org-form__text") {{ $t('But, unfortunately, some data are not available in the traffic police, so we suggest entering them manually') }}:
          ValidationObserver(
            ref="manuallyData"
            slim
          )
            ValidationProvider(
              v-if="!_.get(orgData, 'ownedOrganization.name')"
              :name="$t('Organization name')"
              vid="name"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="manuallyData.name"
                :label="$t('Organization name')"
                :error-messages="errors"
                outlined
              )
            ValidationProvider(
              v-if="showTaxationTypeChoose"
              :name="$t('The type of taxation')"
              vid="tax"
              mode="lazy"
              v-slot="{ errors }"
            )
              v-checkbox(
                :label="$t('I am a vat payer')"
                v-model="manuallyData.isVAT"
                class="mt-0"
              )
      v-col(cols="12" class="mt-8")
        div(class="org-form__btns-wrap")
          v-btn(
            v-if="isRegistration && !(showOrgData || orgExists || (isPhysicalKey || notEnoughData) || notIeOrLlc)"
            class="secondary-button"
            @click="skipStep"
            black
            outlined
          ) {{ $t('Later') }}
          e-button-main(
            :enableIcon="false"
            @click="btnClick"
            :loading="orgDataLoading"
          ) {{ btnText }}
        e-link(
          v-if="!(showOrgData || orgExists || (isPhysicalKey || notEnoughData) || notIeOrLlc)"
          href="https://checkbox.ua/yak-otrymaty-klyuch-ecp.html"
          class="mx-auto mt-5 d-block"
        ) {{ $t('How to get an EDS key?') }}
</template>

<script>
import EButtonMain from '~/components/elements/buttons/e-button-main'
import taxAuth from '~/mixins/pages/tax-auth'
import ELink from '~/components/elements/links/e-link'
import loadOrganizations from '~/middleware/loadOrganizations'
import loadCurrentUser from '~/middleware/loadCurrentUser'
import organization from '~/mixins/organization/organization'
import multiAccountUsers from '~/mixins/organization/multiAccountUsers'

export default {
  components: {
    ELink,
    EButtonMain
  },
  mixins: [taxAuth, organization, multiAccountUsers],
  props: {
    isRegistration: {
      type: Boolean,
      default: false
    },
    isMultiUser: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showOrgData: null,
    orgData: {
      ownedOrganization: {}
    },
    orgDataLoading: false,
    orgExists: false,
    notIeOrLlc: false,
    isPhysicalKey: false,
    notEnoughData: false,
    showManuallyData: false,
    manuallyData: {
      isVAT: null,
      name: null
    }
  }),
  computed: {
    showTaxationTypeChoose () {
      return !this._.get(this.orgData, 'ownedOrganization.isVAT') && !this._.get(this.orgData, 'ownedOrganization.isSingleTax')
    },
    title () {
      if (this.showOrgData) {
        return `${this.$t('Thank you')}, ${this.$t('we received the following data from the SFS')}:`
      } else if (this.orgExists) {
        return this.$t('Organization exists')
      } else if (this.isPhysicalKey || this.notEnoughData || this.notIeOrLlc) {
        return ''
      } else if (this.isRegistration) {
        return this.$t('Company information')
      } else if (this.isMultiUser) {
        return this.$t('Add organization')
      } else {
        return this.$t('You need to add an organization')
      }
    },
    btnText () {
      if (this.showOrgData) {
        return this.isRegistration ? this.$t('Continue') : this.$t('Add organization')
      } else if (this.orgExists) {
        return this.isRegistration ? this.$t('Please log in') : this.$t('Try with EDS key')
      } else if (this.isPhysicalKey || this.notEnoughData || this.notIeOrLlc) {
        return this.$t('Try with EDS key')
      } else {
        return this.$t('Get data from the SFS')
      }
    }
  },
  created () {
    this.destroyIitGlobalDialog()
  },
  methods: {
    skipStep () {
      this.$attrs.btnCall(this.$attrs.step, null)
    },
    async btnClick () {
      if (this.showManuallyData) {
        const valid = await this.$refs.manuallyData.validate()
        if (!valid) {
          return
        } else {
          const { name: manuallyName, isVAT } = this.manuallyData
          const name = manuallyName || this._.get(this.orgData, 'ownedOrganization.name', '')
          const shortName = this._.get(this.orgData, 'ownedOrganization.shortName', '') || name
          this.$set(this.orgData, 'ownedOrganization', {
            ...this._.get(this.orgData, 'ownedOrganization', {}),
            name,
            shortName,
            isVAT: !!isVAT,
            isSingleTax: !isVAT
          })
        }
      }

      if (this.showOrgData) {
        if (this.isRegistration) {
          this.$attrs.btnCall(this.$attrs.step, this.orgData)
        } else {
          await this.handleAddOrganization()
        }
      } else if (this.isRegistration && this.orgExists) {
        await this.$router.push('/auth/login')
      } else {
        await this.continueWithSyncData()
      }
    },
    async continueWithSyncData () {
      const { edsKeyData, sign } = await this.getEdsKeyData({
        fallback: this.continueWithSyncData
      })

      try {
        this.orgDataLoading = true

        const dataFromSfs = await this.getDataFromSfs({ edsKeyData, sign })

        if (this._.get(dataFromSfs, 'exists')) {
          this.orgExists = true
        } else if (!this._.get(dataFromSfs, 'registration')) {
          this.notIeOrLlc = true
        } else {
          const { organizationData, token, personShowType } = this.getOrganizationData({ dataFromSfs, edsKeyData })

          this.$set(this.$data, 'orgData', {
            ownedOrganization: organizationData,
            token
          })

          this.showOrgData = {
            Name: name,
            Edrpou: this._.get(organizationData, 'edrpou'),
            'Person type': this.$t(personShowType),
            Director: edsKeyData.subjFullName
          }

          if (!this._.get(organizationData, 'name') || (!this._.get(organizationData, 'isVAT') && !this._.get(organizationData, 'isSingleTax'))) {
            this.showManuallyData = true
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.$handlers.error(new Error('Failed registration. Info: ' + JSON.stringify({
          identity: edsKeyData.identity,
          sign
        })), this, true)
      } finally {
        this.orgDataLoading = false
      }
    },
    async handleAddOrganization () {
      try {
        this.orgDataLoading = true

        await this.addOrganization({
          organizationData: this._.get(this.orgData, 'ownedOrganization', null),
          token: this._.get(this.orgData, 'token', null),
          isMultiUser: this.isMultiUser
        })

        await loadCurrentUser({}, true)
        await loadOrganizations({}, true)

        if (this.isMultiUser) {
          this.updateAllOrganizationSelectedUserData()
        }

        this.$notification.success(this.$t('Organization successfully added'))
        this.$nextTick(() => {
          this.$attrs.closeModal(true)
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.orgDataLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.org-form {
  width: 100%;
  box-shadow: none;
  padding: 20px 0;

  &--registration {
    border-radius: 24px !important;
    overflow: hidden;
    max-width: 548px;
    margin: 0 auto;
    padding: 32px;
  }

  &__btns-wrap {
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  &__title {
    font-family: $card-title-font-family;
    font-size: 24px;
    line-height: 32px;
    word-break: break-word;
    text-align: center;
    font-weight: 500;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
    }
  }

  &__text {
    font-weight: 700;
    margin: 15px 0;
  }

  ::v-deep {
    .v-text-field {
      &--outlined {
        .v-label {
          top: 13px;

          &--active {
            background: #fff;
            padding: 0 5px;
            transform: translateY(-21px) scale(0.75);
          }
        }
      }
    }

    .v-input:not(.v-input--radio-group, .v-input--checkbox) {
      .v-input__slot {
        background: $input-bg-gray;
        border-radius: $btn-border-radius;
        height: 48px;
        padding-left: 16px !important;
        color: #161B25;
        font-size: 14px;
        position: relative;
        z-index: 2;

        fieldset {
          border: none;
        }

        .v-input__append-inner {
          margin-top: 12px;
        }
      }

      .v-input__control {
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% + 4px);
          height: 52px;
          left: -2px;
          top: -2px;
          background: $body-main-gradient;
          border-radius: 14px;
          z-index: 1;
          opacity: 0;
          visibility: hidden;
          transition: .3s;
        }
      }

      &.v-input--is-focused {
        .v-input__slot {
          background: #fff;
        }

        .v-input__control {
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>
