<template lang="pug">
  ValidationObserver(
    :ref="model.entity"
    slim
  )
    p(class="text") {{ $t('For the registration you need to pass cashier name and come up with cashier`s login and password') }}
    v-form(@submit.prevent.stop="saveAndSendToTax")
      t-orm-fields(
        v-if="renderFields"
        v-bind.sync="editedItem"
        :items="fields"
        :tabs="model.ormTabs"
      )
      p(class="text mb-0") {{$t('Your signature will be saved on a secure cloud service')}}
      div(class="actions")
        t-orm-buttons(
          ref="fields"
          :items="buttons"
          :key="fieldsKey"
          :classes="['text-right']"
        )
</template>

<script>
import Base64js from 'base64-js'
import { VRadio, VRadioGroup } from 'vuetify/lib'
import DOMPurify from 'dompurify'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import ELink from '~/components/elements/links/e-link'
import taxAuth from '~/mixins/pages/tax-auth'
import contentDialog from '~/mixins/dialogs/contentDialog'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import signPluginForOrganization from '~/mixins/dialogs/signPluginForOrganizationDialog'
import { TaxReportService } from '~/services/Tax/Report/TaxReportService'
import signPluginCommonDialog from '~/mixins/dialogs/signPluginCommonDialog'
import EInfoTooltip from '~/components/elements/tooltips/e-info-tooltip'
import TaxAuthService from '~/services/Tax/Auth/TaxAuthService'
import informationDialog from '~/mixins/dialogs/informationDialog'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import MFormDepositsignToken from '~/components/modules/forms/concrete/m-form-depositsign-token'
import responsive from '~/mixins/pages/responsive'

export default {
  components: {
    EInfoTooltip,
    EInputFileContent,
    TOrmButtons,
    TOrmFields,
    ELink,
    VRadio,
    VRadioGroup,
    MFormDepositsignToken
  },
  mixins: [validate, checkPropCtx, filterOrmObject, taxAuth, contentDialog, signPluginCommonDialog, signPluginForOrganization, informationDialog, responsive],
  props: {
    keyFile: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    fieldsKey: 1,
    renderFields: true,
    model: EmployeeOrder,
    editedItem: {},
    taxEmployeeLoading: false,
    uploadCashierEdsKey: true
  }),
  computed: {
    fields () {
      return this.model.ormFields
    },
    buttons () {
      return [
        {
          text: 'Continue',
          attrs: {
            class: 'main-button w-100',
            type: 'submit'
          },
          loading: this.taxEmployeeLoading,
          fullWidth: this.xsDevice
        }
      ]
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
    if (this.item) {
      this.setFields(this.item)
    }
  },
  methods: {
    rerenderFields () {
      // Note. Workaround to force rerender fields and set new values
      // TODO delete this when orm fields will be refactored
      this.renderFields = false
      return this.$nextTick().then(() => {
        this.renderFields = true
      })
    },
    setFields (data) {
      if (!data) {
        return
      }
      const newItem = Object.assign({}, this.editedItem, data)
      this.$set(this.$data, 'editedItem', newItem)
      this.rerenderFields()
    },
    setKeyFields () {
      const data = {
        keyFile: Base64js.fromByteArray(new Uint8Array(this.keyFile.keyFile)),
        keyFileCa: this.keyFile.ca,
        keyPassword: this.keyFile.keyPassword
      }
      this.setFields(data)
    },
    async saveAndSendToTax () {
      if (!await this.validate()) {
        return false
      }
      try {
        this.taxEmployeeLoading = true

        if (this.keyFile && this.uploadCashierEdsKey) {
          this.setKeyFields()
        }
        const payload = this.filterOrmObject(this.editedItem)
        const checkLoginResponse = await this.model.api().post(this.model.$routes[this.model.entity].checkLogin(), {
          login: payload.login
        }, { save: false })
        const login = payload.login
        const freeLogin = this._.get(checkLoginResponse, 'response.data.freeLogin', null)
        if (freeLogin) {
          await this.informationDialog.open({ text: this.$t('dialogs.information.loginOccupiedByAnotherUser', { login: DOMPurify.sanitize(login), freeLogin }), width: '600px' })
          this.editedItem.login = freeLogin
          await this.rerenderFields()
          return
        }
        await this.signPluginForOrganization.use()
        const keyData = this._.get(this.signPluginForOrganization, 'interface.keyData', null)
        const sign = this._.get(this.signPluginForOrganization, 'interface.sign')
        const edsKeyData = new EdsKeyData(keyData)

        if (!keyData) {
          return this.signPluginForOrganization.close()
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          await this.signPluginForOrganization.close()
          return this.saveAndSendToTax()
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          await this.signPluginForOrganization.close()
          return this.saveAndSendToTax()
        }

        try {
          const res = await TaxAuthService.sendAuthData(keyData, data => sign(data, { fallbackMethod: this.saveAndSendToTax }))
          if (!res) {
            return null
          }
        } catch (e) {
          this.$handlers.error(e, this, true)
        }

        const reportService = new TaxReportService(this.signPluginForOrganization.interface, this.saveAndSendToTax)

        payload.T1RXXXXG2S = this._.get(this.$Organization, 'edrpou', null) || this._.get(this.$Organization, 'taxNumber', null)
        payload.HPOST = 'Директор'
        payload.HKBOS = reportService.getHKBOSField({
          ctx: this,
          entity: this._.get(this.model, 'entity'),
          type: 'creation'
        })

        const re = /([0-9]{10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}|[0-9]{9})/

        if (!re.test(payload.HKBOS)) {
          this.$handlers.error(this.$t('It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents'), this)
          return
        }

        if (!payload.HKBOS) {
          this.$handlers.error(this.$t('We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key'), this)
          return
        }

        const createdEntity = this._.get(await this.model.api().create(payload), `entities.${this.model.entity}[0]`, null)
        // The end of deposit sign creation employee

        const xmlResponse = await this.model.api().xmlDoc(createdEntity)
        const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
        const fname = this._.get(xmlResponse, 'response.data.fname', null)
        const signData = await reportService.getEnvelopedData(xml)

        if (!signData) {
          return null
        }

        const taxId = this._.get(await this.model.api().taxRegister(createdEntity, { signData, fname }), 'response.data.response.taxId', null)
        const encodedTaxId = new TextEncoder().encode(String(taxId))
        const signedTaxId = await reportService.getEnvelopedData(encodedTaxId)

        if (!signedTaxId) {
          return null
        }

        await this.model.api().signTaxId(createdEntity, { tax_id: signedTaxId })

        this.$notification.success('entityActions.create.employeeorder')

        await this.signPluginForOrganization.hide()
        this.$emit('success')
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
        await this.signPluginForOrganization.hide()
      } finally {
        this.taxEmployeeLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  font-size: 14px;
  line-height: 1.4;
  color: #7C7C7C;
  font-weight: 300;
  margin-bottom: 32px;
}

.actions {
  padding: 32px 0 20px;
}
</style>
