import { render, staticRenderFns } from "./block-acquiring-cash-register-qr.vue?vue&type=template&id=2d18ecce&scoped=true&lang=pug&"
import script from "./block-acquiring-cash-register-qr.vue?vue&type=script&lang=js&"
export * from "./block-acquiring-cash-register-qr.vue?vue&type=script&lang=js&"
import style0 from "./block-acquiring-cash-register-qr.vue?vue&type=style&index=0&id=2d18ecce&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d18ecce",
  null
  
)

export default component.exports