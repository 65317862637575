<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ $t('Editing the graphic display of the check') }}
    div()
      v-row
        v-col(cols="12" sm="6" class="pt-7")
          v-form(@submit.prevent.stop="saveReceiptHtmlTemplate")
            v-text-field(
              v-model="receiptConfig.html_title"
              :label="$t('HTML page title')"
              outlined
            )
            v-text-field(
              v-model="receiptConfig.email_subject"
              :label="$t('Email list topic')"
              outlined
            )
            div(v-if="logo" class="d-flex align-center mb-6")
              img(
                :src="logo"
                alt="Receipt logo"
                class="dialog__logo mr-2"
              )
              v-tooltip(right)
                template(#activator="{on}")
                  v-btn(
                    v-on="on"
                    @click="deleteReceiptHtmlLogo"
                    :loading="logoLoading"
                    icon
                  )
                    e-svg-icon(size="sm") trash
                | {{ $t('Delete receipt logo') }}
            ValidationObserver(
              ref="logoHtmlInput"
              v-else
            )
              e-input-file-content(
                :value="logo"
                :label="$t('Receipt logo')"
                expect="dataUrl"
                accept=".png"
                ref="logoInput"
                rules="ext:png|size:1024"
                prepend-icon=""
                append-icon="$file"
                @input="updateReceiptHtmlLogo"
                :loading="logoLoading"
                outlined
              )
            wysiwyg(
              v-model="receiptConfig.html_global_header"
              :placeholder="$t('Receipt header')"
              @change="onReceiptHtmlHeaderInput"
              class="app-wysiwyg-editor"
            )
            wysiwyg(
              v-model="receiptConfig.html_global_footer"
              :placeholder="$t('Receipt footer')"
              @change="onReceiptHtmlFooterInput"
              class="app-wysiwyg-editor mt-7"
            )
            v-btn(
              :class="{ 'main-button mt-7': true, 'w-100': xsDevice }"
              :loading="loading"
              type="submit"
            ) {{ $t('Save') }}
        v-col(
          v-if="!xsDevice"
          cols="6"
        )
          div(
            v-if="loading"
            class="dialog__loader"
          )
            e-progress-circular(
              color="#161b25"
              size="xl"
              width="4"
            )
          div(
            ref="receiptHtml"
            v-dompurify-html="receiptHtml"
            v-else
          )
</template>

<script>
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockSettingsHtmlReceiptForm',
  components: {
    EProgressCircular,
    EInputFileContent,
    ESvgIcon
  },
  mixins: [responsive],
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    receipt: {
      type: String,
      default: null
    },
    logoHtml: {
      type: String,
      default: null
    },
    getReceiptHtml: {
      type: Function,
      default: () => {}
    },
    updateReceiptConfig: {
      type: Function,
      default: () => {}
    },
    onConfigChange: {
      type: Function,
      default: () => {}
    },
    onReceiptChange: {
      type: Function,
      default: () => {}
    },
    onLogoChange: {
      type: Function,
      default: () => {}
    },
    model: {
      type: null,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    logoLoading: false,
    receiptLoading: false,
    receiptHtml: null,
    receiptConfig: {},
    logo: null,
    madeInputsFocusable: false
  }),
  computed: {
    receiptEl () {
      return this._.get(this.$refs, 'receiptHtml', null)
    },
    orgId () {
      return this._.get(this.$Organization, 'id', null)
    }
  },
  created () {
    this.receiptConfig = JSON.parse(JSON.stringify(this.config))
    this.receiptHtml = JSON.parse(JSON.stringify(this.receipt))
    this.logo = JSON.parse(JSON.stringify(this.logoHtml))
  },
  updated () {
    this.makeEditorLinksClickable()
  },
  methods: {
    makeEditorLinksClickable () {
      if (!this.madeInputsFocusable) {
        const editors = document.querySelectorAll('.app-wysiwyg-editor')
        if (editors.length) {
          this._.each(editors, (editor) => {
            const inputs = editor.querySelectorAll('[title=Hyperlink] input')
            if (inputs.length) {
              this._.each(inputs, (input) => {
                input.addEventListener('click', (e) => {
                  input.focus()
                })
              })
              this.madeInputsFocusable = true
            }
          })
        }
      }
    },
    onReceiptHtmlHeaderInput (val) {
      const header = this.receiptEl.querySelector(`#cr-organization-header-${this.orgId}`)
      header.innerHTML = val
    },
    onReceiptHtmlFooterInput (val) {
      const footer = this.receiptEl.querySelector(`#cr-organization-footer-${this.orgId}`)
      footer.innerHTML = val
    },
    async saveReceiptHtmlTemplate () {
      try {
        this.loading = true
        if (
          this._.get(this.receiptConfig, 'html_title') !== this._.get(this.config, 'html_title') ||
          this._.get(this.receiptConfig, 'email_subject') !== this._.get(this.config, 'email_subject') ||
          this._.get(this.receiptConfig, 'html_global_header') !== this._.get(this.config, 'html_global_header') ||
          this._.get(this.receiptConfig, 'html_global_footer') !== this._.get(this.config, 'html_global_footer')
        ) {
          await this.updateReceiptConfig(this.receiptConfig)
          this.onConfigChange(this.receiptConfig)
          if (!this.xsDevice) {
            this.receiptLoading = true
            this.receiptHtml = await this.getReceiptHtml()
            this.onReceiptChange(this.receiptHtml)
          }
        }
        this.$notification.success('Receipt settings is saved successfully')
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.receiptLoading = false
        this.loading = false
      }
    },
    async updateReceiptHtmlLogo (logo = '') {
      try {
        if (logo) {
          this.logoLoading = true
          const valid = await this.$refs.logoHtmlInput.validate()
          if (!valid) {
            return
          }
          const logoBase64 = logo.split(',')[1]
          await this.model.api().updateReceiptHtmlLogo({
            file: logoBase64
          })
          this.logo = logo
          this.onLogoChange(this.logo)
          if (!this.xsDevice) {
            this.receiptLoading = true
            this.receiptHtml = await this.getReceiptHtml({
              html_logo: logoBase64
            })
            this.onReceiptChange(this.receiptHtml)
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.logo = null
      } finally {
        this.receiptLoading = false
        this.logoLoading = false
      }
    },
    async deleteReceiptHtmlLogo () {
      try {
        this.logoLoading = true
        await this.model.api().deleteReceiptHtmlLogo()
        this.logo = null
        this.onLogoChange(this.logo)
        if (!this.xsDevice) {
          this.receiptLoading = true
          this.receiptHtml = await this.getReceiptHtml({
            html_logo: null
          })
          this.onReceiptChange(this.receiptHtml)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.receiptLoading = false
        this.logoLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    width: 100%;
    height: 100%;
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: auto;
    max-width: 145px;
  }
}
</style>
