import { Model } from '@vuex-orm/core'
import moment from 'moment'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Employees from '~/modules/employees/models/Employees'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import TaxTypeOutlets from '~/models/classifiers/TaxTypeOutlets'
import { contexts, formats } from '~/const/global'
import TaxCodesDictionary from '~/models/directories/dictionaries/TaxCodesDictionary'
import Outlets from '~/modules/outlets/models/Outlets'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import KatottgDictionary from '~/models/directories/dictionaries/KatottgDictionary'

export class OutletOrder extends PersistAble(OrmModel) {
  static entity = 'outletorder'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'dateSend'
  static defaultSortOrder = true
  static dblClickAction = 'read'
  static persistOptions = {
    insertOrUpdate: [Employees.entity, CashRegisters && CashRegisters.entity]
  }

  static STAN_OBJECT = [
    {
      text: 'Будується / готується до введення в експлуатацію',
      value: '1'
    },
    {
      text: 'Експлуатується',
      value: '2'
    },
    {
      text: 'Тимчасово не експлуатується',
      value: '3'
    },
    {
      text: 'Непридатний до експлуатації',
      value: '5'
    },
    {
      text: 'Об\'єкт відчужено / повернено власнику',
      value: '6'
    },
    {
      text: 'Зміна призначення / перепрофілювання',
      value: '7'
    },
    {
      text: 'орендується',
      value: '8'
    },
    {
      text: 'Здається у оренду',
      value: '9'
    }
  ]

  static TYPE_OF_RIGHTS = [
    {
      text: 'приміщення у власності',
      sfsValue: 'право власностi',
      value: '1'
    },
    {
      text: 'право постійного користування',
      sfsValue: 'право постiйного користування',
      value: '6'
    },
    {
      text: 'приміщення в оренді',
      sfsValue: 'право короткострокового користування оренди або найму',
      value: '8'
    }
  ]

  static orderStatuses = {
    100: {
      text: 'Створено',
      value: 100,
      type: 'order'
    },
    200: {
      text: 'На реєстрації в дпс',
      value: 200,
      type: 'order'
    },
    300: {
      text: 'Зареєстровано',
      value: 300,
      type: 'checkbox'
    },
    400: {
      text: 'Помилка',
      value: 400,
      type: 'error'
    }
  }

  static orderTypes = {
    100: {
      text: 'Створення',
      value: 100,
      type: 'checkbox'
    },
    200: {
      text: 'Оновлення',
      value: 200,
      type: 'order'
    },
    300: {
      text: 'Видалення',
      value: 300,
      type: 'error'
    }
  }

  static orderStatusType = {
    modify: {
      value: '3'
    },
    deactivate: {
      value: '6'
    }
  }

  static fields () {
    return {
      id: this.attr(null),

      // Найменування  - name
      T1RXXXXG4S: this.attr(null),
      // Тип - reqTypeObject
      T1RXXXXG3: this.attr(null),
      // Ідентифікатор об’єкта оподаткування - tocode = reqTypeObject + 5 digits number
      T1RXXXXG5: this.attr(null),
      // Код за КАТОТТГ
      T1RXXXXG6S: this.attr(null),
      // Місцезнаходження - address
      T1RXXXXG8S: this.attr(null),
      // Стан - reqStateObject
      T1RXXXXG9: this.attr(null),
      // Вид права - reqTypeOfRightsObject
      T1RXXXXG10: this.attr(null),
      // Адреса
      T1RXXXXG7S: this.attr(null),

      dateSend: this.attr(null),
      status: this.attr(null),
      type: this.attr(null),
      hasErrorFile: this.attr(null),
      taxErrorMessage: this.attr(null)
    }
  }

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) {
            return {}
          }
          const orderStatus = this.orderStatuses[item.status]

          return {
            id: item.id,
            model: OutletOrder,
            [item.status]: {
              text: orderStatus.text,
              type: orderStatus.type,
              taxErrorMessage: item.taxErrorMessage,
              hasErrorFile: item.hasErrorFile
            }
          }
        }
      }
    }
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', tableHeader: 'modeString', align: 'left', width: '150', sortable: true, filterable: false },
    { text: 'Name ', value: 'T1RXXXXG4S', tableHeader: 'name', filterable: true },
    { text: 'Address', value: 'T1RXXXXG8S', tableHeader: 'addressString', sortable: true, filterable: true, sortQuery: 'address' },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormTrans = {
    single: 'Outlet',
    multy: 'Outlets',
    notificationSingle: 'Outlet ',
    notification: 'Outlet'
  }

  static ormFields = [
    {
      model: 'T1RXXXXG4S',
      component: 'v-text-field',
      provider: {
        vid: 'T1RXXXXG4S',
        name: 'Outlet name',
        rules: 'required|max:50'
      },
      attrs: {
        label: 'Outlet name',
        outlined: true
      },
      hint: 'For example, hotels'
    },
    {
      model: 'T1RXXXXG9',
      component: 'v-select',
      provider: {
        vid: 'T1RXXXXG9',
        name: 'State object',
        rules: 'required'
      },
      attrs: {
        returnObject: false,
        label: 'State object',
        outlined: true,
        visible: false
      },
      default: this.STAN_OBJECT[1].value,
      items: () => this.STAN_OBJECT
    },
    {
      model: 'T1RXXXXG10',
      component: 'v-select',
      provider: {
        vid: 'T1RXXXXG10',
        name: 'Type of point of sale',
        rules: 'required'
      },
      attrs: {
        returnObject: false,
        label: 'Type of point of sale',
        outlined: true
      },
      items: () => this.TYPE_OF_RIGHTS,
      default: this.TYPE_OF_RIGHTS[0].value,
      hint: 'Own or rent'
    },
    new AutocompleteBuilder({
      model: 'T1RXXXXG3',
      label: 'Outlet type ',
      rules: 'required',
      query: model => model.api().rangeFilter({ position: [null, 1001] }),
      loadMoreQuery: model => model.api(),
      returnObject: false,
      fieldVal: ctx => this._.get(ctx.attrs, ctx.field.model),
      hint: 'Choose the one that suits you best'
    }, TaxTypeOutlets).build(),
    {
      model: 'code',
      component: 'v-text-field',
      asyncDefault: (items, lastCode) => {
        return lastCode
      },
      request: async (e) => {
        return this.contexts.isCreate(e.context)
          ? this._.get(await Outlets.api().randomCode(), 'response.data', null)
          : null
      },
      forcedRequest: true,
      provider: {
        vid: 'code',
        name: 'Code',
        rules: 'required|numeric|max:5|min:5'
      },
      attrs: {
        disabled: ctx => ctx.context === contexts.read,
        label: 'Code',
        outlined: true,
        dynamic: true,
        visible: false
      }
    },
    {
      model: 'T1RXXXXG6S',
      component: 'e-input-tree-select',
      label: {
        text: 'Address (KATOTTG Code)'
      },
      cols: {
        cols: 12
      },
      classes: ['mt-3'],
      attrs: {
        outlined: true,
        requestItems: 'onlyRootLevel',
        itemText: 'name',
        model: KatottgDictionary,
        providers: [
          {
            vid: 'katottgRegion',
            name: 'Choose region',
            rules: 'required'
          },
          {
            vid: 'katottgDistrict',
            name: 'Choose district',
            rules: 'required'
          },
          {
            vid: 'katottgTerritory',
            name: 'Choose territory',
            rules: 'required'
          },
          {
            vid: 'katottgSettlement',
            name: 'Choose settlement',
            rules: 'required'
          },
          {
            vid: 'katottgSettlementDistrict',
            name: 'Choose settlement district'
          }
        ],
        labelsConfig: {
          labelPattern: '{labelPrefix} {label}',
          labelPrefix: 'Choose',
          labels: ['Region', 'District', 'Local community', 'Settlement', 'Settlement district']
        }
      }
    },
    {
      model: 'T1RXXXXG8S',
      component: 'v-text-field',
      provider: {
        vid: 'T1RXXXXG8S',
        name: 'Street and number',
        rules: 'required|max:100'
      },
      attrs: {
        label: 'Street and number',
        outlined: true
      },
      hint: 'For example, Zhylyanska street, 41a'
    },
    {
      model: 'T1RXXXXG11',
      component: 'v-checkbox',
      attrs: {
        label: 'Choose setting for outlet creating',
        outlined: true,
        trueValue: '1',
        falseValue: '0'
      }
    },
    {
      model: 'HSTI',
      label: {
        text: 'State Tax Service',
        subText: 'Choose the one that serves the area where the outlet is located'
      },
      component: 'e-dialog-input',
      tooltip: 'Tax code',
      provider: {
        vid: 'HSTI',
        name: 'State Tax Service',
        rules: 'required'
      },
      requestParams: ['$User'],
      asyncDefault: (items, response) => {
        return response
      },
      request: async (e, $User) => {
        const organization = Model.store().$db().model('currentorganization').query().first()
        const taxRegion = this._.get(await TaxCodesDictionary.api().exists(['parent'], false).filter({ cReg: organization.region }).all(), 'entities.taxcodesdictionary[0]', null)
        if (taxRegion) {
          return this._.get(await TaxCodesDictionary.api().parent({ parent: taxRegion }).filter({ cRaj: organization.district }).all(), 'entities.taxcodesdictionary[0]', null)
        }
        return null
      },
      attrs: {
        class: 'mb-4',
        valuePattern: '{nameSti}',
        requestItems: 'onlyRootLevel',
        modalAttrs: {
          component: 'm-orm-dialog-tree-select',
          title: 'Select dpi',
          model: TaxCodesDictionary
        }
      }
    }
  ]

  static ormActions = [
    {
      name: 'delete',
      visible: item => item.status === 400,
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        const confirmed = await confirmationDialog.open({
          title: 'Delete error',
          text: 'Are you sure to delete this order?',
          width: '420px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          }
        })
        if (confirmed) {
          try {
            await OutletOrder.api().del(item)
            AppNotifications.success('Error order was deleted successfully')
          } catch (e) {
            // TODO add logs service and logs errors
            const message = this._.get(e, 'response.data.detail', null) || this._.get(e, 'response.data.message', null)
            AppNotifications.error(message)
          }
        }
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      notification: item => ({ type: 'order ', name: item.name })
    },
    read: {
      title: item => ({ type: 'Outlet  ', name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'dateSend',
            label: 'Date of created',
            value: val => moment(val).format(formats.dateTimeSec)
          },
          {
            model: 'T1RXXXXG4S',
            label: 'Name'
          },
          {
            model: 'T1RXXXXG10',
            label: 'Type of point of sale',
            value: (val) => {
              const stanObject = this._.filter(OutletOrder.TYPE_OF_RIGHTS, item => item.value === val)
              return this._.get(stanObject, '[0].text', '-')
            }
          },
          {
            model: 'T1RXXXXG9',
            label: 'State object',
            value: (val) => {
              const stanObject = this._.filter(OutletOrder.STAN_OBJECT, item => item.value === val)
              return this._.get(stanObject, '[0].text', '-')
            }
          },
          {
            model: 'T1RXXXXG6S',
            label: 'Katottg'
          },
          {
            model: 'T1RXXXXG7S',
            label: 'Address'
          },
          {
            model: 'T1RXXXXG8S',
            label: 'Street and number'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.xmlDoc = function (model) {
        return this.get(Model.$routes.outletorder.xmlDoc(model.$id), { save: false })
      }
      configActions.taxRegister = function (model, payload) {
        return this.post(Model.$routes.outletorder.taxRegister(model.$id), payload)
      }
      configActions.signTaxId = function (model, payload) {
        return this.put(Model.$routes.outletorder.signTaxId(model.$id), payload)
      }
      configActions.removeFromTax = function (payload) {
        return this.post(Model.$routes.outletorder.removeFromTax(), payload)
      }
      configActions.modifyInTax = function (payload) {
        return this.post(Model.$routes.outletorder.modifyInTax(), payload)
      }
      configActions.getActive = function () {
        OutletOrder.deleteAll()
        return this.all()
      }
      return configActions
    }
  }
}

export default OutletOrder
