<template lang="pug">
  v-row(v-if="model.ormFilters" justify="end")
    v-col(cols="12" class="py-0")
        t-orm-fields(v-bind.sync="filters" :items="model.ormFilters" :config="config")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'

export default {
  components: {
    TOrmFields
  },
  inheritAttrs: false,
  props: {
    model: {
      type: Function,
      required: true
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data: () => ({
    filters: {}
  }),
  computed: {
    config () {
      return this.model.getOrmFiltersConfig(this.type)
    }
  },
  watch: {
    filters: {
      handler (cur) {
        this.$emit('input', cur)
      },
      deep: true
    }
  },
  created () {
    this.$set(this.$data, 'filters', this.model.getOrmFiltersObject(null, null, { queryString: this.$route.query }))
  }
}
</script>

<style lang="scss">
  //
</style>
