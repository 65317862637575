<template lang="pug">
  div
    v-tabs(
      v-model="tab"
      class="pt-3 mb-3"
    )
      v-tab(:key="0") {{ $t('Prices') }}
      v-tab(:key="1") {{ $t('Discounts') }}
    v-tabs-items(v-model="tab" :show-arrows="false")
      v-tab-item(:key="0")
        v-row(class="caption font-weight-black")
          v-col() {{$t('Cash register price')}}
          v-col() {{$t('New cash register price')}}
          v-col() {{$t('Receipts limit')}}
          v-col() {{$t('Start date')}}
          v-col() {{$t('Added by')}}
        v-row(:class="{'caption': true, 'green-text': item.active}" v-for="(item, index) in prices" :key="index")
          v-col() {{item.cash_register_price/100}}
          v-col() {{item.price_for_new_cash_register/100}}
          v-col() {{item.overflow_limit}}
          v-col() {{item.start_at}}
          v-col() {{item.applied_by}}

      v-tab-item(:key="1")
        v-row(class="caption font-weight-black")
          v-col() {{$t('Discount')}}
          v-col() {{$t('Start date')}}
          v-col() {{$t('End date')}}
          v-col() {{$t('Added by')}}
        v-row(:class="{'caption': true, 'green-text': item.active}" v-for="(item, index) in discounts" :key="index")
          v-col() {{item.amount}} %
          v-col() {{item.start_at}}
          v-col() {{item.end_at}}
          v-col() {{item.applied_by}}
</template>

<script>
import EInputDatetime from '~/components/elements/inputs/e-input-datetime'
export default {
  components: { EInputDatetime },
  props: {
    prices: {
      type: Array,
      default: () => ([])
    },
    discounts: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    tab: 0
  }),
  methods: {
  }
}
</script>

<style scoped lang="scss">
  .green-text{
    color: green;
  }
</style>
