<template lang="pug">
  div
    block-acquiring-payment-qr(:qrLink="qrLink" :orderId="orderId" :receiptId="receiptId" @close="closeModal(false)" v-if="orderQr")
    block-acquiring-payment-link(:link="link" @close="closeModal" v-else)
</template>

<script>
import BlockAcquiringPaymentLink from '~/modules/receipt/views/components/blocks/block-acquiring-payment-link'
import BlockAcquiringPaymentQr from '~/modules/receipt/views/components/blocks/block-acquiring-payment-qr'

export default {
  components: { BlockAcquiringPaymentQr, BlockAcquiringPaymentLink },
  props: {
    order: {
      type: Object,
      required: true
    },
    qrLink: {
      type: String,
      default: null
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  computed: {
    orderQr () {
      return this._.get(this.order, 'qrId', null)
    },
    orderId () {
      return this._.get(this.order, 'id', null)
    },
    receiptId () {
      return this._.get(this.order, 'receiptId', null)
    },
    link () {
      return this._.get(this.order, 'pageUrl', null)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
