<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title")
      v-btn(
        v-if="formEmployee"
        class="back-arrow"
        @click="clear(true)"
        icon
      )
        e-svg-icon(size="sm") arrow-next
      span {{ $t(title) }}
    div(class="dialog__content")
      form-employee-upload-eds-key(
        v-if="uploadEdsKey"
        @select="handleEdsKeySelect"
      )
      form-employee(
        v-else-if="formEmployee"
        :item="item"
        :key-file="keyFile"
        @success="close"
      )
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import fill from '~/mixins/modules/dialogs/fill'
import FormEmployee from '~/modules/employees/views/components/form-employee'
import apm from '~/mixins/apm/apm'
import contentDialog from '~/mixins/dialogs/contentDialog'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import ELink from '~/components/elements/links/e-link'
import BlockEmployeeCreatingOptions from '~/modules/employees/views/components/block-employee-creating-options'
import FormEmployeeUploadEdsKey from '~/modules/employees/views/components/form-employee-upload-eds-key'
export default {
  components: {
    FormEmployee,
    ESvgIcon,
    ELink,
    TDialog,
    BlockEmployeeCreatingOptions,
    FormEmployeeUploadEdsKey
  },
  mixins: [fill, apm, contentDialog],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    item: null,
    keyFile: null,
    formEmployee: false,
    uploadEdsKey: true
  }),
  computed: {
    title () {
      return this.uploadEdsKey ? 'Read data from eds key' : 'Account access for cashiers'
    }
  },
  methods: {
    clear (comeback = false) {
      this.keyFile = null
      this.item = null

      if (comeback) {
        this.uploadEdsKey = true
        this.formEmployee = false
      }
    },
    close () {
      this.clear()
      this.closeModal(true)
    },
    handleEdsKeySelect (keyData) {
      if (!keyData) { return }

      const subjFullName = this._.get(keyData, 'keyData.certificate_info.certificate.pszSubjFullName', null)
      const subjDRFOCode = this._.get(keyData, 'keyData.certificate_info.certificate.pszSubjDRFOCode', null)
      const publicKeyID = this._.get(keyData, 'keyData.key_id', '')
      const issuerCN = this._.get(keyData, 'keyData.certificate_info.certificate.pszIssuerCN', null)
      const privKeyBeginTime = this._.get(keyData, 'keyData.certificate_start', null)
      const privKeyEndTime = this._.get(keyData, 'keyData.certificate_end', null)
      const certificate = this._.get(keyData, 'keyData.certificate_info.certificate', null)
      const keyFile = keyData.form

      keyFile.ca = this._.get(keyData, 'keyData.ca', null)

      this.item = {
        T1RXXXXG1S: subjFullName,
        T1RXXXXG2S: subjDRFOCode,
        T1RXXXXG3S: publicKeyID.replace(/ /g, '').toLowerCase(),
        ca: issuerCN,
        certificateStart: privKeyBeginTime,
        certificateEnd: privKeyEndTime,
        certificateInfo: certificate
      }
      this.keyFile = keyFile
      this.showForm()
    },
    showForm () {
      this.uploadEdsKey = false
      this.formEmployee = true
    }
  }
}
</script>

<style lang="scss">
.back-arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}
</style>
