<template lang="pug">
  v-row(justify="center")
    v-col(cols="12")
      div(class="print-preview" ref="preview-wrap")
        iframe(ref="receiptDoc" class="d-none")
        pre {{preview}}
    v-col(cols="12")
      v-btn(color="black"  @click="print" dark block)
        | {{$t('Print')}}

</template>

<script>
export default {
  name: 'MBlockReportZView',
  props: {
    preview: {
      type: String,
      required: true
    }
  },
  methods: {
    print () {
      const cw = this.$refs.receiptDoc.contentWindow
      cw.document.open()
      cw.document.write('<pre>')
      cw.document.write(this.preview)
      cw.document.write('</pre>')
      cw.document.close()
      cw.print()
    }
  }
}
</script>

<style scoped lang="scss">
.print-preview {
  height: 400px;
  overflow-y: auto;
}

</style>
