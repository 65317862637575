<template lang="pug">
  ValidationObserver(ref="validator" slim)
    v-form(@submit.prevent.stop="setToken")
      v-row(justify="center" no-gutters)
        v-col(cols="12")
          ValidationProvider( vid="password" :name="$t('Deposit sign key password')" mode="passive" rules="required" v-slot="{ errors }")
            v-text-field(v-model="password" :label="$t('Deposit sign key password')" :error-messages="errors" outlined)
        v-col(cols="12")
          ValidationProvider( vid="token" :name="$t('Deposit sign token')" mode="passive" rules="required" v-slot="{ errors }")
            v-textarea(v-model="token" :label="$t('Deposit sign token')" :error-messages="errors" outlined)
        v-col(cols="auto" v-if="showButton")
          v-btn(class="secondary-button" :loading="loading" type="submit" depressed outlined)
            | {{$t('Continue')}}
</template>

<script>
import Base64js from 'base64-js'

export default {
  name: 'MFormDepositsignToken',
  props: {
    closeModal: {
      type: Function,
      default: () => () => true
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    loading: false,
    token: null,
    password: null,
    localAxios: null,
    data: {
      token: null,
      password: null,
      fullName: null,
      publicKeyId: null,
      DRFOCode: null,
      certificateEnd: null,
      certificateStart: null,
      certificateInfo: null,
      ca: null
    }
  }),
  created () {
    this.localAxios = this.$axios.create()
  },
  methods: {
    async setToken () {
      const valid = await this.validateData()
      if (!valid) { return false }
      this.$emit('setToken', this.data)
      this.closeModal(this.data)
      return this.data
    },
    async validateData () {
      const localValidation = await this.$refs.validator.validate()
      if (!localValidation) { return false }
      this.loading = true
      const passwordValid = await this.checkPassword()
      const tokenValid = await this.checkToken()
      this.loading = false
      if (tokenValid === false) {
        this.$refs.validator.setErrors({
          token: this.$t('This token is not valid. Enter another')
        })
      }
      if (passwordValid === false) {
        this.$refs.validator.setErrors({
          password: this.$t('This password is not valid. Enter another')
        })
      }
      return tokenValid && passwordValid
    },
    async checkPassword () {
      const signData = await (new Blob([this.token])).arrayBuffer()
      try {
        const { data } = await this.localAxios.post('https://depositsign.com/api/v1/checkbox/sign/file',
          {
            FileData: Base64js.fromByteArray(new Uint8Array(signData)),
            AppendData: true,
            Password: this.password
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.token
            }
          })
        return !!data.SignedData
      } catch (e) {
        return false
      }
    },
    async checkToken () {
      try {
        const { data } = await this.localAxios.get('https://depositsign.com/api/v1/checkbox/keys/fromtoken:keyName/certificates/info', {
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        })
        const cert = data.find(elem => elem.KeyUsageType === 3)
        this.data.token = this.token
        this.data.password = this.password
        this.data.publicKeyId = cert.PublicKeyId.toLowerCase()
        this.data.fullName = cert.FullName.trim()
        this.data.DRFOCode = cert.Drfo
        this.data.certificateEnd = cert.ExpireDate
        this.data.certificateStart = cert.StartDate
        this.data.certificateInfo = cert
        this.data.ca = cert.IssuerCN
        return true
      } catch (e) {
        if (e.response && e.response.status === 401) {
          return false
        } else {
          this.$handlers.error(e, this)
        }
      }
    }

  }

}
</script>

<style scoped>

</style>
