<template lang="pug">
  ValidationObserver(:ref="model.entity" slim)
    v-form(@submit.prevent.stop="save")
      v-row()
        v-col(cols="12" class="mt-1 mb-1")
          t-orm-fields(
            v-bind.sync="editedItem"
            :items="fields"
            :tabs="model.ormTabs"
            :context="context"
            :key="key"
          )
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :context="context" :classes="['text-center']")
</template>

<script>
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import validate from '~/mixins/validation/validate'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import taxAuth from '~/mixins/pages/tax-auth'
import ELink from '~/components/elements/links/e-link'
import Taxes from '~/modules/tax-rates/models/Taxes'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import actions from '~/modules/goods/mixins/actions'
import scrollToFailedValidation from '~/mixins/methods/scrollToFailedValidation'

export default {
  components: { TOrmButtons, TOrmFields, ELink, TOrmLabel },
  mixins: [validate, filterOrmObject, checkPropCtx, taxAuth, actions, scrollToFailedValidation],
  props: {
    context: {
      type: String,
      default: 'create'
    },
    selectedItem: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    },
    isDialogPart: {
      type: Boolean,
      default: true
    },
    createdTaxRates: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    model: Taxes,
    editedItem: {
      symbol: null,
      rate: null,
      noVat: null,
      label: null,
      extraRate: null,
      extendedTaxCode: null,
      code: null,
      isDefault: null
    },
    itemLoading: false,
    key: 0,
    extendedTaxCodeOrganizations: [
      'b7a67140-5b12-4e2c-bd72-bbff4a924b9e',
      '03275815-e5e0-4d28-97d1-5583adecea07'
    ]
  }),
  computed: {
    createdTaxRatesSymbols () {
      return this._.uniq(this._.map(this.createdTaxRates, i => i?.symbol))
    },
    isUpdate () {
      return this.context === 'update'
    },
    fields () {
      return [
        {
          model: 'symbol',
          component: 'v-text-field',
          provider: {
            vid: 'symbol',
            name: 'Letter',
            rules: 'required|cyrillic_uk'
          },
          attrs: {
            label: 'Letter',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          },
          hint: 'Specify the letter that will be used to indicate the tax in the check, for VAT it is usually the letter A'
        },
        {
          model: 'rate',
          component: 'v-text-field',
          provider: {
            vid: 'rate',
            name: 'Tax rate (Only for VAT)',
            rules: 'required|integer_or_decimal|min_value:0'
          },
          cast: value => typeof parseFloat(value) === 'number' ? parseFloat(value) : null,
          attrs: {
            label: 'Tax rate (Only for VAT)',
            outlined: true,
            type: 'number',
            min: 0,
            disabled: this._.get(this.editedItem, 'noVat', false)
          },
          cols: {
            cols: 12,
            md: 6
          },
          hint: 'Specify the tax rate, for example, - 20'
        },
        {
          model: 'label',
          component: 'v-text-field',
          provider: {
            vid: 'label',
            name: 'Tax name',
            rules: 'required'
          },
          attrs: {
            label: 'Tax name',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          },
          hint: 'Enter the name of the tax, for example, - VAT'
        },
        {
          model: 'extraRate',
          component: 'v-text-field',
          provider: {
            vid: 'extraRate',
            name: 'Extra rate (excise duty)',
            rules: 'integer_or_decimal|max_value:100|min_value:0'
          },
          cast: value => parseFloat(value) || null,
          attrs: {
            label: 'Extra rate (excise duty)',
            outlined: true,
            type: 'number',
            max: 100,
            min: 0,
            disabled: this._.get(this.editedItem, 'noVat', false)
          },
          cols: {
            cols: 12,
            md: 6
          },
          hint: 'Specify the amount of the additional fee, for example, for excise tax - 5'
        },
        {
          model: 'code',
          component: 'v-text-field',
          provider: {
            vid: 'code',
            name: 'Tax code (Enter manually)',
            rules: 'required|integer|max_value:8|min_value:0'
          },
          asyncDefault: (items, lastCode) => {
            // if last code is null, cast convert this value to NuN
            return lastCode || 0
          },
          request: async () => {
            return this.$isCreateCtx(this.context)
              ? this._.get(await this.model.api().lastPrimaryKey(), 'response.data.code', null)
              : null
          },
          cast: value => parseInt(value),
          attrs: {
            label: 'Tax code (Enter manually)',
            outlined: true
          },
          cols: {
            cols: 12,
            md: this.showExtendedTaxCodeField ? 6 : 12
          },
          hint: 'Any value that will be passed from your front system'
        },
        {
          model: 'extendedTaxCode',
          component: 'v-text-field',
          provider: {
            vid: 'extendedTaxCode',
            name: 'Extended tax code',
            rules: ''
          },
          attrs: {
            label: 'Extended tax code',
            outlined: true,
            visible: this.showExtendedTaxCodeField
          },
          cols: {
            cols: 12,
            md: this.showExtendedTaxCodeField ? 6 : 12
          }
        },
        {
          model: 'noVat',
          component: 'e-input-switch-tooltip',
          provider: {
            vid: 'noVat',
            name: 'No vat'
          },
          attrs: {
            label: 'No vat',
            classes: 'mt-3 mb-5'
          },
          fieldVal: ctx => this._.get(ctx.attrs, ctx.field.model) || false
        },
        {
          model: 'isDefault',
          component: 'e-input-switch-tooltip',
          provider: {
            vid: 'isDefault',
            name: 'Apply to all products'
          },
          attrs: {
            label: 'Apply to all products',
            tooltip: 'The tax rate will be applied to all products that do not have their own tax rate'
          },
          fieldVal: ctx => this._.get(ctx.attrs, ctx.field.model) || false
        }
      ]
    },
    buttons () {
      const text = this.$isCreateCtx(this.context) ? 'Create' : 'Save'
      return [
        {
          text,
          contexts: this.$config.contexts.only('c.u'),
          loading: this.itemLoading || this._.get(this.iitDialog, 'options.loading', false),
          attrs: {
            depressed: true,
            class: 'main-button',
            type: 'submit'
          }
        }
      ]
    },
    showExtendedTaxCodeField () {
      return this.extendedTaxCodeOrganizations.includes(this._.get(this.$Organization, 'id', ''))
    }
  },
  watch: {
    'editedItem.noVat' (val) {
      if (val) {
        this.$set(this.editedItem, 'rate', 0)
        this.$set(this.editedItem, 'extraRate', 0)
        this.key += 1
      }
    }
  },
  created () {
    if (this.isUpdate) {
      this._.each(this.item, (value, key) => {
        if (Object.keys(this.editedItem).includes(key)) {
          this.$set(this.editedItem, key, value)
        }
      })
      // this.$set(this.$data, 'editedItem', {
      //   ...this.editedItem,
      //   ...this.item
      // })
    }
  },
  methods: {
    changeEntityType (type) {
      this.$set(this.productItem, 'type', type)
    },
    close () {
      this.$emit(['dialog', 'close'].join(':'), null)
    },
    async validation () {
      const valid = await this.validate()
      const symbolIsValid = this.$isCreateCtx(this.context) ? !this.createdTaxRatesSymbols.includes(this._.get(this.editedItem, 'symbol')) : true
      const form = this.$refs[this.model.entity]
      if (!symbolIsValid && form?.setErrors) {
        form.setErrors({
          symbol: this.$t('This value is already in use')
        })
      }
      return valid && symbolIsValid
    },
    async save () {
      if (!await this.validation()) {
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
        return false
      }

      try {
        this.itemLoading = true

        const payload = this.filterOrmObject(this.editedItem)

        this.$isCreateCtx(this.context)
          ? await this.model.api()[this.context](payload, false)
          : await this.model.api()[this.context](this.item, payload, false)

        const notification = this.$isCreateCtx(this.context) ? this.$t('was created!') : this.$t('was updated!')
        this.$notification.success([this._.upperFirst(this.$t('Tax ')), notification].join(' ').trim())

        this.close()
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hint {
  font-size: 0.75rem;
  line-height: 1.2;
}
.max-width {
  max-width: 27%;
}
</style>
