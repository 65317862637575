<template lang="pug">
  div(class="e-file-input-wrapper" :class="{ 'w-100': isResponsive }")
    ValidationProvider(:rules="rules" v-slot="{ errors }" :name="label" ref="validation" :mode="validationMode")
      v-btn(
        outlined
        :color="errors.length ? '#ff002b' : '#161B25'"
        :class="{ 'w-100': isResponsive }"
        @click="openFileMenu"
      ) {{ $t('Select file') }}
      div(v-if="errors.length" class="e-button-file__errors") {{ errors[0] }}
      v-file-input(
        @change="upload($event)"
        :value="localValue"
        v-bind="$attrs"
        ref="file"
        :accept="accept"
        multiple
        class="e-button-file"
      )
</template>
<script>

export default {
  name: 'EButtonFileUpload',
  props: {
    accept: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    validationMode: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      localValue: []
    }
  },
  computed: {
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  watch: {
    localValue (uploadedFiles) {
      if (uploadedFiles.length !== this.files.length) {
        this.$emit('update:files', Array.isArray(uploadedFiles) ? uploadedFiles : [uploadedFiles])
      }
    }
  },
  methods: {
    openFileMenu () {
      this.$refs.file.$refs.input.click()
    },
    validate () {
      return this.$refs.validation.validate()
    },
    upload (e) {
      e.forEach((file) => {
        if (!this.localValue.find(f => f.name === file.name)) { this.localValue.push(file) }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.e-file-input-wrapper {
  position: relative;
}
.e-button-file {
  position: absolute;
  opacity: 0 !important;

  &-wrapper {
    position: relative;
  }

  &__errors {
    position: absolute;
    top: 45px;
    left: 0;
    font-size: 10px;
    line-height: 14px;
    color: #ff002b;

    @media (min-width: map-get($breakpoints, 'xs')) {
      top: unset;
      left: unset;
      margin-left: -30px;
    }
  }
}
</style>
