<template lang="pug">
  v-row(justify="center" class="m-block-receipt-sale-discount")
    v-col(cols="12")
      template(v-for="(item, index) in currentGoods")
        v-row(:key="'item' + index" no-gutters class="flex-sm-nowrap")
          v-col(cols="12" sm="auto" class="pr-2 pr-sm-3 m-block-receipt-sale-discount__name")
            h4(v-if="index === 0 && !xsDevice" class="m-block-receipt-sale-discount__title") {{$t('Name')}}
            div
              v-btn(@click="expandChildren(index)" v-if="item.children && item.children.length" class="mr-2" icon small)
                span(v-if="itemOpened === index") ↑
                span(v-else) ↓
              span(class="mb-0") {{ item.goods.name }}
                span(v-if="item.count > 1")  x{{ item.count }}
          v-col(cols="6" sm="auto" class="pr-2 pr-sm-3 m-block-receipt-sale-discount__price")
            h4(v-if="index === 0 && !xsDevice" class="m-block-receipt-sale-discount__title") {{$t('Price')}}
            v-text-field(:value="item.price * item.count" type="number" readonly hide-details outlined)
              template(v-slot:append)
                span(class="pt-1 font-weight-bold text--disabled") &#x20b4;
          v-col(cols="6" sm="auto" class="m-block-receipt-sale-discount__discount")
            h4(v-if="index === 0 && !xsDevice" class="m-block-receipt-sale-discount__title") {{$t('Discount')}}
            div(class="d-flex")
              v-text-field(v-model="item.discountVal" @keypress="floatKeyPress" class="number-input"  :error="item.error" outlined dense)
              v-radio-group(class="mt-0 ml-3 m-block-receipt-sale-discount__discount-type" v-model="item.discountMode" hide-details)
                v-radio(label="%" value="PERCENT" color="black" :ripple="false")
                v-radio(label="₴" value="VALUE" color="black" :ripple="false")

        v-row(v-if="itemOpened === index" :key="'child' + childKey" v-for="(children, childKey ) in _.get(item, 'children', [])" no-gutters)
          v-col(cols="12" sm="auto" class="pr-2 pr-sm-3 m-block-receipt-sale-discount__name")
            p(class="mb-0 pl-9") {{ children.goods.name }}
          v-col(cols="6" sm="auto" class="pr-2 pr-sm-3 m-block-receipt-sale-discount__price")
            v-text-field(:value="children.price * children.count" type="number" readonly hide-details outlined)
              template(v-slot:append)
                span(class="pt-1 font-weight-bold text--disabled") &#x20b4;
          v-col(cols="6" sm="auto" class="m-block-receipt-sale-discount__discount")
            div(class="d-flex")
              v-text-field(v-model="children.discountVal" @keypress="floatKeyPress" class="number-input" :error="children.error" outlined dense)
              v-radio-group(class="mt-0 ml-3 m-block-receipt-sale-discount__discount-type" v-model="children.discountMode" hide-details)
                v-radio(label="%" value="PERCENT" color="black" :ripple="false")
                v-radio(label="₴" value="VALUE" color="black" :ripple="false")
        div(v-if="xsDevice && index !== currentGoods.length - 1" class="m-block-receipt-sale-discount__divider")

    v-col(cols="12" class="mt-2")
      v-row(align="center" no-gutters)
        v-col(sm="auto" class="pr-2 pr-sm-3 m-block-receipt-sale-discount__name") {{ $t('Receipt discount') }}
        v-col(sm="auto" class="m-block-receipt-sale-discount__discount")
          div(class="d-flex align-center")
            v-text-field(v-model="commonDiscount.value" @keypress="floatKeyPress" :error="commonDiscount.error" class="number-input" outlined)
            v-radio-group(class="mt-0 ml-3 m-block-receipt-sale-discount__discount-type" v-model="commonDiscount.mode" hide-details)
              v-radio(label="%" value="PERCENT" color="black" :ripple="false" )
              v-radio(label="₴" value="VALUE" color="black" :ripple="false")

    v-col(cols="auto" class="mt-5")
      v-btn(color="primary" class="main-button" @click="setDiscount")
        | {{$t('Continue')}}
</template>

<script>
import currencyFormat from '~/mixins/filters/currencyFormat'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'

export default {
  components: { ESvgIcon },
  mixins: [currencyFormat, responsive],
  props: {
    goods: {
      type: Array,
      required: true
    },
    discount: {
      type: Object,
      default: null
    },
    isOpened: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    currentGoods: [],
    itemOpened: null,
    commonDiscount: {
      value: 0,
      mode: 'PERCENT'
    }
  }),
  created () {
    this.buildCurrentGoodsData()
  },
  methods: {
    expandChildren (key) {
      if (this.itemOpened === key) {
        this.itemOpened = null
      } else {
        this.itemOpened = key
      }
    },
    buildCurrentGoodsData () {
      this.currentGoods = this.goods.map((item) => {
        const clonedItem = JSON.parse(JSON.stringify(item))
        clonedItem.discountVal = clonedItem.discountVal || 0
        clonedItem.discountMode = clonedItem.discountMode || 'PERCENT'
        if (Number.isInteger(clonedItem.count) && clonedItem.count >= 2) {
          clonedItem.children = []
          for (let i = 1; i <= clonedItem.count; i++) {
            clonedItem.children.push({
              goods: Object.assign({}, clonedItem.goods),
              discountVal: 0,
              discountMode: 'PERCENT',
              price: clonedItem.price,
              count: 1
            })
          }
        }
        return clonedItem
      })
      if (this.discount) {
        this.commonDiscount = Object.assign({}, this.discount)
      }
    },
    checkDiscountNegativeValue (val) {
      return val.toString().includes('-')
    },
    toggleItemErrorState (item, isError) {
      this.$set(item, 'error', isError)
      return isError
    },

    validateDiscount () {
      let hasError = false
      const validate = (item, type = 'items') => {
        if (type === 'items') {
          const cost = item.price * item.count
          if (this.checkDiscountNegativeValue(item.discountVal)) {
            hasError = this.toggleItemErrorState(item, true)
            return
          } else {
            this.toggleItemErrorState(item, false)
          }
          if ((item.discountMode === 'PERCENT' && item.discountVal > 100) || (item.discountMode === 'VALUE' && cost < item.discountVal)) {
            hasError = this.toggleItemErrorState(item, true)
          } else {
            this.toggleItemErrorState(item, false)
          }
        } else if (type === 'commonDiscount') {
          if (this.checkDiscountNegativeValue(item.value)) {
            hasError = this.toggleItemErrorState(item, true)
          } else {
            this.toggleItemErrorState(item, false)
          }
        }
      }
      this.currentGoods.forEach((item) => {
        validate(item)
        if (Array.isArray(item.children)) {
          item.children.forEach(child => validate(child))
        }
      })
      validate(this.commonDiscount, 'commonDiscount')
      if (this) {
        return !hasError
      }
    },
    setDiscount () {
      const valid = this.validateDiscount()
      if (!valid) {
        return
      }
      this.closeModal({
        goods: this.currentGoods,
        discount: this.commonDiscount
      })
    }
  }

}
</script>

<style scoped lang="scss">
.theme--light.v-data-table tbody tr td,
.theme--light.v-data-table thead tr th {
  border-bottom: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.m-block-receipt-sale-discount {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.87);

  ::v-deep .v-input__control {
    position: relative;

    .v-text-field__details {
      position: absolute;
      width: 100%;
      bottom: -17px;
      padding: 0 5px;

      .v-messages {
        font-size: 9px;
      }
    }

    .v-input__slot {
      min-height: 32px !important;
      margin-bottom: 0;
    }
  }

  &__discount-type {
    padding-top: 0;

    ::v-deep .v-input__slot {
      .v-radio {
        margin-bottom: 0 !important;

        .v-input--selection-controls__input {
          height: 17px;
          width: 17px;
          margin-right: 2px;

          .v-icon {
            font-size: 14px;
          }
        }

        .v-icon.mdi-radiobox-marked:before {
          border: 5px solid #161B25;
        }

        .v-icon.mdi-radiobox-marked:before,
        .v-icon.mdi-radiobox-blank:before {
          width: 17px;
          height: 17px;
        }

        .v-label {
          font-size: 12px !important;
          font-weight: bold;
        }
      }
    }
  }

  &__title {
    color: rgba(0, 0, 0, 0.6);
    margin: 0 0 15px;
  }

  &__name {
    font-weight: 700;
    margin-bottom: 10px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      flex-grow: 1;
      flex-basis: 40%;
      margin-bottom: 0;
    }
  }

  &__price {
    width: 115px;
  }

  &__discount {
    width: 135px;
  }

  &__divider {
    background: rgba(0, 0, 0, 0.15);
    height: 1px;
    width: 100%;
    margin: 12px 0;
  }
}
</style>
