import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Receipts from '~/modules/receipt/models/Receipts'
import Dialog from '~/models/system/Dialog'

export class EttnOrders extends PersistAble(OrmModel) {
  static entity = 'ettnOrders'
  static paginated = true
  static defaultSortParam = 'dateCreated'
  static defaultSortOrder = true

  static statuses = {
    created: {
      value: 'created',
      text: 'Очікує оплату'
    },
    canceled: {
      value: 'canceled',
      text: 'Відмінено'
    },
    receiptError: {
      value: 'receipt_error',
      text: 'Помилка при видачі чеку'
    },
    done: {
      value: 'done',
      text: 'Фіскалізовано'
    }
  }

  static ormTrans = {
    single: 'eTTN Order',
    multy: 'eTTN Orders'
  }

  static fields () {
    return {
      id: this.attr(null),
      status: this.attr(null),
      receiptId: this.attr(null),
      ettnNumber: this.attr(null),
      description: this.attr(null),
      dateCreated: this.attr(null),
      dateModified: this.attr(null),
      lastCheckDate: this.attr(null),
      recipientPhone: this.attr(null),
      cashRegister: this.attr(null),
      receiptBody: this.attr(null),
      totalSum: this.attr(null),
      employee: this.attr(null)
    }
  }

  get totalSumString () {
    return ((this.totalSum || 0) / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', width: '150', sortable: true, filterable: false },
    { text: 'eTTN number', value: 'ettnNumber', sortable: true, filterable: false },
    { text: 'eTTN description', value: 'description', sortable: true, filterable: false },
    { text: 'Total sum', value: 'totalSumString', align: 'right', width: '120', sortable: false, filterable: false },
    { text: 'eTTN recipient', value: 'recipientPhone', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }

          const {
            done,
            created,
            canceled
          } = this.statuses

          return {
            id: item.id,
            [created.value]: {
              text: created.text,
              value: created.value,
              appearance: {
                color: 'rgba(255, 170, 1, 0.2)',
                textColor: '#c37f00'
              }
            },
            [done.value]: {
              text: done.text,
              value: done.value,
              appearance: {
                color: '#161b25',
                textColor: '#ffffff'
              }
            },
            success: {
              text: done.text,
              value: 'success',
              appearance: {
                color: '#161b25',
                textColor: '#ffffff'
              }
            },
            [canceled.value]: {
              text: canceled.text,
              value: canceled.value,
              appearance: {
                color: 'rgba(22, 27, 37, 0.1)',
                textColor: '#161b25'
              }
            }
          }
        }
      }
    }
  }

  static ormActions = [
    {
      name: 'read',
      text: 'View eTTN',
      icon: {
        type: 'e-svg-icon',
        text: 'read'
      },
      call: async (item) => {
        await Dialog.query().where('type', 'content_top').first().open({
          title: 'Експрес накладна ' + item.ettnNumber,
          component: 'm-block-ettn-order-view',
          componentProps: {
            order: item
          }
        })
      }
    },
    {
      name: 'delete',
      visible: item => [EttnOrders.statuses.created.value, EttnOrders.statuses.canceled.value].includes(item.status)
    },
    {
      name: 'openReceiptView',
      icon: {
        type: 'e-svg-icon',
        text: 'receipt'
      },
      visible: item => [EttnOrders.statuses.done.value, 'success'].includes(item.status)
    },
    {
      name: 'createReceipt',
      text: 'Create receipt',
      icon: {
        type: 'e-svg-icon',
        text: 'receipt'
      },
      visible: item => [EttnOrders.statuses.receiptError.value].includes(item.status),
      call: async (order) => {
        await Dialog.query().where('type', 'content_top').first().open({
          width: '400px',
          title: 'Create receipt',
          component: 'm-block-acquiring-order-create-receipt', // todo
          componentProps: {
            order
          }
        })
      }
    }
  ]

  static ormDialogs = {
    openReceiptView: 'm-orm-receipt-view-dialog',
    delete: 'm-orm-delete-dialog'
  }

  static ormDialogsConfig = {
    openReceiptView: {
      config: {
        view: 'html',
        width: '470px',
        download: 'pdf',
        downloadNameField: 'payedAtString',
        downloadPrefix: 'receipt',
        requestField: 'receiptId',
        model: Receipts,
        type: 'receipt'
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.validate = function (payload) {
        return this.post(Model.$routes.ettnOrders.validate(), payload, { save: false })
      }
      configActions.createReceipt = function (orderId) {
        return this.put(Model.$routes.ettnOrders.createReceipt(orderId), {}, { dataKey: null })
      }

      return configActions
    }
  }
}

export default EttnOrders
