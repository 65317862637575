<template lang="pug">
  div(class="dialog")
    div(class="dialog__img-wrap")
      img(
        src="~/assets/images/cats/cat-7.svg"
        class="dialog__img"
      )
      div(class="dialog__img-text") {{ $t('Oh! Hint') }}
    div(class="dialog__title") {{ $t('Combine cash register and terminal for accepting payments!') }}
    div(class="dialog__text") {{ $t('To start issuing receipts and accepting non-cash payments, connect one of the types of acquiring') }}
    div(class="dialog__actions")
      v-btn(
        class="secondary-button"
        color="black"
        @click="closeModal"
        outlined
      ) {{ $t('Remind me later') }}
      v-btn(
        class="main-button"
        @click="mainBtnClick"
      ) {{ $t('Choose acquiring') }}
</template>

<script>
export default {
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    mainBtnClick () {
      this.$router.push({ path: '/dashboard/acquiring' })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__img-wrap {
    text-align: center;
  }

  &__img {
    display: block;
    position: relative;
    margin: 0 auto;
    right: 0;
    bottom: -10px;
    z-index: 1;
    width: 112px;
  }

  &__img-text {
    background: $btn-main-bg-gradient;
    border-radius: 12px;
    padding: 8px 20px;
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    display: inline-block;
    position: relative;
    z-index: 2;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    padding: 32px 0 12px;
  }

  &__text {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 5px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 22px;
  }
}
</style>
