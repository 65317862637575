<template lang="pug">
  v-text-field(v-bind="$attrs" v-on="$listeners" v-mask="config")
</template>

<script>
// import { mask } from 'vue-the-mask'
// import masker from 'vue-the-mask/src/masker'
import { VTextField } from 'vuetify/lib'
import mask from 'vue-the-mask/src/directive'
import tokens from 'vue-the-mask/src/tokens'

export default {
  directives: {
    mask
  },
  components: {
    VTextField
  },
  props: {
    mask: {
      type: String,
      required: true,
      validator: (value) => {
        return ['mobile', 'cardMask', 'cardMaskVisible'].includes(value)
      }
    },
    masked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    list () {
      return {
        mobile: {
          mask: '+380##-###-##-##',
          strip: /\+38|\s|-|\(|\)/g
        },
        cardMask: {
          mask: '************####',
          strip: /\*\*\*\*\*\*\*\*\*\*\*\*[0-9]/g
        },
        cardMaskVisible: {
          mask: '################',
          strip: /^\d{16}$/g
        }
      }
    },
    config () {
      return {
        mask: this.list[this.mask].mask,
        tokens: ('tokens' in this.list[this.mask])
          ? this.list[this.mask].tokens
          : tokens,
        masked: this.masked
      }
    }
  },
  methods: {
    stripInput (val) {
      this.$emit('input', this.strip(val))
    },
    stripChange (val) {
      this.$emit('change', this.strip(val))
    },
    strip (val) {
      return 'strip' in this.list[this.mask]
        ? val.replace(this.list[this.mask].strip, '')
        : val
    }
  }
}
</script>

<style lang="scss">
  //
</style>
