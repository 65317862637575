<template lang="pug">
  div(class="modal")
    div(class="modal__img")
      img(:src="require(`~/assets/images/cats/cat-${catType}.svg`)")
    h3(v-if="title" class="modal__title") {{ title }}
    p(v-if="text" class="modal__text") {{ text }}
    v-btn(
      class="main-button modal__btn"
      @click="handleBtnClick"
    ) {{ btnText }}
</template>

<script>
export default {
  name: 'BlockStatusModal',
  props: {
    catType: {
      type: [String, Number],
      default: '1'
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: 'Дякую'
    },
    onBtnClick: {
      type: Function,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    async handleBtnClick () {
      if (this._.isFunction(this.onBtnClick)) {
        await this.onBtnClick()
      } else {
        await this.closeModal()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  text-align: center;
  padding: 20px 0;

  @media (max-width: map-get($breakpoints, 'sm')) {
    padding: 10px 0;
  }

  &__img {
    margin-bottom: 24px;

    img {
      width: 100%;
      max-width: 200px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    color: #000;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 1.5;
    color: rgba(124, 124, 124, 1);
    margin: 12px 0 0;
  }

  &__btn {
    min-width: 187px;
    margin: 32px auto 0;
  }
}
</style>
