<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    :value="isOpened"
    v-on="$listeners"
    :max-width="width"
    :showCloseBtn="showCloseBtn"
    @input="close"
  )
    template(#title v-if="title" )
      p(class='ma-0 pa-0' :class="titleClass") {{title}}
    template(#subtitle v-if="subtitle" )
      p(class='text-center ma-0 mt-2 pa-0') {{subtitle}}
    template(#content)
      v-row(:class="{ 'h-100': contentFullHeight }")
        v-col(cols="12" v-if="text" :class="{ 'h-100': contentFullHeight }")
          | {{text}}
        v-col(cols="12" v-if="component" :class="{ 'h-100': contentFullHeight }")
          component(
            :is="component"
            v-bind="{...componentProps, isOpened}"
            :closeModal="onCloseEvent"
            :toggleCloseBtn="toggleCloseBtn"
            :changeDialogWidth="changeDialogWidth"
            v-on="componentListeners"
          )
    template(#actions v-if="showActions" )
      v-row(no-gutters justify="center" )
        v-col(cols="auto")
          v-btn(@click="confirm" color="primary"  class="main-button" depressed)
            | {{buttonText.approve}}
        v-col(cols="auto")
          v-btn(@click="close" class="ml-4 secondary-button color-error" outlined)
            | {{buttonText.dismiss}}
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import Dialog from '~/models/system/Dialog'

// used components
// TODO this must be rethink
import MBlockReceiptSaleDiscount from '~/modules/receipt/views/components/m-block-receipt-sale-discount'
import MBlockReceiptSaleMixedPaymentTypes from '~/modules/receipt/views/components/m-block-receipt-sale-mixed-payment-types'
import MBlockShiftClose from '~/components/modules/blocks/concrete/shift/m-block-shift-close'
import BlockReportZView from '~/modules/reports/views/components/block-report-z-view'
import MFormDepositsignToken from '~/components/modules/forms/concrete/m-form-depositsign-token'
import MFormBillingTaxBill from '~/components/modules/forms/concrete/billing/m-form-billing-tax-bill'
import MBlockMultipleTaxBillsSending from '~/components/modules/blocks/concrete/tax-bills/m-block-multiple-tax-bills-sending'
import MFormBlock from '~/components/modules/blocks/m-form-block'
import MBlockGoodsImportResult from '~/components/modules/blocks/concrete/goods/m-block-goods-import-result'
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import FormEmployeeModify from '~/modules/employees/views/components/form-employee-modify'
import FormEmployeeUploadEdsKey from '~/modules/employees/views/components/form-employee-upload-eds-key'
import FormOutletModify from '~/modules/outlets/views/components/form-outlet-modify.vue'
import MFormPaymentSettings from '~/components/modules/forms/concrete/payment/m-form-payment-settings'
import MChildGoodsList from '~/components/modules/m-child-goods-list'
import MBlockReceiptSaleExciseStamp from '~/components/modules/blocks/concrete/receipt/sale/m-block-receipt-sale-excise-stamp'
import MBlockBarcodeScanner from '~/components/modules/blocks/concrete/m-block-barcode-scanner'
import MBlockGoodsScanner from '~/components/modules/blocks/concrete/goods/m-block-goods-scanner'
import MBlockSetOrganization from '~/components/modules/blocks/concrete/m-block-set-organization'
import MBlockWrongSuppliesCount from '~/modules/goods/views/components/m-block-wrong-supplies-count'
import MBlockSupportMessage from '~/components/modules/blocks/concrete/m-block-support-message'
import MGoodsImport from '~/modules/goods/views/components/m-goods-import'
import MGoodsExport from '~/modules/goods/views/components/m-goods-export'
import MBlockGoodsRemoval from '~/modules/goods/views/components/m-block-goods-removal'
import MBlockOrmReceiptView from '~/modules/receipt/views/components/m-block-orm-receipt-view'
import BlockProcessingEmployeeLogin from '~/modules/receipt/views/components/dialogs/block-processing-employee-login'
import BlockReceiptService from '~/modules/receipt/views/components/dialogs/block-receipt-service'
import BlockReceiptTextView from '~/modules/receipt/views/components/dialogs/block-receipt-text-view'
import BlockOrderError from '~/modules/orders/views/components/errors/block-order-error'
import MBlockEttnOrderView from '~/modules/ettn/views/m-block-ettn-order-view'
import MBlockAcquiringOrderView from '~/modules/acquiring/views/_old/m-block-acquiring-order-view.vue'
import MBlockAcquiringOrderCreateReceipt from '~/modules/acquiring/views/_old/m-block-acquiring-order-create-receipt.vue'
import BlockSetAcquiringSettings from '~/modules/cashRegisters/views/components/block-set-acquiring-settings.vue'
import BlockAcquiringPaymentInfo from '~/modules/receipt/views/components/dialogs/block-acquiring-payment-info'
import BlockSetEttnSettings from '~/modules/cashRegisters/views/components/block-set-ettn-settings.vue'
import BlockAddNotification from '~/modules/notifications/views/components/block-add-notification'
import BlockNotificationsList from '~/modules/notifications/views/components/block-notifications-list'
import BlockSingleNotification from '~/modules/notifications/views/components/block-single-notification'
import TOrmSimpleData from '~/components/templates/orm/t-orm-simple-data'
import BlockMoneyTransfer from '~/modules/payments/views/components/block-money-transfer'
import BlockAddWorkingOutlet from '~/modules/we-are-working/views/components/block-add-working-outlet'
import BlockCreateTaxReport from '~/modules/reports/views/components/block-create-tax-report'
import BlockTaxReportView from '~/modules/reports/views/components/block-tax-report-view'
import MBlockSwitchSuppliesSetting from '~/modules/goods/views/components/m-block-switch-supplies-setting'
import MFormOfficePayment from '~/components/modules/blocks/concrete/payment/m-form-office-payment'
import MFormSmsPayment from '~/components/modules/blocks/concrete/payment/m-form-sms-payment'
import BlockChangeEmployeeEdsKey from '~/modules/employees/views/components/block-change-employee-eds-key'
import BlockReviewForm from '~/modules/reviews/views/components/block-review-form'
import BlockPosTerminalPaymentProcess from '~/modules/receipt/views/components/dialogs/block-pos-terminal-payment-process'
import BlockSettingsHtmlReceiptForm from '~/modules/profile/views/components/block-settings-html-receipt-form'
import BlockSettingsTextReceiptForm from '~/modules/profile/views/components/block-settings-text-receipt-form'
import BlockTaxReportAdditionForm from '~/modules/reports/views/components/block-tax-report-addition-form'
import BlockSignDsPdf from '~/modules/employees/views/components/block-sign-ds-pdf'
import BlockTapToPhoneForm from '~/modules/acquiring/views/_old/tap-to-phone/block-tap-to-phone-form.vue'
import FormDepositsignPassword from '~/modules/employees/views/components/form-depositsign-password'
import BlockPosTerminalForm from '~/modules/acquiring/views/_old/pos-terminals/block-pos-terminal-form.vue'
import BlockAllGoodsDeleting from '~/modules/goods/views/components/block-all-goods-deleting'
import BlockPosTerminalSettingsForm from '~/modules/acquiring/views/_old/pos-terminals/block-pos-terminal-settings-form.vue'
import FormOutletSettings from '~/modules/outlets/views/components/form-outlet-settings.vue'
import BlockABankAcquiringOrderPdf from '~/modules/acquiring/views/_old/a-bank/block-a-bank-acquiring-order-pdf'
import BlockProductView from '~/modules/goods/views/components/block-product-view'
import BlockTapToPhoneSettingsForm from '~/modules/acquiring/views/_old/tap-to-phone/block-tap-to-phone-settings-form.vue'
import BlockAddRefund from '~/modules/refunds/views/components/block-add-refund'
import BlockConfirmRefundRequestForm from '~/modules/refunds/views/components/block-confirm-refund-request-form'
import BlockApplicationPdfView from '~/modules/refunds/views/components/block-application-pdf-view'
import BlockEpzDataForm from '~/modules/receipt/views/components/dialogs/block-epz-data-form'
import BlockAcquiringUsageInfo from '~/modules/acquiring/views/dialogs/block-acquiring-usage-info'
import BlockOschadbankUnlinkTerminal from '~/modules/acquiring/views/_old/oschadbank/block-oschadbank-unlink-terminal'
import BlockReceiptsExport from '~/modules/receipt/views/components/dialogs/block-receipts-export'
import MFormBillingPriceSet from '~/components/modules/forms/concrete/billing/m-form-billing-price-set'
import BlockCancelReceipt from '~/modules/receipt/views/components/dialogs/block-cancel-receipt'
import BlockStatusModal from '~/modules/dialogs/block-status-modal'
import BlockAcquiringDeleteItemModal from '~/modules/acquiring/views/block-acquiring-delete-item-modal.vue'
import BlockAcquiringForm from '~/modules/acquiring/views/block-acquiring-form.vue'
import BlockAcquiringTerminalBindForm from '~/modules/acquiring/views/block-acquiring-terminal-bind-form'
import BlockAcquiringCashRegisterQr from '~/modules/acquiring/views/block-acquiring-cash-register-qr'
import BlockAcquiringEditForm from '~/modules/acquiring/views/block-acquiring-edit-form'
import CashRegisterFormDialog from '~/modules/cashRegisters/views/components/cash-register-form-dialog.vue'
import FormOutlet from '~/modules/outlets/views/components/creating-form/form-outlet.vue'
import EmployeeFormDialog from '~/modules/employees/views/components/employee-form-dialog.vue'

export default {
  components: {
    EmployeeFormDialog,
    FormOutlet,
    CashRegisterFormDialog,
    MFormBillingPriceSet,
    TOrmSimpleData,
    BlockSetEttnSettings,
    BlockAcquiringPaymentInfo,
    BlockSetAcquiringSettings,
    MBlockAcquiringOrderCreateReceipt,
    MBlockAcquiringOrderView,
    MBlockEttnOrderView,
    MGoodsExport,
    MGoodsImport,
    MFormPaymentSettings,
    FormEmployeeUploadEdsKey,
    MBlockGoodsImportResult,
    MFormDepositsignToken,
    MFormBillingTaxBill,
    TOrmButtons,
    TDialog,
    MBlockReceiptSaleDiscount,
    MBlockReceiptSaleMixedPaymentTypes,
    QrcodeVue,
    MBlockShiftClose,
    BlockReportZView,
    MBlockMultipleTaxBillsSending,
    MFormBlock,
    MOrmTable,
    FormEmployeeModify,
    FormOutletModify,
    MChildGoodsList,
    MBlockReceiptSaleExciseStamp,
    MBlockBarcodeScanner,
    MBlockGoodsScanner,
    MBlockSetOrganization,
    MBlockWrongSuppliesCount,
    MBlockSupportMessage,
    MBlockGoodsRemoval,
    MBlockOrmReceiptView,
    BlockProcessingEmployeeLogin,
    BlockReceiptService,
    BlockReceiptTextView,
    BlockOrderError,
    BlockAddNotification,
    BlockNotificationsList,
    BlockSingleNotification,
    BlockMoneyTransfer,
    BlockAddWorkingOutlet,
    BlockCreateTaxReport,
    BlockTaxReportView,
    MBlockSwitchSuppliesSetting,
    MFormOfficePayment,
    MFormSmsPayment,
    BlockChangeEmployeeEdsKey,
    BlockReviewForm,
    BlockPosTerminalPaymentProcess,
    BlockSettingsHtmlReceiptForm,
    BlockSettingsTextReceiptForm,
    BlockTaxReportAdditionForm,
    BlockSignDsPdf,
    BlockTapToPhoneForm,
    FormDepositsignPassword,
    BlockPosTerminalForm,
    BlockAllGoodsDeleting,
    BlockPosTerminalSettingsForm,
    FormOutletSettings,
    BlockABankAcquiringOrderPdf,
    BlockProductView,
    BlockTapToPhoneSettingsForm,
    BlockAddRefund,
    BlockConfirmRefundRequestForm,
    BlockApplicationPdfView,
    BlockEpzDataForm,
    BlockAcquiringUsageInfo,
    BlockOschadbankUnlinkTerminal,
    BlockReceiptsExport,
    BlockCancelReceipt,
    BlockStatusModal,
    BlockAcquiringDeleteItemModal,
    BlockAcquiringForm,
    BlockAcquiringTerminalBindForm,
    BlockAcquiringCashRegisterQr,
    BlockAcquiringEditForm
  },
  props: {
    type: {
      type: String,
      default: 'content'
    }
  },
  data: () => ({
    showCloseBtn: true,
    dialogWidth: null
  }),
  computed: {
    dialog () {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.dialog, 'isOpened', false)
    },
    params () {
      return this._.get(this.dialog, 'params', '')
    },
    title () {
      const title = this._.get(this.params, 'title', '')
      return this._.isFunction(title) ? title(this) : this.$t(title)
    },
    subtitle () {
      const subtitle = this._.get(this.params, 'subtitle', '')
      return this._.isFunction(subtitle) ? subtitle(this) : this.$t(subtitle)
    },
    text () {
      const text = this._.get(this.params, 'text', '')
      return this._.isFunction(text) ? text(this) : this.$t(text)
    },
    showActions () {
      return this._.get(this.params, 'showActions', false)
    },
    component () {
      return this._.get(this.params, 'component')
    },
    componentProps () {
      return this._.get(this.params, 'componentProps')
    },
    componentListeners () {
      return this._.get(this.params, 'componentListeners')
    },
    contentFullHeight () {
      return this._.get(this.params, 'contentFullHeight', false)
    },
    buttonText () {
      const defaultTexts = {
        approve: this.$t('Approve'),
        dismiss: this.$t('Dismiss')
      }
      return this._.get(this.params, 'buttonText', defaultTexts)
    },
    width () {
      return this.dialogWidth || this._.get(this.params, 'width', '800px')
    },
    titleClass () {
      return this._.get(this.params, 'titleClass', 'mb-3')
    },
    onClose () {
      return this._.get(this.params, 'onClose')
    }
  },
  watch: {
    $route () {
      if (this.isOpened) {
        this.close()
      }
    },
    'params.hideCloseButton' (val) {
      this.showCloseBtn = !val
    }
  },
  created () {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    changeDialogWidth (val) {
      this.dialogWidth = val
    },
    async close () {
      this.changeDialogWidth(null)
      if (this._.isFunction(this.onClose)) {
        await this.onClose()
      }
      await this.dialog.close(false, true)
    },
    confirm () {
      this.dialog.close(true, true)
    },
    onCloseEvent (payload, confirmed = true) {
      this.dialog.close(payload, confirmed)
    },
    toggleCloseBtn (val) {
      this.showCloseBtn = val
    }
  }
}
</script>
