<template lang="pug">
  v-col(v-bind="cols" class="py-0 pr-2 pl-0")
    span(:class="spanClasses") {{ this._.upperFirst($tc(labelText, 1)) }}
      //span(v-if="isRequired" class="ml-1 title error--text") *
    span(
      v-if="labelSubText"
      class="label__subtext"
    ) {{ $t(labelSubText) }}
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    grid: {
      type: Object,
      default: () => ({ cols: 12 })
    },
    context: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'm-orm-view-table'].includes(value)
      }
    }
  },
  computed: {
    isRequired () {
      if (this.context === 'default') {
        return this._.get(this.item.provider, 'rules', '').includes('required')
      }

      return false
    },
    spanClasses () {
      const list = [
        'label',
        this._.get(this.item, 'label.className', '')
      ]
      return this._.compact(list)
    },
    cols () {
      return this._.get(this.item.label, 'cols', this.grid)
    },
    labelText () {
      return (this.context === 'm-orm-view-table' && this.item.viewLabel)
        ? this._.get(this.item, 'label.text', this.item.viewLabel || this.item.attrs.viewLabel)
        : this._.get(this.item, 'label.text', this.item.label || this.item.attrs.label)
    },
    labelSubText () {
      return this._.get(this.item, 'label.subText', '')
    }
  }
}
</script>

<style lang="scss">
  .label {
    font-size: 12px;
    text-transform: uppercase;
    color: #7C7C7C;
    display: block;

    &__subtext {
      display: block;
      font-size: 11px;
      line-height: 1.5;
      margin-top: 4px;
    }
  }
</style>
