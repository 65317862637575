import authUser from '~/modules/auth/authUser'

export default function ({ store, route, redirect }) {
  if (authUser.get().isOwner || authUser.get().isOwnerWithoutOrganization) {
    if (route.name !== 'dashboard') {
      redirect('/dashboard')
    }
  } else if (authUser.get().isEmployee) {
    redirect('/dashboard/createreceipts')
  } else if (authUser.get().isEmergencyCashier) {
    redirect('/dashboard/cashregisters')
  } else if (route.name === 'dashboard') {
    redirect('/dashboard/organizations')
  }
}
