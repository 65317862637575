import { Model } from '@vuex-orm/core'
import moment from 'moment-timezone'
import Outlets from '~/modules/outlets/models/Outlets'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import { contexts, formats } from '~/const/global'
import APIPlatformConnector from '~/plugins/vuex-orm/plugins/_drivers/APIPlatformConnector'
import OutletsSearch from '~/modules/outlets/models/OutletsSearch'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import Dialog from '~/models/system/Dialog'
import CashRegisterOrder from '~/modules/cashRegisters/models/CashRegisterOrder'
import AppNotifications from '~/services/Notifications/AppNotifications'
import SignPluginService from '~/services/SignPluginService/SignPluginService'
import authUser from '~/modules/auth/authUser'
import Organization from '~/models/directories/Organization'
import TimestampAble from '~/models/mixins/TimestampAble'

export class CashRegisters extends PersistAble(Directory, [
  TimestampAble
]) {
  static entity = 'cashregisters'
  static orderEntity = CashRegisterOrder
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'number'
  static defaultSortOrder = false
  static dblClickAction = 'read'
  static persistOptions = {
    insertOrUpdate: ['outlet']
  }

  static itemModes = {
    checkbox: {
      text: 'Active',
      value: 'checkbox',
      mode: 100
    },
    deactivated: {
      text: 'Deactivated in SFS',
      value: 'deactivated',
      mode: 200
    },
    alert: {
      text: 'Need interaction',
      value: 'alert',
      mode: 300
    },
    notActive: {
      text: 'Not active',
      value: 'not_active',
      mode: 350
    },
    deactivatedByUser: {
      text: 'Deactivated by user',
      value: 'deactivated_by_user',
      mode: 400
    },
    onRemovalInSfs: {
      text: 'On removal in SFS',
      value: 'onRemovalInSfs'
    },
    hasRemovalOrderError: {
      text: 'Active',
      value: 'hasRemovalOrderError'
    },
    onModifyInSfs: {
      text: 'On modify in SFS',
      value: 'onModifyInSfs'
    },
    hasModifyOrderError: {
      text: 'Active',
      value: 'hasModifyOrderError'
    },
    test: {
      text: 'Test cash register',
      value: 'TEST'
    },
    reserve: {
      text: 'Reserve cash register',
      value: 'reserve'
    }
  }

  static checkTaxOrdersStatus (item) {
    const sortedTaxOrders = this._.sortBy(this._.get(item, 'cashRegisterTaxOrders', []), order => new Date(order.dateSend).getTime())
    const lastTaxOrder = this._.last(sortedTaxOrders)
    const onRemovalInSfs = this._.get(lastTaxOrder, 'type') === 300 && this._.get(lastTaxOrder, 'status') === 200
    const hasRemovalOrderError = this._.get(lastTaxOrder, 'type') === 300 && this._.get(lastTaxOrder, 'status') === 400 && this._.get(item, 'mode') === 100
    const onModifyInSfs = this._.get(lastTaxOrder, 'type') === 200 && this._.get(lastTaxOrder, 'status') === 200
    const hasModifyOrderError = this._.get(lastTaxOrder, 'type') === 200 && this._.get(lastTaxOrder, 'status') === 400 && this._.get(item, 'mode') === 100

    return {
      lastTaxOrder,
      onRemovalInSfs,
      hasRemovalOrderError,
      onModifyInSfs,
      hasModifyOrderError,
      actionVisible: !onRemovalInSfs && !onModifyInSfs
    }
  }

  static fields () {
    return {
      outletId: this.attr(null),

      id: this.attr(null),
      fiscalNumber: this.attr(null),
      number: this.attr(null),
      name: this.attr(null),
      accStart: this.attr(null),
      licenseKey: this.attr(null),
      outlet: this.belongsTo(Outlets, 'outletId'),
      address: this.attr(null),
      mode: this.attr(null),
      dateCreated: this.attr(null),
      dateModified: this.attr(null),
      cashRegisterTaxOrders: this.attr(null),
      deactivatedAt: this.attr(null),
      activatedAt: this.attr(null),
      isTest: this.attr(null),
      isReserve: this.attr(null),
      acquiringSettings: this.attr(null),
      emergencySettings: this.attr(null),
      ettnSettings: this.attr(null)
      // cashRegisterTaxOrders: this.hasMany(CashRegisterOrder, 'cashRegister', 'id')
    }
  }

  get outletString () {
    return this.outlet ? this.outlet.name || '-' : 'No outlet'
  }

  get deactivatedAtString () {
    return this.deactivatedAt ? moment(this.deactivatedAt).format(formats.date) : null
  }

  get activatedAtString () {
    return this.activatedAt ? moment(this.activatedAt).format(formats.date) : null
  }

  get modeString () {
    const { onRemovalInSfs, hasRemovalOrderError, onModifyInSfs, hasModifyOrderError, test, reserve, deactivatedByUser } = CashRegisters.itemModes
    const orderStatus = CashRegisters.checkTaxOrdersStatus(this)
    if (this._.get(orderStatus, onRemovalInSfs.value)) {
      return onRemovalInSfs.value
    } else if (this._.get(orderStatus, hasRemovalOrderError.value)) {
      return hasRemovalOrderError.value
    } else if (this._.get(orderStatus, onModifyInSfs.value)) {
      return onModifyInSfs.value
    } else if (this._.get(orderStatus, hasModifyOrderError.value)) {
      return hasModifyOrderError.value
    } else if (this.isTest) {
      return test.value
    } else if (this.isReserve && this.mode !== deactivatedByUser.value) {
      return reserve.value
    }
    return this.mode
  }

  static ormRelationMap = [
    'outlet'
  ]

  static ormTrans = {
    single: 'Cash register',
    multy: 'Cash registers',
    notification: 'Cash register'
  }

  static ormHeaders = [
    { text: 'Status', value: 'modeString', align: 'left', width: '150', sortable: true, filterable: true },
    { text: 'Fiscal number', value: 'fiscalNumber', align: 'left', filterable: true, sortable: false },
    { text: 'Cash register number', value: 'number', align: 'left', sortable: true, filterable: true },
    { text: 'License key', value: 'licenseKey', align: 'left', filterable: true, sortable: false },
    { text: 'Outlet name', value: 'outletString', sortable: true, sortQuery: 'outlet.address' },
    { text: 'Registration address', value: 'address', width: '450', sortable: true },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormMobileTitle = 'fiscalNumber'

  static ormRowsConfig = {
    disabled: false
  }

  static ormColsComponents = {
    licenseKey: {
      component: 'e-copy-text'
    },
    modeString: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }
          const {
            checkbox,
            deactivated,
            alert,
            notActive,
            deactivatedByUser,
            onRemovalInSfs,
            hasRemovalOrderError,
            onModifyInSfs,
            hasModifyOrderError,
            test,
            reserve
          } = this.itemModes

          return {
            id: item.id,
            model: CashRegisterOrder,
            [checkbox.mode]: {
              text: checkbox.text,
              type: checkbox.value
            },
            [deactivated.mode]: {
              text: deactivated.text,
              type: deactivated.value,
              tooltip: 'Cash register was deactivated in sfs'
            },
            [notActive.mode]: {
              text: notActive.text,
              type: notActive.value
            },
            [alert.mode]: {
              text: alert.text,
              type: alert.value,
              tooltip: 'It need to user interaction to use this cash register'
            },
            [deactivatedByUser.mode]: {
              text: deactivatedByUser.text,
              type: deactivatedByUser.value,
              tooltip: 'Cash register is deactivated, so it will be not added to invoice'
            },
            [onRemovalInSfs.value]: {
              text: onRemovalInSfs.text,
              type: onRemovalInSfs.value,
              tooltip: 'The cash register is on removal in the SFS'
            },
            [hasRemovalOrderError.value]: {
              text: hasRemovalOrderError.text,
              type: hasRemovalOrderError.value,
              error: {
                id: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.id'),
                taxErrorMessage: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.taxErrorMessage'),
                hasErrorFile: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.hasErrorFile')
              }
            },
            [onModifyInSfs.value]: {
              text: onModifyInSfs.text,
              type: onModifyInSfs.value,
              tooltip: 'The cash register is on modify in the SFS'
            },
            [hasModifyOrderError.value]: {
              text: hasModifyOrderError.text,
              type: hasModifyOrderError.value,
              error: {
                id: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.id'),
                taxErrorMessage: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.taxErrorMessage'),
                hasErrorFile: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.hasErrorFile')
              }
            },
            [test.value]: {
              text: test.text,
              type: test.value,
              tooltip: 'Use this cash register to test issuing checks',
              appearance: {
                color: 'rgba(255,106,0,0.1)',
                textColor: 'rgba(255,106,0,1)'
              }
            },
            [reserve.value]: {
              text: reserve.text,
              type: reserve.value,
              appearance: {
                color: 'rgba(4,174,213,0.1)',
                textColor: '#04aed5'
              }
            }
          }
        }
      }
    }
  }

  static ormFilters = [
    {
      model: 'mode',
      component: 'v-select',
      attrs: {
        items: [
          { text: 'Активна', value: CashRegisters.itemModes.checkbox.mode },
          { text: 'Деактивована', value: CashRegisters.itemModes.deactivated.mode },
          { text: 'Деактивовано користувачем', value: CashRegisters.itemModes.deactivatedByUser.mode }
        ],
        label: 'Status',
        outlined: true,
        clearable: true,
        hideDetails: true
      },
      fieldVal: () => ''
    },
    new AutocompleteBuilder({
      model: 'outlet',
      label: 'Outlet  ',
      emmitEmptyObject: true,
      autoWidth: true,
      emitSearch: false,
      hideDetails: true,
      query: model => model.api().filter({ mode: Outlets.itemModes.checkbox.mode })
    }, OutletsSearch).build(),
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 4
              },
              fields: [
                'mode'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 4
              },
              fields: [
                'outlet'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 4
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = []

  // TODO add approach to crete custom actions
  static ormActions = [
    {
      name: 'qrCodeGenerate',
      text: 'Licence key QR code',
      visible: (item) => {
        const isSuperAdmin = authUser.get() && authUser.get().isSuperAdmin
        const isEmergencyCashier = authUser.get() && authUser.get().isEmergencyCashier
        return !isSuperAdmin && !isEmergencyCashier && (item.mode === CashRegisters.itemModes.checkbox.mode)
      },
      icon: {
        type: 'e-svg-icon',
        text: 'qr-code'
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'License key',
          component: 'qrcode-vue',
          width: '390px',
          componentProps: {
            value: item.licenseKey,
            level: 'H',
            size: 300
          }
        })
      }
    },
    {
      name: 'setData',
      text: 'Set data',
      visible: (item) => {
        const isSuperAdmin = authUser.get() && authUser.get().isSuperAdmin
        return !isSuperAdmin && (item.mode === CashRegisters.itemModes.alert.mode) && !item.isTest
      },
      icon: {
        type: 'e-svg-icon',
        text: 'wrench'
      },
      call: (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          title: 'Cash register data installation',
          width: '600px',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Set',
            fields: [
              new AutocompleteBuilder({
                model: 'outlet',
                label: 'outlet',
                rules: 'required',
                context: ['update_local'],
                query: (model, item) => {
                  return model.api().filter({ mode: Outlets.itemModes.checkbox.mode, address: this._.get(item, 'parent.address', '') })
                },
                fieldVal: ctx => this._.get(item, 'outlet')
              }, OutletsSearch).build()
            ],
            onSubmit: async (data) => {
              await CashRegisters.api().activate(item, data)
              AppNotifications.success('Data successfully installed')
              await contentDialog.close()
            }
          }
        })
      }
    },
    {
      name: 'edit',
      visible: (item) => {
        const isSuperAdmin = authUser.get() && authUser.get().isSuperAdmin
        return !isSuperAdmin && (item.mode === CashRegisters.itemModes.checkbox.mode || item.mode === CashRegisters.itemModes.alert.mode) && this._.get(CashRegisters.checkTaxOrdersStatus(item), 'actionVisible') && !item.isTest
      },
      call: (cashRegister, data, ctx) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          title: 'Cash register modifying',
          width: '860px',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Save',
            fields: [
              {
                model: 'M04',
                component: 'v-select',
                provider: {
                  vid: 'M04',
                  name: 'Prro type',
                  rules: 'required'
                },
                attrs: {
                  label: 'Prro type',
                  outlined: true
                },
                items: () => Object.values(CashRegisterOrder.prroTypes)
              },
              new AutocompleteBuilder({
                model: 'outlet',
                label: 'outlet',
                rules: 'required',
                query: model => model.api().filter({ mode: Outlets.itemModes.checkbox.mode }),
                fieldVal: () => this._.get(cashRegister, 'outlet')
              }, OutletsSearch).build()
            ],
            onSubmit: async (data) => {
              if (data.outlet) {
                data.outlet = `/outlets/${this._.get(data, 'outlet.id')}`
              }
              await SignPluginService.sendEntityTaxOrder({
                type: 'modify',
                entity: cashRegister,
                orderModel: CashRegisterOrder,
                model: CashRegisters,
                organization: ctx.$Organization,
                data: {
                  ...data,
                  cashRegister: `/cash-registers/${this._.get(cashRegister, 'id', '')}`
                },
                ctx: this
              })
              await contentDialog.close()
            }
          }
        })
      }
    },
    {
      name: 'delete',
      visible: (item) => {
        const isSuperAdmin = authUser.get() && authUser.get().isSuperAdmin
        return !isSuperAdmin && (item.mode === CashRegisters.itemModes.checkbox.mode) && this._.get(CashRegisters.checkTaxOrdersStatus(item), 'actionVisible') && !item.isTest
      },
      call: (cashRegister, target, ctx) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        confirmationDialog.open({
          title: 'Cash register removal',
          text: ctx => ctx.$t('Are you sure to delete cash register?', { number: this._.get(cashRegister, 'fiscalNumber') }),
          hint: {
            icon: 'exclamation-warning',
            text: 'Importantly! Before deleting the cash register, make sure that there are no open changes'
          },
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            await SignPluginService.sendEntityTaxOrder({
              type: 'removal',
              entity: cashRegister,
              orderModel: CashRegisterOrder,
              model: CashRegisters,
              organization: ctx.$Organization,
              data: {
                cashRegister: `/cash-registers/${this._.get(cashRegister, 'id', '')}`
              },
              ctx: this
            })
          }
        })
      }
    },
    {
      name: 'deactivate',
      text: item => item.mode === CashRegisters.itemModes.checkbox.mode ? 'Deactivate' : 'Activate',
      icon: {
        type: 'e-svg-icon',
        text: 'activation'
      },
      visible: (item) => {
        const isSuperAdmin = authUser.get() && authUser.get().isSuperAdmin
        return isSuperAdmin && (item.mode === CashRegisters.itemModes.checkbox.mode || item.mode === CashRegisters.itemModes.deactivatedByUser.mode)
      },
      call: async (cashRegister) => {
        if (cashRegister.mode === CashRegisters.itemModes.checkbox.mode) {
          await CashRegisters.api().deactivate(cashRegister)
        } else {
          await CashRegisters.api().activatePayable(cashRegister)
        }
      }
    },
    {
      name: 'activate',
      text: 'Activate',
      icon: {
        type: 'e-svg-icon',
        text: 'activation'
      },
      visible: (item) => {
        const isSuperAdmin = authUser.get() && authUser.get().isSuperAdmin
        return !isSuperAdmin && (item.mode === CashRegisters.itemModes.notActive.mode) && !item.isTest
      },
      call: async (item) => {
        const outletId = this._.get(item, 'outlet.id')
        if (outletId) {
          const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
          await confirmationDialog.open({
            title: 'Confirmation of checkout activation',
            onConfirm: async () => {
              await CashRegisters.api().activateNotActive(item, {
                outlet: `/outlets/${outletId}`
              })
              AppNotifications.success('Cash register activated successfully')
            }
          })
        } else {
          const contentDialog = Dialog.query().where('type', 'content').first()
          await contentDialog.open({
            title: 'Cash register activation',
            text: 'Before activation, you need to set the cash register data',
            width: '600px',
            component: 'm-form-block',
            componentProps: {
              buttonText: 'Activate',
              fields: [
                new AutocompleteBuilder({
                  model: 'outlet',
                  label: 'outlet',
                  rules: 'required',
                  context: ['update_local'],
                  query: (model, item) => {
                    return model.api().filter({ mode: Outlets.itemModes.checkbox.mode, address: this._.get(item, 'parent.address', '') })
                  },
                  fieldVal: ctx => this._.get(item, 'outlet')
                }, OutletsSearch).build()
              ],
              onSubmit: async (data) => {
                await CashRegisters.api().activateNotActive(item, {
                  outlet: `/outlets/${this._.get(data, 'outlet.id', '')}`
                })
                AppNotifications.success('Cash register activated successfully')
                await contentDialog.close()
              }
            }
          })
        }
      }
    },
    {
      name: 'deleteTestData',
      text: 'Delete',
      icon: {
        type: 'e-svg-icon',
        text: 'trash'
      },
      visible: item => item.isTest,
      call: async (cashRegister) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        await confirmationDialog.open({
          title: 'Підтвердити видалення',
          text: 'Всі тестові дані, включаючи чеки та зміни, будуть видалені.',
          onConfirm: async () => {
            await Organization.api().deleteTestData()
            CashRegisters.delete(cashRegister.id)
          }
        })
      }
    },
    {
      name: 'setupEmergency',
      text: 'Emergency close shifts',
      icon: {
        type: 'e-svg-icon',
        text: 'exclamation-warning'
      },
      visible: (item) => {
        return (item.mode === CashRegisters.itemModes.checkbox.mode) && item.emergencySettings?.emergencyAvailable
      },
      call: (item, data, ctx) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          title: 'Emergency close shifts',
          width: '600px',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Save',
            fields: [
              {
                model: 'details',
                component: 'v-textarea',
                provider: {
                  vid: 'details',
                  name: 'Details',
                  rules: ''
                },
                attrs: {
                  label: 'Short description',
                  outlined: true
                }
              }
            ],
            onSubmit: async (data) => {
              const payload = {
                cashRegisterId: item.id,
                details: data.details
              }
              try {
                await CashRegisters.api().processingEmergencySetup(payload)
                ctx.$notification.success(ctx.$t('The request was created successfully'))
                await contentDialog.close()
              } catch (e) {
                ctx.$handlers.error(e, ctx)
              }
            }
          }
        })
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    default: {
      config: {
        context: 'create'
      }
    },
    read: {
      title: item => ({ type: 'Cash register ', name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'accStart',
            label: 'Date created in SFS',
            hideEmpty: true
          },
          {
            model: 'activatedAtString',
            label: 'Date activated',
            hideEmpty: true,
            visible: item => (item.mode === CashRegisters.itemModes.checkbox.mode) || (item.mode === CashRegisters.itemModes.alert.mode)
          },
          {
            model: 'deactivatedAtString',
            label: 'Date of deactivated',
            hideEmpty: true,
            visible: item => (item.mode === CashRegisters.itemModes.deactivated.mode) || (item.mode === CashRegisters.itemModes.deactivatedByUser.mode)
          },
          {
            model: 'fiscalNumber',
            label: 'Fiscal number',
            hideEmpty: true
          },
          {
            model: 'number',
            label: 'Number'
          },
          {
            model: 'name',
            label: 'Name'
          },
          {
            model: 'address',
            label: 'Address'
          },
          {
            model: 'licenseKey',
            label: 'License key',
            hideEmpty: true
          },
          {
            model: 'outlet',
            label: 'Outlet  ',
            value: (val) => {
              if (val.name && val.address) {
                return `${val.name}, ${val.address}`
              }
              return val.name || val.address
            },
            hideEmpty: true
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.activate = function (model, payload) {
        const connector = new APIPlatformConnector(this.model, { ...this.params }, contexts.update)
        return this.put(Model.$routes.cashregisters.activate(model.$id), { outlet: 'outlets/' + payload.outlet.id }, connector.config)
      }
      configActions.deactivate = function (model) {
        return this.put(Model.$routes.cashregisters.deactivate(model.$id))
      }
      configActions.activatePayable = function (model) {
        return this.put(Model.$routes.cashregisters.activatePayable(model.$id))
      }
      configActions.updateSettings = function (model, payload) {
        return this.put(Model.$routes.cashregisters.updateSettings(model.$id), payload)
      }
      configActions.activateNotActive = function (model, payload) {
        return this.put(Model.$routes.cashregisters.activateNotActive(model.$id), payload)
      }
      configActions.processingEmergencySetup = function (payload) {
        return this.post(Model.$routes.cashregisters.processingEmergencySetup(), payload)
      }
      return configActions
    }
  }
}

export default CashRegisters
