import { render, staticRenderFns } from "./block-employee-creating-options.vue?vue&type=template&id=9fb2055a&scoped=true&lang=pug&"
import script from "./block-employee-creating-options.vue?vue&type=script&lang=js&"
export * from "./block-employee-creating-options.vue?vue&type=script&lang=js&"
import style0 from "./block-employee-creating-options.vue?vue&type=style&index=0&id=9fb2055a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fb2055a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
