<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('Money transfer') }}
    div(class="dialog__content")
      h4 {{ $t('On balance') }}: {{ balance | currencyFormat }} {{ $t('uah') }}
      ValidationObserver(ref="form" class="dialog__form")
        v-form(@submit.prevent.stop="transfer")
          ValidationProvider(
            :name="$t('USREOU of the recipient')"
            rules="required"
            mode="lazy"
            vid="recipientEdrpou"
            v-slot="{ errors }"
          )
            v-text-field(
              v-model="form.recipientEdrpou"
              :label="$t('USREOU of the recipient')"
              :error-messages="errors"
              :loading="loading"
              outlined
            )
          ValidationProvider(
            :name="$t('Sum')"
            :rules="`required|money|max_value:${balance}`"
            mode="lazy"
            vid="sum"
            v-slot="{ errors }"
          )
            v-text-field(
              v-model="form.sum"
              :label="$t('Sum')"
              :error-messages="errors"
              :loading="loading"
              outlined
            )
          v-row(justify="center")
            v-col(cols="auto" class="py-0")
              v-btn(class="main-button" type="submit" :loading="loading")
                | {{ $t('Transfer') }}
</template>

<script>
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import TransferRequests from '~/modules/payments/models/TransferRequests'
import signPluginForOrganization from '~/mixins/dialogs/signPluginForOrganizationDialog'
import currencyFormat from '~/mixins/filters/currencyFormat'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  name: 'BlockPayments',
  mixins: [signPluginForOrganization, currencyFormat, contentDialog],
  data: () => ({
    form: {
      sum: null,
      recipientEdrpou: null
    },
    loading: false
  }),
  computed: {
    model () {
      return TransferRequests
    },
    balance () {
      return (this._.get(this.$Organization, 'billing.units', 0) / 100)
    }
  },
  methods: {
    async isValidFrom () {
      return await this.$refs.form.validate()
    },
    async transfer () {
      if (!await this.isValidFrom()) {
        return
      }

      try {
        this.loading = true
        await this.signPluginForOrganization.use()
        const keyData = this._.get(this.signPluginForOrganization, 'interface.keyData', null)
        const edsKeyData = new EdsKeyData(keyData)

        if (!keyData) {
          return await this.signPluginForOrganization.close()
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          return await this.signPluginForOrganization.close()
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          return await this.signPluginForOrganization.close()
        }

        const edrpou = this._.get(this.$Organization, 'edrpou', '')
        const sign = this._.get(this.signPluginForOrganization, 'interface.sign')
        const signedData = typeof sign === 'function' && await sign(edrpou)

        if (!signedData) {
          return null
        }

        const payload = {
          sum: this.form.sum * 100,
          sign: signedData,
          recipientEdrpou: this.form.recipientEdrpou
        }

        await this.model.api().create(payload)
        await this.contentDialog.close()
        this.$notification.success(this.$t('Payment request successfully submitted'))
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        await this.signPluginForOrganization.close()
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 0 0 20px;

  &__form {
    padding: 10px 0;
    margin-top: 1.5rem;
    display: block;

    > span {
      flex-grow: 1;
    }
  }
}
</style>
