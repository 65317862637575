import { render, staticRenderFns } from "./block-oschadbank-unlink-terminal.vue?vue&type=template&id=fc09b62a&scoped=true&lang=pug&"
import script from "./block-oschadbank-unlink-terminal.vue?vue&type=script&lang=js&"
export * from "./block-oschadbank-unlink-terminal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc09b62a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VBtn,VForm})
