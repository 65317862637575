<template lang="pug">
  ValidationObserver(ref="form" slim)
    v-form(class="tap-to-phone-contact-form")
      t-orm-fields(v-bind.sync="dataForm" :items="fields")
      div(class="tap-to-phone-contact-form__actions d-flex justify-end pt-8")
        v-btn(
          class="main-button"
          :loading="loading"
          @click="submit"
        ) {{ $t('Send') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields.vue'
import KatottgDictionary from '~/models/directories/dictionaries/KatottgDictionary'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import Oschadbank from '~/modules/acquiring/models/Oschadbank'

export default {
  name: 'BlockTapToPhoneContactForm',
  components: {
    TOrmFields
  },
  props: {
    documents: {
      type: Array,
      required: true
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      dataForm: {
        name: null,
        phone: null,
        email: null,
        region: null
      },
      loading: false
    }
  },
  computed: {
    fields () {
      return [
        {
          model: 'name',
          component: 'v-text-field',
          provider: {
            name: 'First name',
            vid: 'name',
            rules: 'required'
          },
          attrs: {
            label: 'First name',
            outlined: true
          }
        },
        {
          model: 'phone',
          component: 'e-input-mask',
          provider: {
            name: 'Phone',
            vid: 'phone',
            rules: 'required|phone'
          },
          attrs: {
            label: 'Phone',
            outlined: true,
            mask: 'mobile'
          }
        },
        {
          model: 'email',
          component: 'v-text-field',
          provider: {
            name: 'Email',
            vid: 'email',
            rules: 'required|email'
          },
          attrs: {
            label: 'Email',
            type: 'email',
            outlined: true
          }
        },
        new AutocompleteBuilder({
          model: 'region',
          label: 'Region',
          itemClass: 'ws-pre',
          rules: 'required',
          itemsPerPage: 30,
          query: model => model.api().filter({ 'exists[parent]': false })
        }, KatottgDictionary).build()
      ]
    }
  },
  created () {
    this.$emit('changeTitle', 'Contacts')
  },
  methods: {
    async submit () {
      const valid = await this.$refs.form.validate()

      if (valid) {
        try {
          this.loading = true

          this.dataForm.region = this.dataForm.region.name
          const formData = new FormData()
          this.documents.forEach((document, idx) => formData.append(`file${idx + 1}`, document))
          Object.entries(this.dataForm).map(([key, value]) => {
            formData.append(key, value)
          })

          this._.get(await Oschadbank.api().createOrder(formData), 'response.data')

          await this.tableRequest()

          this.$gtm.push({
            event: 'submit_tap_to_phone',
            organization: this._.get(this.$Organization, 'owner.email')
          })

          this.$emit('showOschadbankAcquiringOrderInfo', true)
        } catch (e) {
          this.$handlers.error(e, this)
          this.closeModal()
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
