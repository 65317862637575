<template lang="pug">
  div(class="acquiring-payment-qr")
    div(
      v-if="paymentPending"
      class="acquiring-payment-qr__block"
    )
      div(class="acquiring-payment-qr__frame")
        img(
          class="acquiring-payment-qr__frame-img"
          src="~/assets/images/cats/cat-11.svg"
          alt="cat"
        )
        qrcode-vue(
          :value="qrLink"
          level="H"
          :size="xsDevice ? 110 : 140"
          class="acquiring-payment-qr__frame-qr"
        )
      h3(class="acquiring-payment-qr__title") QR код для оплати
      p(class="acquiring-payment-qr__text") Для оплати клієнту потрібно відсканувати даний QR код або скористатися QR кодом наданим Monobank
      e-progress-linear(size="md")
      v-btn(
        @click="resetQr"
        class="acquiring-payment-qr__btn"
        :loading="resetQrLoading"
      ) Скасувати замовлення

    div(
      v-else-if="paymentError"
      class="acquiring-payment-qr__block"
    )
      img(
        class="acquiring-payment-qr__img"
        src="~/assets/images/cats/cat-5.svg"
        alt="cat"
      )
      h3(class="acquiring-payment-qr__title") Помилка при оплаті! {{errorText}}
      v-btn(
        @click="close"
        class="acquiring-payment-qr__btn main-button"
      ) Закрити

    div(
      v-else-if="paymentSuccess"
      class="acquiring-payment-qr__block"
    )
      img(
        class="acquiring-payment-qr__img mb-1"
        src="~/assets/images/cats/cat-9.svg"
        alt="cat"
      )
      h3(class="acquiring-payment-qr__title") Оплата успішна

    div(
      v-else-if="receiptPending"
      class="acquiring-payment-qr__block"
    )
      img(
        class="acquiring-payment-qr__img mb-1"
        src="~/assets/images/cats/cat-10.svg"
        alt="cat"
      )
      h3(class="acquiring-payment-qr__title") Видача фіскального чеку
      e-progress-linear(
        item-class="mt-10"
        size="md"
      )

    div(
      v-else-if="receiptError"
      class="acquiring-payment-qr__block"
    )
      img(
        class="acquiring-payment-qr__img"
        src="~/assets/images/cats/cat-5.svg"
        alt="cat"
      )
      h3(class="acquiring-payment-qr__title") Помилка при видачі чеку! {{errorText}}
      v-btn(
        @click="close"
        class="acquiring-payment-qr__btn main-button"
      ) Закрити
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import EProgressLinear from '~/components/elements/progress/e-progress-linear'
import ETextWithIcon from '~/components/elements/text/e-text-with-icon'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import AcquiringInvoices from '~/modules/acquiring/models/AcquiringInvoices'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import Receipts from '~/modules/receipt/models/Receipts'
import authData from '~/modules/receipt/mixins/getters/authData'
import converters from '~/mixins/methods/converters'
import receiptDialogs from '~/modules/receipt/mixins/receiptDialogs'
import responsive from '~/mixins/pages/responsive'

const workflow = {
  paymentPending: 'paymentPending',
  paymentError: 'paymentError',
  paymentSuccess: 'paymentSuccess',
  receiptPending: 'receiptPending',
  receiptError: 'receiptError'
}

export default {
  components: { ESvgIcon, ETextWithIcon, EProgressLinear, QrcodeVue },
  mixins: [authData, converters, receiptDialogs, responsive],
  props: {
    orderId: {
      type: String,
      required: true
    },
    receiptId: {
      type: String,
      required: true
    },
    qrLink: {
      type: String,
      required: true
    }
  },
  data: () => ({
    state: workflow.paymentPending,
    errorText: null,
    done: false,
    resetQrLoading: false,
    checkOrderRequest: null
  }),
  computed: {
    model () {
      return AcquiringInvoices
    },
    paymentPending () {
      return this.state === workflow.paymentPending
    },
    paymentError () {
      return this.state === workflow.paymentError
    },
    paymentSuccess () {
      return this.state === workflow.paymentSuccess
    },
    receiptPending () {
      return this.state === workflow.receiptPending
    },
    receiptError () {
      return this.state === workflow.receiptError
    }
  },
  created () {
    this.process()
  },
  methods: {
    async process () {
      this.state = workflow.paymentPending
      const orderStatus = await this.checkOrder()
      if (!orderStatus) {
        return
      }
      if (orderStatus.status === this.model.statuses.paymentError.value) {
        this.errorText = orderStatus.error
        this.state = workflow.paymentError
      } else if (orderStatus.status === this.model.statuses.receiptError.value) {
        this.errorText = orderStatus.error
        this.state = workflow.receiptError
      } else if (orderStatus.status === this.model.statuses.done.value) {
        this.state = workflow.paymentSuccess
        await this.wait(2000)
        this.state = workflow.receiptPending
        const receipt = await this.checkReceipt()
        if (receipt.status === Receipts.processingStatuses.DONE) {
          const receiptHtml = this._.get(await Receipts.api().processingReadText(receipt.id), 'response.data')
          const qrCode = this._.get(await Receipts.api().processingReadQrCode(receipt.id), 'response.data', '')
          await this.openReceiptTextDialog({
            isReceipt: true,
            preview: {
              receipt: receiptHtml,
              qrCode: await this.blobToBase64(qrCode),
              receiptId: receipt.id
            }
          })
        } else {
          this.state = workflow.receiptError
        }
      }
    },
    async checkOrder () {
      const intervalRequest = new IntervalRequest(() => this.model.api().checkStatus(this.orderId), { exponential: 1.1, interval: 1500, maxDelay: 1e5 })
      const resolveCondition = response => [this.model.statuses.done.value, this.model.statuses.paymentError.value, this.model.statuses.receiptError.value].includes(response.response.data.status)
      this.checkOrderRequest = intervalRequest
      return this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
    },
    async checkReceipt () {
      const token = this.token
      const intervalRequest = new IntervalRequest(() => Receipts.api().processingRead(this.receiptId, token))
      const resolveCondition = response => response.response.data.status === Receipts.processingStatuses.DONE || response.response.data.status === Receipts.processingStatuses.ERROR
      return this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
    },
    wait (microseconds) {
      return new Promise(resolve => setTimeout(resolve, microseconds))
    },

    async resetQr () {
      this.resetQrLoading = true
      try {
        await this.model.api().resetQr(this.orderId)
        this.checkOrderRequest.stopExponential()
        this.$notification.success('Замовлення скасовано')
        this.close()
      } catch (e) {
        this.resetQrLoading = false
        this.$handlers.error(e, this)
      } finally {
        this.resetQrLoading = false
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.acquiring-payment-qr {
  &__block {
    text-align: center;
    padding: 16px 0 20px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 32px;
  }

  &__text {
    font-size: 12px;
    color: rgba(124, 124, 124, 1);
    font-weight: 300;
    line-height: 20px;
    margin: 12px 0 24px;
  }

  &__img {
    width: 100%;
    max-width: 200px;
    margin-bottom: 24px;
  }

  &__btn {
    margin-top: 32px;
    min-width: 187px;
  }

  &__frame {
    width: 250px;
    position: relative;
    margin: 0 auto 24px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      width: 310px;
    }
  }

  &__frame-img {
    width: 100%;
  }

  &__frame-qr {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
}
</style>
