<template lang="pug">
  v-row(class="mb-5")
    v-col(cols="12" v-if="isLoading")
      e-process-progress(
        :value="processedItemsValue"
        :current="processedItems"
        :total="totalItems"
      )
    v-col(cols="12" class="text-center" v-if="!isLoading")
      v-btn(
        class="secondary-button"
        style="min-width: 100%"
        @click="downloadFile(fileTypes.excel.value)"
        :loading="loading.excel"
        outlined
      )
        e-svg-icon(size="xmd" name="xlsx" class="mr-2")
        | {{$t('Excel file')}}
    v-col(cols="12" class="text-center" v-if="!isLoading")
      v-btn(
        class="secondary-button"
        style="min-width: 100%"
        @click="downloadFile(fileTypes.csv.value)"
        :loading="loading.csv"
        outlined
      )
        e-svg-icon(size="xmd" name="csv" class="mr-2")
        | {{$t('Csv file')}}
</template>

<script>
import Download from 'js-file-download'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import contentDialog from '~/mixins/dialogs/contentDialog'
import EProcessProgress from '~/components/elements/progress/e-process-progress'

export default {
  components: {
    ESvgIcon,
    EProcessProgress
  },
  mixins: [contentDialog],
  props: {
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: {
      excel: false,
      csv: false
    },
    request: {}
  }),
  computed: {
    model () {
      return AccountingGoods
    },
    fileTypes () {
      return {
        excel: {
          value: 'excel',
          format: 'xlsx'
        },
        csv: {
          value: 'csv',
          format: 'csv'
        }
      }
    },
    isLoading () {
      return this.loading.excel || this.loading.csv
    },
    processedItemsValue () {
      return Math.round(this.processedItems / (this.totalItems / 100)) || 0
    },
    processedItems () {
      return this._.get(this.request, 'response.response.data.processed_items', 0)
    },
    totalItems () {
      return this._.get(this.request, 'response.response.data.total_items', 0)
    }
  },
  beforeDestroy () {
    if (typeof this.request.stopExponential === 'function') {
      this.request.stopExponential()
    }
  },
  methods: {
    checkStatus (id) {
      const resolveStatus = 'done'
      const request = new IntervalRequest(() => this.model.api().checkExportStatus(id), {
        interval: 2e3,
        count: 300,
        maxDelay: 18e5 // ~30 minutes
      })
      this.request = request
      return request.startExponential(response => this._.get(response, 'response.data.status', null) === resolveStatus)
    },
    async downloadFile (type) {
      try {
        this.loading[type] = true
        const action = `export${this._.capitalize(type)}`
        const taskId = this._.get(await this.model.api()[action](this.file), 'response.data.task_id', null)
        const resolved = this._.get(await this.checkStatus(taskId), 'response.data', null)

        if (resolved) {
          const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
          const blob = this._.get(await this.model.api().exportFile(taskId, {
            encoding: isMacLike ? 'utf8' : 'windows-1251'
          }), 'response.data', null)
          Download(blob, `goods.${this.fileTypes[type].format}`)
        }
        if (this.closeModal) {
          this.closeModal(true)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading[type] = false
      }
    }
  }

}
</script>

<style scoped lang="scss">
</style>
