<template lang="pug">
  div(class="dialog")
    div(class="dialog__title mb-5 mt-1") {{ $t('Excise stamps of product') }} "{{ _.get(item, 'goods.name') }}"
    ValidationObserver(ref="form" slim)
      v-form(@submit.prevent.stop="setExciseStamps")
        div
          p(class="excise-stamps__hint") {{ $t('To add multiple excise stamps, enter a value in the field') }}
          ValidationProvider(
            v-for="stamp in stampsCount"
            :key="stamp"
            rules="exciseStamp"
            :name="$t('Excise stamp')"
            :vid="stamps[stamp]"
            v-slot="{ errors }"
            mode="lazy"
          )
            e-input-scanner(
              :placeholder="$t('Enter excise stamp')"
              v-model="stamps[stamp]"
              class="mb-1"
              @change="onStampChange"
              :error-messages="errors"
              translate
              outlined
            )
        div(class="dialog__actions text-center")
          v-btn(
            color="primary"
            class="main-button"
            type="submit"
          ) {{ $t('Continue') }}
</template>

<script>
import EInputScanner from '~/components/elements/inputs/e-input-scanner'

export default {
  components: {
    EInputScanner
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      required: true
    },
    goods: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    stamps: {
      0: null
    }
  }),
  computed: {
    stampsCount () {
      return Object.keys(this.stamps)
    }
  },
  watch: {
    stamps: {
      handler (stamps) {
        const lastIndex = this._.last(Object.keys(stamps))
        if (stamps[lastIndex]) {
          this.$set(this.stamps, +lastIndex + 1, null)
        }
      },
      deep: true
    }
  },
  created () {
    this.fillStampsData()
  },
  methods: {
    async setExciseStamps () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      const stamps = this._.filter(Object.values(this.stamps), val => val !== null && val !== '')
      const goodsClone = JSON.parse(JSON.stringify(this.goods))
      this._.each(goodsClone, (good) => {
        if (this._.get(good, 'goods.id') === this._.get(this.item, 'goods.id')) {
          good.goods.excise_barcodes = stamps || []
        }
      })
      this.closeModal(goodsClone)
    },
    fillStampsData () {
      this._.map(this._.get(this.item, 'goods.excise_barcodes', []), (stamp, index) => {
        this.$set(this.stamps, index, stamp)
      })
    },
    onStampChange () {
      this._.each(this.stamps, (value, key) => {
        if (value === '') {
          this.$delete(this.stamps, key)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.excise-stamps {
  &__hint {
    font-size: 0.75rem;
    line-height: 1.3;
    margin: -15px 0 15px;
  }
}
</style>
