import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import Organization from '~/models/directories/Organization'
import OrganizationManagers from '~/models/directories/pivot/OrganizationManagers'
import Employees from '~/modules/employees/models/Employees'

export default class User extends ChainInheritance(Administration, [
  SoftDeletAble,
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'users'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'dateCreated'
  static defaultSortOrder = true
  static persistOptions = {
    insertOrUpdate: ['organization', 'employee', 'organizationManager']
  }

  static ROLES = {
    user: 'ROLE_USER',
    owner: 'ROLE_OWNER',
    admin: 'ROLE_ADMIN',
    headCashier: 'ROLE_HEAD_CASHIER',
    emergencyCashier: 'ROLE_EMERGENCY_CASHIER',
    employee: 'ROLE_CASHIER',
    superAdmin: 'ROLE_SUPER_ADMIN',
    billingManager: 'ROLE_BILLING_MANAGER',
    partnersManager: 'ROLE_PARTNERS_MANAGER',
    clientsManager: 'ROLE_CLIENTS_MANAGER',
    accountant: 'ROLE_ACCOUNTANT'
  }

  static ROLE_NAMES= {
    [User.ROLES.owner]: 'Власник',
    [User.ROLES.employee]: 'Касир',
    [User.ROLES.superAdmin]: 'Супер Адмін',
    [User.ROLES.billingManager]: 'Білінг менеджер',
    [User.ROLES.partnersManager]: 'Партнер менеджер',
    [User.ROLES.clientsManager]: 'Клієнтський менеджер',
    [User.ROLES.emergencyCashier]: 'Аварійний касир',
    [User.ROLES.accountant]: 'Бухгалтер'
  }

  static fields () {
    return super.fields({
      // Dynamic relation fields.
      org_id: this.attr(null),
      own_org_id: this.attr(null),
      employee_id: this.attr(null),

      id: this.attr(null),
      email: this.attr(null),
      login: this.attr(null),
      phone: this.attr(null),
      roles: this.attr([]),
      organization: this.belongsTo(Organization, 'org_id'),
      managedOrganizations: this.belongsToMany(Organization, OrganizationManagers, 'userId', 'organizationId'),
      ownedOrganization: this.belongsTo(Organization, 'own_org_id'),
      profile: this.attr(null),
      relatedEmployee: this.belongsTo(Employees, 'employee_id'),
      referralCode: this.attr(null)
    })
  }

  get fullName () {
    return `${this._.get(this, 'profile.firstName', '')} ${this._.get(this, 'profile.lastName', '')}`
  }

  get currentOrganization () {
    return this.organization
  }

  get rolesString () {
    let roles = ''
    this.roles.map((role, key) => {
      if (role !== User.ROLES.user) {
        roles += User.ROLE_NAMES[role] + ' '
      }
    })

    return roles || '-'
  }

  get isOwnerWithoutOrganization () {
    return this.roles.includes(User.ROLES.user) &&
      !this.roles.includes(User.ROLES.owner) &&
      !this.roles.includes(User.ROLES.employee) &&
      !this.roles.includes(User.ROLES.superAdmin) &&
      !this.roles.includes(User.ROLES.headCashier) &&
      !this.roles.includes(User.ROLES.emergencyCashier) &&
      !this.roles.includes(User.ROLES.admin) &&
      !this.roles.includes(User.ROLES.accountant)
  }

  get isOwner () {
    return this.roles.includes(User.ROLES.owner)
  }

  get isAdmin () {
    return this.roles.includes(User.ROLES.admin)
  }

  get isHeadCashier () {
    return this.roles.includes(User.ROLES.headCashier)
  }

  get isEmergencyCashier () {
    return this.roles.includes(User.ROLES.emergencyCashier)
  }

  get isEmployee () {
    return this.roles.includes(User.ROLES.employee)
  }

  get isOrganizationUser () {
    return this.isAdmin || this.isEmployee || this.isHeadCashier || this.isEmergencyCashier || this.isAccountant
  }

  get isSuperAdmin () {
    return this.roles.includes(User.ROLES.superAdmin)
  }

  get isBillingManager () {
    return this.roles.includes(User.ROLES.billingManager)
  }

  get isPartnersManager () {
    return this.roles.includes(User.ROLES.partnersManager)
  }

  get isClientsManager () {
    return this.roles.includes(User.ROLES.clientsManager)
  }

  get isAccountant () {
    return this.roles.includes(User.ROLES.accountant)
  }

  get isPawnshop () {
    return this._.get(this.ownedOrganization, 'isPawnshop', false)
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFieldsMap = {
    firstName: 'profile.firstName',
    lastName: 'profile.lastName',
    lastLogin: 'profile.lastLogin'
  }

  static ormTrans = {
    single: 'user',
    multy: 'users'
  }

  static ormHeaders = [
    { text: 'Full name', value: 'fullName', sortable: true, filterable: true },
    { text: 'Roles', value: 'rolesString', sortable: false, filterable: false },
    { text: 'E-mail', value: 'email', sortable: true, filterable: true },
    { text: 'Login ', value: 'login', sortable: true, filterable: true },
    { text: 'Phone', value: 'phone', sortable: true, filterable: true },
    { text: 'Visited at', value: 'updatedAt', sortable: true },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['active'],
      'sort-desc': [true]
    })
  }

  static ormFields = [
    {
      model: 'firstName',
      component: 'v-text-field',
      provider: {
        vid: 'firstName',
        name: 'First name',
        rules: 'required'
      },
      attrs: {
        label: 'First name',
        outlined: true
      }
    },
    {
      model: 'lastName',
      component: 'v-text-field',
      provider: {
        vid: 'lastName',
        name: 'Last name',
        rules: 'required'
      },
      attrs: {
        label: 'Last name',
        outlined: true
      }
    },
    {
      model: 'email',
      component: 'v-text-field',
      provider: {
        vid: 'email',
        name: 'E-mail',
        rules: 'required|email'
      },
      attrs: {
        label: 'E-mail (login)',
        type: 'email',
        outlined: true
      }
    },
    {
      model: 'login',
      component: 'v-text-field',
      provider: {
        vid: 'login',
        name: 'Login ',
        rules: 'required'
      },
      attrs: {
        label: 'Login ',
        type: 'text',
        outlined: true
      }
    },
    {
      model: 'phone',
      component: 'e-input-mask',
      provider: {
        vid: 'phone',
        name: 'Phone',
        rules: 'required|phone'
      },
      attrs: {
        label: 'Phone',
        mask: 'mobile',
        outlined: true
      }
    }
  ]

  static ormActions = [
    {
      name: 'login'
    },
    {
      name: 'edit'
    },
    {
      name: 'read',
      text: 'View',
      icon: {
        text: 'info',
        type: 'e-svg-icon'
      }
    }
  ]

  static ormDialogs = {
    default: 'm-orm-crud-dialog',
    edit: 'm-orm-crud-dialog',
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    edit: {
      config: {
        context: 'update'
      }
    },
    read: {
      title: item => ({ name: item.fullName }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'profile.firstName',
            label: 'First name'
          },
          {
            model: 'profile.lastName',
            label: 'Last name',
            value: val => val || '-'
          },
          {
            model: 'login',
            label: 'Login '
          },
          {
            model: 'email',
            label: 'Email',
            value: val => val || '-'
          },
          {
            model: 'phone',
            label: 'Phone',
            value: val => val || '-'
          },
          {
            model: 'rolesString',
            label: 'Roles'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.addManagedOrganization = function (id, payload) {
        return this.put(
          Model.$routes.users.addManagedOrganization(id),
          payload
        )
      }
      configActions.changePassword = function (id, payload) {
        return this.put(Model.$routes.users.changePassword(id), payload)
      }
      configActions.getCurrent = function () {
        return this.get(Model.$routes.users.profile(), {
          dataKey: null,
          save: true,
          persistBy: 'create',
          persistOptions: {
            insertOrUpdate: ['organization']
          }
        })
      }
      return configActions
    }
  }
}
