<template lang="pug">
  div(:class="['e-input-tree-select', ...$attrs.classes]")
    ValidationProvider(
      v-for="(child, index) in [rootItems, ...children]"
      :key="index"
      v-slot="{ errors }"
      v-bind="provider(index)"
    )
      v-select(
        v-bind="$attrs"
        :value="selectedItems[index]"
        :class="$attrs.classes"
        :label="inputLabel(index)"
        :items="child"
        :loading="loading"
        :suffix="suffix(index)"
        @change="loadChildren($event, index)"
        :error-messages="errors"
        :item-value="itemValue"
        return-object
      )
</template>

<script>
export default {
  props: {
    model: {
      type: Function,
      default: () => {}
    },
    requestItems: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    labelsConfig: {
      type: Object,
      default: () => {}
    },
    providers: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    loading: false,
    children: [],
    selectedItems: []
  }),
  computed: {
    rootItems () {
      return this.model.query().where(this.model.ormTreeConfig.parentFieldName, null).orderBy('position', 'desc').get()
    }
  },
  async created () {
    this.model.deleteAll()
    await this.model.api()[this.requestItems]()
  },
  methods: {
    async loadChildren (item, index) {
      this.children.splice(index)
      this.selectedItems.splice(index)
      this.selectedItems[index] = item

      if (this.$attrs.value) {
        this.$emit('input', null)
      }

      if (this.model.ormTreeConfig.asyncLoading && item[this.model.ormTreeConfig.hasChildrenFlagName]) {
        this.loading = true
        this._.get(await this.model.api()[this.model.ormTreeConfig.asyncLoading.requestMethod](item.id), 'response.data.data')
        this._.get(this.model.query().withAllRecursive(10).whereId(item.id).first(), 'children', [])
        this.children = [
          ...this.children,
          this._.get(this.model.query().withAllRecursive(10).whereId(item.id).first(), 'children', [])
        ]
        this.loading = false
      }
      this.$emit('input', item)
    },
    inputLabel (index) {
      const { labelPattern, labels, labelPrefix } = this.labelsConfig

      if (!labels || (Array.isArray(labels) && index >= labels.length)) {
        return this.$t(labelPrefix) || ''
      }

      return labelPattern.replace(/\{(\w*)\}/g, (match, v) => {
        if (v === 'label' && labels && labels.length) {
          if (labelPrefix) {
            return this.$t(labels[index]).toLowerCase() || ''
          }
          return this.$t(labels[index]) || ''
        }
        return this.$t(this.labelsConfig[v]) || ''
      })
    },
    suffix (index) {
      const item = this.selectedItems[index]
      if (index && item && (index + 1) === this.selectedItems.length) {
        return this._.get(this.$attrs, 'value.code', '')
      }
    },
    provider (index) {
      if (!this.providers[index]) {
        return {}
      }
      return {
        ...this.providers[index],
        mode: this._.get(this.providers[index], 'mode', '') || 'passive',
        name: `"${this.$t(this._.get(this.providers[index], 'name', ''))}"`
      }
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
