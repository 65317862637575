<template lang="pug">
  div(class="modal")
    div(class="modal__img")
      img(src="~/assets/images/cats/cat-1.svg")
    h3(class="modal__title") {{ cardData.title }}
    p(class="modal__text") {{ cardData.text }}
    div(class="modal__btns")
      v-btn(
        class="modal__btn"
        @click="closeModal"
      ) {{ cardData.cancelBtnText }}
      v-btn(
        class="secondary-button error-btn modal__btn"
        @click="okBtnClick"
        :loading="loading"
      ) {{ cardData.okBtnText }}
</template>

<script>
import AcquiringMono from '~/modules/acquiring/models/AcquiringMono'
import CasgRegisters from '~/modules/cashRegisters/models/CashRegisters'
import acquiring from '~/modules/acquiring/mixins/acquiring'

export default {
  name: 'BlockAcquiringDeleteItemModal',
  mixins: [acquiring],
  props: {
    item: {
      type: Object,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    model () {
      return AcquiringMono
    },
    type () {
      const type = this._.get(this.item, 'type')
      if (type === this.model.itemTypes.internet.value) {
        return 'internetAcquiring'
      } else if (type === this.model.itemTypes.qr.value) {
        return 'qrAcquiring'
      } else if (type === this.model.itemTypes.tapToPhone.value) {
        return 'tapToMono'
      }
      return 'posTerminals'
    },
    isPosTerminals () {
      return this.type === 'posTerminals'
    },
    isTapToMono () {
      return this.type === 'tapToMono'
    },
    isQr () {
      return this.type === 'qrAcquiring'
    },
    isInternet () {
      return this.type === 'internetAcquiring'
    },
    cardData () {
      return this.cardsData[this.type]
    },
    cardsData () {
      return {
        posTerminals: {
          title: 'Ви збираєтесь видалити POS-термінал з вашого облікового запису',
          text: 'Цю дію неможливо буде скасувати. Ви справді бажаєте підтвердити видалення?',
          okBtnText: 'Так, видалити',
          cancelBtnText: 'Скасувати'
        },
        tapToMono: {
          title: 'Ви збираєтесь видалити Tap to mono з вашого облікового запису',
          text: 'Цю дію неможливо буде скасувати. Ви справді бажаєте підтвердити видалення?',
          okBtnText: 'Так, видалити',
          cancelBtnText: 'Скасувати'
        },
        qrAcquiring: {
          title: 'Ви збираєтесь видалити QR-касу з вашого облікового запису',
          text: 'Цю дію неможливо буде скасувати. Ви справді бажаєте підтвердити видалення?',
          okBtnText: 'Так, видалити',
          cancelBtnText: 'Скасувати'
        },
        internetAcquiring: {
          title: 'Відключити інтернет-еквайринг',
          text: 'Ви збираєтесь відключити інтернет-еквайринг у вашому обліковому записі. Цю дію неможливо буде скасувати. Ви справді бажаєте підтвердити відключення?',
          okBtnText: 'Так, відключити',
          cancelBtnText: 'Скасувати'
        }
      }
    }
  },
  methods: {
    async okBtnClick () {
      try {
        this.loading = true
        let notification = 'POS-термінал успішно видалено'
        const kassaId = this._.get(this.item, 'integrationDetails.kassaId')
        const acquiringSettings = this._.get(await CasgRegisters.api().read(kassaId), 'response.data.acquiringSettings')
        const acquiringSettingsId = this._.get(this._.find(acquiringSettings, { type: this._.get(this.item, 'type') }), 'id', null)

        await this.model.api().unbind({
          code: this._.get(this.item, 'code')
        })

        if (this.isTapToMono) {
          await this.unbindCheckboxTapToPhone({
            terminalId: this._.get(this.item, 'integrationDetails.terminalId')
          })
          notification = 'Tap to mono успішно видалено'
        } else if (this.isQr) {
          await this.unbindCheckboxQrOrInternetSettings({ acquiringSettingsId })
          notification = 'QR-касу успішно видалено'
        } else if (this.isInternet) {
          await this.unbindCheckboxQrOrInternetSettings({ acquiringSettingsId })
          notification = 'Інтернет-еквайринг успішно видалено'
        }

        await this.refreshTable()
        this.$notification.success(notification)
        await this.closeModal()
      } catch (e) {
        await this.refreshTable()
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  text-align: center;
  padding: 20px 0;

  @media (max-width: map-get($breakpoints, 'sm')) {
    padding: 10px 0;
  }

  &__img {
    margin-bottom: 24px;

    img {
      width: 100%;
      max-width: 204px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    color: #000;
    width: 100%;
    margin: 0 auto 12px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 1.5;
    color: rgba(124, 124, 124, 1);
    margin-bottom: 32px;
  }

  &__btns {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    width: 100%;
    max-width: 187px;
  }
}
</style>
