import { render, staticRenderFns } from "./m-form-accounting-goods.vue?vue&type=template&id=77df3a45&scoped=true&lang=pug&"
import script from "./m-form-accounting-goods.vue?vue&type=script&lang=js&"
export * from "./m-form-accounting-goods.vue?vue&type=script&lang=js&"
import style0 from "./m-form-accounting-goods.vue?vue&type=style&index=0&id=77df3a45&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77df3a45",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCol,VForm,VRow,VTab,VTabItem,VTabs,VTabsItems})
