import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Dialog from '~/models/system/Dialog'

export class AcquiringMono extends PersistAble(OrmModel) {
  static entity = 'acquiringmono'
  static paginated = false
  static primaryKey = 'code'

  static ormTrans = {
    single: 'POS-terminals',
    multy: 'POS-terminal'
  }

  static itemTypes = {
    pos: {
      value: 'pos'
    },
    tapToPhone: {
      value: 'taptophone'
    },
    internet: {
      value: 'internet'
    },
    qr: {
      value: 'qr'
    }
  }

  static itemStatuses = {
    processing: {
      text: 'Сформована заявка',
      value: 'registration_process'
    },
    created: {
      text: 'Створено',
      value: 'created'
    },
    active: {
      text: 'Активний',
      value: 'active'
    },
    connected: {
      text: 'Підключено',
      value: 'connected'
    }
  }

  static fields () {
    return {
      terminal: this.attr(null),
      name: this.attr(null),
      type: this.attr(null),
      status: this.attr(null),
      code: this.attr(null),
      integrationDetails: this.attr(null),
      qrList: this.attr(null)
    }
  }

  get statusString () {
    if (get(this.integrationDetails, 'kassaId') && this.status === AcquiringMono.itemStatuses.active.value) {
      return 'connected'
    }
    return this.status
  }

  get cashRegisterString () {
    const cashRegisterName = get(this.integrationDetails, 'kassaName')
    const cashRegisterNumber = get(this.integrationDetails, 'kassaNumber')
    return cashRegisterName && cashRegisterNumber ? `№${cashRegisterName} (${cashRegisterNumber})` : '–'
  }

  static ormHeaders = [
    { text: 'Status', value: 'statusString', sortable: true, filterable: false, sortQuery: 'usage' },
    { text: 'Terminal name', value: 'name', sortable: false, filterable: false },
    { text: 'Connected to cash register', value: 'cashRegisterString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    statusString: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          const {
            processing,
            created,
            active,
            connected
          } = this.itemStatuses
          return {
            id: item.terminal,
            model: AcquiringMono,
            [processing.value]: {
              text: processing.text,
              type: processing.value,
              appearance: {
                color: 'rgba(0, 255, 71, 0.1)',
                textColor: 'rgba(2, 205, 71, 1)'
              }
            },
            [created.value]: {
              text: created.text,
              type: created.value,
              appearance: {
                color: 'rgba(4,174,213,0.1)',
                textColor: '#04aed5'
              }
            },
            [active.value]: {
              text: active.text,
              type: active.value,
              appearance: {
                color: 'rgba(0, 120, 232, 0.1)',
                textColor: 'rgba(0, 120, 232, 1)'
              }
            },
            [connected.value]: {
              text: connected.text,
              type: connected.value,
              appearance: {
                color: 'rgba(0, 0, 0, 1)',
                textColor: 'rgba(255, 255, 255, 1)'
              }
            }
          }
        }
      }
    }
  }

  static ormActions = [
    {
      name: 'qrCodeGenerate',
      text: 'Licence key QR code',
      visible: (item) => {
        return get(item, 'integrationDetails.kassaId') && item.status === AcquiringMono.itemStatuses.active.value
      },
      icon: {
        type: 'e-svg-icon',
        text: 'qr-code'
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-acquiring-cash-register-qr',
          width: '350px',
          componentProps: {
            item
          }
        })
      }
    },
    {
      name: 'bind',
      text: 'Bind terminal',
      icon: {
        type: 'e-svg-icon',
        text: 'cash-register-2'
      },
      visible: (item) => {
        return !get(item, 'integrationDetails.kassaId') && item.status === AcquiringMono.itemStatuses.active.value
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        const isQr = get(item, 'type') === AcquiringMono.itemTypes.qr.value
        const qrListLength = get(item, 'qrList.length')
        if (isQr && !qrListLength) {
          dialog.open({
            component: 'block-status-modal',
            width: '550px',
            componentProps: {
              text: 'Ой, ви ще не створили QR код в monobank. Перейдіть в кабінет ФОП у розділ "Управління касами"',
              btnText: 'Перейти до кабінету ФОП',
              catType: '5',
              onBtnClick: () => {
                window.open('https://web.monobank.ua/', '_blank')
                dialog.close()
              }
            }
          })
        } else {
          dialog.open({
            component: 'block-acquiring-terminal-bind-form',
            width: '700px',
            componentProps: {
              item
            }
          })
        }
      }
    },
    {
      name: 'edit',
      visible: (item) => {
        const isActive = get(item, 'integrationDetails.kassaId') && item.status === AcquiringMono.itemStatuses.active.value
        if (item.type === AcquiringMono.itemTypes.qr.value || item.type === AcquiringMono.itemTypes.internet.value) {
          return isActive && get(item, 'integrationDetails.xToken')
        }
        return isActive
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-acquiring-edit-form',
          width: '700px',
          componentProps: {
            item
          }
        })
      }
    },
    {
      name: 'delete',
      visible: (item) => {
        return get(item, 'integrationDetails.kassaId') && item.status === AcquiringMono.itemStatuses.active.value
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-acquiring-delete-item-modal',
          width: '560px',
          componentProps: {
            item
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.terminalRegistration = function (payload) {
        return this.post(Model.$routes.acquiringmono.terminalRegistration(), payload, { save: false })
      }
      configActions.terminalBind = function (payload) {
        return this.post(Model.$routes.acquiringmono.terminalBind(), payload, { save: false })
      }
      configActions.tapToMonoCheckboxBind = function (payload) {
        return this.post(Model.$routes.acquiringmono.tapToMonoCheckboxBind(), payload, { save: false })
      }
      configActions.unbind = function (payload) {
        return this.post(Model.$routes.acquiringmono.unbind(), payload, { save: false })
      }
      configActions.rebind = function (payload) {
        return this.post(Model.$routes.acquiringmono.rebind(), payload, { save: false })
      }
      return configActions
    }
  }
}

export default AcquiringMono
