<template lang="pug">
  div()
    form-outlet-address(
      v-if="showAddressForm"
      v-model="editedItem"
      @back="onOutletAddressBack"
      @propertyChange="onPropertyChange"
      @nextStep="onNextStep"
      @saveAddress="onSaveAddress"
      :savedAddress="savedAddress"
    )
    form-outlet-tax-address(
      v-else-if="showTaxAddressForm"
      v-model="editedItem"
      @back="onTaxAddressBack"
      @propertyChange="onPropertyChange"
      :hasSeparatePlace="hasSeparatePlace"
      :loading="itemLoading"
      @nextStep="onNextStep"
    )
    form-outlet-synchronization(
      v-else-if="showSynchronize"
      @back="onSynchronizeBack"
      @nextStep="onNextStep"
    )
    form-outlet-first-screen(
      v-model="editedItem"
      :hasSeparatePlace="hasSeparatePlace"
      @nextStep="onNextStep"
      v-else
    )
</template>

<script>
import Base64js from 'base64-js'
import KatottgDictionary from '~/models/directories/dictionaries/KatottgDictionary'
import TOrmFields from '~/components/templates/orm/t-orm-fields.vue'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import taxAuth from '~/mixins/pages/tax-auth'
import { TaxReportService } from '~/services/Tax/Report/TaxReportService'
import signPluginForOrganization from '~/mixins/dialogs/signPluginForOrganizationDialog'
import TaxAuthService from '~/services/Tax/Auth/TaxAuthService'
import OutletOrder from '~/modules/outlets/models/OutletOrder'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import Outlets from '~/modules/outlets/models/Outlets'
import FormOutletFirstScreen from '~/modules/outlets/views/components/creating-form/form-outlet-first-screen'
import FormOutletAddress from '~/modules/outlets/views/components/creating-form/form-outlet-address'
import FormOutletTaxAddress from '~/modules/outlets/views/components/creating-form/form-outlet-tax-address'
import contentDialog from '~/mixins/dialogs/contentDialog'
import FormOutletSynchronization from '~/modules/outlets/views/components/creating-form/form-outlet-synchronization'

export default {
  components: {
    TOrmFields,
    FormOutletFirstScreen,
    FormOutletAddress,
    FormOutletTaxAddress,
    FormOutletSynchronization
  },
  mixins: [filterOrmObject, taxAuth, signPluginForOrganization, contentDialog],
  props: {
    closeModal: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  data: () => ({
    editedItem: {},
    itemLoading: false,
    showSynchronize: false,
    showAddressForm: false,
    hasSeparatePlace: true,
    showTaxAddressForm: false,
    savedAddress: null
  }),
  computed: {
    model () {
      return OutletOrder
    },
    btnLoading () {
      return this.itemLoading || this._.get(this.iitDialog, 'options.loading', false)
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  },
  methods: {
    onSaveAddress (val) {
      this.$set(this.$data, 'savedAddress', val)
    },
    onSynchronizeBack () {
      this.showSynchronize = false
    },
    onOutletAddressBack () {
      this.showAddressForm = false
    },
    onTaxAddressBack () {
      this.showTaxAddressForm = false
      this.showAddressForm = this.hasSeparatePlace
    },
    async onNextStep ({ showAddressForm, showTaxAddressForm, hasSeparatePlace, send, afterSync, showSynchronize } = {}) {
      this.showAddressForm = showAddressForm || false
      this.showTaxAddressForm = showTaxAddressForm || false
      this.showSynchronize = showSynchronize || false
      this.hasSeparatePlace = hasSeparatePlace

      const regKatottg = this._.get(this.$Organization, 'regKatottg')
      if (showTaxAddressForm && !hasSeparatePlace && !regKatottg && !afterSync) {
        this.showSynchronize = true
        this.showTaxAddressForm = false
      }

      if (send) {
        await this.saveAndSendToTax()
      }
    },
    onPropertyChange ({ key, value } = {}) {
      this.$set(this.editedItem, key, value)
    },
    async showSuccessModal () {
      await this.contentDialog.open({
        component: 'block-status-modal',
        width: '560px',
        componentProps: {
          title: 'Ви успішно створили торгову точку',
          btnText: 'Продовжити',
          catType: '7'
        }
      })
    },
    async saveAndSendToTax () {
      try {
        this.itemLoading = true
        const payload = this.filterOrmObject(this.editedItem)
        payload.code = this._.get(await Outlets.api().randomCode(), 'response.data', null)
        payload.T1RXXXXG11 = this._.get(payload, 'T1RXXXXG11') || '0'
        payload.T1RXXXXG3 = payload.T1RXXXXG3.code
        // dpi processing
        payload.HSTI = payload.HSTI.cSti + ''
        payload.T1RXXXXG5 = payload.T1RXXXXG3 + payload.code

        if (payload.T1RXXXXG6S && typeof payload.T1RXXXXG6S === 'object') {
          payload.T1RXXXXG6S = KatottgDictionary.getCode(payload.T1RXXXXG6S)
        }

        delete payload.code

        await this.signPluginForOrganization.use()
        const keyData = this._.get(this.signPluginForOrganization, 'interface.keyData', null)
        const sign = this._.get(this.signPluginForOrganization, 'interface.sign')
        const edsKeyData = new EdsKeyData(keyData)

        if (!keyData) {
          return this.signPluginForOrganization.close()
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          await this.signPluginForOrganization.close()
          return this.saveAndSendToTax()
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          await this.signPluginForOrganization.close()
          return this.saveAndSendToTax()
        }

        try {
          const res = await TaxAuthService.sendAuthData(keyData, data => sign(data, { fallbackMethod: this.saveAndSendToTax }))
          if (!res) {
            return null
          }
        } catch (e) {
          this.$handlers.error(e, this, true)
        }

        const reportService = new TaxReportService(this.signPluginForOrganization.interface, this.saveAndSendToTax)

        const createdEntity = this._.get(await this.model.api().create(payload), `entities.${this.model.entity}[0]`, null)
        const xmlResponse = await this.model.api().xmlDoc(createdEntity)
        const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
        const fname = this._.get(xmlResponse, 'response.data.fname', null)
        const signData = await reportService.getEnvelopedData(xml)

        if (!signData) {
          return null
        }

        const taxId = this._.get(await this.model.api().taxRegister(createdEntity, { signData, fname }), 'response.data.response.taxId', null)
        const encodedTaxId = new TextEncoder().encode(String(taxId))
        const signedTaxId = await reportService.getEnvelopedData(encodedTaxId)

        if (!signedTaxId) {
          return null
        }

        await this.model.api().signTaxId(createdEntity, { tax_id: signedTaxId })
        await this.signPluginForOrganization.hide()

        await this.showSuccessModal()
      } catch (e) {
        this.$handlers.error(e, this)
        await this.signPluginForOrganization.hide()
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding: 5px 0 20px;
  }
}
</style>
