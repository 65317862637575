import TaxReports from '~/modules/reports/models/TaxReports'
import scrollToFailedValidation from '~/mixins/methods/scrollToFailedValidation'

const taxReportsAdditions = {
  mixins: [scrollToFailedValidation],
  data: () => ({
    loading: false,
    form: {
      HBOS: null,
      HKVED: null,
      HNAME: null,
      HTIN: null,
      HZY: null,
      R08G1D: null,
      R08G2D: null,
      R081G1: null,
      reportingPeriod: null,
      reportingType: null,
      taxSum: {
        R091G2: null,
        R091G3: null,
        R091G4: null,
        R092G2: null,
        R092G3: null,
        R092G4: null,
        R093G2: null,
        R093G3: null,
        R093G4: null,
        R094G2: null,
        R094G3: null,
        R094G4: null,
        R095G2: null,
        R095G3: null,
        R095G4: null,
        R096G2: null,
        R096G3: null,
        R096G4: null,
        R097G2: null,
        R097G3: null,
        R097G4: null,
        R098G2: null,
        R098G3: null,
        R098G4: null,
        R099G2: null,
        R099G3: null,
        R099G4: null,
        R0910G2: null,
        R0910G3: null,
        R0910G4: null,
        R0911G2: null,
        R0911G3: null,
        R0911G4: null,
        R0912G2: null,
        R0912G3: null,
        R0912G4: null,
        R09G2: null,
        R09G4: null
      }
    }
  }),
  computed: {
    model () {
      return TaxReports
    },
    taxSumTableRows () {
      return [
        { month: 'Січень', field1: 'R091G2', field2: 'R091G3', field3: 'R091G4' },
        { month: 'Лютий', field1: 'R092G2', field2: 'R092G3', field3: 'R092G4' },
        { month: 'Березень', field1: 'R093G2', field2: 'R093G3', field3: 'R093G4' },
        { month: 'Квітень', field1: 'R094G2', field2: 'R094G3', field3: 'R094G4' },
        { month: 'Травень', field1: 'R095G2', field2: 'R095G3', field3: 'R095G4' },
        { month: 'Червень', field1: 'R096G2', field2: 'R096G3', field3: 'R096G4' },
        { month: 'Липень', field1: 'R097G2', field2: 'R097G3', field3: 'R097G4' },
        { month: 'Серпень', field1: 'R098G2', field2: 'R098G3', field3: 'R098G4' },
        { month: 'Вересень', field1: 'R099G2', field2: 'R099G3', field3: 'R099G4' },
        { month: 'Жовтень', field1: 'R0910G2', field2: 'R0910G3', field3: 'R0910G4' },
        { month: 'Листопад', field1: 'R0911G2', field2: 'R0911G3', field3: 'R0911G4' },
        { month: 'Грудень', field1: 'R0912G2', field2: 'R0912G3', field3: 'R0912G4' },
        { month: 'УСЬОГО', field1: 'R09G2', field2: null, field3: 'R09G4' }
      ]
    }
  },
  created () {
    if (this.item) {
      const itemCopy = JSON.parse(JSON.stringify(this.item)) || {}
      // eslint-disable-next-line no-prototype-builtins
      if (itemCopy.hasOwnProperty('R081G1') && !itemCopy.R081G1) {
        itemCopy.R081G1 = '6'
      }
      this._.each(this._.get(itemCopy, 'taxSum'), (val, key) => {
        if (key.includes('G3') && !val) {
          itemCopy.taxSum[key] = '22'
        }
      })
      this.$set(this.$data, 'form', itemCopy)
    }
  },
  methods: {
    formatNumber (val) {
      if (!val) {
        return val
      }
      let value = val
      if (value.slice(-3) === '.00' || value.slice(-3) === ',00') {
        value = value.slice(0, -3)
      }
      return value
    },
    onSumChange (fieldKey) {
      if (fieldKey !== 'R09G2' && fieldKey !== 'R09G4') {
        if (fieldKey.includes('G2') || fieldKey.includes('G3')) {
          const index = this._.findIndex(this.taxSumTableRows, i => i.field1 === fieldKey || i.field2 === fieldKey)

          for (let i = index; i < this.taxSumTableRows.length; i++) {
            const item = this.taxSumTableRows[i]
            if (item) {
              const itemField1Key = item.field1
              const itemField2Key = item.field2
              const itemField3Key = item.field3
              const currentFieldVal = this._.get(this.form, `taxSum.${fieldKey}`)
              let field1Val = this._.get(this.form, `taxSum.${itemField1Key}`)
              let field2Val = this._.get(this.form, `taxSum.${itemField2Key}`)

              if (itemField1Key !== 'R09G2') {
                if (fieldKey.includes('G2')) {
                  field1Val = currentFieldVal
                  this.$set(this.form.taxSum, itemField1Key, this.formatNumber(currentFieldVal))
                } else if (fieldKey.includes('G3')) {
                  field2Val = currentFieldVal
                  this.$set(this.form.taxSum, itemField2Key, this.formatNumber(currentFieldVal))
                }

                const field3Val = (field1Val / 100 * field2Val).toFixed(2)
                this.$set(this.form.taxSum, itemField3Key, this.formatNumber(field3Val))
              }
            }
          }
        }

        let R09G2 = 0
        let R09G4 = 0
        this._.each(this.form.taxSum, (val, key) => {
          if (val) {
            // if ((key.includes('G2') || key.includes('G3')) && key !== 'R09G4')
            if (key.includes('G2') && key !== 'R09G2') {
              R09G2 += parseFloat(val)
            }
            if (key.includes('G4') && key !== 'R09G4') {
              R09G4 += parseFloat(val)
            }
          }
        })
        if (typeof R09G2 === 'number') {
          this.$set(this.form.taxSum, 'R09G2', this.formatNumber(R09G2.toFixed(2)))
        }
        if (typeof R09G4 === 'number') {
          this.$set(this.form.taxSum, 'R09G4', this.formatNumber(R09G4.toFixed(2)))
        }
      }
    },
    async submit () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.scrollToFailedValidation(this.$refs.form.$el)
          return
        }
        this.onSubmit(this.form)
        this.closeModal(this.form)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}

export default taxReportsAdditions
