import CurrentUser from '~/modules/auth/Models/CurrentUser'

const multiAccountUsers = {
  data: () => ({
    multiOrganizationUsers: [
      'ccb3558d-d613-46dd-8a7f-d30f9cf34fd0', // MD Fashion prod
      'def83ffe-e908-4e91-b91c-211fb0201c55', // Kulyk stage
      '441687ec-a3e6-4dec-9b86-6b473cb7bafc' // MD Fashion 2 prod
    ]
  }),
  computed: {
    isMultiAccountUser () {
      return this.multiOrganizationUsers.includes(this._.get(this.$User, 'id'))
    }
  },
  methods: {
    updateAllOrganizationSelectedUserData () {
      if (this._.get(this.$localStorageClientData(), 'globalAllOrganizationsSelected') && this.isMultiAccountUser) {
        CurrentUser.update({
          where: this._.get(this.$User, 'id'),
          data: {
            globalAllOrganizationsSelected: true
          }
        })
      }
    }
  }
}

export default multiAccountUsers
