import { Model } from '@vuex-orm/core'

const resolvers = {}

class Dialog extends Model {
  static entity = '_dialogs';

  static fields () {
    return {
      id: this.uid(),
      isOpened: this.attr(false),
      isVisible: this.attr(true),
      interface: this.attr(null),
      resolve: this.attr(null),
      reject: this.attr(null),
      type: this.attr(null),
      params: this.attr(null)
    }
  }

  setInstance (instance) {
    this.instance = instance
  }

  async open (params) {
    const promise = new Promise((resolve, reject) => {
      resolvers[this.id] = {
        resolve,
        reject
      }
    })
    this.isOpened = true
    this.isVisible = true
    this.params = params
    await this.$save()
    return promise
  }

  async show () {
    const promise = new Promise((resolve, reject) => {
      resolvers[this.id] = {
        resolve,
        reject
      }
    })
    this.isVisible = true
    await this.$save()
    return promise
  }

  async hide (data, confirmed = true) {
    if (confirmed) {
      this.resolvePromise(data)
    } else {
      this.rejectPromise(data)
    }
    delete resolvers[this.id]
    this.isVisible = false
    await this.$save()
  }

  async close (data, confirmed = true) {
    if (confirmed) {
      this.resolvePromise(data)
    } else {
      this.rejectPromise(data)
    }
    delete resolvers[this.id]
    this.isOpened = false
    this.params = null
    this.interface = null
    await this.$save()
  }

  async saveData (data) {
    Object.assign(this, data)
    await this.$save()
  }

  resolvePromise (data) {
    resolvers[this.id] && resolvers[this.id].resolve(data)
  }

  rejectPromise (data) {
    resolvers[this.id] && resolvers[this.id].reject(data)
  }

  isShadow () {
    return this.interface && this.isVisible === false
  }

  use (params = {}) {
    if (!this.isShadow()) {
      return this.open(params)
    } else {
      return this.interface
    }
  }
}

export default Dialog
