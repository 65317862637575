<template lang="pug">
  div(class="cards-wrap")
    v-card(
      v-for="item in items"
      :key="item.key"
      class="card"
      @click="item.onClick(item.key)"
      outlined
    )
      div(class="card__content")
        e-svg-icon(size="xxlg") {{ item.icon }}
        div()
          h3(class="card__title") {{ $t(item.title) }}
          p(class="card__text")
            span(
              :class="{ 'mr-1': item.link }"
            ) {{  $t(item.text) }}
            e-link(
              v-if="item.link"
              :href="item.link"
            ) {{ $t(item.linkText) }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import contentDialog from '~/mixins/dialogs/contentDialog'
import ELink from '~/components/elements/links/e-link'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'

export default {
  name: 'BlockEmployeeCreatingOptions',
  components: {
    ESvgIcon,
    ELink
  },
  mixins: [contentDialog],
  computed: {
    items () {
      return [
        {
          key: 'edsKey',
          icon: 'eds-key',
          title: 'Read the EDS key of the cashier',
          text: 'Upload the cashier\'s EDS key or electronic seal that will be used for signature',
          onClick: key => this.handleClick(key)
        },
        // {
        //   key: 'depositSign',
        //   icon: 'cloud-key',
        //   title: 'Generate a DepositSign key',
        //   text: 'We will generate and add to the system the EDS key created through our partners DepositSign',
        //   link: 'https://depositsign.com/',
        //   linkText: 'DepositSign',
        //   onClick: key => this.handleDsClick(key)
        // },
        {
          key: 'manually',
          icon: 'pen-icon',
          title: 'Add manually',
          text: 'You have to fill in all the data manually so that we can add the cashier',
          onClick: key => this.handleClick(key)
        }
      ]
    }
  },
  created () {
    this.$emit('changeTitle', 'Add employee')
  },
  methods: {
    handleClick (key) {
      this.$emit('select', key)
    },
    async handleDsClick (key) {
      try {
        const allowedDsRequest = this._.get(await EmployeeOrder.api().allowedDsRequest(), 'response.data.allowed', false)
        if (allowedDsRequest) {
          this.handleClick(key)
        } else {
          this.$handlers.error(this.$t('Unfortunately, the number of cloud KEPs from Deposit Sign should not exceed the number of Active cash registers'), this)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cards-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card {
  border-radius: 12px;
  background: #F0F1F2;
  border: none !important;
  cursor: pointer;
  transition: .3s;
  overflow: hidden;

  &:hover {
    background: rgba(22, 27, 37, 0.12);
  }

  &__content {
    padding: 16px;
    display: flex;
    gap: 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    color: #161B25;
    margin: 12px 0;
  }

  &__text {
    font-size: 14px;
    font-weight: 300;
    color: rgba(22, 27, 37, 0.75);
    margin: 0;
  }
}
</style>
