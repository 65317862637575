<template lang="pug">
  div(class="how-to-get-card")
    div(class="how-to-get-card__item")
      e-svg-icon(size="xlg" class="mb-3") smartphone
      h4(class="how-to-get-card__title") Встановіть застосунок ABank24
      p(class="how-to-get-card__text") Завантажуйте застосунок з
        |
        |
        a(class="how-to-get-card__link" href="https://play.google.com/store/apps/details?id=ua.com.abank" target="_blank") Google Play
        |
        | або
        |
        a(class="how-to-get-card__link" href="https://apps.apple.com/ua/app/abank24/id1261267716?l=uk" target="_blank") App Store
    div(class="how-to-get-card__item")
      e-svg-icon(size="xlg" class="mb-3") credit-card
      h4(class="how-to-get-card__title") Зареєструйтеся у застосунку
      p(class="how-to-get-card__text") Зареєструйтеся в системі ABank24. Після реєстрації в системі Ви зможете зареєструвати рахунок ФОП та подати заявку на Еквайринг
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon.vue'

export default {
  name: 'BlockABankHowToGetCard',
  components: {
    ESvgIcon
  },
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    }
  },
  created () {
    this.changeTitle('How to register an A-Bank card')
    this.toggleCloseBtn(true)
  }
}
</script>

<style scoped lang="scss">
.how-to-get-card {
  display: flex;
  gap: 15px;
  justify-content: space-between;

  @media (max-width: map-get($breakpoints, 'sm')) {
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;
    max-width: 300px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      max-width: none;
    }
  }

  &__title {
    color: #000;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 12px;
    line-height: 18px;
  }

  &__text {
    font-size: 12px;
    color: #000;
    font-weight: 300;
    margin: 0;
    line-height: 18px;
  }

  &__link {
    color: #000;
  }
}
</style>
