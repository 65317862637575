<template lang="pug">
  div(:class="{'e-files-status-wrapper-scroll': files.length > 2 }")
    v-card(
      v-for="(file, index) in files"
      :key="index"
      class="e-file-status"
      elevation="0"
    )
      div(class="d-flex align-center")
        div(class="mr-5")
          div(class="e-file-status__icon" :class="{'error': isUploadError }")
            e-svg-icon(name="file-text-white")
        div
          div(class="e-file-status__file-name") {{ file.name }}
          div(class="e-file-status__status" :class="{'error': isUploadError }") {{ fileStatus }}
      div(class="d-flex align-center")
        div(class="e-file-status__size mr-2") {{ formatBytes(file.size) }}
        v-btn(
          @click.stop="$emit('delete')"
          class="e-file-status__delete"
        )
          e-svg-icon(name="trash")
</template>

<script>
import prettyBytes from 'pretty-bytes'
import ESvgIcon from '~/components/elements/icons/e-svg-icon.vue'

export default {
  name: 'BlockTapToPhoneFileStatus',
  components: {
    ESvgIcon
  },
  props: {
    files: {
      type: Array,
      default: null
    },
    // TODO: related to errors on file uploading, for now unused, because vee-validate validate input
    isUploadError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fileStatus () {
      return this.isUploadError ? this.$t('Error') : this.$t('Downloaded')
    }
  },
  methods: {
    formatBytes (bytes) {
      return prettyBytes(bytes)
    }
  }
}
</script>

<style lang="scss" scoped>
.e-files-status-wrapper-scroll {
  max-height: 150px;
  overflow-y: scroll;
}

.e-file-status {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #F0F1F2;
  border-radius: 12px !important;

  padding: 9px 8px;
  margin-top: 16px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    background: #43CCB4;
    border-radius: 100%;

    &.error {
      background: #FF1744;
    }
  }

  &__file-name {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  &__status {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #43CCB4;

    &.error {
      color: #FF1744;
    }
  }

  &__size {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__delete {
    background: none !important;
  }
}
</style>
