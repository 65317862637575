<template lang="pug">
  div(class="relative no-print")
    v-btn(
      v-if="!xsDevice && mini"
      class="sidebar__collapse-button sidebar__collapse-button--mini"
      @click="toggleMini"
    )
      v-icon(v-if="savedMini") mdi-chevron-right
      v-icon(v-else class="mr-1") mdi-chevron-left
    v-navigation-drawer(
      v-if="showSidebar"
      v-model="drawer"
      :color="this.$vuetify.theme.currentTheme.drawer"
      :dark="true"
      :back-text="$t('Main menu')"
      :mini-variant="mini"
      :class="{'sidebar': true, 'sidebar--mini': mini, 'mobile-active': sidebar }"
      :app="!(mdDevice && !mini)"
      id="app-sidebar"
      width="280"
      mini-variant-width="72"
      permanent
      clipped
      fixed
    )
      template(v-slot:prepend)
        v-list(class="py-0")
          v-list-item(dense one-line :class="mini ? 'px-3' : ''")
            v-list-item-content(style="overflow: visible; padding: 0;")
              v-row(align="center" justify="space-between")
                v-col(cols="12" :class="{ 'sidebar__header': true, 'sidebar__header--mini': mini, 'sidebar__header--mobile': xsDevice }")
                  v-btn(
                    v-if="!xsDevice && !mini"
                    class="sidebar__collapse-button"
                    @click="toggleMini"
                  )
                    v-icon(v-if="savedMini") mdi-chevron-right
                    v-icon(v-else class="mr-1") mdi-chevron-left
                  div(class="d-flex align-center")
                    img(
                      v-if="mini"
                      :class="{ 'pointer': !allOrganizationsSelected, 'mr-2': !mini }"
                      :src="require('~/assets/images/logo/checkbox-logo-mini.svg')"
                      @click="allOrganizationsSelected ? null : onMenuItemClick('/dashboard')"
                    )
                    v-toolbar-title(
                      v-if="!mini"
                      :class="{ 'pointer': !allOrganizationsSelected, 'pb-0 pl-3': true }"
                      :title="$nuxt.$options.head.title"
                      @click="allOrganizationsSelected ? null : onMenuItemClick('/dashboard')"
                    )
                      v-img(
                        :src="require('~/assets/images/logo/check-by-mono-logo.svg')"
                        height="42px"
                        width="74px"
                      )
                  div(v-if="$User.isOwner")
                    block-notifications-button(
                      :small="mini"
                      @input="onNotificationButtonInput"
                      @openAllNotifications="onMenuItemClick"
                    )
                v-col(
                  v-if="$User.isOwner || $User.isOrganizationUser"
                  cols="12"
                  :style="mini ? 'padding: 0 20px 24px;' : 'padding: 0 12px 24px;'"
                )
                  e-input-organization-change(
                    v-if="!mini && $Organization"
                    @change="organizationChange"
                    @focus="menuOutsideItemFocused = true"
                    @blur="menuOutsideItemFocused = false"
                  )
                  block-offline-status(:mini="mini")

      v-list(:class="['pt-0']")
        v-list-item-group
          v-window(v-model="step")
            v-window-item(:value="1")
              template(v-for="(item, i) in items")
                template(v-if="!item.children")
                  v-list-item(
                    v-if="getParam(item.visible, $User, true) && (('model' in item) ? $ability.can(ormActions.read, item.model) : true)"
                    active-class="active"
                    :key="i"
                    class="mt-2 sidebar__menu-item"
                    :to="item.href"
                  )
                    v-list-item-icon(v-if="item.icon" class="d-flex align-center justify-center")
                      component(:is="item.icon.type" v-bind="item.icon.options ? item.icon.options : {}") {{ item.icon.text }}

                    v-list-item-content
                      v-list-item-title(:role="item.href ? 'button' : null") {{ _.upperFirst($t(item.text)) }}
                        v-icon(v-if="item.children && item.children.length" right class="icon-right") mdi-chevron-right
                template(v-if="item.children && item.children.length")
                  v-list-group(
                    :value="true"
                    disabled
                    append-icon=""
                    v-if="showParent(item)"
                    :class="{'sidebar__list-group': true, 'sidebar__list-group--mini': mini, 'sidebar__list-group--highlighted': item.highlight && item.highlight($User) }"
                  )
                    div(
                      v-if="item.highlight && item.highlight($User)"
                      :class="{ 'highlight-block': true, 'highlight-block--mini': mini, 'highlight-block--mobile': xsDevice }"
                    )
                    template(#activator)
                      v-list-item-content(class="py-0" v-if="!mini")
                        v-list-item-title(
                          :role="item.href ? 'button' : null"
                          class="sidebar__list-group-title"
                        ) {{ _.upperFirst($t(item.text)) }}
                    v-list-item(
                      v-for="(item, i) in item.children"
                      v-if="showChildren(item)"
                      :key="i"
                      class="sidebar__menu-item"
                      :to="item.href ? item.href : null"
                      :href="item.link ? item.link : null"
                      :target="item.link ? '_blank' : null"
                      @click="onMenuItemClick()"
                      active-class="active"
                      dense
                    )
                      v-list-item-icon(v-if="item.icon" class="d-flex align-center justify-center")
                        component(:is="item.icon.type" v-bind="item.icon.options ? item.icon.options : {}") {{ item.icon.text }}

                      v-list-item-content
                        v-list-item-title(:role="item.href ? 'button' : null" :style="item.color ? {color: item.color} : {}") {{ _.upperFirst($t(item.text))}}
                          e-svg-icon(class="sidebar__exclamation-icon" v-if="showExclamation(item)") exclamation
                          v-icon(v-if="item.children && item.children.length" right class="icon-right") mdi-chevron-right
                          span(v-if="item.beta && item.beta($Organization)" class="sidebar__note") {{ $t('Beta')}}
                div(
                  v-if="showParent(item) && mini"
                  class="mt-5 mb-5"
                  :class="{ 'px-1': mini }"
                )
                  hr(size="1px" color="rgba(255,255,255,0.4)")

              div(class="sidebar__bottom")
                div(v-if="xsDevice" class="px-4 pt-2")
                  v-list-item-title(role="button" class="mb-3") {{ $t('Contacts') }}
                  m-support-buttons(mobile)

                v-list-item(
                  class="sidebar__menu-item mt-2"
                  @click="logout"
                )
                  v-list-item-icon(class="d-flex justify-center align-center")
                    e-svg-icon(size="xmd") logout
                  v-list-item-content
                    v-list-item-title {{ $t('Logout') }}

                v-list-item(
                  v-if="superAdminToken"
                  class="sidebar__menu-item mt-6"
                  @click="backToAdmin"
                )
                  v-list-item-icon(class="d-flex justify-center align-center")
                    v-icon mdi-chevron-left
                  v-list-item-content(v-if="!mini")
                    v-list-item-title {{ $t('Return to admin') }}

    div(
      v-if="xsDevice"
      class="mobile-menu"
      :class="{'mobile-menu--all-orgs': allOrganizationsSelected, 'mobile-menu--sidebar-opened': sidebar }"
    )
      div(
        v-for="(item, index) in mobileMenuItems"
        v-if="item.visible"
        :key="index"
        @click="item.call"
        :class="{'mobile-menu__item': true, 'active': item.active }"
      )
        e-svg-icon {{ item.icon }}
        div {{ $t(item.text) }}
</template>

<script>
import { VIcon } from 'vuetify/lib'
import { actions } from '~/const/global'
import { menuItems } from '~/const/menuItems'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EInputOrganizationChange from '~/components/elements/inputs/concrete/e-input-organization-change'
import Invoices from '~/models/billing/Invoices'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import auth from '~/modules/auth/auth'
import responsive from '~/mixins/pages/responsive'
import MSupportButtons from '~/components/modules/m-support-buttons'
import signPluginForOrganization from '~/mixins/dialogs/signPluginForOrganizationDialog'
import BlockNotificationsButton from '~/modules/notifications/views/components/block-notifications-button'
import taxAuth from '~/mixins/pages/tax-auth'
import TaxInfo from '~/modules/reports/models/TaxInfo'
import multiAccountUsers from '~/mixins/organization/multiAccountUsers'
import BlockOfflineStatus from '~/modules/offline-status/views/components/block-offline-status'

export default {
  components: {
    BlockOfflineStatus,
    MSupportButtons,
    EProgressCircular,
    EInputOrganizationChange,
    EFlatIcon,
    ESvgIcon,
    VIcon,
    BlockNotificationsButton
  },
  mixins: [responsive, signPluginForOrganization, taxAuth, multiAccountUsers],
  data: () => ({
    step: 1,
    backText: null,
    drawer: true,
    mini: false,
    savedMini: false,
    sidebar: false,
    showFriendBonus: true,
    menuOutsideItemFocused: false
  }),
  computed: {
    allOrganizationsSelected () {
      return this._.get(this.$User, 'globalAllOrganizationsSelected', false)
    },
    superAdminToken () {
      return auth.getSuperAdminAuthToken()
    },
    headHeight () {
      const isOwner = this.$User.isOwner || this.$User.isOrganizationUser
      if (this.mini) {
        return isOwner ? '157px' : '75px'
      }
      return isOwner ? '162px' : '75px'
    },
    ormActions () {
      return actions
    },
    items () {
      return menuItems
    },
    notMobileDevice () {
      return this.mdDevice && !this.xsDevice
    },
    showSidebar () {
      return !this.xsDevice || this.sidebar
    },
    mobileMenuItems () {
      const sellPath = '/dashboard/createreceipts'
      const returnPath = '/dashboard/refundreceipt'
      return [
        {
          text: 'Selling',
          icon: 'sell-3',
          active: this.$route.path === sellPath && !this.sidebar,
          call: () => this.mobileMenuItemClick(sellPath),
          visible: !this._.get(this.$User, 'globalAllOrganizationsSelected')
        },
        {
          text: 'Return',
          icon: 'selling-3',
          active: this.$route.path === returnPath && !this.sidebar,
          call: () => this.mobileMenuItemClick(returnPath),
          visible: !this._.get(this.$User, 'globalAllOrganizationsSelected')
        },
        {
          text: 'Other',
          icon: 'menu',
          active: this.sidebar,
          call: () => {
            this.sidebar = !this.sidebar
          },
          visible: true
        }
      ]
    }
  },
  watch: {
    drawer (val) {
      this.$emit('input', val)
    }
  },
  created () {
    this.updateAllOrganizationSelectedUserData()
    this.getTaxInfo()

    if (!this.$route.path.includes('payment') && this.$Organization) {
      Invoices.api().filter({ 'order[date]': 'desc' }).all({ status: 'invoiced' })
    }

    if (!this.xsDevice) {
      const savedDrawerState = this._.get(this.$localStorageClientData(), 'isDrawerCollapsed')
      if (savedDrawerState === undefined) {
        this.$setLocalStorageClientData({
          isDrawerCollapsed: true
        })
        this.mini = true
        this.savedMini = true
      } else {
        this.mini = savedDrawerState
        this.savedMini = savedDrawerState
      }
    }

    this.showFriendBonus = this._.get(this.$localStorageClientData(), 'showFriendBonus', true)
  },
  mounted () {
    this.onHoverListener()
  },
  methods: {
    onNotificationButtonInput (val) {
      if (!this.xsDevice) {
        this.menuOutsideItemFocused = val
      }
    },
    onHoverListener () {
      if (!this.xsDevice) {
        const drawer = document.querySelector('#app-sidebar')
        const contentWrap = document.querySelector('.v-content__wrap')
        if (drawer) {
          drawer.addEventListener('mouseover', () => {
            if (this.mini && this.savedMini && !this.menuOutsideItemFocused) {
              this.mini = false
            }
          })
          drawer.addEventListener('mouseleave', () => {
            if (!this.mini && this.savedMini && !this.menuOutsideItemFocused) {
              this.mini = true
            }
          })
        }
        if (contentWrap) {
          contentWrap.addEventListener('click', () => {
            if (!this.mini && this.savedMini && !this.menuOutsideItemFocused) {
              this.mini = true
            }
          })
        }
      }
    },
    async getTaxInfo () {
      if (this.$User.isSuperAdmin || this.$User.isOwnerWithoutOrganization || !this.$Organization || this.$Organization?.isDraft) {
        return
      }
      try {
        await TaxInfo.api().getTaxInfo()
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    showParent (item) {
      return this.getParam(item.visible, this.$User, true) && (('model' in item) ? this.$ability.can(this.ormActions.read, item.model) : true) && this.hasVisibleChildren(item)
    },
    showChildren (item) {
      return this.getParam(item.visible, this.$User, true) && (('model' in item) ? this.$ability.can(this.ormActions.read, item.model) : true) && this.showOnMobile(item)
    },
    showOnMobile (item) {
      return !(this.xsDevice && (item.href && (item.href.includes('createreceipts') || item.href.includes('refundreceipt'))))
    },
    mobileMenuItemClick (path) {
      this.sidebar = false
      this.$router.push(path)
    },
    onMenuItemClick (route) {
      if (route) {
        this.$router.push(route)
      }
      if (!this.notMobileDevice) {
        this.sidebar = false
      }
    },
    organizationChange () {
      this.signPluginForOrganization.close()
      Invoices.api().filter({ 'order[date]': 'desc' }).all({ status: 'invoiced' })
      this.$emit('rerenderContent')
    },
    showExclamation (item) {
      return item.text === 'Payment' && Boolean(Invoices.query().where('status', 'invoiced').first())
    },
    toggleMini () {
      this.savedMini = !this.savedMini
      this.mini = this.savedMini
      this.$setLocalStorageClientData({
        isDrawerCollapsed: this.savedMini
      })
    },
    hasVisibleChildren (item) {
      const children = this._.get(item, 'children', [])
      if (children.length) {
        const visible = children.filter(child => this.showChildren(child))
        return visible.length
      }
      return true
    },
    getParam (param, item, def) {
      if (param === undefined) {
        return def
      }
      if (typeof param === 'function') {
        return param(item, this.$Organization)
      }
      return param
    },
    async backToAdmin () {
      await auth.applyAdminToken()
      this.$router.push('/dashboard/organizations')
    },
    async logout () {
      await auth.logout()
      this.destroyIitGlobalDialog()
      await this.$router.push({ name: 'auth-login' })
    }
  }
}
</script>

<style lang="scss">
.dashboard {
  &__append-list {
    border-radius: 0;
  }
}

.sidebar {
  background: linear-gradient(180deg, #002C6F 0%, #2D0050 100%) !important;
  max-height: 100% !important;
  height: 100% !important;
  overflow: visible !important;

  &__collapse-button {
    position: fixed;
    right: -19px;
    top: 28px;
    width: 20px !important;
    height: 35px !important;
    padding: 0 !important;
    background-color: #002C6F !important;
    border-radius: 0 7px 7px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
    transition: .2s;
    color: #fff !important;

    &--mini {
      left: 72px;
      top: 23px;
      height: 32px !important;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    gap: 15px;
    transition: 0s;
    padding-top: 25px;
    padding-bottom: 25px;

    &--mini {
      flex-direction: column;
    }
  }

  &.mobile-active {
    z-index: 998 !important;
    width: 100% !important;
    height: calc(100% - 53px) !important;
    border-bottom: 1px solid $side-menu-main-text-color !important;
  }

  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .favourite {
      position: absolute;
      right: 15px;

      svg {
        fill: #a5a4bf;

        &:hover {
          fill: #fff;
        }
      }
    }
  }

  .v-list-item {
    &__name {
      max-width: 160px;
    }

    &__content {
      padding: 5px 0;
    }

    &__icon {
      height: 100%;
      margin: 0 12px 0 0 !important;

      svg {
        fill: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .v-list-group {
    position: relative;
    margin-bottom: 24px;

    &__header {
      &.v-list-item {
        height: 16px;
        min-height: auto;
        margin-bottom: 8px;
        cursor: default;

        .v-ripple__container {
          display: none;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  &__exclamation-icon {
    position: absolute;
    width: 17px;
    top: 7px;
    margin-left: 8px;
  }

  &__list-group-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.3);
  }

  &__menu-item {
    height: 32px;
    min-height: 32px;
    font-family: $card-body-font-family;
    padding: 0 12px;
    margin: 1px 0;

    &::before {
      background: rgba(0, 120, 232, 1);
      border-radius: 8px;
    }

    &:hover {
      &::before {
        opacity: 0.2 !important;
      }
    }

    .v-ripple__container {
      border-radius: 8px;
    }

    &.active {
      .v-list-item__content {
        .v-list-item {
          &__title {
            color: #fff;
          }

          &__icon {
            margin: 8px 12px 8px 0 !important;

            svg {
              fill: rgba(255, 255, 255, 0.6);
            }
          }
        }
      }

      @media (min-width: map-get($breakpoints, 'md')) {
        &:hover {
          &::before {
            opacity: 1 !important;
          }
        }

        &::before {
          background: rgba(0, 120, 232, 1);
          opacity: 1;
          z-index: -1;
        }
      }
    }

    .v-list-item__title {
      color: rgba(255, 255, 255, 0.8);
      font-size: 13px;
      line-height: 17.5px;
      font-weight: 600;
      overflow: visible;
    }

    .v-icon.icon-right {
      opacity: 0;
    }

    &:hover {
      .v-icon.icon-left {
        transform: translateX(-5px);
        transition: transform .5s, -webkit-transform .5s;
      }

      .v-icon.icon-right {
        opacity: 1;
        animation: opacity1 1s;
        transform: translateX(5px);
        transition: transform .5s, -webkit-transform .5s;
      }
    }
  }

  &__list-group {
    &--mini {
      .v-list-group__header {
        display: none;
      }
    }

    &--highlighted {
      .sidebar__menu-item {
        &::before,
        .v-ripple__container {
          //left: 7px;
          //width: calc(100% - 14px);
        }

        &:last-child {
          &::before,
          .v-ripple__container {
            //border-bottom-left-radius: 10px;
            //border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }

  &__bonus-wrapper {
    padding: 4px 18px;
    background: $color-warning;
    font-size: 11px;
    color: #000;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;

    .v-list-item__icon {
      margin: 0;
    }
  }

  &__note {
    font-size: 0.7rem;
    color: $color-warning;
    position: relative;
    top: -7px;
    left: 6px;

    &--small {
      font-size: 0.65rem;
    }
  }

  &--mini {
    .v-list-item__icon {
      margin: 0 !important;
    }

    .v-list-group {
      margin-bottom: 0;
    }
  }
}

.mobile-menu {
  background: #002C6F;
  width: 100%;
  height: 54px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;

  &--sidebar-opened {
    background: #2D0050;
  }

  &--all-orgs {
    justify-content: space-around;
  }

  &__item {
    color: #fff;
    font-size: 11px;
    text-align: center;
    padding: 0 25px;
    line-height: 14px;
    opacity: 0.6;
    transition: 0.3s;

    &.active {
      opacity: 1;
    }
  }
}

.highlight-block {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;

  &--mini {
    width: auto;
  }

  &--mobile {
    width: auto;
  }
}
</style>
