import { render, staticRenderFns } from "./block-a-bank-how-to-get-card.vue?vue&type=template&id=0762a56e&scoped=true&lang=pug&"
import script from "./block-a-bank-how-to-get-card.vue?vue&type=script&lang=js&"
export * from "./block-a-bank-how-to-get-card.vue?vue&type=script&lang=js&"
import style0 from "./block-a-bank-how-to-get-card.vue?vue&type=style&index=0&id=0762a56e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0762a56e",
  null
  
)

export default component.exports