import { Reports } from '~/modules/reports/models/Reports'

const processingReports = {
  methods: {
    createXReport (token) {
      return Reports.api().processingCreateXReport(token)
    },
    getReport (id) {
      return Reports.api().processingGetReport(id)
    },
    getReportText (id) {
      return Reports.api().processingGetReportText(id)
    }
  }
}

export default processingReports
