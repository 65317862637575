<template lang="pug">
  div(class="status")
    span(v-if="!statusText") –
    v-btn(
      v-else-if="neededAction"
      class="status__btn"
      :class="{ 'status__btn--red': dsStatus === model.dsKeyStatuses.APPLICATION_RECEIVED.value }"
      @click="handleAction"
      :loading="loading"
      dark
      small
    ) {{ $t(actionBtnText) }}
    v-tooltip(
      :disabled="!tooltipText"
      top
      v-else
    )
      template(#activator="{ on, attrs }")
        v-chip(
          v-bind="colors"
          v-on="on"
          small
        )
          e-svg-icon(
            v-if="icon"
            size="xs"
            class="mr-2"
          ) {{ icon }}
          span {{ $t(statusText) }}
      span() {{ $t(tooltipText) }}
</template>

<script>
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import contentDialog from '~/mixins/dialogs/contentDialog'
import Employees from '~/modules/employees/models/Employees'

export default {
  name: 'BlockEmployeeEdsKeyStatus',
  components: {
    ESvgIcon
  },
  mixins: [contentDialog],
  props: {
    item: {
      type: null,
      default: null
    },
    isOrder: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    model () {
      return EmployeeOrder
    },
    dsStatus () {
      if (!this.isOrder) {
        return ''
      }
      return this._.get(this.item, 'depositSignRequest.status')
    },
    hasUnloadedKey () {
      return this._.get(this.item, 'isCloudSign2') && !this._.get(this.item, 'edsKey.signAgent', null)
    },
    employeeWithDepositSignKey () {
      const allowedModes = (
        this._.get(this.item, 'mode') === Employees.itemModes.checkbox.mode ||
        this._.get(this.item, 'mode') === Employees.itemModes.alert.mode ||
        this._.get(this.item, 'mode') === Employees.itemModes.onRemovalInSfs.mode ||
        this._.get(this.item, 'mode') === Employees.itemModes.hasRemovalOrderError.mode ||
        this._.get(this.item, 'mode') === Employees.itemModes.onModifyInSfs.mode ||
        this._.get(this.item, 'mode') === Employees.itemModes.hasModifyOrderError.mode
      )
      return Boolean(!this.isOrder && this._.get(this.item, 'signatureType') === 'DEPOSITSIGN' && allowedModes)
    },
    employeeWithCheckboxKey () {
      return this._.get(this.item, 'isCloudSign')
    },
    icon () {
      if (this.employeeWithCheckboxKey && !this.keyEnded) {
        return this.hasUnloadedKey ? 'key_gray' : 'key_white'
      }
      return ''
    },
    neededAction () {
      if (!this.isOrder) {
        return null
      } else if (this.dsStatus === this.model.dsKeyStatuses.DONE.value && this._.get(this.item, 'status') === this.model.orderStatuses['100'].value) {
        return 'sendToTax'
      } else if (this.dsStatus === this.model.dsKeyStatuses.APPLICATION_RECEIVED.value) {
        return 'signDSPdf'
      }
      return null
    },
    actionBtnText () {
      if (this.neededAction === 'sendToTax') {
        return 'Send to sfs'
      } else if (this.neededAction === 'signDSPdf') {
        return this.model.dsKeyStatuses.APPLICATION_RECEIVED.text
      }
      return ''
    },
    hasError () {
      return !this.isOrder && this.dsStatus === this.model.dsKeyStatuses.ERROR.value
    },
    applicationExpired () {
      return this.dsStatus === this.model.dsKeyStatuses.APPLICATION_EXPIRED.value
    },
    tooltipText () {
      if (this.hasError) {
        return this._.get(this.item, 'depositSignRequest.message')
      } else if (this.employeeWithCheckboxKey) {
        if (this.keyEnded) {
          return this.keyEnded
        } else if (this.hasUnloadedKey) {
          return 'The key is launched on a secure cloud service. The signature agent is not running'
        } else {
          return 'The key is launched on a secure cloud service. The signing agent is running'
        }
      } else if (this.applicationExpired) {
        return 'You have not completed your application within 24 hours. The application can be deleted in the "Actions" menu'
      }
      return ''
    },
    statusText () {
      if (this.employeeWithDepositSignKey) {
        return 'DepositSign key'
      } else if (this.employeeWithCheckboxKey) {
        if (this.keyEnded) {
          return 'View the error'
        } else {
          return 'Checkbox key'
        }
      } else if (this.dsStatus) {
        return this._.get(this.model, `dsKeyStatuses[${this.dsStatus}].text`, '')
      }
      return ''
    },
    colors () {
      if (this.hasError || this.keyEnded || this.applicationExpired) {
        return this.colorsMap.error
      } else if (this.dsStatus === this.model.dsKeyStatuses.DONE.value || this.employeeWithDepositSignKey || this.employeeWithCheckboxKey) {
        return this.colorsMap.done
      }
      return this.colorsMap.default
    },
    colorsMap () {
      return {
        done: {
          color: 'rgba(0, 120, 232, 1)',
          textColor: '#fff'
        },
        alert: {
          color: 'rgba(255, 170, 1, 0.2)',
          textColor: '#c37f00'
        },
        error: {
          color: 'rgba(255, 0, 43, 0.1)',
          textColor: '#ff002b'
        },
        default: {
          color: 'transparent',
          textColor: '#000'
        }
      }
    },
    keyEnded () {
      const date = new Date()
      const certificateEnd = this._.get(this.item, 'certificateEnd')
      if (certificateEnd === null) {
        return false
      } else if (date.getTime() > new Date(certificateEnd)) {
        // this.keyEndedText = this.$t('Having ended the term of the EDS key of the given cashier')
        return this.$t('Having ended the term of the EDS key of the given cashier')
      } else if (date.setDate(date.getDate() + 5) > new Date(certificateEnd)) {
        const dateEnd = new Date(certificateEnd)
        let delta = Math.floor((dateEnd - new Date()) / 1000)
        const days = Math.floor(delta / 86400)
        delta -= days * 86400
        const hours = Math.floor(delta / 3600) % 24
        delta -= hours * 3600
        const minutes = Math.floor(delta / 60) % 60
        let count = `${days}д`
        if (hours) {
          count += ` ${hours}г`
        }
        if (minutes) {
          count += ` ${minutes}хв`
        }
        // this.keyEndedText = this.$t('The term for the EDS key of the given cashier will end in days', { count })
        return this.$t('The term for the EDS key of the given cashier will end in days', { count })
      }
      return ''
    }
  },
  methods: {
    async handleAction () {
      if (this.neededAction === 'sendToTax') {
        await this.sendToTax()
      } else if (this.neededAction === 'signDSPdf') {
        await this.openSignPdfDialog()
      }
    },
    async openSignPdfDialog () {
      try {
        this.loading = true
        const depositSignRequest = this._.get(await this.model.api().depositSignRequestRead(this._.get(this.item, 'depositSignRequest.id')), 'response.data')
        await this.contentDialog.open({
          component: 'block-sign-ds-pdf',
          width: '500px',
          componentProps: {
            item: this.item,
            depositSignRequest
          }
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async sendToTax () {
      try {
        this.loading = true
        const token = this._.get(await this.model.api().read(this._.get(this.item, 'id')), 'response.data.dsToken')
        await this.contentDialog.open({
          component: 'form-depositsign-password',
          width: '500px',
          componentProps: {
            item: this.item,
            model: this.model,
            token
          }
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.status {
  &__btn {
    max-height: 25px;
    font-size: 11px;
    border-radius: 6px;
    background-color: $focus-color !important;

    &--red {
      background-color: rgba(238, 0, 0, 0.1) !important;
      color: rgba(238, 0, 0, 1);
    }
  }

  &::v-deep {
    .v-chip {
      border-radius: 8px;
      padding: 0 7px;
      font-size: .69rem;
      height: 22px;
    }

    .e-link {
      font-size: .59rem !important;
    }
  }
}
</style>
