<template lang="pug">
  div(class="tap-to-phone-acquiring-order-info")
    p(class="tap-to-phone-acquiring-order-info__icon") ✅
    h3(class="tap-to-phone-acquiring-order-info__title") {{ $t('The application for the use of acquiring has been successfully signed and sent') }}
</template>

<script>
export default {
  name: 'BlockOschadbankAcquiringOrderInfo',
  created () {
    this.$emit('changeTitle', '')
  }
}
</script>

<style scoped lang="scss">
.tap-to-phone-acquiring-order-info {
  text-align: center;
  padding: 40px 24px;

  &__icon {
    font-size: 48px;
    margin-bottom: 40px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 18px;
    }
  }
}
</style>
