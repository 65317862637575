import Acquiring from '~/modules/acquiring/models/Acquiring'
import AcquiringMono from '~/modules/acquiring/models/AcquiringMono'
import AcquiringSettings from '~/modules/acquiring/models/AcquiringSettings'

const acquiring = {
  methods: {
    async refreshTable () {
      return await AcquiringMono.api().all({}, { dataKey: null })
    },
    async rebindCheckboxTapToPhone ({ cashRegisterId, terminalId }) {
      if (terminalId && cashRegisterId) {
        return await Acquiring.api().update({
          $id: terminalId
        }, {
          cashRegister: `cash-registers/${cashRegisterId}`
        })
      }
    },
    async unbindCheckboxTapToPhone ({ terminalId }) {
      if (terminalId) {
        return await Acquiring.api().del({
          $id: terminalId
        })
      }
    },
    async bindCheckboxTapToPhone ({ ptName, kassaId }) {
      return await AcquiringMono.api().tapToMonoCheckboxBind({
        ptName,
        cashRegister: `cash-registers/${kassaId}`
      })
    },
    async unbindCheckboxQrOrInternetSettings ({ acquiringSettingsId }) {
      if (acquiringSettingsId) {
        return await AcquiringSettings.api().del({
          $id: acquiringSettingsId
        })
      }
    },
    async bindCheckboxQrOrInternetSettings ({ kassaId, token, qrList, defaultQr, type, name, acquiringSettingsId, isUpdate = false }) {
      if (kassaId && token) {
        const qrCodes = []

        if (Array.isArray(qrList) && qrList.length) {
          this._.each(qrList, (qr) => {
            const qrId = this._.get(qr, 'invoiceId')
            const shortQrId = this._.get(qr, 'shortId')
            qrCodes.push({
              default: shortQrId === defaultQr,
              amountType: 'merchant',
              pageUrl: `https://pay.mbnk.biz/${qrId}`,
              qrId,
              shortQrId
            })
          })
        }

        const payload = {
          cashRegister: `cash-registers/${kassaId}`,
          qrCodes
        }

        if (isUpdate) {
          return await AcquiringSettings.api().update({ $id: acquiringSettingsId }, payload)
        } else {
          return await AcquiringSettings.api().create({
            ...payload,
            token,
            type,
            name,
            valid: true
          })
        }
      }
    }
  }
}

export default acquiring
