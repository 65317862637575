<template lang="pug">
  div(class="a-bank-connecting")
    div(
      v-if="infoLoading"
      class="a-bank-connecting__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    div(v-else)
      block-a-bank-not-a-client(
        v-if="step === steps.notClient"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
      )
      block-a-bank-how-to-get-card(
        v-else-if="step === steps.howToGetCard"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
      )
      block-a-bank-information-check(
        v-else-if="step === steps.checkInformation"
        :changeTitle="changeTitle"
        :changeStep="changeStep"
        :steps="steps"
        :toggleCloseBtn="toggleCloseBtn"
        :model="model"
      )
      block-a-bank-account-opening(
        v-else-if="step === steps.accountOpening"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
        :changeStep="changeStep"
        :steps="steps"
        :changeAccountOrder="changeAccountOrder"
        :changeAccountOrderError="changeAccountOrderError"
        :changeShowFooterActions="changeShowFooterActions"
        :changeAccountOrderBase64Pdf="changeAccountOrderBase64Pdf"
      )
      block-a-bank-account-application-loader(
        v-else-if="step === steps.accountApplicationLoader"
        :changeTitle="changeTitle"
        :showBankSelectionForm="showBankSelectionForm"
        :changeStep="changeStep"
        :steps="steps"
        :toggleCloseBtn="toggleCloseBtn"
        :showError="accountOrderError"
        :accountOrder="accountOrder"
      )
      block-a-bank-acquiring-order(
        v-else-if="step === steps.acquiringOrder"
        ref="acquiringOrderForm"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
      )
      block-a-bank-acquiring-order-pdf(
        v-else-if="step === steps.acquiringOrderPdf"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
        :changeStep="changeStep"
        :steps="steps"
        :acquiringOrderBase64Pdf="acquiringOrderBase64Pdf"
        :acquiringOrder="acquiringOrder"
        :tableRequest="tableRequest"
        :changeAcquiringOrderInfo="changeAcquiringOrderInfo"
        is-on-boarding-dialog-part
      )
      block-a-bank-acquiring-order-info(
        v-else-if="step === steps.acquiringOrderInfo"
        :info="acquiringOrderErrorInfo"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
      )
      div(
        v-if="showActions"
        class="a-bank-connecting__actions"
      )
        ul(
          v-if="showStepper && !xsDevice"
          class="a-bank-connecting__stepper"
        )
          li(class="a-bank-connecting__stepper-item") {{ $t('Become bank client') }}
          li(
            class="a-bank-connecting__stepper-item"
            :class="{ 'a-bank-connecting__stepper-item--disabled': step === steps.howToGetCard }"
          ) {{ $t('Open FOP') }}
          li(
            class="a-bank-connecting__stepper-item"
            :class="{ 'a-bank-connecting__stepper-item--disabled': step === steps.howToGetCard || step === steps.accountOpening }"
          ) Еквайринг
        v-btn(
          class="main-button"
          @click="handleClick"
          :loading="loading"
        ) {{ $t(buttonText) }}
</template>

<script>
import Base64js from 'base64-js'
import responsive from '~/mixins/pages/responsive'
import EProgressCircular from '~/components/elements/progress/e-progress-circular.vue'
import BlockABankNotAClient from '~/modules/acquiring/views/_old/a-bank/block-a-bank-not-a-client.vue'
import BlockABankHowToGetCard from '~/modules/acquiring/views/_old/a-bank/block-a-bank-how-to-get-card.vue'
import BlockABankInformationCheck from '~/modules/acquiring/views/_old/a-bank/block-a-bank-information-check.vue'
import BlockABankAccountOpening from '~/modules/acquiring/views/_old/a-bank/block-a-bank-account-opening.vue'
import BlockABankAccountApplicationLoader from '~/modules/acquiring/views/_old/a-bank/block-a-bank-account-application-loader.vue'
import BlockABankAcquiringOrder from '~/modules/acquiring/views/_old/a-bank/block-a-bank-acquiring-order.vue'
import BlockABankAcquiringOrderPdf from '~/modules/acquiring/views/_old/a-bank/block-a-bank-acquiring-order-pdf.vue'
import BlockABankAcquiringOrderInfo from '~/modules/acquiring/views/_old/a-bank/block-a-bank-acquiring-order-info.vue'
import ABank from '~/modules/acquiring/models/ABank'
import AccountOrder from '~/modules/acquiring/models/AccountOrder'
import signPluginForOrganization from '~/mixins/dialogs/signPluginForOrganizationDialog'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import TaxAuthService from '~/services/Tax/Auth/TaxAuthService'
import AcquiringOrder from '~/modules/acquiring/models/AcquiringOrder'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  name: 'BlockABankConnecting',
  components: {
    BlockABankAcquiringOrderInfo,
    BlockABankAcquiringOrderPdf,
    BlockABankAcquiringOrder,
    BlockABankAccountApplicationLoader,
    BlockABankAccountOpening,
    BlockABankInformationCheck,
    BlockABankHowToGetCard,
    BlockABankNotAClient,
    EProgressCircular
  },
  mixins: [responsive, signPluginForOrganization],
  data: () => ({
    infoLoading: false,
    loading: false,
    step: null,
    clientStatus: null,
    accountOrder: null,
    accountOrderBase64Pdf: null,
    accountOrderError: false,
    acquiringOrder: null,
    acquiringOrderBase64Pdf: null,
    acquiringOrderErrorInfo: false,
    showFooterActions: true,
    intervalRequest: null
  }),
  computed: {
    model () {
      return ABank
    },
    accountModel () {
      return AccountOrder
    },
    acquiringModel () {
      return AcquiringOrder
    },
    steps () {
      return {
        notClient: 'notClient',
        howToGetCard: 'howToGetCard',
        checkInformation: 'checkInformation',
        accountOpening: 'accountOpening',
        accountApplicationLoader: 'accountApplicationLoader',
        acquiringOrder: 'acquiringOrder',
        acquiringOrderPdf: 'acquiringOrderPdf',
        acquiringOrderInfo: 'acquiringOrderInfo',
        terminalConnectingOptions: 'terminalConnectingOptions'
      }
    },
    showActions () {
      return this.showFooterActions &&
        this.step !== this.steps.checkInformation &&
        this.step !== this.steps.accountApplicationLoader &&
        this.step !== this.steps.acquiringOrderPdf &&
        this.step !== this.steps.acquiringOrderInfo
    },
    showStepper () {
      return this.step === this.steps.howToGetCard ||
        this.step === this.steps.accountOpening ||
        this.step === this.steps.acquiringOrder
    },
    buttonText () {
      if (this.step === this.steps.howToGetCard) {
        return 'I have registered'
      } else if (this.step === this.steps.accountOpening) {
        return 'Sign the application'
      } else if (this.step === this.steps.acquiringOrder) {
        return 'Submit an application'
      }
      return 'Continue'
    }
  },
  watch: {
    step (val) {
      if (val === this.steps.accountOpening || val === this.steps.acquiringOrderPdf) {
        this.changeDialogWidth('900px')
      } else {
        this.changeDialogWidth('755px')
      }
    }
  },
  created () {
    this.getClientInfo()
    this.changeTitle('Add POS-terminal')
    this.toggleCloseBtn(true)
  },
  beforeDestroy () {
    if (this.intervalRequest) {
      this.intervalRequest.stopExponential()
    }
  },
  methods: {
    changeTitle (val) {
      this.$emit('changeTitle', val)
    },
    showBankSelectionForm (val) {
      this.$emit('showBankSelectionForm', val)
    },
    toggleCloseBtn (val) {
      this.$emit('toggleCloseBtn', val)
    },
    changeDialogWidth (val) {
      this.$emit('changeDialogWidth', val)
    },
    changeStep (val) {
      this.step = val
    },
    changeAccountOrder (val) {
      this.accountOrder = val
    },
    changeAccountOrderBase64Pdf (val) {
      this.accountOrderBase64Pdf = val
    },
    changeAccountOrderError (val) {
      this.accountOrderError = val
    },
    changeAcquiringOrderInfo (val) {
      this.acquiringOrderErrorInfo = val
    },
    changeShowFooterActions (val) {
      this.showFooterActions = val
    },
    async tableRequest () {
      await this.$emit('tableRequest')
    },
    goToStepByClientStatus () {
      switch (this.clientStatus) {
        case this.model.CLIENT_STATUSES.greenPhysClient:
          this.changeStep(this.steps.accountOpening)
          break
        case this.model.CLIENT_STATUSES.fopWithAccount:
        case this.model.CLIENT_STATUSES.fopWithAcquiring:
          this.changeStep(this.steps.acquiringOrder)
          break
        default:
          this.changeStep(this.steps.notClient)
          break
      }
    },
    async getClientInfo () {
      try {
        this.infoLoading = true
        this.clientStatus = this._.get(await this.model.api().getClientStatus(), 'response.data.clientType')
        this.goToStepByClientStatus()
      } catch (e) {
        this.$handlers.error(e, this)
        this.$emit('closeModal')
      } finally {
        this.infoLoading = false
      }
    },
    async sendAccountPdf () {
      try {
        this.loading = true

        await this.signPluginForOrganization.use()
        const keyData = this._.get(this.signPluginForOrganization, 'interface.keyData', null)
        const sign = this._.get(this.signPluginForOrganization, 'interface.sign')
        const edsKeyData = new EdsKeyData(keyData)

        if (!keyData) {
          return this.signPluginForOrganization.close()
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          await this.signPluginForOrganization.close()
          return this.sendAccountPdf()
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          await this.signPluginForOrganization.close()
          return this.sendAccountPdf()
        }

        try {
          const res = await TaxAuthService.sendAuthData(keyData, data => sign(data, { fallbackMethod: this.sendAccountPdf }))
          if (!res) {
            return null
          }
        } catch (e) {
          this.$handlers.error(e, this, true)
        }

        const pdf = Base64js.toByteArray(this.accountOrderBase64Pdf)
        const signData = await this.signPluginForOrganization.interface.sign(pdf, { fallbackMethod: this.sendAccountPdf })

        if (!signData) {
          this.$handlers.error('An error occurred while signing the document. Please try again or contact your administrator', this)
          return null
        }

        await this.accountModel.api().sendSigned(this._.get(this.accountOrder, 'id'), {
          pdf: signData
        })
        await this.signPluginForOrganization.hide()

        this.$gtm.push({
          event: 'success_sign_a_bank_account_opening',
          organization: this._.get(this.$Organization, 'owner.email')
        })

        this.changeStep(this.steps.accountApplicationLoader)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    checkStatus (model, id) {
      const request = new IntervalRequest(() => model.api().read(id), {
        interval: 1e3,
        count: 300,
        maxDelay: 12e4 // ~2 minutes
      })
      this.intervalRequest = request
      return request.startExponential((response) => {
        const status = this._.get(response, 'response.data.status', null)
        return status === model.STATUSES.signaturePending || status === model.STATUSES.error
      })
    },
    async sendAcquiringOrder () {
      try {
        this.loading = true
        const formRef = this.$refs.acquiringOrderForm
        const valid = formRef ? await formRef.$refs.form.validate() : false
        if (!valid) {
          return
        }
        const formData = formRef.form
        const outletId = this._.get(formData, 'outlet.id', null)
        const mccCode = this._.get(formData, 'mccCode.mcc', null)
        const payload = {
          ...formData,
          mccCode: mccCode ? `${mccCode}` : null,
          outlet: outletId ? `/outlets/${outletId}` : null
        }
        const order = this._.get(await this.acquiringModel.api().create(payload), 'response.data')
        const orderId = this._.get(order, 'id')
        const status = this._.get(await this.checkStatus(this.acquiringModel, orderId), 'response.data.status', null)

        await this.tableRequest()

        if (status === this.acquiringModel.STATUSES.signaturePending) {
          this.acquiringOrder = order
          this.acquiringOrderBase64Pdf = this._.get(await this.acquiringModel.api().getDoc(orderId), 'response.data.pdf')
          this.changeStep(this.steps.acquiringOrderPdf)
        } else {
          this.$emit('closeModal')
        }

        this.$gtm.push({
          event: 'success_a_bank_acquiring_order',
          organization: this._.get(this.$Organization, 'owner.email')
        })
      } catch (e) {
        this.$handlers.error(e, this)
        await this.tableRequest()
        this.$emit('closeModal')
      } finally {
        this.loading = false
      }
    },
    async handleClick () {
      switch (this.step) {
        case this.steps.notClient:
          this.changeStep(this.steps.howToGetCard)
          break
        case this.steps.howToGetCard:
          this.$gtm.push({
            event: 'download_a_bank_mobile_app',
            organization: this._.get(this.$Organization, 'owner.email')
          })
          this.changeStep(this.steps.checkInformation)
          break
        case this.steps.accountOpening:
          await this.sendAccountPdf()
          break
        case this.steps.acquiringOrder:
          await this.sendAcquiringOrder()
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
.a-bank-connecting {
  padding: 0 0 20px;

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
  }

  &__actions {
    padding-top: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__stepper {
    display: flex;
    gap: 24px;
    list-style: none;
    padding: 0;
    flex-grow: 1;
  }

  &__stepper-item {
    color: #000;
    font-size: 12px;
    font-weight: 300;

    &--disabled {
      opacity: 0.2;
    }

    &:before {
      content: '1';
      display: inline-flex;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-right: 4px;
      border: 1px solid #000;
      align-items: center;
      justify-content: center;
    }

    &:nth-child(2) {
      &:before {
        content: '2';
      }
    }

    &:nth-child(3) {
      &:before {
        content: '3';
      }
    }
  }
}
</style>
