<template lang="pug">
  div(class="tap-to-phone-bank-selection")
    h3(class="tap-to-phone-bank-selection__title") {{ $t('Choose the bank you want to connect and click "Continue"') }}
    div(class="tap-to-phone-bank-selection__cards-wrap")
      div(
        v-for="item in banksCards"
        class="tap-to-phone-bank-selection__card"
        :class="{ selected: selectedBank === item.key, disabled: !item.readyToUse }"
        :key="item.key"
        @click="cardClick(item)"
      )
        div(class="tap-to-phone-bank-selection__card-header")
          img(
            :src="require(`~/assets/images/icons/${item.logo}`)"
            alt="logo"
          )
          //span(v-if="false" class="tap-to-phone-bank-selection__card-tooltip")
          //  e-info-tooltip(v-if="item.key === banks.aBank") {{ $t('To order a POS terminal, you need to perform several steps:') }}
          //    ul
          //      li {{ $t('Install the ABank24 mobile application and register') }}
          //      li {{ $t('Sign an application for opening a FOP account') }}
          //      li {{ $t('Sign the application for connecting acquiring') }}
        div(class="tap-to-phone-bank-selection__card-content")
          div(class="mb-3")
            p(class="tap-to-phone-bank-selection__card-title") {{ $t(item.titleFirst) }}
            p(class="tap-to-phone-bank-selection__card-text") {{ $t(item.textFirst) }}
          div()
            p(class="tap-to-phone-bank-selection__card-title") {{ $t(item.titleSecond) }}
            p(class="tap-to-phone-bank-selection__card-text") {{ $t(item.textSecond) }}
    div(
      class="d-flex justify-end pb-5"
    )
      v-btn(
        class="main-button"
        @click="btnClick"
      ) {{ $t('Continue') }}
</template>

<script>
import EInfoTooltip from '~/components/elements/tooltips/e-info-tooltip.vue'

export default {
  name: 'BlockTapToPhoneBankSelection',
  components: {
    EInfoTooltip
  },
  props: {
    banks: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    selectedBank: null
  }),
  computed: {
    banksCards () {
      return [
        {
          key: this.banks.oschad,
          logo: 'oschadbank-logo.svg',
          titleFirst: '1.3%',
          textFirst: 'Комісія від суми транзакцій',
          titleSecond: 'Оренда терміналу',
          textSecond: 'Безкоштовно',
          readyToUse: true
        },
        {
          key: this.banks.raiffeisen,
          logo: 'raiffeisen-bank-logo.svg',
          titleFirst: 'Скоро',
          textFirst: 'Комісія від суми транзакцій',
          titleSecond: 'Скоро',
          textSecond: 'Безкоштовно',
          readyToUse: false
        }
      ]
    }
  },
  created () {
    this.selectedBank = this.banks.oschad
    this.$gtm.push({
      event: 'select_tap_to_phone_bank_view',
      organization: this._.get(this.$Organization, 'owner.email')
    })
  },
  methods: {
    cardClick (item) {
      if (!item.readyToUse) {
        this.selectedBank = null
        return
      }
      this.selectedBank = item.key
    },
    btnClick () {
      if (this.selectedBank) {
        this.$gtm.push({
          event: 'bank_selection_tap_to_phone_oschadbank',
          organization: this._.get(this.$Organization, 'owner.email')
        })
        this.$emit('changeSelectedBank', this.selectedBank)
        this.$emit('showTapToPhoneUploadDocuments', true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tap-to-phone-bank-selection {
  &__title {
    font-size: 13px;
    font-weight: 300;
    color: rgba(22, 27, 37, 0.75);
  }

  &__cards-wrap {
    display: flex;
    gap: 12px;
    padding: 20px 2px 32px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;
    }
  }

  &__card {
    border: 1px solid #D0D5DD;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    max-width: 34%;

    &.selected {
      border: 1px solid transparent;
      box-shadow: 0 0 0 2px #161B25;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      max-width: 100%;
    }
  }

  &__card-header {
    height: 80px;
    background: #F0F1F2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    position: relative;
  }

  &__card-content {
    padding: 20px;
    background: #fff;
  }

  &__card-title {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin: 0;
  }

  &__card-text {
    font-size: 12px;
    font-weight: 300;
    color: #000;
    margin: 0;
  }

  &__card-tooltip {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
}
</style>
