<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t(cardData.title) }}
    div(
      v-if="globalLoading"
      class="dialog__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    v-form(
      @submit.prevent.stop="submit"
      class="pt-4"
      v-else
    )
      div()
        ValidationObserver(ref="form" slim)
          t-orm-fields(
            v-bind.sync="form"
            :items="fields"
          )
        div(v-if="qrList.length > 1")
          p(class="mb-1") {{$t('Found QR cash registers')}}:
          v-radio-group(
            class="mt-0 mb-2"
            v-model="defaultQr"
          )
            v-radio(
              v-for="qr in qrList"
              :key="qr.shortId"
              :label="qr.shortId"
              :value="qr.shortId"
              color="black"
              :ripple="false"
            )
      div(class="dialog__actions mt-2")
        v-btn(
          class="dialog__btn"
          @click="closeModal"
        ) {{ $t(cardData.cancelBtnText) }}
        v-btn(
          class="main-button dialog__btn"
          type="submit"
          :loading="loading"
        ) {{ $t(cardData.okBtnText) }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'
import contentDialog from '~/mixins/dialogs/contentDialog'
import AcquiringMono from '~/modules/acquiring/models/AcquiringMono'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import acquiring from '~/modules/acquiring/mixins/acquiring'
import CasgRegisters from '@/modules/cashRegisters/models/CashRegisters'

export default {
  name: 'BlockAcquiringEditForm',
  components: {
    TOrmFields,
    EProgressCircular
  },
  mixins: [contentDialog, acquiring],
  props: {
    item: {
      type: Object,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    globalLoading: false,
    form: {
      cashRegister: null
    },
    defaultQr: null,
    prevDefaultQr: null
  }),
  computed: {
    model () {
      return AcquiringMono
    },
    type () {
      const type = this._.get(this.item, 'type')
      if (type === this.model.itemTypes.internet.value) {
        return 'internetAcquiring'
      } else if (type === this.model.itemTypes.qr.value) {
        return 'qrAcquiring'
      } else if (type === this.model.itemTypes.tapToPhone.value) {
        return 'tapToMono'
      }
      return 'posTerminals'
    },
    isPosTerminals () {
      return this.type === 'posTerminals'
    },
    isTapToMono () {
      return this.type === 'tapToMono'
    },
    isQr () {
      return this.type === 'qrAcquiring'
    },
    isInternet () {
      return this.type === 'internetAcquiring'
    },
    terminalType () {
      if (this.isTapToMono) {
        return 'MT'
      } else if (this.isQr) {
        return 'MQ'
      } else if (this.isInternet) {
        return 'MI'
      }
      return 'PQ'
    },
    cardData () {
      return this.cardsData[this.type]
    },
    cardsData () {
      return {
        posTerminals: {
          title: 'Редагування POS-терміналу',
          okBtnText: 'Зберегти',
          cancelBtnText: 'Скасувати'
        },
        tapToMono: {
          title: 'Редагування tap to mono',
          okBtnText: 'Зберегти',
          cancelBtnText: 'Скасувати'
        },
        qrAcquiring: {
          title: 'Редагування QR-каси',
          okBtnText: 'Зберегти',
          cancelBtnText: 'Скасувати'
        },
        internetAcquiring: {
          title: 'Редагування інтернет еквайрингу',
          okBtnText: 'Зберегти',
          cancelBtnText: 'Скасувати'
        }
      }
    },
    fields () {
      return [
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Cash register ',
          returnObject: true,
          itemClass: 'ws-pre',
          rules: 'required',
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode })
        }, CashRegistersSearch).build()
      ]
    },
    cashRegisterId () {
      return this._.get(this.item, 'integrationDetails.kassaId')
    },
    qrList () {
      return this._.get(this.item, 'qrList') || []
    }
  },
  created () {
    this.getCashRegister()
  },
  methods: {
    setDefaultQr (defaultQr) {
      if (this.isQr || this.isInternet) {
        const qr = defaultQr || this._.get(this.qrList, '[0].shortId', null)
        this.$set(this.$data, 'defaultQr', qr)
        this.$set(this.$data, 'prevDefaultQr', qr)
      }
    },
    async getCashRegister () {
      try {
        this.globalLoading = true
        await CashRegistersSearch.api().read(this.cashRegisterId)
        const cashRegister = CashRegistersSearch.query().whereId(this.cashRegisterId).first()
        this.$set(this.form, 'cashRegister', cashRegister)
        const acquiringSettings = this._.get(cashRegister, 'acquiringSettings', [])
        const qrCodes = this._.get(this._.find(acquiringSettings, { type: this._.get(this.item, 'type') }), 'qrCodes')
        let defaultQr = null
        if (qrCodes?.length) {
          defaultQr = this._.get(this._.find(qrCodes, i => i.default), 'shortQrId', null)
        }
        this.setDefaultQr(defaultQr)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.globalLoading = false
      }
    },
    async submit () {
      const newCashRegisterId = this._.get(this.form, 'cashRegister.id')
      if (newCashRegisterId === this.cashRegisterId && this.defaultQr === this.prevDefaultQr) {
        await this.closeModal()
        return
      }

      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        const acquiringSettings = this._.get(this.form, 'cashRegister.acquiringSetting')
        const hasAcquiring = Boolean(this._.find(acquiringSettings, { type: this._.get(this.item, 'type') }))
        if (hasAcquiring && this._.get(this.$refs, 'form.setErrors')) {
          this.$refs.form.setErrors({
            cashRegister: 'До каси вже прикріплений еквайринг даного типу. Оберіть іншу касу'
          })
          return
        }
        const oldCashRegisterAcquiringSettings = this._.get(await CasgRegisters.api().read(this.cashRegisterId), 'response.data.acquiringSettings')
        const oldCashRegisterAcquiringSettingsId = this._.get(this._.find(oldCashRegisterAcquiringSettings, { type: this._.get(this.item, 'type') }), 'id', null)

        let notification = 'POS-термінал успішно відредаговано'

        if (newCashRegisterId !== this.cashRegisterId) {
          await this.model.api().rebind({
            code: this._.get(this.item, 'code'),
            kassaId: newCashRegisterId,
            cashierId: null
          })
        }

        const qrOrInternetPayload = {
          kassaId: this._.get(this.form, 'cashRegister.id'),
          token: this._.get(this.item, 'integrationDetails.xToken'),
          qrList: this.isInternet ? [] : this.qrList,
          defaultQr: this.defaultQr,
          acquiringSettingsId: oldCashRegisterAcquiringSettingsId,
          type: this._.get(this.item, 'type', null),
          name: this._.get(this.item, 'name', null),
          isUpdate: true
        }

        if (this.isTapToMono) {
          await this.rebindCheckboxTapToPhone({
            terminalId: this._.get(this.item, 'integrationDetails.terminalId'),
            cashRegisterId: this._.get(this.form, 'cashRegister.id')
          })
          notification = 'Tap to mono успішно відредаговано'
        } else if (this.isQr) {
          await this.bindCheckboxQrOrInternetSettings(qrOrInternetPayload)
          notification = 'QR-касу успішно відредаговано'
        } else if (this.isInternet) {
          await this.bindCheckboxQrOrInternetSettings(qrOrInternetPayload)
          notification = 'Інтернет-еквайринг успішно відредаговано'
        }

        await this.refreshTable()
        this.$notification.success(notification)
        await this.closeModal()
      } catch (e) {
        await this.refreshTable()
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
  }

  &__btn {
    width: 100%;
    max-width: 187px;
  }
}
</style>
