<template lang="pug">
  div(class="tap-to-phone-settings-form")
    div(
      v-if="isDialogPart"
      class="dialog__title pos-terminal-settings-form__title mb-3"
    )
      span {{ $t(title) }}
    v-form
      ValidationObserver(ref="form" slim)
        t-orm-fields(v-bind.sync="form" :items="fields")
    div(class="tap-to-phone-settings-form__actions")
      v-btn(
        class="main-button d-block mx-auto mb-4"
        @click="save"
        :loading="loading"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields.vue'
import Acquiring from '~/modules/acquiring/models/Acquiring'
import Merchants from '~/modules/acquiring/models/Merchants'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import ProvidersSearch from '~/modules/acquiring/models/ProvidersSearch'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'

export default {
  name: 'BlockTapToPhoneSettingsForm',
  components: {
    TOrmFields
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isDialogPart: {
      type: Boolean,
      default: false
    },
    isUpdating: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      form: {
        provider: null,
        clientLogin: null,
        clientPhone: null,
        clientUsername: null,
        clientPassword: null,
        ptName: null,
        cashRegister: null,
        isActive: false,
        monobankToken: null,
        monobankTerminal: null,
        oschadTerminal: null
      },
      availableMonobankTerminals: [],
      merchants: null,
      foundMerchant: null,
      clientLoginKey: 0,
      monobankTokenKey: 0,
      debouncedGetMonobankTerminals: null,
      oschadMerchants: [],
      showOshadMerchantsSelect: false,
      showOshadTerminalsSelect: false,
      oschadMerchantsLoading: false,
      oschadTerminalsLoading: false,
      oschadTerminals: [],
      clientLoginDisabled: false
    }
  },
  computed: {
    model () {
      return Acquiring
    },
    title () {
      if (this.isEditing) {
        return 'Tap to phone editing'
      } else if (this.isUpdating) {
        return 'Tap to phone settings'
      }
      return 'Add Tap to Phone'
    },
    merchantModel () {
      return Merchants
    },
    existedMerchants () {
      return Merchants.query().all()
    },
    providerId () {
      return this._.get(this.form, 'provider.providerId') || this._.get(this.item, 'merchant.provider.providerId')
    },
    isOschadbankSelected () {
      return this.providerId === '8394d703-1d77-4e79-832f-f9ba030e2a7d' // oschad providerId prod
    },
    isMonobankSelected () {
      return this.providerId === 'c8053ea5-ffdf-4d1e-81ce-3817da44e7a8' // monobank providerId prod
    },
    fields () {
      const ptNameRules = this.isOschadbankSelected ? 'required|integer|max:8|min:8' : 'required|tap2PhoneTerminalId'
      return [
        new AutocompleteBuilder({
          model: 'provider',
          label: 'Bank',
          returnObject: true,
          itemClass: 'ws-pre',
          rules: 'required',
          visible: () => !this.isEditing && !this.isUpdating,
          query: model => model.api(),
          itemsPerPage: 30
        }, ProvidersSearch).build(),
        {
          model: 'clientLogin',
          component: 'v-text-field',
          provider: {
            vid: 'clientLogin',
            name: 'Login for acquiring',
            rules: 'required|noSpaces'
          },
          attrs: {
            visible: !this.isEditing && !this.isMonobankSelected && !this.showOshadMerchantsSelect,
            label: 'Login for acquiring',
            outlined: true,
            disabled: this.clientLoginDisabled
          },
          key: this.clientLoginKey,
          cast: item => item ? item.trim() : null
        },
        {
          model: 'clientLogin',
          component: 'v-select',
          provider: {
            vid: 'clientLogin',
            name: 'Login for acquiring',
            rules: 'required|noSpaces'
          },
          attrs: {
            visible: !this.isEditing && this.showOshadMerchantsSelect,
            label: 'Login for acquiring',
            outlined: true,
            disabled: this.oschadMerchantsLoading,
            loading: this.oschadMerchantsLoading
          },
          key: this.clientLoginKey,
          items: () => this.oschadMerchants
        },
        {
          model: 'oschadTerminal',
          component: 'v-select',
          provider: {
            vid: 'oschadTerminal',
            name: 'Available terminals',
            rules: 'required'
          },
          attrs: {
            visible: !this.isEditing && this.showOshadTerminalsSelect,
            label: 'Available terminals',
            outlined: true,
            returnObject: true,
            itemText: (i) => {
              let str = this._.get(i, 'pt_name')
              const serialNumber = this._.get(i, 'serial_number')
              if (serialNumber) {
                str += ` (${serialNumber})`
              }
              return str
            },
            disabled: this.oschadTerminalsLoading,
            loading: this.oschadTerminalsLoading
          },
          items: () => this.oschadTerminals
        },
        {
          model: 'monobankToken',
          component: 'v-text-field',
          provider: {
            vid: 'monobankToken',
            name: 'Access token',
            rules: 'required',
            mode: 'aggressive'
          },
          attrs: {
            visible: this.isMonobankSelected && !this.isEditing && !this.isUpdating,
            label: 'Access token',
            outlined: true
          },
          key: this.monobankTokenKey
        },
        {
          model: 'monobankTerminal',
          component: 'v-select',
          provider: {
            vid: 'monobankTerminal',
            name: 'Available terminals',
            rules: 'required'
          },
          attrs: {
            visible: this.isMonobankSelected && !this.isEditing && !this.isUpdating,
            label: 'Available terminals',
            outlined: true,
            returnObject: true,
            itemText: 'name'
          },
          items: () => this.availableMonobankTerminals
        },
        {
          model: 'clientPhone',
          component: 'v-text-field',
          provider: {
            vid: 'clientPhone',
            name: 'Phone for acquiring'
          },
          attrs: {
            label: 'Phone for acquiring',
            outlined: true,
            visible: false
          }
        },
        {
          model: 'clientUsername',
          component: 'v-text-field',
          provider: {
            vid: 'clientUsername',
            name: 'Username for acquiring'
          },
          attrs: {
            label: 'Username for acquiring',
            outlined: true,
            visible: false
          }
        },
        {
          model: 'clientPassword',
          component: 'v-text-field',
          provider: {
            vid: 'clientPassword',
            name: 'Password for acquiring'
          },
          attrs: {
            label: 'Password for acquiring',
            outlined: true,
            visible: false
          }
        },
        {
          model: 'ptName',
          component: 'v-text-field',
          provider: {
            vid: 'ptName',
            name: 'Terminal ID',
            rules: ptNameRules
          },
          attrs: {
            label: 'Terminal ID',
            outlined: true,
            visible: !this.isMonobankSelected && !this.isOschadbankSelected
          },
          cast: item => item || null,
          hint: {
            text: 'Acceptance of contactless payment cards works through the NFC of your mobile device.',
            link: 'https://wiki.checkbox.ua/uk/app/mobile/android_acquiring',
            linkText: 'Instructions to connect'
          },
          fieldVal: () => this._.get(this.item, 'ptName', null)
        },
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Connected to cash register',
          rules: 'required',
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (!this._.get(this.item, 'cashRegister', null) && items && items.length === 1) {
              return items[0]
            }
          },
          fieldVal: () => this._.get(this.item, 'cashRegister', null),
          itemValue: item => this._.get(item, 'id', null)
        }, CashRegistersSearch).build(),
        {
          model: 'isActive',
          component: 'v-switch',
          provider: {
            vid: 'isActive',
            name: 'Connect'
          },
          attrs: {
            label: 'Connect'
          },
          fieldVal: () => this._.get(this.item, 'isActive', true)
        }
      ]
    }
  },
  watch: {
    'form.provider' (val) {
      this.showOshadMerchantsSelect = false
      this.showOshadTerminalsSelect = false
      const foundMerchant = this._.find(this.existedMerchants, (item) => {
        return this._.get(item, 'provider.providerId') === this._.get(val, 'providerId')
      })
      if (this.isMonobankSelected) {
        this.$set(this.form, 'monobankToken', this._.get(foundMerchant, 'monoAccessToken', null))
        this.$set(this.form, 'clientLogin', null)
        this.monobankTokenKey += 1
      } else {
        this.$set(this.form, 'clientLogin', this._.get(foundMerchant, 'clientLogin', null))
        this.clientLoginKey += 1
      }
      this.foundMerchant = foundMerchant
      if (this.isOschadbankSelected) {
        const clientLogin = this._.get(foundMerchant, 'clientLogin')
        this.showOshadTerminalsSelect = true
        if (!clientLogin) {
          this.showOshadMerchantsSelect = true
        } else {
          this.clientLoginDisabled = true
        }
        if (!this.oschadMerchants?.length && !this.oschadMerchantsLoading && !clientLogin) {
          this.getOschadMerchants()
        }
      }
    },
    'form.monobankToken' () {
      this.debouncedGetMonobankTerminals()
    },
    'form.clientLogin' (val) {
      if (this.showOshadTerminalsSelect && val && !this.oschadTerminals?.length) {
        this.getOschadTerminals()
      }
    }
  },
  async created () {
    this.debouncedGetMonobankTerminals = this._.debounce(this.getMonobankTerminals, 500)
    if (this.item) {
      this.$set(this.$data, 'form', {
        ...this.form,
        cashRegister: this._.get(this.item, 'cashRegister')
      })
    }
    await this.merchantModel.api().all()
    this.$gtm.push({
      event: 'connect_tap_to_phone_to_cashregister_view',
      organization: this._.get(this.$Organization, 'owner.email')
    })
  },
  methods: {
    async getOschadMerchants () {
      try {
        this.oschadMerchantsLoading = true
        this.oschadMerchants = this._.get(await this.merchantModel.api().oschad(), 'response.data.merchants', [])
      } catch (e) {
        if (e.response.status === 404) {
          this.oschadMerchants = []
        } else {
          this.$handlers.error(e, this)
        }
      } finally {
        this.oschadMerchantsLoading = false
      }
    },
    async getOschadTerminals () {
      try {
        if (!this.showOshadMerchantsSelect) {
          this.oschadTerminalsLoading = true
        }
        this.oschadTerminals = this._.get(await this.model.api().oschad({
          login: this._.get(this.form, 'clientLogin', null),
          all: false
        }), 'response.data.terminals', [])
      } catch (e) {
        if (e.response.status === 404) {
          this.oschadTerminals = []
        } else {
          this.$handlers.error(e, this)
        }
      } finally {
        this.oschadTerminalsLoading = false
      }
    },
    async getMonobankTerminals () {
      try {
        this.availableMonobankTerminals = this._.get(await this.model.api().getMonobankTerminals({ token_mono: this._.get(this.form, 'monobankToken') }), 'response.data.list')
      } catch (e) {
        this.$handlers.error(e, this.$refs.form)
      }
    },
    async save () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }

        if (this.isEditing || this.isUpdating) {
          const payload = {
            cashRegister: this._.get(this.form, 'cashRegister') ? `cash-registers/${this._.get(this.form, 'cashRegister.id', null)}` : null,
            ptName: this._.get(this.form, 'ptName', null),
            isActive: this._.get(this.form, 'isActive', null)
          }

          if (this.isMonobankSelected) {
            payload.ptName = this._.get(this.form, 'monobankTerminal.name')
            payload.monoId = this._.get(this.form, 'monobankTerminal.code')
          } else if (this.isOschadbankSelected) {
            payload.ptName = this._.get(this.form, 'oschadTerminal.pt_name')
          }

          await this.model.api().update(this.item, payload)

          if (this.isUpdating) {
            const payload = { clientLogin: this._.get(this.form, 'clientLogin', null) }
            await this.merchantModel.api().update({
              ...this._.get(this.item, 'merchant', {}),
              $id: this._.get(this.item, 'merchant.id')
            }, payload)
            this.$notification.success(this.$t('TAP2Phone setup successfully connected'))
          } else {
            this.$notification.success(this.$t('TAP2Phone setup successfully edited'))
          }
        } else {
          let merchant = this.foundMerchant
          const clientLogin = this._.get(this.form, 'clientLogin', null)

          if (!merchant) {
            const merchantPayload = {
              provider: this.form.provider ? `providers/${this._.get(this.form, 'provider.$id')}` : null,
              clientLogin: this._.get(this.form, 'clientLogin', null)
            }

            if (this.isMonobankSelected) {
              merchantPayload.monoAccessToken = this._.get(this.form, 'monobankToken')
              delete merchantPayload.clientLogin
            }

            merchant = this._.get(await this.merchantModel.api().create(merchantPayload), 'response.data')
          } else if (this._.get(merchant, 'clientLogin') !== clientLogin) {
            await this.merchantModel.api().update(merchant, { clientLogin })
          }

          const terminalPayload = {
            merchant: this._.get(merchant, 'id') ? `merchants/${this._.get(merchant, 'id', null)}` : null,
            cashRegister: this._.get(this.form, 'cashRegister') ? `cash-registers/${this._.get(this.form, 'cashRegister.id', null)}` : null,
            ptName: this._.get(this.form, 'ptName', null),
            isActive: this._.get(this.form, 'isActive', null)
          }

          if (this.isMonobankSelected) {
            terminalPayload.ptName = this._.get(this.form, 'monobankTerminal.name')
            terminalPayload.monoId = this._.get(this.form, 'monobankTerminal.code')
          } else if (this.isOschadbankSelected) {
            terminalPayload.ptName = this._.get(this.form, 'oschadTerminal.pt_name')
          }

          await this.model.api().create(terminalPayload)
          this.$notification.success(this.$t('TAP2Phone setup successfully created'))
        }

        this.$gtm.push({
          event: 'submit_tap_to_phone_to_cash_register',
          organization: this._.get(this.$Organization, 'owner.email')
        })

        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this.$refs.form)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
