<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title")
      v-btn(
        class="back-arrow"
        @click="$emit('back')"
        icon
      )
        e-svg-icon(size="sm") arrow-next
      | Вкажіть адресу приміщення
    ValidationObserver(
      ref="form"
      slim
    )
      v-form(@submit.prevent.stop="nextStep")
        t-orm-fields(
          v-bind.sync="$attrs.value"
          v-on="$listeners"
          :items="fields"
        )
        t-orm-fields(
          v-bind.sync="address"
          :items="addressFields"
        )
        e-input-switch-tooltip(
          v-model="placeType"
          label="Я власник приміщення"
          @input="placeTypeChange"
        )
        div(class="dialog__actions")
          v-btn(
            type="submit"
            class="main-button"
          ) {{ $t('Continue') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields.vue'
import OutletOrder from '~/modules/outlets/models/OutletOrder'
import EInputSwitchTooltip from '~/components/elements/inputs/e-input-switch-tooltip'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import KatottgDictionary from '~/models/directories/dictionaries/KatottgDictionary'

export default {
  components: {
    EInputSwitchTooltip,
    TOrmFields,
    ESvgIcon
  },
  props: {
    savedAddress: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    placeType: true,
    address: {
      street: '',
      house: ''
    }
  }),
  computed: {
    model () {
      return OutletOrder
    },
    addressFields () {
      return [
        {
          model: 'street',
          component: 'v-text-field',
          provider: {
            vid: 'street',
            name: 'Street',
            rules: 'required|max:90'
          },
          attrs: {
            label: 'Street',
            outlined: true
          },
          cols: {
            cols: 12,
            sm: 6
          }
        },
        {
          model: 'house',
          component: 'v-text-field',
          provider: {
            vid: 'house',
            name: 'House',
            rules: 'required|max:10'
          },
          attrs: {
            label: 'House',
            outlined: true
          },
          cols: {
            cols: 12,
            sm: 6
          }
        }
      ]
    },
    fields () {
      return [
        {
          model: 'T1RXXXXG6S',
          component: 'e-input-tree-select',
          attrs: {
            outlined: true,
            requestItems: 'onlyRootLevel',
            itemText: 'name',
            model: KatottgDictionary,
            providers: [
              {
                vid: 'katottgRegion',
                name: 'Region',
                rules: 'required'
              },
              {
                vid: 'katottgDistrict',
                name: 'District',
                rules: 'required'
              },
              {
                vid: 'katottgTerritory',
                name: 'Local community ',
                rules: 'required'
              },
              {
                vid: 'katottgSettlement',
                name: 'Settlement',
                rules: 'required'
              },
              {
                vid: 'katottgSettlementDistrict',
                name: 'Settlement district '
              }
            ],
            labelsConfig: {
              labelPattern: '{label}',
              labels: ['Region', 'District', 'Local community ', 'Settlement', 'Settlement district ']
            }
          }
        }
      ]
    }
  },
  watch: {
    address: {
      handler (val = {}) {
        const { street, house } = val
        this.$emit('propertyChange', {
          key: 'T1RXXXXG8S',
          value: street && house ? `${street}, ${house}` : ''
        })
        this.$emit('saveAddress', val)
      },
      deep: true
    }
  },
  created () {
    if (this.savedAddress) {
      this.$set(this.address, 'street', this._.get(this.savedAddress, 'street'))
      this.$set(this.address, 'house', this._.get(this.savedAddress, 'house'))
    }
  },
  methods: {
    placeTypeChange (val) {
      const value = val ? this.model.TYPE_OF_RIGHTS[0].value : this.model.TYPE_OF_RIGHTS[2].value
      this.$emit('propertyChange', {
        key: 'T1RXXXXG10',
        value
      })
    },
    async nextStep () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      this.$emit('nextStep', { showTaxAddressForm: true, hasSeparatePlace: true })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0;
  }
}

.back-arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}
</style>
