import { Model } from '@vuex-orm/core'
import Employees from '~/modules/employees/models/Employees'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import ShortAble from '~/models/mixins/ShortAble'
import FilterAble from '~/models/mixins/FilterAble'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import { contexts } from '~/const/global'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import APIPlatformConnector from '~/plugins/vuex-orm/plugins/_drivers/APIPlatformConnector'
import OutletOrder from '~/modules/outlets/models/OutletOrder'
import Dialog from '~/models/system/Dialog'

export class Outlets extends ChainInheritance(Directory, [
  FilterAble,
  ShortAble,
  PersistAble
]) {
  static entity = 'outlet'
  static orderEntity = OutletOrder
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam= 'mode'
  static defaultSortOrder = false
  static dblClickAction = 'read'
  static persistOptions = {
    insertOrUpdate: [Employees.entity, CashRegisters && CashRegisters.entity]
  }

  static itemModes = {
    checkbox: {
      text: 'Active',
      value: 'checkbox',
      mode: 100
    },
    deactivated: {
      text: 'Deactivated in SFS',
      value: 'deactivated',
      mode: 200
    },
    alert: {
      text: 'Need interaction',
      value: 'alert',
      mode: 300
    },
    onRemovalInSfs: {
      text: 'On removal in SFS',
      value: 'onRemovalInSfs'
    },
    hasRemovalOrderError: {
      text: 'Active',
      value: 'hasRemovalOrderError'
    },
    onModifyInSfs: {
      text: 'On modify in SFS',
      value: 'onModifyInSfs'
    },
    hasModifyOrderError: {
      text: 'Active',
      value: 'hasModifyOrderError'
    }
  }

  static checkTaxOrdersStatus (item) {
    const sortedTaxOrders = this._.sortBy(this._.get(item, 'outletTaxOrders', []), order => new Date(order.dateSend).getTime())
    const lastTaxOrder = this._.last(sortedTaxOrders)
    const onRemovalInSfs = this._.get(lastTaxOrder, 'type') === 300 && this._.get(lastTaxOrder, 'status') === 200
    const hasRemovalOrderError = this._.get(lastTaxOrder, 'type') === 300 && this._.get(lastTaxOrder, 'status') === 400 && this._.get(item, 'mode') === 100
    const onModifyInSfs = this._.get(lastTaxOrder, 'type') === 200 && this._.get(lastTaxOrder, 'status') === 200
    const hasModifyOrderError = this._.get(lastTaxOrder, 'type') === 200 && this._.get(lastTaxOrder, 'status') === 400 && this._.get(item, 'mode') === 100

    return {
      lastTaxOrder,
      onRemovalInSfs,
      hasRemovalOrderError,
      onModifyInSfs,
      hasModifyOrderError,
      actionVisible: !onRemovalInSfs && !onModifyInSfs
    }
  }

  static fields () {
    return {
      // shadow
      code: this.attr(),

      id: this.attr(null),

      // request fields
      reqStreet: this.attr(), // reference address
      reqStateObject: this.attr(), // reference stanObject
      reqTypeObject: this.attr(), // reference typeObjectName
      reqTypeOfRightsObject: this.attr(), // reference typeOfRights
      toCode: this.attr(null), // tocode = reqTypeObject + 5 digits number
      dpi: this.attr(null), // code dpi
      status: this.attr(), // server generated
      reqErrorText: this.attr(null),
      reqCreatedAt: this.attr(null),
      reqErrorCode: this.attr(null),

      mode: this.attr(null),
      name: this.attr(null),
      address: this.attr(null),
      regNumber: this.attr(), // TODO define this field
      stateObject: this.attr(null), // from synchronization
      typeObject: this.attr(null), // from synchronization
      typeOfRightsObject: this.attr(null), // from synchronization
      accStart: this.attr(null), // дата взяття на облік
      accEnd: this.attr(null), // дата зняття з обліку
      accLastChange: this.attr(null), // дата останніх змін в дпс
      reqEncryptedId: this.attr(null),
      taxDocId: this.attr(null),

      outletTaxOrders: this.attr(null),
      dateCreated: this.attr(null),
      hasErrorFile: this.attr(null),
      taxTime: this.attr(null),
      timeFrom: this.attr(null),
      timeTo: this.attr(null)
    }
  }

  static ormRowsConfig = {
    disabled: null
  }

  static ormTrans = {
    single: 'Outlet',
    multy: 'Outlets',
    notificationSingle: 'Outlet ',
    notification: 'Outlet'
  }

  get addressString () {
    let address = this.address && this.address.toString().slice()

    if (this.address && this.address.toString().toLowerCase().startsWith('україна')) {
      address = address.replace('УКРАЇНА, ', '')
    }

    return address || this.reqStreet
  }

  get modeString () {
    const { onRemovalInSfs, hasRemovalOrderError, onModifyInSfs, hasModifyOrderError } = Outlets.itemModes
    const orderStatus = Outlets.checkTaxOrdersStatus(this)
    if (this._.get(orderStatus, onRemovalInSfs.value)) {
      return onRemovalInSfs.value
    } else if (this._.get(orderStatus, hasRemovalOrderError.value)) {
      return hasRemovalOrderError.value
    } else if (this._.get(orderStatus, onModifyInSfs.value)) {
      return onModifyInSfs.value
    } else if (this._.get(orderStatus, hasModifyOrderError.value)) {
      return hasModifyOrderError.value
    }
    return this.mode
  }

  static ormHeaders = [
    { text: 'Mode', value: 'modeString', align: 'left ', width: '150', sortable: true, filterable: false },
    { text: 'Name', value: 'name', width: '250', sortable: true, filterable: true },
    { text: 'Address', value: 'addressString', sortable: true, filterable: true, sortQuery: 'address' },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    modeString: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }
          const {
            checkbox,
            deactivated,
            alert,
            onRemovalInSfs,
            hasRemovalOrderError,
            onModifyInSfs,
            hasModifyOrderError
          } = this.itemModes

          return {
            id: item.id,
            model: OutletOrder,
            [checkbox.mode]: {
              text: checkbox.text,
              type: checkbox.value
            },
            [deactivated.mode]: {
              text: deactivated.text,
              type: deactivated.value,
              tooltip: 'Outlet was deactivated in sfs'
            },
            [alert.mode]: {
              text: alert.text,
              type: alert.value,
              tooltip: ''
            },
            onRemovalInSfs: {
              text: onRemovalInSfs.text,
              type: onRemovalInSfs.value,
              tooltip: 'The outlet is on removal in the SFS'
            },
            hasRemovalOrderError: {
              text: hasRemovalOrderError.text,
              type: hasRemovalOrderError.value,
              error: {
                id: this._.get(Outlets.checkTaxOrdersStatus(item), 'lastTaxOrder.id'),
                taxErrorMessage: this._.get(Outlets.checkTaxOrdersStatus(item), 'lastTaxOrder.taxErrorMessage'),
                hasErrorFile: this._.get(Outlets.checkTaxOrdersStatus(item), 'lastTaxOrder.hasErrorFile')
              }
            },
            onModifyInSfs: {
              text: onModifyInSfs.text,
              type: onModifyInSfs.value,
              tooltip: 'The outlet is on modify in the SFS'
            },
            hasModifyOrderError: {
              text: hasModifyOrderError.text,
              type: hasModifyOrderError.value,
              error: {
                id: this._.get(Outlets.checkTaxOrdersStatus(item), 'lastTaxOrder.id'),
                taxErrorMessage: this._.get(Outlets.checkTaxOrdersStatus(item), 'lastTaxOrder.taxErrorMessage'),
                hasErrorFile: this._.get(Outlets.checkTaxOrdersStatus(item), 'lastTaxOrder.hasErrorFile')
              }
            }
          }
        }
      }
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = []

  static ormActions = [
    {
      name: 'settings',
      text: 'Settings',
      icon: {
        type: 'e-svg-icon',
        text: 'settings-2'
      },
      visible: (item, ctx) => {
        return (item.mode === Outlets.itemModes.checkbox.mode ||
          item.mode === Outlets.itemModes.alert.mode) &&
          this._.get(Outlets.checkTaxOrdersStatus(item), 'actionVisible') &&
          !ctx.$User.isAccountant
      },
      call: (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          width: '600px',
          component: 'form-outlet-settings',
          componentProps: {
            item
          }
        })
      }
    },
    {
      name: 'edit',
      visible: (item, ctx) => {
        return (item.mode === Outlets.itemModes.checkbox.mode ||
          item.mode === Outlets.itemModes.alert.mode ||
          item.mode === Outlets.itemModes.deactivated.mode) &&
          this._.get(Outlets.checkTaxOrdersStatus(item), 'actionVisible') &&
          !ctx.$User.isAccountant
      },
      call: (outlet) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          title: 'Outlet modifying',
          width: '860px',
          component: 'form-outlet-modify',
          componentProps: {
            item: outlet,
            onSubmit: async () => await contentDialog.close()
          }
        })
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    default: {
      config: {
        context: 'create'
      }
    },
    read: {
      title: item => ({ type: 'Outlet  ', name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'toCode',
            label: 'Code'
          },
          {
            model: 'regNumber',
            label: 'Registration number',
            hideEmpty: true
          },
          {
            model: 'name',
            label: 'Name'
          },
          {
            model: 'dpi',
            label: 'Tax code'
          },
          {
            model: 'address',
            label: 'Address'
          },
          {
            model: 'stateObject',
            label: 'State object'
          },
          {
            model: 'typeObject',
            label: 'Type object'
          },
          {
            model: 'typeOfRightsObject',
            label: 'Type of rights'
          },
          {
            model: 'accStart',
            label: 'Date of accounting'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.randomCode = function (model, payload) {
        const connector = new APIPlatformConnector(this.model, { ...this.params }, contexts.read)
        return this.get(Model.$routes.outlet.randomCode(), connector.config)
      }
      configActions.getCoordinates = function (id) {
        return this.get(Model.$routes.outlet.getCoordinates(id), { save: false })
      }
      return configActions
    }
  }
}

export default Outlets
