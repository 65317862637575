import { each, find } from 'lodash'
import auth from '~/modules/auth/auth'
import authUser from '~/modules/auth/authUser'
import { menuItems } from '~/const/menuItems'
import { reports } from '~/modules/reports/constants/reports'

export default function ({ store, redirect, route, error }) {
  const user = authUser.get()
  if (!auth.isAuthenticated()) {
    const route = store?.$router?.history?.current?.path
    return redirect({
      path: '/auth/login',
      query: {
        from: route
      }
    })
  }

  if (user) {
    const path = route.path
    const excludedRoutes = ['/weareworking', '/forbidden']
    let isAllowedRoute = excludedRoutes.includes(path)
    const reportsRoute = find(reports, i => i.route === path)

    if (reportsRoute) {
      isAllowedRoute = reportsRoute.visible(user, user?.ownedOrganization)
    } else {
      each(menuItems, (item) => {
        if (path === '/dashboard') {
          isAllowedRoute = user?.isOwner || user?.isOwnerWithoutOrganization || user?.isAdmin
        } else if (item.href === path) {
          isAllowedRoute = item.visible(user, user?.ownedOrganization)
        } else if (item.children && item.visible(user, user?.ownedOrganization)) {
          each(item.children, (child) => {
            const isLinkVisible = child.visible ? child.visible(user, user?.ownedOrganization) : true
            if (child.href === path && isLinkVisible) {
              isAllowedRoute = child.visible ? child.visible(user, user?.ownedOrganization) : true
            }
          })
        }
      })
    }

    if (!isAllowedRoute) {
      if (user?.isEmployee || user?.isHeadCashier) {
        redirect('/dashboard/createreceipts')
      } else if (user?.isEmergencyCashier) {
        redirect('/dashboard/cashregisters')
      } else if (user?.isAccountant) {
        redirect('/dashboard/reports')
      } else if (user?.isSuperAdmin) {
        redirect('/dashboard/organizations')
      } else {
        redirect('/dashboard/onboarding')
      }
    }
  }
}
