var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.submit($event)}}},[_c('div',{staticClass:"addition"},[_c('div',{staticClass:"addition__header"},[_c('div',[_c('p',[_vm._v("Відмітка про одержання")]),_c('p',[_vm._v("(штамп контролюючого органу)")])]),_c('div',[_c('p',[_vm._v("Додаток 2")]),_c('p',[_vm._v("до податкової декларації платника єдиного податку третьої групи на період дії воєнного, надзвичайного стану в Україні")])])]),_c('div',{staticClass:"addition__data"},[_c('div',[_c('h3',{staticClass:"mb-3"},[_vm._v("ВІДОМОСТІ")]),_c('p',{staticClass:"bigger"},[_c('b',[_vm._v("про суми нарахованого доходу застрахованих осіб та суми нарахованого єдиного внеску")])])]),_c('div',{staticClass:"addition__type-table"},[_c('div',{staticClass:"addition__type-table-row"},[_c('div',[_vm._v("01")]),_c('div',[_c('ValidationProvider',{attrs:{"vid":"reportingType","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"true-value":"HZ","false-value":null,"error-messages":errors,"hide-details":""},model:{value:(_vm.form.reportingType),callback:function ($$v) {_vm.$set(_vm.form, "reportingType", $$v)},expression:"form.reportingType"}})]}}])})],1),_c('div',[_vm._v("Звітна")])]),_c('div',{staticClass:"addition__type-table-row"},[_c('div',[_vm._v("02")]),_c('div',[_c('ValidationProvider',{attrs:{"vid":"reportingType","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"true-value":"HZN","false-value":null,"error-messages":errors,"hide-details":""},model:{value:(_vm.form.reportingType),callback:function ($$v) {_vm.$set(_vm.form, "reportingType", $$v)},expression:"form.reportingType"}})]}}])})],1),_c('div',[_vm._v("Звітна нова")])])])]),_c('div',{staticClass:"addition__text-input-block"},[_c('div',[_c('p',[_c('span',{staticClass:"mr-1"},[_vm._v("Реєстраційний номер облікової картки платника податків або серія (за наявності) та номер паспорта")]),_c('sup',[_vm._v("1")])])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"HTIN","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Number'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HTIN),callback:function ($$v) {_vm.$set(_vm.form, "HTIN", $$v)},expression:"form.HTIN"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-input-block"},[_c('div',[_c('p',[_vm._v("Прізвище, імя, по батькові (за наявності)")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"HNAME","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Full name'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HNAME),callback:function ($$v) {_vm.$set(_vm.form, "HNAME", $$v)},expression:"form.HNAME"}})]}}])})],1)]),_c('div',{staticClass:"addition__period"},[_c('div',[_c('p',[_vm._v("Податковий (звітний) період:")])]),_c('div',{staticClass:"addition__period-inputs"},[_c('ValidationProvider',{attrs:{"name":" ","vid":"reportingPeriod","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Month'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.reportingPeriod),callback:function ($$v) {_vm.$set(_vm.form, "reportingPeriod", $$v)},expression:"form.reportingPeriod"}})]}}])}),_c('ValidationProvider',{attrs:{"name":" ","vid":"HZY","mode":"lazy","rules":"required|length:4|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Year '),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HZY),callback:function ($$v) {_vm.$set(_vm.form, "HZY", $$v)},expression:"form.HZY"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-input-block"},[_c('div',[_c('p',[_vm._v("Код основного виду економічної діяльності")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"HKVED","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('KVED'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HKVED),callback:function ($$v) {_vm.$set(_vm.form, "HKVED", $$v)},expression:"form.HKVED"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-input-block addition__text-input-block--borderless"},[_c('div',[_c('p',[_vm._v("Період перебування фізичної особи - підприємця на спрощеній системі оподаткування")])]),_c('div',{staticClass:"addition__right-block-inputs"},[_c('ValidationProvider',{attrs:{"name":" ","vid":"R08G1D","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-input-datetime',{attrs:{"error-messages":errors,"label":"З","outlined":""},model:{value:(_vm.form.R08G1D),callback:function ($$v) {_vm.$set(_vm.form, "R08G1D", $$v)},expression:"form.R08G1D"}})]}}])}),_c('ValidationProvider',{attrs:{"name":" ","vid":"R08G2D","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-input-datetime',{attrs:{"error-messages":errors,"label":"По","outlined":""},model:{value:(_vm.form.R08G2D),callback:function ($$v) {_vm.$set(_vm.form, "R08G2D", $$v)},expression:"form.R08G2D"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-input-block"},[_c('div',[_c('p',[_c('span',{staticClass:"mr-1"},[_vm._v("Код категорії застрахованої особи")]),_c('sup',[_vm._v("2")])])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"R081G1","mode":"lazy","rules":"required|max:2|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Code'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.R081G1),callback:function ($$v) {_vm.$set(_vm.form, "R081G1", $$v)},expression:"form.R081G1"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-block"},[_c('div',[_c('p',[_vm._v("ВИЗНАЧЕННЯ СУМ НАРАХОВАНОГО ДОХОДУ ЗАСТРАХОВАНИХ ОСІБ ТА СУМИ НАРАХОВАНОГО ЄДИНОГО ВНЕСКУ")])])]),_c('div',{staticClass:"addition__table"},[_c('div',{staticClass:"addition__table-row addition__table-row--head"},[_c('div',[_c('p',[_vm._v("Місяць")])]),_c('div',[_c('p',[_vm._v("Самостійно визначена сума доходу, на яку нараховується єдиний внесок з урахуванням максимальної величини")])]),_c('div',[_c('p',[_c('span',{staticClass:"mr-1"},[_vm._v("Розмір єдиного внеску, відсоток")]),_c('sup',[_vm._v("3")])])]),_c('div',[_c('p',[_vm._v("Сума єдиного внеску, яка підлягає сплаті на небюджетні рахунки, за даними звітного (податкового) періоду (графа 2 х графа 3) грн, коп")])])]),_vm._l((_vm.taxSumTableRows),function(item,index){return _c('div',{key:index,staticClass:"addition__table-row"},[_c('div',[_c('p',[_vm._v(_vm._s(item.month))])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":item.field1,"mode":"lazy","rules":"required|decimal_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Sum'),"error-messages":errors,"full-width":"","outlined":""},on:{"input":function($event){return _vm.onSumChange(item.field1)}},model:{value:(_vm.form.taxSum[item.field1]),callback:function ($$v) {_vm.$set(_vm.form.taxSum, item.field1, $$v)},expression:"form.taxSum[item.field1]"}})]}}],null,true)})],1),_c('div',[(item.field2)?_c('ValidationProvider',{attrs:{"name":" ","vid":item.field2,"mode":"lazy","rules":"required|decimal_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Percent'),"error-messages":errors,"full-width":"","outlined":""},on:{"input":function($event){return _vm.onSumChange(item.field2)}},model:{value:(_vm.form.taxSum[item.field2]),callback:function ($$v) {_vm.$set(_vm.form.taxSum, item.field2, $$v)},expression:"form.taxSum[item.field2]"}})]}}],null,true)}):_vm._e()],1),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":item.field3,"mode":"lazy","rules":"required|decimal_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Sum'),"error-messages":errors,"full-width":"","outlined":"","disabled":""},on:{"input":function($event){return _vm.onSumChange(item.field3)}},model:{value:(_vm.form.taxSum[item.field3]),callback:function ($$v) {_vm.$set(_vm.form.taxSum, item.field3, $$v)},expression:"form.taxSum[item.field3]"}})]}}],null,true)})],1)])})],2),_c('div',{staticClass:"addition__footer"},[_c('div',[_c('p',[_c('sup',[_vm._v("1")]),_c('span',{staticClass:"ml-1"},[_vm._v("Серію (за наявності) та номер паспорта зазначають фізичні особи, які через релігійні переконання відмовляються від прийняття реєстраційного номера облікової картки платника податків та офіційно повідомили про це відповідний контролюючий орган і мають відмітку в паспорті.")])]),_c('p',[_c('sup',[_vm._v("2")]),_c('span',{staticClass:"ml-1"},[_vm._v("Зазначається код категорії застрахованої особи \"6\" - фізична особа - підприємець на спрощеній системі оподаткування.")])]),_c('p',[_c('sup',[_vm._v("3")]),_c('span',{staticClass:"ml-1"},[_vm._v("Зазначається розмір єдиного внеску, встановлений законом.")])])])]),_c('div',{staticClass:"addition__footer-input"},[_c('div',[_c('p',[_vm._v("Наведена інформація є вірною:")]),_c('p',[_vm._v("Фізична особа – платник єдиного внеску або уповноважена особа")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"HBOS","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ПІБ","error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HBOS),callback:function ($$v) {_vm.$set(_vm.form, "HBOS", $$v)},expression:"form.HBOS"}})]}}])})],1)])]),_c('div',{staticClass:"py-6"},[_c('v-btn',{staticClass:"main-button d-block mx-auto",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t('Save')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }