<template lang="pug">
  div(class="a-bank-account-opening")
    div(
      v-if="docLoading"
      class="a-bank-account-opening__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    embed(
      v-else-if="base64Pdf"
      :src="`data:application/pdf;base64,${base64Pdf}#toolbar=0&navpanes=0&scrollbar=0`"
      class="a-bank-account-opening__iframe"
    )
</template>

<script>
import EProgressCircular from '~/components/elements/progress/e-progress-circular.vue'
import AccountOrder from '~/modules/acquiring/models/AccountOrder'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  name: 'BlockABankAccountOpening',
  components: {
    EProgressCircular
  },
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    changeStep: {
      type: Function,
      default: () => {}
    },
    changeAccountOrder: {
      type: Function,
      default: () => {}
    },
    changeAccountOrderError: {
      type: Function,
      default: () => {}
    },
    changeShowFooterActions: {
      type: Function,
      default: () => {}
    },
    changeAccountOrderBase64Pdf: {
      type: Function,
      default: () => {}
    },
    steps: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    docLoading: false,
    docUrl: '',
    base64Pdf: null,
    intervalRequest: null
  }),
  computed: {
    model () {
      return AccountOrder
    }
  },
  created () {
    this.changeTitle('Opening an FOP account')
    this.toggleCloseBtn(true)
    this.getDoc()
  },
  beforeDestroy () {
    if (this.intervalRequest) {
      this.intervalRequest.stopExponential()
    }
  },
  methods: {
    showNextStep ({ error, order }) {
      this.changeStep(this.steps.accountApplicationLoader)
      this.changeAccountOrderError(error)
      this.changeAccountOrder(order)
    },
    async showPdf (order) {
      this.base64Pdf = this._.get(await this.model.api().getDoc(order.id), 'response.data.pdf')
      this.changeAccountOrder(order)
      this.changeAccountOrderBase64Pdf(this.base64Pdf)
    },
    checkStatus (id) {
      const request = new IntervalRequest(() => this.model.api().read(id), {
        interval: 1e3,
        count: 300,
        maxDelay: 18e4 // ~3 minutes
      })
      this.intervalRequest = request
      return request.startExponential((response) => {
        const status = this._.get(response, 'response.data.status', null)
        return status === this.model.STATUSES.signaturePending || status === this.model.STATUSES.error
      })
    },
    async checkUntilResolved (order) {
      const status = this._.get(await this.checkStatus(order.id), 'response.data.status', null)
      if (status === this.model.STATUSES.error) {
        this.showNextStep({
          error: true
        })
      } else if (status === this.model.STATUSES.signaturePending) {
        await this.showPdf(order)
      }
    },
    async getDoc () {
      try {
        this.docLoading = true
        this.changeShowFooterActions(false)

        await this.model.deleteAll()
        await this.model.api().all()

        const order = this.model.query().where('status', (val) => {
          return val !== this.model.STATUSES.error && val !== this.model.STATUSES.success
        }).first()

        if (!order) {
          const order = this._.get(await this.model.api().create(), 'response.data')
          await this.checkUntilResolved(order)
        } else if (order.status === this.model.STATUSES.new || order.status === this.model.STATUSES.created) {
          await this.checkUntilResolved(order)
        } else if (order.status === this.model.STATUSES.signaturePending) {
          await this.showPdf(order)
        } else if (order.status === this.model.STATUSES.signed || order.status === this.model.STATUSES.onApproval) {
          this.showNextStep({
            error: false,
            order
          })
        }
        this.changeShowFooterActions(true)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.docLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.a-bank-account-opening {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
  }

  &__iframe {
    border: 2px solid #F0F1F2;
    width: 100%;
    height: 600px;
  }
}
</style>
