<template lang="pug">
  div(class="a-bank-account-application-loader")
    p(class="a-bank-account-application-loader__icon") {{ icon }}
    h3(class="a-bank-account-application-loader__title") {{ $t(title) }}
    h3(class="a-bank-account-application-loader__text") {{ $t(text) }}
    v-btn(
      v-if="error"
      class="secondary-button a-bank-account-application-loader__button"
      @click="resetBank"
      dark
    ) {{ $t('Choose another bank') }}
    v-progress-linear(
      class="a-bank-account-application-loader__progress"
      v-else
      indeterminate
    )
</template>

<script>
import AccountOrder from '~/modules/acquiring/models/AccountOrder'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  name: 'BlockABankAccountApplicationLoader',
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    showBankSelectionForm: {
      type: Function,
      default: () => {}
    },
    changeStep: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    steps: {
      type: Object,
      default: () => {}
    },
    accountOrder: {
      type: Object,
      default: () => {}
    },
    showError: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    error: false,
    intervalRequest: null
  }),
  computed: {
    model () {
      return AccountOrder
    },
    icon () {
      if (this.error) {
        return '❌'
      }
      return '✍️'
    },
    title () {
      if (this.error) {
        return 'Unfortunately, you received a refusal from the bank to open a FOP account'
      }
      return 'The application is under consideration at the bank'
    },
    text () {
      if (this.error) {
        return 'But you can still try to get services from another bank'
      }
      return 'It will take no more than 2 minutes'
    }
  },
  created () {
    this.changeTitle('')

    if (this.showError) {
      this.showErrorScreen()
    } else {
      this.toggleCloseBtn(false)
      this.checkInformation()
    }
  },
  beforeDestroy () {
    if (this.intervalRequest) {
      this.intervalRequest.stopExponential()
    }
  },
  methods: {
    checkStatus (id) {
      const request = new IntervalRequest(() => this.model.api().read(id), {
        interval: 1e3,
        count: 300,
        maxDelay: 3e5 // ~5 minutes
      })
      this.intervalRequest = request
      return request.startExponential((response) => {
        const status = this._.get(response, 'response.data.status', null)
        return status === this.model.STATUSES.success || status === this.model.STATUSES.error
      })
    },
    showErrorScreen () {
      this.error = true
      this.toggleCloseBtn(true)
    },
    resetBank () {
      this.changeTitle('Add POS-terminal')
      this.showBankSelectionForm(true)
    },
    async checkInformation () {
      try {
        this.error = false
        this.toggleCloseBtn(false)
        const status = this._.get(await this.checkStatus(this.accountOrder.id), 'response.data.status', null)
        if (status === this.model.STATUSES.error) {
          this.showErrorScreen()
        } else if (status === this.model.STATUSES.success) {
          this.changeStep(this.steps.acquiringOrder)
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.showErrorScreen()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.a-bank-account-application-loader {
  text-align: center;

  &__icon {
    font-size: 48px;
    margin-bottom: 40px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 18px;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 300;
    color: #000;
    margin: 0;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 12px;
    }
  }

  &__progress {
    margin-top: 40px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-top: 30px;
    }
  }

  &__button {
    margin-top: 32px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-top: 24px;
    }
  }
}
</style>
