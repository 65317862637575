<template lang="pug">
  div(class="acquiring-payment-link")
    img(
      class="acquiring-payment-link__img"
      src="~/assets/images/cats/cat-7.svg"
      alt="cat"
    )
    h3(class="acquiring-payment-link__title") {{ $t('Link for payment') }}
    p(class="acquiring-payment-link__text") Відправ це посилання клієнту. Після оплати чек буде видано автоматично. Переглянути статус оплати можна в меню "Замовлення".
    div(class="acquiring-payment-link__link") {{ linkText }}
    div(class="acquiring-payment-link__actions")
      v-btn(
        @click="$emit('close')"
      ) {{ $t('Cancel') }}
      v-btn(
        class="main-button"
        @click="copyToClipboard"
      ) {{ $t('Copy') }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import clipboard from '~/mixins/global/_clipboard'
export default {
  components: { ESvgIcon, ELink },
  mixins: [clipboard],
  props: {
    link: {
      type: String,
      required: true
    }
  },
  computed: {
    linkText () {
      return this.link || this.$attrs.value
    }
  },
  methods: {
    copyToClipboard () {
      try {
        this.copy(this.link)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }

}
</script>

<style scoped lang="scss">
.acquiring-payment-link {
  text-align: center;
  padding: 32px 0;

  &__title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 32px;
    margin-bottom: 12px;
  }

  &__img {
    width: 100%;
    max-width: 200px;
    margin-bottom: 24px;
  }

  &__text {
    color: rgba(124, 124, 124, 1);
    font-size: 12px;
    line-height: 20px;
    margin: 0 auto 24px;
    width: 100%;
    max-width: 350px;
  }

  &__actions {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .v-btn {
      width: 100%;
      max-width: 187px;
    }
  }

  &__link {
    font-size: 14px;
    padding: 16px;
    line-height: 16px;
    font-weight: 300;
    color: #000;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 12px;
  }
}
</style>
