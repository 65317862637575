import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class AcquiringSettings extends PersistAble(OrmModel) {
  static entity = 'acquiringsettings'
  static paginated = true
  static defaultSortOrder = true

  static ormTrans = {
    single: 'Acquiring setting',
    multy: 'Acquiring settings'
  }

  static fields () {
    return {
      id: this.attr(null),
      cashRegister: this.attr(null),
      name: this.attr(null),
      qrCodes: this.attr(null),
      type: this.attr(null),
      token: this.attr(null),
      valid: this.attr(null)
    }
  }

  get cashRegisterString () {
    const cashRegister = this.cashRegister
    return cashRegister ? `№${cashRegister.number} (${cashRegister.fiscalNumber})` : '-'
  }

  get terminalNameString () {
    return this.name ? this.name : '-'
  }

  static ormHeaders = [
    { text: 'Terminal name', value: 'terminalNameString', sortable: false, filterable: false },
    { text: 'Connected to cash register', value: 'cashRegisterString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormActions = []

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}

export default AcquiringSettings
