<template lang="pug">
  div
    e-link(@click="show" v-if="!loading") {{$t('View')}}
    e-progress-circular(v-else)
</template>

<script>

import ELink from '~/components/elements/links/e-link'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import Billings from '~/models/billing/Billings'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  components: { EProgressCircular, ELink },
  mixins: [contentDialog],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async show () {
      try {
        this.loading = true
        const prices = this._.get(await Billings.api().getPrices(this.value), 'response.data', {})
        this.loading = false
        await this.contentDialog.open({
          component: 'm-form-billing-price-set',
          width: '800px',
          componentProps: {
            prices: prices.prices,
            discounts: prices.discounts
          }
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
