<template lang="pug">
  v-autocomplete(
    v-bind="$attrs"
    v-on="listeners"
    :value="asyncDefaultVal || val"
    :items="rows"
    :loading="isLoading || selectLoading"
    :readonly="isLocked"
    ref="autocomplete"
    @update:search-input="setSearchString"
    :class="{ 'autocomplete__selection-max-height': selectionMaxHeight }"
    :filter="localSearchFilter"
  )
    template(v-slot:selection="{ item, attrs, selected }" v-if="multiple")
      v-chip(:input-value="selected" v-bind="attrs" @click:close="remove(item)" small close)
        span(class="autocomplete__chip-text" :title="chipText(item)") {{ chipText(item) }}
    template(v-slot:item="{ item }")
      div(:class="{ 'v-list-item__content': true, 'v-list-item__content--append': deleteEntityBtn }" :style="'width:' + inputWidth")
        div(:class="itemClass" style="font-size:14px" :title="item") {{ item }}
        div(v-if="deleteEntityBtn")
          v-btn(
            @click.stop="deleteEntityBtnClick(item)"
            icon
            small
          )
            e-svg-icon(size="xmd") trash
    template(#append-item)
      div(
        v-if="showLoader"
        class="autocomplete__loader"
      )
        e-progress-linear(
          v-if="isLoading"
          :color="loaderColor"
          size="md"
        )
      v-card(v-intersect.quiet="chunkRequest")
      div(class="autocomplete__link")
        e-link(
          v-if="addEntityBtn"
          @click="addEntityBtnClick"
        ) {{ $t(addEntityBtn.text) }}
      v-btn(
        v-if="loadMoreBtn"
        @click="loadMore"
        class="secondary-button mx-auto d-block my-2"
        outlined
        depressed
      ) {{ $t('Another type') }}
</template>

<script>
import DOMPurify from 'dompurify'
import filtrationMethods from '~/mixins/tables/_includes/filtrationMethods'
import ctx from '~/mixins/props/ctx'
import ELink from '~/components/elements/links/e-link'
import EInfoTooltip from '~/components/elements/tooltips/e-info-tooltip'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import EProgressLinear from '~/components/elements/progress/e-progress-linear'

const PADDING_SIZE = 32 // padding length for slot of autocomplete

export default {
  components: {
    ESvgIcon,
    ELink,
    EInfoTooltip,
    EProgressLinear
  },
  mixins: [filtrationMethods, ctx, confirmationDialog],
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: [Object, Array, null],
      default: null
    },
    item: {
      type: Object,
      required: true
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    emmitEmptyObject: {
      type: Boolean,
      default: false
    },
    selectLoading: {
      type: Boolean,
      default: false
    },
    addEntityBtn: {
      type: Object,
      default: () => {}
    },
    deleteEntityBtn: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    debouncedSearchString: null,
    isLoading: false,
    totalItems: 0,
    itemsPerPage: 10,
    page: 1,
    inputWidth: 'auto',
    hideLoadMoreBtn: false,
    asyncDefaultVal: null,
    selectionMaxHeight: false,
    searching: false,
    debounceTimeout: 800,
    canLoadMore: true,
    cachedItems: [],
    cacheItems: false
  }),
  computed: {
    loadMoreBtn () {
      return !this.isLoading && this.item.loadMoreQuery && !this.hideLoadMoreBtn && !this.debouncedSearchString
    },
    multiple () {
      return this._.get(this.item, 'attrs.multiple', false)
    },
    itemsFilter () {
      return this._.get(this.item, 'attrs.itemsFilter', null)
    },
    useDefaultSearchFilter () {
      return this._.get(this.item, 'attrs.useDefaultSearchFilter', false)
    },
    searchFilter () {
      return this._.get(this.item, 'attrs.searchFilter', false)
    },
    val () {
      if (!this.value) {
        return this.value
      }
      return this.handleSaveModelInstance(this.value)
    },
    itemClass () {
      return ['v-list-item__title', this.$attrs.itemClass && this.$attrs.itemClass]
    },
    model () {
      return this.item.searchModel
    },
    rows () {
      if (this.model.orderBy) {
        let modelQuery = this.model.query()
        this._.map(this.model.orderBy, (dir, key) => {
          modelQuery = modelQuery.orderBy(key, dir)
        })
        return modelQuery.get()
      }
      const rows = [...this.model.all()]
      const saveModelInstance = this._.get(this.item, 'saveModelInstance')
      if (saveModelInstance === false && Array.isArray(this.val)) {
        this._.each(this.val, (item) => {
          const index = this._.findIndex(rows, row => row?.id === item?.id)
          if (index === -1) {
            rows.push(item)
          }
        })
      }

      if (this.cacheItems) {
        const filteredRows = this._.isFunction(this.itemsFilter) ? this.itemsFilter(rows) : rows
        const nextRows = []
        this._.each(filteredRows, (row) => {
          const found = this._.find(this.cachedItems, row)
          if (!found) {
            this.cachedItems.push(row)
            nextRows.push(row)
          }
        })
        return rows
      }

      return this._.isFunction(this.itemsFilter) ? this.itemsFilter(rows) : rows
    },
    isRequestAble () {
      const items = (this.cacheItems ? this.cachedItems : this.model.all()) || []
      const itemsLength = this._.get(items, 'length')
      const isRequestAble = this.totalItems === null ? this.canLoadMore : (itemsLength < this.totalItems)
      return !this.isLoading && isRequestAble
    },
    isLocked () {
      return (this.isLoading && this.rows.length > 1) || this._.get(this.$attrs, 'readonly', false)
    },
    listeners () {
      return Object.assign({},
        this.$listeners,
        {
          input: (val) => {
            // eslint-disable-next-line new-cap
            const empty = this.emmitEmptyObject ? new this.model({ id: null }) : null
            this.$emit('input', this._.isUndefined(val) ? empty : val)
          },
          'update:search-input': (val) => {
            if (this._.get(this.item, 'emitSearch', true)) {
              this.$emit('update:search-input', this.multiple ? this.value : val)
            }
          }
        }
      )
    },
    itemsPerPageParam () {
      return this._.get(this.item, 'attrs.itemsPerPage') || this.itemsPerPage
    },
    customQuery () {
      return this._.get(this.$attrs, 'customQuery', () => {})
    },
    requestOnMounted () {
      return this._.get(this.item, 'attrs.requestOnMounted', true)
    },
    showLoader () {
      return this._.get(this.item, 'attrs.showLoader', false)
    },
    loaderColor () {
      return this._.get(this.item, 'attrs.loaderColor', 'primary')
    },
    initialTotalItems () {
      const totalItems = this._.get(this.item, 'attrs.initialTotalItems', null)
      return typeof totalItems === 'number' ? totalItems : null
    }
  },
  watch: {
    debouncedSearchString (val) {
      if (this.value && val && !this.multiple) {
        this.$emit('input', null)
      }

      if (!val && this._.get(this.$attrs, 'deleteAllOnSearch', true)) {
        this.model.deleteAll()
      }

      this.searching = true
      this.debouncedSearch()
    },
    value (val) {
      this.asyncDefaultVal = val
      if (!this.multiple || !val || (this._.isArray(val) && val.length === 0)) {
        this.selectionMaxHeight = false
        return
      }
      const autocomplete = this._.get(this.$refs, 'autocomplete.$el')
      if (autocomplete) {
        const selections = autocomplete.querySelector('.v-select__selections')

        if (selections) {
          this.selectionMaxHeight = selections.clientHeight >= 100
        }
      }
    }
  },
  created () {
    if (this.model.paginated) {
      this.debouncedSearch = this._.debounce(async (incrementPage) => {
        if (incrementPage) {
          this.page += 1
        } else {
          this.page = 1
        }
        await this.requesting()
      }, this.debounceTimeout)
    }
    this.$set(this.$data, 'cacheItems', this._.get(this.$attrs, 'cacheItems'))
    this.setPropsWatching()
  },
  mounted () {
    this.$nextTick(async () => {
      if (this.requestOnMounted) {
        await this.requesting()
      } else {
        this.totalItems = this.initialTotalItems // To make isRequestAble true
      }
      if (this._.get(this.item, 'asyncDefault', null) && this._.isFunction(this.item.asyncDefault)) {
        const asyncDefaultVal = this.item.asyncDefault(this.rows)
        this.handleSaveModelInstance(asyncDefaultVal)
        this.asyncDefaultVal = asyncDefaultVal
        this.asyncDefaultVal && this.$emit('input', this.asyncDefaultVal)
      }
    })
  },
  methods: {
    handleSaveModelInstance (val) {
      const saveModelInstance = this._.get(this.item, 'saveModelInstance')
      if (this.multiple && this._.isArray(val)) {
        return this._.map(val, (item) => {
          if (item instanceof this.model) {
            return item
          }
          // eslint-disable-next-line new-cap
          const tmp = new this.model(item)
          if (saveModelInstance) {
            tmp.$save()
          }
          return tmp
        })
      }
      if (val instanceof this.model) {
        return val
      }
      // eslint-disable-next-line new-cap
      const tmp = new this.model(val)
      if (saveModelInstance) {
        tmp.$save()
      }
      return tmp
    },
    setPropsWatching () {
      const props = this._.get(this.item, 'attrs.watchProps')
      const handler = this._.get(this.item, 'attrs.onWatchProps')
      if (props && Array.isArray(props) && this._.isFunction(handler)) {
        this._.each(props, (prop) => {
          this.$watch(prop, async (val, prevVal) => {
            await handler(this, val, prevVal)
          })
        })
      }
    },
    async addEntityBtnClick () {
      const menu = document.querySelector('.v-autocomplete__content.menuable__content__active')
      if (menu) {
        menu.style.display = 'none'
      }
      if (this._.isFunction(this.addEntityBtn.call)) {
        const entity = await this.addEntityBtn.call(this.item)
        if (entity) {
          this.$emit('input', entity)
        }
        await this.requesting()
      }
    },
    async deleteEntityBtnClick (item) {
      const menu = document.querySelector('.v-autocomplete__content.menuable__content__active')
      if (menu) {
        menu.style.display = 'none'
      }
      await this.confirmationDialog.open({
        title: this.$t('Supplier deleting'),
        text: this.$t('The supplier _supplier will be removed', { supplier: DOMPurify.sanitize(item.name) }),
        onConfirm: async () => await this.model.api().del(item)
      })
    },
    localSearchFilter (item, queryText, itemText) {
      if (this._.isFunction(this.searchFilter)) {
        return this.searchFilter(item, queryText, itemText)
      }
      if (!this.useDefaultSearchFilter && !this.multiple && this._.isFunction(this.item.query)) {
        return item
      }
      return itemText.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    },
    async loadMore () {
      this.hideLoadMoreBtn = true
      await this.requesting()
      this.chunkRequest()
    },
    setSearchString (payload) {
      // if (!(payload instanceof this.model) && typeof payload !== 'object') {
      if (payload === null || (!(payload instanceof this.model) && typeof payload !== 'object')) {
        this.debouncedSearchString = payload
      }
    },
    queryCallback () {
      if (this._.isFunction(this.item.query)) {
        let config = {}
        const query = this.hideLoadMoreBtn ? 'loadMoreQuery' : 'query'

        if (this.page === 1 && !this.useDefaultSearchFilter && !this.searchFilter) {
          config = { persistBy: 'create' }
        }

        if (this.customQuery(this)) {
          return this.item[query](this.model, this.ctx, {
            params: {
              limit: this.itemsPerPageParam,
              offset: this.page,
              search: this.debouncedSearchString
            },
            ...config
          }, this.$User)
        }

        if (this.item[query](this.model, this.ctx, {}, this.$User)) {
          return this.item[query](this.model, this.ctx, {}, this.$User).all({
            itemsPerPage: this.itemsPerPageParam,
            page: this.page,
            search: this.debouncedSearchString
          }, config)
        }
      }
    },
    async requesting () {
      if ((!this.$Organization && !this.$User.isSuperAdmin) || this.isLoading || this._.get(this.$attrs, 'disabled') || this._.get(this.$attrs, 'readonly')) {
        return
      }

      try {
        this.isLoading = true
        const response = await this.queryCallback()
        this.totalItems = this._.get(response, 'response.data.meta.totalItems', 0)
        this.canLoadMore = this._.get(response, 'response.data.data.length', 0) >= this.itemsPerPageParam

        if (this.$refs.autocomplete) {
          if (!this.autoWidth) {
            this.$set(this.$data, 'inputWidth', this.$refs.autocomplete.$el.clientWidth - PADDING_SIZE + 'px')
          } else {
            this.$set(this.$data, 'inputWidth', 'auto')
          }
        }

        if (this.$refs.autocomplete && this.$refs.autocomplete.isMenuActive) {
          this.$refs.autocomplete.onScroll()
        }

        // Because items width after request may be increased
        if (this.autoWidth && this.$refs.autocomplete) {
          this.$refs.autocomplete.updateMenuDimensions()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.isLoading = false
        this.searching = false
      }
    },
    chunkRequest (entries, observer, isIntersecting) {
      const items = (this.cacheItems ? this.cachedItems : this.model.all()) || []
      const allItemsReceived = items.length === this.totalItems
      if (!this.isRequestAble || (this.isLoading && !isIntersecting) || this.searching || allItemsReceived) {
        return
      }

      this.debouncedSearch(true)
    },
    remove (item) {
      const filteredValue = this._.filter(this.value, i => i.id !== item.id)
      this.$emit('input', filteredValue)
    },
    chipText (item) {
      const attrsChipText = this._.get(this.$attrs, 'chipText', null)
      return this._.isFunction(attrsChipText) ? attrsChipText(item) : attrsChipText
    }
  }
}
</script>

<style lang="scss" scoped>
.autocomplete {
  &__selection-max-height {
    ::v-deep {
      .v-select__selections {
        margin: 5px 0 7px;
        overflow-y: auto;
        max-height: 100px;
      }
    }
  }

  &__link {
    font-size: 13px;
    padding: 0 16px 3px;
    width: 100%;
  }

  &__chip-text {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__loader {
    width: 100%;
    height: 4px;
    padding: 0 12px;
    float: left;
  }
}

.v-list-item__content--append {
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 20px;

  * {
    flex: none;
  }
}
.v-list-item__title {
  white-space: normal !important;
}
</style>
