import Receipts from '~/modules/receipt/models/Receipts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import _commonData from '~/modules/receipt/mixins/getters/commonData'
import converters from '~/mixins/methods/converters'
import receiptDialogs from '~/modules/receipt/mixins/receiptDialogs'

const processingReceipt = {
  mixins: [_commonData, converters, receiptDialogs],
  methods: {
    async processPosTerminalPayment ({ payload, posTerminalSettings, isReturn = false, terminalPayload, onError }) {
      const res = await this.contentDialog.open({
        component: 'block-pos-terminal-payment-process',
        width: '450px',
        componentProps: {
          receiptPayload: payload,
          posTerminalSettings,
          terminalPayload,
          isReturn,
          onError
        }
      })
      const receiptHtml = this._.get(res, 'receiptHtml')
      const receiptId = this._.get(res, 'receiptId')
      const qrCode = this._.get(res, 'qrCode')
      if (receiptHtml && receiptId && qrCode) {
        await this.openReceiptTextDialog({
          isReceipt: true,
          preview: {
            receipt: receiptHtml,
            qrCode: await this.blobToBase64(qrCode),
            receiptId
          }
        })
      }
      return res
    },

    // top level methods
    async createServiceReceipt (token, amount) {
      const payload = {
        payment: {
          type: 'CASH',
          value: amount,
          label: 'Готівка'
        }
      }
      await this.authenticatedCashier.maybeStartSignAgent()
      return Receipts.api().processingCreateService(payload, token)
    },

    async createReceiptCompletely ({ token, payload, receiptViewType = 'html', onError }) {
      if (receiptViewType !== 'htmlAndId') {
        await this.authenticatedCashier.maybeStartSignAgent()
      }
      const receiptId = this._.get(await this.createReceipt(token, payload), 'response.data.id')
      const intervalRequest = new IntervalRequest(() => this.readReceipt(token, receiptId))
      const resolveCondition = response => response.response.data.status === Receipts.processingStatuses.DONE || response.response.data.status === Receipts.processingStatuses.ERROR
      // const rejectCondition = response => response.response.data.status === Receipts.processingStatuses.ERROR
      const resultReceipt = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
      if (resultReceipt.transaction.status === 'ERROR' && resultReceipt.transaction.response_error_message) {
        if (typeof onError === 'function') {
          await onError(resultReceipt)
        }
        throw new Error(this.$t('Error when get receipt:') + ' ' + resultReceipt.transaction.response_error_message)
      }
      switch (receiptViewType) {
        case 'html': {
          return this._.get(await Receipts.api().processingReadHtml(receiptId), 'response.data')
        }
        case 'img' : {
          const res = this._.get(await Receipts.api().processingReadPng(receiptId), 'response.data')
          return {
            imgSrc: await this.blobToBase64(res)
          }
        }
        case 'text' : {
          const preview = this._.get(await Receipts.api().processingReadText(receiptId), 'response.data')
          const qrCode = this._.get(await Receipts.api().processingReadQrCode(receiptId), 'response.data', '')
          return {
            receipt: preview,
            qrCode: await this.blobToBase64(qrCode),
            receiptId
          }
        }
        case 'htmlAndId': {
          const receiptHtml = this._.get(await Receipts.api().processingReadHtml(receiptId), 'response.data')
          return { receiptHtml, resultReceipt }
        }
        default : {
          return resultReceipt
        }
      }
    },

    // low level methods
    createReceipt (token, payload) {
      return Receipts.api().processingCreate(payload, token)
    },
    readReceipt (token, id) {
      return Receipts.api().processingRead(id, token)
    }
  }

}

export default processingReceipt
