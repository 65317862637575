<template lang="pug">
  div(class="dialog")
    template(v-if="!showConfirm")
      h3(class="dialog__title") {{ $t(cardData.title) }}
      p(class="dialog__text") {{ $t(cardData.text) }}
      v-form(class="pt-9")
        div()
          ValidationObserver(ref="form" slim)
            t-orm-fields(
              v-bind.sync="form"
              :items="fields"
            )
        div(class="dialog__actions mt-2")
          v-btn(
            class="dialog__btn"
            @click="closeModal"
          ) {{ $t(cardData.cancelBtnText) }}
          v-btn(
            class="main-button dialog__btn"
            :loading="loading"
            @click="goToConfirm"
          ) {{ $t(cardData.okBtnText) }}
    template(v-else)
      h3(class="dialog__title") {{ $t('Order confirmation') }}
      p(class="dialog__text") {{ $t(cardData.confirmLabel) }}
      p(v-if="form.outletAddress" class="dialog__text dialog__text--black mb-4") {{ form.outletAddress }}
      p(class="dialog__text") {{ $t(cardData.confirmText) }}
      v-checkbox(
        v-model="isConfirmed"
        :label="$t(cardData.checkboxLabel)"
      )
      div(class="dialog__actions mt-2")
        v-btn(
          class="dialog__btn"
          @click="showConfirm = false"
        ) {{ $t('Go back') }}
        v-btn(
          class="main-button dialog__btn"
          :loading="loadingConfirm"
          :disabled="!isConfirmed"
          @click="submit"
        ) {{ $t('Send') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import OutletsSearch from '~/modules/outlets/models/OutletsSearch'
import Outlets from '~/modules/outlets/models/Outlets'
import contentDialog from '~/mixins/dialogs/contentDialog'
import AcquiringMono from '~/modules/acquiring/models/AcquiringMono'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'

export default {
  name: 'BlockAcquiringForm',
  components: {
    TOrmFields
  },
  mixins: [contentDialog],
  props: {
    type: {
      type: String,
      default: 'posTerminals'
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    loadingConfirm: false,
    form: {
      name: null,
      cashRegister: null,
      outlet: null,
      outletAddress: null,
      outletName: null,
      tradePointId: null,
      site: null
    },
    showConfirm: false,
    isConfirmed: false
  }),
  computed: {
    model () {
      return AcquiringMono
    },
    isPosTerminals () {
      return this.type === 'posTerminals'
    },
    isTapToMono () {
      return this.type === 'tapToMono'
    },
    isQr () {
      return this.type === 'qrAcquiring'
    },
    isInternet () {
      return this.type === 'internetAcquiring'
    },
    terminalType () {
      if (this.isTapToMono) {
        return 'MT'
      } else if (this.isQr) {
        return 'MQ'
      } else if (this.isInternet) {
        return 'MI'
      }
      return 'PQ'
    },
    cardData () {
      return this.cardsData[this.type]
    },
    cardsData () {
      return {
        posTerminals: {
          title: 'Add POS-terminal',
          text: 'Після заповнення інформації нижче ваше замовлення на POS-термінал буде відправлено у банк. Термінал буде відправлено поштою на вказану вами адресу, а слідкувати за статусом заявки ви зможете через ваш особистий кабінет.',
          okBtnText: 'Замовити',
          cancelBtnText: 'Скасувати',
          checkboxLabel: 'I confirm the order of the terminal and its delivery to the specified address',
          confirmLabel: 'Check the terminal shipping address:',
          confirmText: 'If you confirm the order, your application will be sent to the bank and processed.'
        },
        tapToMono: {
          title: 'Order tap to mono terminal',
          text: 'Після заповнення інформації нижче ваше замовлення буде відправлено у банк і з вами звʼяжуться, щоб підключити та активувати послугу Tap to mono. Слідкувати за статусом заявки ви зможете через ваш особистий кабінет.',
          okBtnText: 'Замовити',
          cancelBtnText: 'Скасувати',
          checkboxLabel: 'I confirm the order of the terminal',
          confirmLabel: 'Check the cash register to which you connect acquiring:',
          confirmText: 'If you confirm the order, your application will be sent to the bank and processed. '
        },
        qrAcquiring: {
          title: 'Order QR code',
          text: 'Після заповнення інформації нижче ваше замовлення на QR-код буде відправлено у банк. Касу з QR-кодом буде відправлено поштою на вказану вами адресу, а слідкувати за статусом заявки ви зможете через ваш особистий кабінет.',
          okBtnText: 'Замовити',
          cancelBtnText: 'Скасувати',
          checkboxLabel: 'I confirm the order of the terminal and its delivery to the specified address',
          confirmLabel: 'Check the terminal shipping address:',
          confirmText: 'If you confirm the order, your application will be sent to the bank and processed.'
        },
        internetAcquiring: {
          title: 'Connect internet acquiring',
          text: 'Після заповнення інформації нижче вашу заявку на підключення інтернет-еквайрингу буде відправлено у банк і з вами звʼяжуться, щоб підключити та активувати послугу. Слідкувати за статусом заявки ви зможете через ваш особистий кабінет.',
          okBtnText: 'Підключити',
          cancelBtnText: 'Скасувати',
          checkboxLabel: 'I confirm the order of the terminal',
          confirmLabel: 'Check the cash register to which you connect acquiring:',
          confirmText: 'If you confirm the order, your application will be sent to the bank and processed. '
        }
      }
    },
    fields () {
      return [
        new AutocompleteBuilder({
          model: 'outlet',
          label: 'Outlet  ',
          returnObject: true,
          itemClass: 'ws-pre',
          rules: 'required',
          visible: () => this.isPosTerminals,
          query: model => model.api().filter({ mode: Outlets.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (items && items.length === 1) {
              return items[0]
            }
          }
        }, OutletsSearch).build(),
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Cash register ',
          returnObject: true,
          itemClass: 'ws-pre',
          rules: 'required',
          visible: () => !this.isPosTerminals,
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (items && items.length === 1) {
              return items[0]
            }
          }
        }, CashRegistersSearch).build(),
        {
          model: 'outletName',
          component: 'v-text-field',
          provider: {
            vid: 'outletName',
            name: 'Outlet name',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            disabled: true,
            dynamic: true,
            label: 'Outlet name'
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'outletAddress',
          component: 'v-text-field',
          provider: {
            vid: 'outletAddress',
            name: 'Outlet address',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            disabled: true,
            dynamic: true,
            label: 'Outlet address'
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'name',
          component: 'v-text-field',
          provider: {
            vid: 'name',
            name: 'Terminal name',
            rules: 'required|latin_dashes_spaces|max:18'
          },
          attrs: {
            outlined: true,
            label: 'Terminal name'
          },
          hint: 'Введіть назву вашого магазину, для відображення у виписці покупця латиницею'
        },
        {
          model: 'site',
          component: 'v-text-field',
          provider: {
            vid: 'site',
            name: 'Web site address',
            rules: 'required|url'
          },
          attrs: {
            outlined: true,
            label: 'Web site address',
            visible: () => this.isInternet
          }
        }
      ]
    }
  },
  watch: {
    'form.outlet' (val) {
      this.$set(this.form, 'outletName', this._.get(val, 'name'))
      this.$set(this.form, 'outletAddress', this._.get(val, 'address'))
    },
    'form.cashRegister' (val) {
      this.$set(this.form, 'outletName', this._.get(val, 'outlet.name'))
      this.$set(this.form, 'outletAddress', this._.get(val, 'outlet.address'))
    }
  },
  methods: {
    async showSuccessModal () {
      await this.contentDialog.open({
        component: 'block-status-modal',
        width: '560px',
        componentProps: {
          title: 'Ваше замовлення було надіслано!',
          text: 'Тепер вам потрібно підписати договір у додатку monobank та переглянути інструкцію з наступними кроками на пошті'
        }
      })
    },
    async showErrorModal () {
      await this.contentDialog.open({
        component: 'block-status-modal',
        width: '560px',
        componentProps: {
          catType: '5',
          title: 'На жаль, ваш запит відхилено',
          text: 'Зверніться в нашу службу турботи',
          btnText: 'Зрозуміло'
        }
      })
    },
    generatePayload () {
      const payload = {
        type: this.terminalType,
        name: this._.get(this.form, 'name'),
        address: this._.get(this.form, 'outletAddress', null),
        tradePointId: this._.get(this.form, 'outlet.id', null),
        site: null,
        kassaId: null,
        cashierId: null,
        businessId: localStorage.getItem('monobankBusinessId')
      }
      if (this.isTapToMono || this.isQr || this.isInternet) {
        payload.kassaId = this._.get(this.form, 'cashRegister.id')
      }
      const site = this._.get(this.form, 'site', null)
      if (site) {
        payload.site = site.startsWith('http') ? site : `https://${site}`
      }
      return payload
    },
    async goToConfirm () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        this.showConfirm = true
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async submit () {
      try {
        this.loadingConfirm = true
        const payload = this.generatePayload()
        await this.model.api().terminalRegistration(payload)
        await this.tableRequest()
        await this.showSuccessModal()
      } catch (e) {
        await this.showErrorModal()
      } finally {
        this.loadingConfirm = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
  }

  &__text--black {
    color: #000;
  }

  &__btn {
    width: 100%;
    max-width: 187px;
  }
}
</style>
