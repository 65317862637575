<template lang="pug">
  div(
    v-if="value"
    class="cols-map"
  )
    v-tooltip(
      v-if="tooltip"
      right
    )
      template(#activator="{ on, attrs }")
        span(
          v-if="!chips"
          v-on="on"
        ) {{ output }}
        v-chip(
          v-bind="color"
          v-on="on"
          @click="openErrorModal"
          :class="{ 'cols-map__chip': true, 'cols-map__chip--pointer': showError }"
          small
          v-else
        ) {{ output }}
      span() {{ tooltip }}
    div(v-else)
      span(v-if="!chips") {{ output }}
      v-chip(
        v-bind="color"
        @click="openErrorModal"
        :class="{ 'cols-map__chip': true, 'cols-map__chip--pointer': showError }"
        small
        v-else
      ) {{ output }}
      e-link(
        v-if="hasErrorFile && !taxErrorMessage"
        @click="loadError"
        class="d-block mt-1"
        :loading="errorLoading"
        underline
      ) {{ $t('View') }}

    e-hint-tooltip(
      v-if="showError"
      icon="exclamation"
      classes="ml-1 mt-1 cursor-pointer"
      :styles="{ verticalAlign: '-4px' }"
      :onClick="openErrorModal"
      right
    )
      span {{ $t('Click to view the error') }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EHintTooltip from '~/components/elements/tooltips/e-hint-tooltip'

import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  components: {
    EHintTooltip,
    ESvgIcon,
    ELink
  },
  mixins: [
    contentDialog
  ],
  props: {
    map: {
      type: [Array, Object],
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    template: {
      type: String,
      default: '{value} - {text}'
    },
    chips: {
      type: Boolean,
      default: false
    },
    valueField: {
      type: String,
      default: null
    },
    randomColor: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    appearance: {
      checkbox: {
        color: 'rgba(0, 120, 232, 0.1)',
        textColor: '#0078E8'
      },
      hasRemovalOrderError: {
        color: 'rgba(0, 120, 232, 0.1)',
        textColor: '#0078E8'
      },
      hasModifyOrderError: {
        color: 'rgba(0, 120, 232, 0.1)',
        textColor: '#0078E8'
      },
      deactivated: {
        color: 'rgba(152, 152, 152, 0.1)',
        textColor: 'rgba(124, 124, 124, 1)'
      },
      deactivatedByUser: {
        color: 'rgba(152, 152, 152, 0.1)',
        textColor: 'rgba(124, 124, 124, 1)'
      },
      alert: {
        color: 'rgba(255, 170, 1, 0.2)',
        textColor: '#c37f00'
      },
      onRemovalInSfs: {
        color: 'rgba(255, 170, 1, 0.2)',
        textColor: '#c37f00'
      },
      onModifyInSfs: {
        color: 'rgba(255, 170, 1, 0.2)',
        textColor: '#c37f00'
      },
      order: {
        color: 'rgba(255, 170, 1, 0.2)',
        textColor: '#c37f00'
      },
      tax: {
        color: 'rgba(22, 27, 37, 0.1)',
        textColor: '#161b25'
      },
      start: {
        color: 'rgba(22, 27, 37, 0.1)',
        textColor: '#161b25'
      },
      error: {
        color: 'rgba(238, 0, 0, 0.1)',
        textColor: 'rgba(238, 0, 0, 1)'
      }
    },
    errorLoading: false
  }),
  computed: {
    model () {
      return this._.get(this.map, 'model', null)
    },
    taxErrorMessage () {
      return this._.get(this.item, 'taxErrorMessage', null) || this._.get(this.item, 'error.taxErrorMessage', null)
    },
    hasErrorFile () {
      return this._.get(this.item, 'hasErrorFile') || this._.get(this.item, 'error.hasErrorFile')
    },
    showError () {
      return this.taxErrorMessage || this._.get(this.item, 'error', null)
    },
    item () {
      if (this.valueField && Array.isArray(this.map)) {
        return this.map.find(item => item[this.valueField] === this.value)
      } else {
        return this.map[this.value]
      }
    },
    tooltip () {
      return this.$t(this._.get(this, 'item.tooltip'))
    },
    color () {
      return this._.get(this.item, 'appearance', '') || this.appearance[this._.get(this.item, 'type', '')]
    },
    output () {
      // TODO apply full template support
      return this.template.replace(/{value}/gi, this.value).replace(/{text}/gi, this.$t(this._.get(this.item, 'text', '')))
    },
    certificateEnd () {
      return this._.get(this.map, 'certificateEnd', null)
    }
  },
  methods: {
    async loadError () {
      try {
        this.errorLoading = true
        const requestId = this._.get(this.item, 'error.id') || this._.get(this.map, 'id')
        const res = await this.model.api().get(this.model.$routes[this.model.entity].error(requestId), {
          responseType: 'blob'
        })
        const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob), '_blank')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.errorLoading = false
      }
    },
    async openErrorModal () {
      if (!this.showError) {
        return
      }
      await this.contentDialog.open({
        component: 'block-order-error',
        width: '600px',
        componentProps: {
          hasErrorFile: this.hasErrorFile,
          message: this.taxErrorMessage,
          orderId: this._.get(this.item, 'error.id') || this._.get(this.map, 'id'),
          model: this.model
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.cols-map {
  display: flex;
  padding: 10px 0;

  &__hint {
    color: $color-warning;
    font-weight: 700;
  }

  &__chip {
    cursor: default;

    &--pointer {
      cursor: pointer;
    }
  }

  &::v-deep {
    .v-chip {
      border-radius: 8px;
      padding: 0 7px;
      font-size: .69rem;
      height: 22px;
    }

    .e-link {
      font-size: .59rem !important;
    }
  }
}
</style>
