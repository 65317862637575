<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ $t('Editing the text display of the check') }}
    div()
      v-row
        v-col(cols="12" sm="7" class="pt-7")
          v-form(@submit.prevent.stop="saveReceiptTextTemplate")
            ValidationObserver(
              ref="receiptSettings"
              slim
            )
              t-orm-fields(
                v-bind.sync="receiptSettings"
                :items="receiptSettingsFields"
              )
            div(v-if="logo" class="d-flex align-center mb-6")
              img(
                :src="logo"
                alt="Receipt logo"
                class="dialog__logo mr-2"
              )
              v-tooltip(right)
                template(#activator="{on}")
                  v-btn(
                    v-on="on"
                    @click="deleteReceiptTextLogo"
                    :loading="logoLoading"
                    icon
                  )
                    e-svg-icon(size="sm") trash
                | {{ $t('Delete receipt logo') }}
            ValidationObserver(
              ref="logoTextInput"
              v-else
            )
              e-input-file-content(
                :value="logo"
                :label="$t('Receipt logo')"
                expect="dataUrl"
                accept=".png"
                ref="logoInput"
                rules="ext:png|size:1024"
                prepend-icon=""
                append-icon="$file"
                @input="updateReceiptTextLogo"
                :loading="logoLoading"
                outlined
              )
            v-text-field(
              v-model="receiptConfig.text_global_header"
              :label="$t('Receipt header')"
              outlined
            )
            v-text-field(
              v-model="receiptConfig.text_global_footer"
              :label="$t('Receipt footer')"
              outlined
            )
            v-btn(
              :class="{ 'main-button': true, 'w-100': xsDevice }"
              :loading="loading"
              type="submit"
            ) {{ $t('Save') }}
        v-col(
          v-if="!xsDevice"
          cols="5"
        )
          div(
            v-if="receiptLoading"
            class="dialog__loader"
          )
            e-progress-circular(
              color="#161b25"
              size="xl"
              width="4"
            )
          div(v-else)
            img(:src="receiptPng" alt="Receipt"  class="dialog__img")
</template>

<script>
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Organization from '~/models/directories/Organization'
import currentOrganization from '~/modules/auth/services/currentOrganization'

export default {
  name: 'BlockSettingsTextReceiptForm',
  components: {
    EProgressCircular,
    EInputFileContent,
    ESvgIcon,
    TOrmFields
  },
  mixins: [responsive],
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    receipt: {
      type: String,
      default: null
    },
    logoText: {
      type: String,
      default: null
    },
    getReceiptText: {
      type: Function,
      default: () => {}
    },
    updateReceiptConfig: {
      type: Function,
      default: () => {}
    },
    onConfigChange: {
      type: Function,
      default: () => {}
    },
    onReceiptChange: {
      type: Function,
      default: () => {}
    },
    onLogoChange: {
      type: Function,
      default: () => {}
    },
    model: {
      type: null,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    logoLoading: false,
    receiptLoading: false,
    receiptPng: null,
    receiptConfig: {},
    logo: null,
    receiptSettings: {
      width: null,
      pngWidth: null,
      paperWidth: null,
      qrScale: null
    }
  }),
  computed: {
    receiptSettingsFields () {
      return [
        {
          model: 'width',
          component: 'v-text-field',
          provider: {
            vid: 'width',
            name: 'Receipt width',
            rules: 'numeric|max_value:100|min_value:22'
          },
          attrs: {
            label: 'Receipt width',
            type: 'number',
            outlined: true,
            min: 22,
            max: 100
          },
          cols: {
            cols: 12,
            md: 6
          },
          fieldVal: () => this._.get(this.$Organization, 'receiptSetting.width', null),
          cast: value => value ? parseInt(value) : null,
          tooltipHint: 'The width of the receipt is indicated in the number of characters'
        },
        {
          model: 'pngWidth',
          component: 'v-text-field',
          provider: {
            vid: 'pngWidth',
            name: 'Receipt png width',
            rules: 'numeric|max_value:100|min_value:22'
          },
          attrs: {
            label: 'Receipt png width',
            type: 'number',
            outlined: true,
            min: 22,
            max: 100
          },
          cols: {
            cols: 12,
            md: 6
          },
          fieldVal: () => this._.get(this.$Organization, 'receiptSetting.pngWidth', null),
          cast: value => value ? parseInt(value) : null,
          tooltipHint: 'The width of the receipt png view is indicated in the number of characters'
        },
        {
          model: 'paperWidth',
          component: 'v-select',
          provider: {
            vid: 'paperWidth',
            name: 'Paper width'
          },
          attrs: {
            label: 'Paper width',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          },
          fieldVal: () => this._.get(this.$Organization, 'receiptSetting.paperWidth', null),
          items: [{ text: '58 ' + this.$t('mm'), value: 58 }, { text: '80 ' + this.$t('mm'), value: 80 }],
          tooltipHint: 'Default value: 58'
        },
        {
          model: 'qrScale',
          component: 'v-text-field',
          provider: {
            vid: 'qrScale',
            name: 'Qr code scale',
            rules: 'numeric|max_value:100|min_value:30'
          },
          attrs: {
            label: 'Qr code scale',
            type: 'number',
            outlined: true,
            min: 30,
            max: 100
          },
          cols: {
            cols: 12,
            md: 6
          },
          fieldVal: () => this._.get(this.$Organization, 'receiptSetting.qrScale', 50),
          cast: value => value ? parseInt(value) : null,
          tooltipHint: 'Relative size in percent or receipt qrcode'
        }
      ]
    }
  },
  created () {
    this.receiptConfig = JSON.parse(JSON.stringify(this.config))
    this.receiptPng = JSON.parse(JSON.stringify(this.receipt))
    this.logo = JSON.parse(JSON.stringify(this.logoText))
  },
  methods: {
    checkReceiptSettingsDiff () {
      const receiptSettingsKeys = Object.keys(this.receiptSettings)
      let isDiff = false
      for (let i = 0; i < receiptSettingsKeys.length; i++) {
        const key = receiptSettingsKeys[i]
        if (this.receiptSettings[key] !== this._.get(this.$Organization, `receiptSetting.${key}`, null)) {
          isDiff = true
        }
      }
      return isDiff
    },
    async saveReceiptTextTemplate () {
      try {
        this.loading = true
        const valid = await this.$refs.receiptSettings.validate()
        if (!valid) {
          return
        }
        const receiptSettingsDiff = this.checkReceiptSettingsDiff()
        if (receiptSettingsDiff) {
          if (!await this.$checkOrganization()) {
            return
          }
          const payload = {
            receiptSetting: {
              id: this._.get(this.$Organization, 'receiptSetting.id'),
              ...this.receiptSettings
            }
          }
          await Organization.api().update(this.$Organization, payload)
          await Organization.api().read(this.$Organization.id)
          currentOrganization.refresh()
        }
        if (
          this._.get(this.receiptConfig, 'text_global_header') !== this._.get(this.config, 'text_global_header') ||
          this._.get(this.receiptConfig, 'text_global_footer') !== this._.get(this.config, 'text_global_footer')
        ) {
          await this.updateReceiptConfig(this.receiptConfig)
          this.onConfigChange(this.receiptConfig)
          if (!this.xsDevice) {
            this.receiptLoading = true
            this.receiptPng = await this.getReceiptText()
            this.onReceiptChange(this.receiptPng)
          }
        }
        this.$notification.success('Receipt settings is saved successfully')
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.receiptLoading = false
        this.loading = false
      }
    },
    async updateReceiptTextLogo (logo = '') {
      try {
        if (logo) {
          this.logoLoading = true
          const valid = await this.$refs.logoTextInput.validate()
          if (!valid) {
            return
          }
          const logoBase64 = logo.split(',')[1]
          await this.model.api().updateReceiptTextLogo({
            file: logoBase64
          })
          this.logo = logo
          this.onLogoChange(this.logo)
          if (!this.xsDevice) {
            this.receiptLoading = true
            this.receiptPng = await this.getReceiptText({
              text_logo: logoBase64
            })
            this.onReceiptChange(this.receiptPng)
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.logo = null
      } finally {
        this.receiptLoading = false
        this.logoLoading = false
      }
    },
    async deleteReceiptTextLogo () {
      try {
        this.logoLoading = true
        await this.model.api().deleteReceiptTextLogo()
        this.logo = null
        this.onLogoChange(this.logo)
        if (!this.xsDevice) {
          this.receiptLoading = true
          this.receiptPng = await this.getReceiptText({
            text_logo: null
          })
          this.onReceiptChange(this.receiptPng)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.receiptLoading = false
        this.logoLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    width: 100%;
    height: 100%;
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: auto;
    max-width: 145px;
  }

  &__img {
    width: 100%;
    max-width: 350px;
    display: block;
    margin: 0 auto;
  }
}
</style>
