<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title")
      v-btn(
        class="back-arrow"
        @click="$emit('back')"
        icon
      )
        e-svg-icon(size="sm") arrow-next
      | Cинхронізувати дані
    p(class="dialog__text") Для отримання адреси реєстрації, необхідно синхронізувати дані з ДПС
    div(class="dialog__actions")
      v-btn(
        @click="synchronize"
        class="main-button"
        :loading="loading"
      ) {{ $t('Synchronize') }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import SignPluginService from '~/services/SignPluginService/SignPluginService'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'

export default {
  components: {
    ESvgIcon
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async synchronize () {
      try {
        this.loading = true
        await SignPluginService.synchronizeOrganization({ organization: this.$Organization, ctx: this })
        currentOrganizationService.updateCurrentOrganization()
        const regKatottg = this._.get(this.$Organization, 'regKatottg')

        this.$emit('nextStep', {
          showTaxAddressForm: regKatottg,
          showAddressForm: !regKatottg,
          hasSeparatePlace: !regKatottg,
          afterSync: true
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__text {
    color: #7C7C7C;
    font-size: 14px;
    font-weight: 300;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0;
  }
}
</style>
