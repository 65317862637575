<template lang="pug">
  div
    div(class="tap-to-phone-upload-documents-item")
      div(class="d-flex")
        div(class="mr-5")
          div(class="tap-to-phone-upload-documents-item__icon")
            e-svg-icon(:name="item.icon")
        div(class="tap-to-phone-upload-documents-item__content")
          h4(class="tap-to-phone-upload-documents-item__title") {{ item.title }}
          div(class="tap-to-phone-upload-documents-item__subtitle")
            slot(name="subtitle")
      div(class="tap-to-phone-upload-documents-item__actions" :class="{ 'pt-3': isResponsive }")
        e-button-file-upload(
          :files.sync="files"
          ref="fileInput"
          :accept="accept"
          :rules="rules"
          :label="item.title"
          validationMode="aggressive"
        )
    block-tap-to-phone-file-status(
      v-if="files && files.length"
      :files="files"
      :is-upload-error="uploadError"
      @delete="removeFile"
    )
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon.vue'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content.vue'
import EButtonFileUpload from '~/components/elements/buttons/e-button-file-upload.vue'
import BlockTapToPhoneFileStatus from '~/modules/acquiring/views/_old/tap-to-phone/block-tap-to-phone-file-status.vue'

export default {
  name: 'BlockTapToPhoneUploadDocumentsItem',
  components: {
    BlockTapToPhoneFileStatus,
    EButtonFileUpload,
    ESvgIcon,
    EInputFileContent
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      files: [],
      uploadError: false
    }
  },
  computed: {
    accept () {
      return 'image/*,.docx,.pdf'
    },
    validationAccept () {
      return 'jpg,jpeg,png,docx,pdf'
    },
    rules () {
      const rules = 'required|size:30000|ext:'

      return rules + this.validationAccept
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  watch: {
    files (val) {
      this.$emit(`update:${this.item.name}`, val)
    }
  },
  methods: {
    removeFile (fileIndex) {
      this.files.splice(fileIndex, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.tap-to-phone-upload-documents-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  &__icon {
    width: 48px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #F0F1F2;
    border-radius: 12px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  &__subtitle {
    max-width: 450px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }

  &__actions {
    width: 100%;
    @media (min-width: map-get($breakpoints, 'xs')) {
      width: auto;
    }
  }
}
</style>
