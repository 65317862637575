<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ $t('Delete all goods') }}
    div(
      v-if="loading"
      class="pt-3 pb-6"
    )
      e-process-progress(
        :value="processedItemsValue"
        :current="processedItems"
        :total="totalItems"
      )
    div(v-else)
      div {{ $t('Are you sure to delete all goods') }}
      div(class="dialog__actions")
        v-btn(
          @click="confirm"
          class="main-button"
          :loading="loading"
        ) {{ $t('Approve') }}
        v-btn(
          @click="close"
          class="secondary-button color-error"
          outlined
        ) {{ $t('Dismiss') }}
</template>

<script>
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import EProcessProgress from '~/components/elements/progress/e-process-progress'

export default {
  name: 'BlockAllGoodsDeleting',
  components: {
    EProcessProgress
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    request: null
  }),
  computed: {
    model () {
      return AccountingGoods
    },
    processedItemsValue () {
      return Math.round(this.processedItems / (this.totalItems / 100)) || 0
    },
    processedItems () {
      return this._.get(this.request, 'response.response.data.processed_items', 0)
    },
    totalItems () {
      return this._.get(this.request, 'response.response.data.total_items', 0)
    }
  },
  methods: {
    async close () {
      await this.closeModal()
    },
    async confirm () {
      try {
        this.loading = true
        const taskId = this._.get(await this.model.api().deleteAll(), 'response.data.task_id', null)
        const intervalRequest = new IntervalRequest(() => this.model.api().deleteAllStatus(taskId), {
          maxDelay: 3e5 // ~5 minutes
        })
        this.request = intervalRequest
        const resolveCondition = ({ response }) => (response.data.status === 'done')
        const deletedCount = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data.processed_items', null)
        this.$notification.success(this.$t('The number of deleted goods is ') + deletedCount)
        this.model.deleteAll()
        await this.close()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    justify-content: center;
    gap: 16px;
    display: flex;
    margin-top: 20px;
  }
}
</style>
