import { Model } from '@vuex-orm/core'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import Directory from '~/models/abstracts/Directory'
import Dialog from '~/models/system/Dialog'

export default class Notifications extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'notifications'
  static paginated = true
  static defaultSortParam = 'date'

  static ormTrans = {
    single: 'Notification',
    multy: 'Notification'
  }

  static priorities = {
    low: {
      value: 'low',
      text: 'Низький'
    },
    middle: {
      value: 'middle',
      text: 'Середній'
    },
    high: {
      value: 'high',
      text: 'Високий'
    }
  }

  static statuses = {
    draft: {
      value: 'draft',
      text: 'Чернетка'
    },
    published: {
      value: 'published',
      text: 'Опубліковано'
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      title: this.attr(null),
      message: this.attr(null),
      date: this.attr(null),
      viewed: this.attr(null),
      availableAt: this.attr(null),
      dateCreated: this.attr(null),
      priority: this.attr(null),
      status: this.attr(null),
      organization: this.attr(null)
    }
  }

  get statusString () {
    return this._.get(Notifications.statuses, `${this.status}.text`)
  }

  get priorityString () {
    return this._.get(Notifications.priorities, `${this.priority}.text`)
  }

  get availableAtString () {
    return this.getDateTime(this.availableAt)
  }

  get organizationString () {
    return this._.get(this.organization, 'name')
  }

  static ormHeaders = [
    { text: 'Status', value: 'statusString', sortable: false, filterable: false },
    { text: 'Priority', value: 'priorityString', sortable: false, filterable: false },
    { text: 'Title', value: 'title', sortable: false, filterable: false },
    { text: 'Message', value: 'message', sortable: false, filterable: false },
    { text: 'Date sent', value: 'availableAtString', sortable: false, filterable: false },
    { text: 'Organization ', value: 'organizationString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    message: {
      component: 'e-text-ellipsis'
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'edit',
      visible: item => item.status === Notifications.statuses.draft.value,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        if (item) {
          await dialog.open({
            component: 'block-add-notification',
            width: '650px',
            componentProps: {
              item,
              type: 'edit'
            }
          })
        }
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.createNotification = function (payload) {
        return this.post(Model.$routes.notifications.list(), payload)
      }
      configActions.updateNotification = function (id, payload) {
        return this.put(Model.$routes.notifications.concrete(id), payload)
      }
      configActions.checkNew = function () {
        return this.get(Model.$routes.notifications.checkNew())
      }
      configActions.getUserList = function () {
        return this.get(Model.$routes.notifications.userList())
      }
      configActions.readAll = function () {
        return this.get(Model.$routes.notifications.readAll())
      }
      configActions.readNotification = function (id) {
        return this.get(Model.$routes.notifications.concrete(id))
      }
      configActions.createNotificationFromFile = function (payload) {
        const formData = new FormData()

        for (const [key, value] of Object.entries(payload)) {
          formData.append(key, value)
        }

        return this.post(Model.$routes.notifications.createFormFile(), formData, {
          responseType: 'json',
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      }

      return configActions
    }
  }
}
