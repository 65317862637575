<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t(cardData.title) }}
    v-form(
      @submit.prevent.stop="submit"
      class="pt-4"
    )
      div()
        ValidationObserver(ref="form" slim)
          t-orm-fields(
            v-bind.sync="form"
            :items="fields"
          )
        div(v-if="qrList.length > 1")
          p(class="mb-1") {{$t('Found QR cash registers')}}:
          v-radio-group(
            class="mt-0 mb-2"
            v-model="defaultQr"
          )
            v-radio(
              v-for="qr in qrList"
              :key="qr.shortId"
              :label="qr.shortId"
              :value="qr.shortId"
              color="black"
              :ripple="false"
            )
      div(class="dialog__actions mt-2")
        v-btn(
          class="dialog__btn"
          @click="closeModal"
        ) {{ $t(cardData.cancelBtnText) }}
        v-btn(
          class="main-button dialog__btn"
          type="submit"
          :loading="loading"
        ) {{ $t(cardData.okBtnText) }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import contentDialog from '~/mixins/dialogs/contentDialog'
import AcquiringMono from '~/modules/acquiring/models/AcquiringMono'
import CashRegistersSearch from '~/modules/cashRegisters/models/CashRegistersSearch'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import acquiring from '~/modules/acquiring/mixins/acquiring'

export default {
  name: 'BlockAcquiringTerminalBindForm',
  components: {
    TOrmFields
  },
  mixins: [contentDialog, acquiring],
  props: {
    item: {
      type: Object,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    form: {
      cashRegister: null
    },
    defaultQr: null
  }),
  computed: {
    model () {
      return AcquiringMono
    },
    type () {
      const type = this._.get(this.item, 'type')
      if (type === this.model.itemTypes.internet.value) {
        return 'internetAcquiring'
      } else if (type === this.model.itemTypes.qr.value) {
        return 'qrAcquiring'
      } else if (type === this.model.itemTypes.tapToPhone.value) {
        return 'tapToMono'
      }
      return 'posTerminals'
    },
    isPosTerminals () {
      return this.type === 'posTerminals'
    },
    isTapToMono () {
      return this.type === 'tapToMono'
    },
    isQr () {
      return this.type === 'qrAcquiring'
    },
    isInternet () {
      return this.type === 'internetAcquiring'
    },
    cardData () {
      return this.cardsData[this.type]
    },
    cardsData () {
      return {
        posTerminals: {
          title: 'Прикріпити POS-термінал до каси',
          okBtnText: 'Прикріпити',
          cancelBtnText: 'Скасувати'
        },
        tapToMono: {
          title: 'Прикріпити Tap to mono до каси',
          okBtnText: 'Прикріпити',
          cancelBtnText: 'Скасувати'
        },
        qrAcquiring: {
          title: 'Прикріпити QR-еквайринг до каси',
          okBtnText: 'Прикріпити',
          cancelBtnText: 'Скасувати'
        },
        internetAcquiring: {
          title: 'Прикріпити Інтернет-еквайринг до каси',
          okBtnText: 'Прикріпити',
          cancelBtnText: 'Скасувати'
        }
      }
    },
    fields () {
      return [
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Cash register ',
          returnObject: true,
          itemClass: 'ws-pre',
          rules: 'required',
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (items && items.length === 1) {
              return items[0]
            }
          }
        }, CashRegistersSearch).build()
      ]
    },
    qrList () {
      return this._.get(this.item, 'qrList') || []
    }
  },
  created () {
    this.setDefaultQr()
  },
  methods: {
    setDefaultQr () {
      if (this.isQr && this._.get(this.qrList, 'length')) {
        this.$set(this.$data, 'defaultQr', this._.get(this.qrList, '[0].shortId'))
      }
    },
    async showSuccessModal () {
      let title = 'POS-термінал було успішно прикріплено до каси'
      if (this.isTapToMono) {
        title = 'Tap to mono було успішно прикріплено до каси'
      } else if (this.isQr) {
        title = 'QR-еквайринг було успішно прикріплено до каси'
      } else if (this.isInternet) {
        title = 'Інтернет-еквайринг було успішно прикріплено до каси'
      }
      await this.contentDialog.open({
        component: 'block-status-modal',
        width: '560px',
        componentProps: {
          title
        }
      })
    },
    generatePayload () {
      return {
        code: this._.get(this.item, 'code'),
        kassaId: this._.get(this.form, 'cashRegister.id'),
        cashierId: null
      }
    },
    async submit () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        const acquiringSettings = this._.get(this.form, 'cashRegister.acquiringSetting')
        const hasAcquiring = Boolean(this._.find(acquiringSettings, { type: this._.get(this.item, 'type') }))
        if (hasAcquiring && this._.get(this.$refs, 'form.setErrors')) {
          this.$refs.form.setErrors({
            cashRegister: 'До каси вже прикріплений еквайринг даного типу. Оберіть іншу касу'
          })
          return
        }

        const payload = this.generatePayload()
        await this.model.api().terminalBind(payload)

        if (this.isTapToMono) {
          await this.bindCheckboxTapToPhone({
            ptName: this._.get(this.item, 'terminal'),
            kassaId: this._.get(payload, 'kassaId', '')
          })
        } else if (this.isQr || this.isInternet) {
          await this.bindCheckboxQrOrInternetSettings({
            kassaId: this._.get(payload, 'kassaId'),
            qrList: this.isInternet ? [] : this.qrList,
            defaultQr: this.defaultQr,
            token: this._.get(this.item, 'integrationDetails.xToken'),
            type: this._.get(this.item, 'type', null),
            name: this._.get(this.item, 'name', null)
          })
        }

        await this.refreshTable()
        await this.showSuccessModal()
      } catch (e) {
        await this.refreshTable()
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
  }

  &__btn {
    width: 100%;
    max-width: 187px;
  }
}
</style>
