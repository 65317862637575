<template lang="pug">
  div(:class="{ 'support-buttons': true, 'mobile': mobile }")
    a(
      v-if="!showPhoneBtn"
      :href="`tel:${telLink}`"
      class="support-buttons__link"
      target="_blank"
    ) тел. {{ tel }} (07:00 - 01:00)
    div(
      v-for="(btn, key) in supportButtons"
      :key="key"
      v-if="btn.visible"
    )
      v-tooltip(v-if="btn.tooltip" bottom)
        template(#activator="{on}")
          v-btn(
            v-on="on"
            @click="callAction(btn.call)"
            class="support-buttons__btn"
            :x-small="smDevice"
            :small="!smDevice"
            style="height:35px"
            :id="btn.id"
            v-bind="$attrs"
            depressed
          )
            e-svg-icon(:size="iconSize || btn.iconSize || 'md'") {{ btn.icon }}
        | {{ btn.tooltip }}
      v-btn(
        @click="callAction(btn.call)"
        class="support-buttons__btn"
        :x-small="smDevice"
        :small="!smDevice"
        style="height:35px"
        :id="btn.id"
        v-bind="$attrs"
        depressed
        v-else
      )
        e-svg-icon(:size="iconSize || btn.iconSize || 'md'") {{ btn.icon }}
</template>

<script>
import responsive from '~/mixins/pages/responsive'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import contentDialog from '~/mixins/dialogs/contentDialog'
import MonobankAuth from '~/models/auth/MonobankAuth'

export default {
  components: {
    ESvgIcon
  },
  mixins: [responsive, contentDialog],
  props: {
    showPhoneBtn: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: String,
      default: ''
    },
    checkboxSupport: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showChatWidget: false,
    deepLink: ''
  }),
  computed: {
    tel () {
      if (this.checkboxSupport) {
        return '0 800 330 676'
      }
      return '0 800 202 602'
    },
    telLink () {
      return 'tel:+38' + this.tel.replace(/\s/g, '')
    },
    supportButtons () {
      return [
        {
          icon: this.mobile ? 'telegram-2' : 'telegram',
          tooltip: 'Telegram',
          call: () => this.openLink(this.checkboxSupport ? 'https://t.me/checkboxua_bot' : `https://t.me/monopay_acq_bot?start=${this.deepLink}`),
          visible: true
        },
        {
          icon: this.mobile ? 'viber-2' : 'viber',
          tooltip: 'Viber',
          iconSize: 'lg',
          call: () => this.openLink(this.checkboxSupport ? 'viber://pa?chatURI=checkboxua' : `viber://pa?chatURI=monopay&context=${this.deepLink}`),
          visible: true
        },
        {
          icon: 'phone',
          iconSize: 'xmd',
          call: () => this.openLink(this.telLink),
          visible: this.showPhoneBtn
        }
      ]
    }
  },
  mounted () {
    this.getDeepLink()
  },
  methods: {
    async openReviewForm () {
      await this.contentDialog.open({
        component: 'block-review-form',
        width: '600px'
      })
    },
    async getDeepLink () {
      this.deepLink = this._.get(await MonobankAuth.api().deepLink(), 'response.data.result')
    },
    openLink (url) {
      window.open(url, '_blank')
    },
    callAction (action) {
      if (this._.isFunction(action)) {
        action()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.support-buttons {
  display: flex;
  align-items: center;
  gap: 5px;

  &__link {
    color: rgba(0, 0, 0, 0.6) !important;
    text-align: center;
    font-size: .8rem;
    text-decoration: none;
    margin-right: 7px;
  }

  &.mobile {
    flex-wrap: wrap;
    gap: 20px;

    .support-buttons {
      &__link {
        order: 1;
        margin-right: 0;
        width: 100%;
        display: block;
        text-align: left;
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: 13px;
      }

      &__btn {
        background-color: rgba(240, 241, 242, 0.2) !important;
      }
    }
  }
}
</style>
