import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import Oschadbank from '~/modules/acquiring/models/Oschadbank'

export class Acquiring extends PersistAble(OrmModel) {
  static entity = 'acquiring'
  static paginated = true
  static defaultSortOrder = true

  static ormTrans = {
    single: 'Acquiring',
    multy: 'Acquiring'
  }

  static fields () {
    return {
      id: this.attr(null),
      cashRegister: this.attr(null),
      merchant: this.attr(null),
      ptName: this.attr(null),
      oschadbankAcquiringOrder: this.attr(null),
      monoId: this.attr(null),
      isActive: this.attr(null)
    }
  }

  get cashRegisterString () {
    const cashRegister = this.cashRegister
    return cashRegister ? `№${cashRegister.number} (${cashRegister.fiscalNumber})` : '-'
  }

  get merchantClientLogin () {
    return this.merchant.clientLogin || '-'
  }

  get ptNameString () {
    return this.ptName || '-'
  }

  get statusString () {
    if (this.oschadbankAcquiringOrder?.status === Oschadbank.STATUSES.success && !this.isActive) {
      return 'active'
    } else if (this.oschadbankAcquiringOrder?.status === Oschadbank.STATUSES.success && this.isActive) {
      return 'connected'
    }
    return this.oschadbankAcquiringOrder?.status || (this.isActive ? 'active' : 'deactivated')
  }

  static ormHeaders = [
    { text: 'Status', value: 'statusString', sortable: false, filterable: false },
    { text: 'Bank', value: 'merchant.provider.name', sortable: false, filterable: false },
    { text: 'Login ', value: 'merchantClientLogin', sortable: false, filterable: false },
    { text: 'Terminal ID', value: 'ptNameString', sortable: false, filterable: false },
    { text: 'Connected to cash register', value: 'cashRegisterString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormMobileTitle = 'cashRegisterString'

  static ormColsComponents = {
    statusString: {
      component: 'block-tap-to-phone-status'
    },
    cashRegisterString: {
      component: 'block-tap-to-phone-cash-register-status'
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'edit',
      visible: (item) => {
        return item.merchant?.clientLogin || item.ptName || item.cashRegister
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-tap-to-phone-settings-form',
          width: '650px',
          componentProps: {
            item,
            isEditing: true,
            isDialogPart: true
          }
        })
      }
    },
    {
      name: 'delete',
      call: (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        dialog.open({
          title: 'Are you sure you want to delete TAP2Phone settings?',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            await Acquiring.api().del(item)
            AppNotifications.success('TAP2Phone setup successfully deleted')
          }
        })
      }
    }
  ]

  static ormDialogs = {
    delete: 'm-orm-delete-dialog'
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getMonobankTerminals = function (payload) {
        return this.post(Model.$routes.acquiring.monoTerminals(), payload, { save: false })
      }
      configActions.oschad = function (payload) {
        return this.post(Model.$routes.acquiring.oschad(), payload, { save: false })
      }
      configActions.oschadUnlink = function (payload) {
        return this.post(Model.$routes.acquiring.oschadUnlink(), payload, { save: false })
      }
      return configActions
    }
  }
}

export default Acquiring
