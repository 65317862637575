<template lang="pug">
  ValidationObserver(ref="validator"  slim)
    v-form(@submit.prevent.stop="saveToken")
      v-row(no-gutters)
        v-col(cols="12")
          ValidationProvider(mode="passive" :name="$t('NP token')" vid="token" v-slot="{ errors }")
            v-text-field(v-model="token" outlined=true :placeholder="$t('NP token')" :error-messages="errors")
      v-row(justify="center")
        v-col(cols="auto")
          v-btn(class="main-button mb-2" type="submit" :loading="loading")
            | {{ $t('Save') }}
</template>

<script>
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'

export default {
  props: {
    cashRegisterId: {
      type: String,
      required: true
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false,
    token: null
  }),
  computed: {
    cashRegister () {
      return CashRegisters.query().whereId(this.cashRegisterId).first()
    }
  },
  created () {
    this.token = this._.get(this.cashRegister, 'ettnSettings.token', null)
  },
  methods: {
    async saveToken () {
      const valid = await this.$refs.validator.validate()
      if (!valid) { return null }

      const payload = {
        ettn: {
          token: this.token || null
        }
      }

      try {
        this.loading = true
        await CashRegisters.api().updateSettings(this.cashRegister, payload)
        this.$notification.success(this.$t('NP token was saved'))
        this.closeModal(true)
      } catch (e) {
        this.$handlers.error(e, this.$refs.validator)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//
</style>
