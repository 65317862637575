<template lang="pug">
  v-autocomplete(
    v-bind="$attrs"
    :items="items"
    item-text="name"
    :label="$t('Organizations')"
    @change="handleChange"
    dense
    flat
    outlined
    multiple
    return-object
  )
</template>

<script>
export default {
  name: 'EInputOrganizationsSelect',
  computed: {
    managedOrganizations () {
      return this.$User.managedOrganizations
    },
    ownedOrganization () {
      return this.$User.ownedOrganization
    },
    allOrganizationsItem () {
      const organizationsIds = this._.map(this.listOrganizations, i => i?.id)
      return {
        name: this.$t('All organizations'),
        value: organizationsIds,
        allOrganizationsItem: true
      }
    },
    listOrganizations () {
      const arr = []
      if (this.ownedOrganization) {
        arr.push(this.ownedOrganization)
      }
      return arr.concat(this.managedOrganizations).sort((a, b) => a.name.localeCompare(b.name))
    },
    items () {
      return [
        this.allOrganizationsItem,
        ...this.listOrganizations
      ]
    }
  },
  created () {
    this.$emit('input', [this.allOrganizationsItem])
  },
  methods: {
    handleChange (val) {
      const allOrgsItemSelected = this._.find(val, i => i?.allOrganizationsItem)
      if (allOrgsItemSelected) {
        if (val[0].allOrganizationsItem && val.length !== 1) {
          this.$emit('input', this._.filter(val, i => !i?.allOrganizationsItem))
        } else {
          this.$emit('input', [this.allOrganizationsItem])
        }
      } else if (!val.length || val.length === this.allOrganizationsItem.value.length) {
        this.$emit('input', [this.allOrganizationsItem])
      } else {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
