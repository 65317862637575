<template lang="pug">
  div(class="dialog")
    block-cash-register-type-instructions(
      v-if="showTypeInstructions"
      @back="showTypeInstructions = false"
    )
    ValidationObserver(
      :ref="model.entity"
      slim
      v-else
    )
      h3(class="dialog__title") {{ $t('Add cash register') }}
      v-form(@submit.prevent.stop="saveAndSendToTax")
        t-orm-fields(v-bind.sync="editedItem"
          :items="fields"
          :tabs="model.ormTabs"
        )
        div(class="dialog__actions")
          div(
            class="dialog__link"
            @click="showTypeInstructions = true"
          ) Як обрати тип каси
          v-btn(
            type="submit"
            :loading="btnLoading"
            class="main-button"
          ) {{ $t('Add cash register') }}
</template>

<script>
import Base64js from 'base64-js'
import TOrmFields from '~/components/templates/orm/t-orm-fields.vue'
import validate from '~/mixins/validation/validate'
import Organization from '~/models/directories/Organization'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import taxAuth from '~/mixins/pages/tax-auth'
import { CashRegisterOrder } from '~/modules/cashRegisters/models/CashRegisterOrder'
import signPluginForOrganization from '~/mixins/dialogs/signPluginForOrganizationDialog'
import { TaxReportService } from '~/services/Tax/Report/TaxReportService'
import TaxAuthService from '~/services/Tax/Auth/TaxAuthService'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import BlockCashRegisterTypeInstructions from '~/modules/cashRegisters/views/components/block-cash-register-type-instructions.vue'
import CashRegisters from '~/modules/cashRegisters/models/CashRegisters'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  components: {
    BlockCashRegisterTypeInstructions,
    TOrmFields
  },
  mixins: [validate, filterOrmObject, taxAuth, signPluginForOrganization, confirmationDialog, contentDialog],
  data: () => ({
    editedItem: {
      M04: null,
      outlet: null
    },
    itemLoading: false,
    showTypeInstructions: false
  }),
  computed: {
    model () {
      return CashRegisterOrder
    },
    fields () {
      return this._.get(this.model, 'ormFields', [])
    },
    organization () {
      return Organization.query().whereId(this.$User.organization[Organization.primaryKey]).first()
    },
    btnLoading () {
      return this.itemLoading || this._.get(this.iitDialog, 'options.loading', false)
    }
  },
  methods: {
    close () {
      this.$emit('dialog:close', null)
    },
    async getCashRegisterNumber () {
      const lastCode = this._.get(await CashRegisters.api().lastPrimaryKey(), 'response.data.number', null)
      return lastCode ? (lastCode + 1) + '' : ''
    },
    async showSuccessModal () {
      await this.contentDialog.open({
        component: 'block-status-modal',
        width: '560px',
        componentProps: {
          title: 'Ви успішно додали касу',
          btnText: 'Продовжити',
          catType: '7'
        }
      })
    },
    async saveAndSendToTax () {
      if (!await this.validate()) {
        return false
      }
      try {
        this.itemLoading = true
        const payload = this.filterOrmObject(this.editedItem)
        if (!payload.outlet.name) {
          const confirmedClosing = await this.confirmationDialog.open({
            title: this.$t('Confirmation cash register creating'),
            text: this.$t('Outlet without name'),
            width: '490px'
          })
          if (!confirmedClosing) {
            this.close()
            return
          }
        }

        payload.R04G12S = await this.getCashRegisterNumber()
        payload.R04G11S = `checkbox_${payload.R04G12S}`
        await this.signPluginForOrganization.use()
        const keyData = this._.get(this.signPluginForOrganization, 'interface.keyData', null)
        const sign = this._.get(this.signPluginForOrganization, 'interface.sign')
        const edsKeyData = new EdsKeyData(keyData)

        if (!keyData) {
          return this.signPluginForOrganization.close()
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          await this.signPluginForOrganization.close()
          return this.saveAndSendToTax()
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          await this.signPluginForOrganization.close()
          return this.saveAndSendToTax()
        }

        try {
          const res = await TaxAuthService.sendAuthData(keyData, data => sign(data, { fallbackMethod: this.saveAndSendToTax }))
          if (!res) {
            return null
          }
        } catch (e) {
          this.$handlers.error(e, this, true)
        }

        const reportService = new TaxReportService(this.signPluginForOrganization.interface, this.saveAndSendToTax)
        payload.HKBOS = reportService.getHKBOSField({
          ctx: this,
          entity: this._.get(this.model, 'entity'),
          type: 'creation'
        })

        const re = /([0-9]{10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}|[0-9]{9})/

        if (!re.test(payload.HKBOS)) {
          this.$handlers.error(this.$t('It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents'), this)
          return
        }

        if (!payload.HKBOS) {
          this.$handlers.error(this.$t('We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key'), this)
          return
        }

        const createdEntity = this._.get(await this.model.api().create(payload), `entities.${this.model.entity}[0]`, null)
        const xmlResponse = await this.model.api().xmlDoc(createdEntity)
        const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
        const fname = this._.get(xmlResponse, 'response.data.fname', null)
        const signData = await reportService.getEnvelopedData(xml)

        if (!signData) {
          return null
        }

        const taxId = this._.get(await this.model.api().taxRegister(createdEntity, { signData, fname }), 'response.data.response.taxId', null)
        const encodedTaxId = new TextEncoder().encode(String(taxId))
        const signedTaxId = await reportService.getEnvelopedData(encodedTaxId)

        if (!signedTaxId) {
          return null
        }

        await this.model.api().signTaxId(createdEntity, { tax_id: signedTaxId })

        await this.signPluginForOrganization.hide()
        this.showSuccessModal()
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
        await this.signPluginForOrganization.hide()
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 5px 0 20px;
  }

  &__link {
    cursor: pointer;
    font-weight: 300;
    font-size: 14px;
    color: $e-link-color;
  }
}
</style>
