<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('Employees key replacement') }}
    v-form(@submit.prevent.stop="onSubmit")
      ValidationObserver(ref="form" slim)
        t-orm-fields(
          v-bind.sync="editedItem"
          :items="fields"
        )
        v-radio-group(v-model="uploadCashierEdsKey" class="radio-group mt-0 pt-0" row)
          template(v-slot:label)
            span(class="hint") {{$t('Launch Checkbox Signature on a secure cloud service')}}?
          v-radio(:ripple="false" color="black" key="yes" :value="true")
            template(v-slot:label)
              span(class="hint") {{$t('yes')}}
          v-radio(:ripple="false" color="black" key="no" :value="false" :label="$t('no')")
            template(v-slot:label)
              span(class="hint") {{$t('no')}}
      div(class="dialog__actions")
        v-btn(
          class="main-button d-block mx-auto"
          :loading="loading"
          type="submit"
      ) {{ $t('Save') }}
</template>

<script>
import Base64js from 'base64-js'
import MFormBlock from '~/components/modules/blocks/m-form-block'
import Employees from '~/modules/employees/models/Employees'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import SignPluginService from '~/services/SignPluginService/SignPluginService'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'

export default {
  name: 'BlockChangeEmployeeEdsKey',
  components: {
    MFormBlock,
    TOrmFields
  },
  props: {
    item: {
      type: null,
      default: null
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false,
    uploadCashierEdsKey: true,
    editedItem: {
      ca: null,
      keyFile: null,
      keyPassword: null
    },
    fields: [
      {
        model: 'ca',
        component: 'v-select',
        provider: {
          vid: 'ca',
          name: 'Qualified provider trust services',
          rules: 'required'
        },
        attrs: {
          outlined: true,
          label: 'Qualified provider trust services',
          itemText: 'issuerCNs[0]',
          itemValue: 'address'
        },
        // default: () => null,
        request: async (ctx) => {
          const data = await fetch(Employees.$routes.acskProviders)
          return await data.json()
          // const response = await data.json()
          // return [{ address: null, issuerCNs: [ctx.$i18n.t('Autoselect ACSK Provider')] }, ...response]
        }
      },
      {
        model: 'keyFile',
        component: 'e-input-file-content',
        provider: {
          vid: 'keyFile',
          name: 'Select the EDS key',
          rules: 'required'
        },
        attrs: {
          outlined: true,
          label: 'Select the EDS key',
          prependIcon: '',
          appendIcon: '$file',
          accept: '.dat,.pfx,.pk8,.zs2,.jks',
          hideProvider: true,
          expect: 'arrayBuffer'
        },
        hint: 'Possible formats: *.dat, *.pfx, *.pk8, *.zs2, *.jks'
      },
      {
        model: 'keyPassword',
        component: 'e-input-password',
        provider: {
          vid: 'keyPassword',
          name: 'Enter the EDS key password',
          rules: 'required'
        },
        attrs: {
          type: 'password',
          outlined: true,
          label: 'Enter the EDS key password'
        }
      }
    ]
  }),
  computed: {},
  methods: {
    async checkKey (data) {
      const payload = {
        ca: data.ca,
        keyPassword: data.keyPassword,
        keyFile: Base64js.fromByteArray(new Uint8Array(data.keyFile))
      }
      return this._.get(await Employees.api().checkKey(payload), 'response.data', null)
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        const keyData = await this.checkKey(this.editedItem)

        await EmployeeOrder.api().validateReplacementKey(this._.get(this.item, 'id'), {
          keyReplacementId: this._.get(keyData, 'key_id')
        })

        const DRFOCode = this._.get(this.item, 'T1RXXXXG2S') || this._.get(this.$Organization, 'edrpou', null) || this._.get(this.$Organization, 'taxNumber', null)
        const deletedOrder = await SignPluginService.sendEntityTaxOrder({
          type: 'modify',
          entity: this.item,
          orderModel: EmployeeOrder,
          model: Employees,
          organization: this.$Organization,
          data: {
            T1RXXXXG4S: EmployeeOrder.itemTypes.deactivate.value,
            T1RXXXXG1S: this._.get(this.item, 'name', null),
            T1RXXXXG2S: DRFOCode,
            employee: `/employees/${this._.get(this.item, 'id')}`
          },
          showSuccessMessage: false,
          ctx: this,
          signPluginParams: {
            showEmployeeHint: true
          }
        })

        if (!deletedOrder) {
          this.$notification.error(this.$t('An error occurred when deleting the cashier. Please try again or contact an administrator'))
          return
        }

        const replacementPayload = {
          T1RXXXXG1S: this._.get(this.item, 'name', null),
          T1RXXXXG4S: EmployeeOrder.itemTypes.head_cashier.value,
          T1RXXXXG3S: this._.get(keyData, 'key_id'),
          signatureType: 'AGENT',
          T1RXXXXG2S: DRFOCode,
          HPOST: 'Директор',
          deletedOrder: `employee-tax-orders/${this._.get(deletedOrder, 'id')}`,
          ca: this._.get(keyData, 'certificate_info.certificate.pszIssuerCN', null),
          certificateStart: this._.get(keyData, 'certificate_start', null),
          certificateEnd: this._.get(keyData, 'certificate_end', null),
          certificateInfo: this._.get(keyData, 'certificate_info.certificate', null)
        }

        if (this.uploadCashierEdsKey) {
          replacementPayload.keyFileCa = this._.get(this.editedItem, 'ca', null)
          replacementPayload.keyPassword = this._.get(this.editedItem, 'keyPassword', null)
          replacementPayload.keyFile = Base64js.fromByteArray(new Uint8Array(this._.get(this.editedItem, 'keyFile', null)))
        }

        await SignPluginService.sendEntityTaxOrder({
          type: 'keyReplacement',
          entity: this.item,
          orderModel: EmployeeOrder,
          model: Employees,
          organization: this.$Organization,
          data: replacementPayload,
          ctx: this,
          signPluginParams: {
            showEmployeeHint: true
          }
        })

        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.radio-group {
  &::v-deep {
    legend.v-label {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
</style>
