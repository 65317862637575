<template lang="pug">
  div(class="a-bank-not-a-client")
    p(class="a-bank-not-a-client__text") {{ $t('Unfortunately, you are not yet a client of A-Bank, so to order a POS terminal you need to perform several steps:') }}
    ul(class="a-bank-not-a-client__list")
      li(class="a-bank-not-a-client__text a-bank-not-a-client__list-item")
        span
          | Встановіть
          |
          a(href="https://a-bank.com.ua/services/abank" target="_blank") мобільний застосунок ABank24
          |  та пройдіть реєстрацію
        img(
          src="~assets/images/a-bank-app-qr.svg"
          class="d-block mt-3 ml-9 w-100"
          style="max-width: 125px"
        )
      li(class="a-bank-not-a-client__text a-bank-not-a-client__list-item") {{ $t('Sign the form about opening a FOP account') }}
      li(class="a-bank-not-a-client__text a-bank-not-a-client__list-item") {{ $t('Sign the form for connecting acquiring') }}
</template>

<script>
export default {
  name: 'BlockABankNotAClient',
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    }
  },
  created () {
    this.changeTitle('Add POS-terminal')
    this.toggleCloseBtn(true)
  }
}
</script>

<style scoped lang="scss">
.a-bank-not-a-client {
  background-color: #E5FEE7;
  padding: 20px;
  border-radius: 10px;

  &__text {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: #000;
    margin-bottom: 20px;
  }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__list-item {
    position: relative;

    &:before {
      content: '1';
      border-radius: 50%;
      border: 1px solid #000;
      width: 20px;
      height: 20px;
      margin-right: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &:nth-child(2) {
      &:before {
        content: '2'
      }
    }

    &:nth-child(3) {
      &:before {
        content: '3'
      }
    }
  }
}
</style>
