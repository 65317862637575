<template lang="pug">
  div(class="dialog")
    div(class="dialog__title mb-3") {{ $t('Unlink terminal') }}
    v-form(@submit.prevent.stop="send")
      ValidationObserver(ref="form" slim)
        t-orm-fields(
          v-bind.sync="form"
          :items="fields"
        )
      div(class="dialog__actions text-center")
        v-btn(
          class="main-button"
          type="submit"
          :loading="loading"
        ) {{ $t('Unlink') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields.vue'
import Merchants from '~/modules/acquiring/models/Merchants'
import Acquiring from '~/modules/acquiring/models/Acquiring'

export default {
  name: 'BlockOschadbankUnlinkTerminal',
  components: {
    TOrmFields
  },
  data: () => ({
    form: {
      terminal: null
    },
    terminalsLoading: false,
    loading: false,
    terminals: []
  }),
  computed: {
    model () {
      return Acquiring
    },
    merchantModel () {
      return Merchants
    },
    oschadProviderId () {
      return '8394d703-1d77-4e79-832f-f9ba030e2a7d'
    },
    fields () {
      return [
        {
          model: 'terminal',
          component: 'v-select',
          provider: {
            vid: 'terminal',
            name: 'Available terminals',
            rules: 'required'
          },
          attrs: {
            label: 'Available terminals',
            outlined: true,
            returnObject: true,
            itemText: (i) => {
              let str = this._.get(i, 'pt_name')
              const serialNumber = this._.get(i, 'serial_number')
              if (serialNumber) {
                str += ` (${serialNumber})`
              }
              return str
            },
            disabled: this.terminalsLoading,
            loading: this.terminalsLoading
          },
          items: () => this.terminals
        }
      ]
    }
  },
  created () {
    this.getTerminals()
  },
  methods: {
    async getTerminals () {
      try {
        this.terminalsLoading = true
        await this.merchantModel.api().all()
        const merchant = this.merchantModel.query().where('provider', i => i?.providerId === this.oschadProviderId).first()
        this.terminals = this._.get(await this.model.api().oschad({
          login: this._.get(merchant, 'clientLogin', null),
          all: true
        }), 'response.data.terminals', [])
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.terminalsLoading = false
      }
    },
    async send () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        await this.model.api().oschadUnlink({
          ped_id: this._.get(this.form, 'terminal.ped_id', null)
        })
        this.$notification.success(this.$t('Terminal successfully unlinked'))
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
