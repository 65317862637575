import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import moment from 'moment'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import { contexts, formats } from '~/const/global'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'

export class EmployeeOrder extends PersistAble(OrmModel) {
  static entity = 'employeeorder'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'dateSend'
  static defaultSortOrder = true
  static dblClickAction = 'read'

  static signTypes = {
    agent: {
      text: 'AGENT',
      value: 'AGENT'
    },
    depositsign: {
      text: 'DEPOSITSIGN',
      value: 'DEPOSITSIGN'
    }
  }

  static itemTypes = {
    cashier: {
      text: 'Касир',
      value: 'Касир',
      mode: 100
    },
    head_cashier: {
      text: 'Старший касир',
      value: 'Старший касир',
      mode: 100
    },
    deactivate: {
      text: 'Припинення роботи',
      value: 'Припинення роботи',
      mode: 200
    }
  }

  static orderStatuses = {
    100: {
      text: 'Створено',
      value: 100,
      type: 'order'
    },
    200: {
      text: 'На реєстрації в дпс',
      value: 200,
      type: 'order'
    },
    300: {
      text: 'Зареєстровано',
      value: 300,
      type: 'checkbox'
    },
    400: {
      text: 'Помилка',
      value: 400,
      type: 'error'
    }
  }

  static dsKeyStatuses = {
    PENDING: {
      text: 'Verification',
      value: 'PENDING'
    },
    REQUEST_PROCESSED: {
      text: 'The key is generated',
      value: 'REQUEST_PROCESSED'
    },
    APPLICATION_RECEIVED: {
      text: 'A signature is required',
      value: 'APPLICATION_RECEIVED'
    },
    APPLICATION_SIGNED: {
      text: 'Signed',
      value: 'APPLICATION_SIGNED'
    },
    APPLICATION_EXPIRED: {
      text: 'Deadline overdue',
      value: 'APPLICATION_EXPIRED'
    },
    DONE: {
      text: 'Token received',
      value: 'DONE'
    },
    ERROR: {
      text: 'Error',
      value: 'ERROR'
    }
  }

  static orderTypes = {
    100: {
      text: 'Створення',
      value: 100,
      type: 'checkbox'
    },
    200: {
      text: 'Оновлення',
      value: 200,
      type: 'order'
    },
    300: {
      text: 'Видалення',
      value: 300,
      type: 'error'
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      // name
      T1RXXXXG1S: this.attr(null),
      // DRFO
      T1RXXXXG2S: this.attr(null),
      // Public key
      T1RXXXXG3S: this.attr(null),
      // Type
      T1RXXXXG4S: this.attr(null),
      // Директор
      HPOST: this.attr(null),
      login: this.attr(null),
      password: this.attr(null),
      signatureType: this.attr(null),
      // Deposit sign token
      dsToken: this.attr(null),
      // Deposit sign password
      dsPass: this.attr(null),
      dateSend: this.attr(null),
      status: this.attr(null),
      type: this.attr(null),
      hasErrorFile: this.attr(null),
      taxErrorMessage: this.attr(null),
      depositSignRequest: this.attr(null),
      certificateEnd: this.attr(null),
      // Fields for sending to the TAX
      HBOS: this.attr(null),
      HEXECUTOR: this.attr(null),
      HFILL: this.attr(null),
      HKBOS: this.attr(null),
      HNAME: this.attr(null),
      HNUM: this.attr(null),
      HSTI: this.attr(null),
      HTEL: this.attr(null),
      HTIN: this.attr(null),
      M01: this.attr(null),
      dateCreated: this.attr(null),
      declarHead: this.attr(null),
      employee: this.attr(null),
      response: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Employee ',
    multy: 'Employees',
    notificationUpdate: 'Employee data',
    notification: 'Employee'
  }

  get emptyPlaceholder () {
    return '-'
  }

  get certificateEndAsString () {
    return this.getDateTime(this.certificateEnd) || this.emptyPlaceholder
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', tableHeader: 'modeString', align: 'left', width: '150', sortable: true, filterable: false },
    { text: 'Employee login', value: 'login', filterable: true },
    { text: 'Full name', value: 'T1RXXXXG1S', tableHeader: 'name', filterable: true, sortable: true },
    { text: 'EDS key', value: 'edsKey', filterable: false, sortable: false },
    { text: 'Key expire date', value: 'certificateEndAsString', filterable: true, sortable: true },
    { text: 'Pin code ', value: 'emptyPlaceholder', tableHeader: 'pinCode', filterable: false, sortable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) {
            return {}
          }
          const orderStatus = this.orderStatuses[item.status]

          return {
            id: item.id,
            model: EmployeeOrder,
            [item.status]: {
              text: orderStatus.text,
              type: orderStatus.type,
              taxErrorMessage: item.taxErrorMessage,
              hasErrorFile: item.hasErrorFile
            }
          }
        }
      }
    },
    edsKey: {
      component: 'block-employee-eds-key-status',
      attrs: {
        isOrder: true
      }
    }
  }

  static ormFields = [
    {
      model: 'T1RXXXXG1S',
      component: 'v-text-field',
      provider: {
        vid: 'T1RXXXXG1S',
        name: 'Specify the name of the cashier',
        rules: 'required|max:150'
      },
      attrs: {
        disabled: ctx => ctx.context === contexts.read,
        label: 'Specify the name of the cashier',
        type: 'text',
        outlined: true
      }
    },
    {
      model: 'login',
      component: 'v-text-field',
      provider: {
        vid: 'login',
        name: 'Login ',
        rules: 'latin_dashes|required|max:20'
      },
      attrs: {
        disabled: ctx => ctx.context === contexts.read,
        label: 'Login ',
        type: 'text',
        outlined: true
      },
      label: {
        text: 'Come up with password and login for cashier',
        className: 'mb-4'
      },
      cols: {
        cols: 12
      }
    },
    {
      model: 'password',
      component: 'e-input-password',
      provider: {
        vid: 'password',
        name: 'Password',
        rules: 'required|min:6|max:50'
      },
      attrs: {
        label: 'Password',
        type: 'password',
        outlined: true,
        autocomplete: 'new-password',
        showPasswordStrength: true,
        hasHint: true
      }
    },
    {
      model: 'T1RXXXXG4S',
      component: 'v-select',
      fieldVal: () => this.itemTypes.head_cashier.value,
      provider: {
        vid: 'T1RXXXXG4S',
        name: 'Choose the type of cashier',
        rules: 'required'
      },
      attrs: {
        visible: false
      },
      items: () => Object.values(this.itemTypes)
    },
    {
      model: 'T1RXXXXG2S',
      component: 'v-text-field',
      provider: {
        vid: 'T1RXXXXG2S',
        name: 'DRFOCode',
        rules: 'required|inn_passport'
      },
      attrs: {
        visible: false
      }
    },
    {
      model: 'T1RXXXXG3S',
      component: 'v-text-field',
      provider: {
        vid: 'T1RXXXXG3S',
        name: 'Specify the EDS key ID',
        rules: 'required'
      },
      attrs: {
        label: 'Specify the EDS key ID',
        type: 'text',
        outlined: true,
        visible: false
      },
      hint: {
        text: 'How to find the key id –',
        linkText: 'Instructions',
        link: 'https://docs.google.com/document/d/1oARtTaDOeco2McGeeqNVIWxJeVzyjhyJgB4VDlHrdC8'
      }
    },
    {
      model: 'signatureType',
      component: 'v-select',
      fieldVal: () => this.signTypes.agent.value,
      provider: {
        vid: 'signatureType',
        name: 'Signature type',
        rules: 'required'
      },
      attrs: {
        visible: false
      },
      items: () => Object.values(this.signTypes)
    },
    {
      model: 'HPOST',
      component: 'v-text-field',
      provider: {
        vid: 'HPOST',
        name: 'HPOST',
        rules: 'required'
      },
      attrs: {
        visible: false
      }
    }
  ]

  static ormActions = [
    {
      name: 'delete',
      visible: (item) => {
        const hasExpiredDsApplication = get(item, 'depositSignRequest.status') === EmployeeOrder.dsKeyStatuses.APPLICATION_EXPIRED.value
        return item.status === EmployeeOrder.orderStatuses['400'].value || hasExpiredDsApplication
      },
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        const confirmed = await confirmationDialog.open({
          title: 'Delete employee',
          text: 'Are you sure to delete this order?',
          width: '420px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          }
        })
        if (confirmed) {
          try {
            await EmployeeOrder.api().del(item)
            AppNotifications.success('Error order was deleted successfully')
          } catch (e) {
            // TODO add logs service and logs errors
            const message = this._.get(e, 'response.data.detail', null) || this._.get(e, 'response.data.message', null)
            AppNotifications.error(message)
          }
        }
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      notification: item => ({ type: 'order ', name: item.name })
    },
    read: {
      title: item => ({ type: 'employee', name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'dateSend',
            label: 'Date of created',
            value: val => moment(val).format(formats.dateTimeSec)
          },
          {
            model: 'login',
            label: 'Login '
          },
          {
            model: 'T1RXXXXG1S',
            label: 'Full name'
          },
          {
            model: 'T1RXXXXG4S',
            label: 'Cashier type'
          },
          {
            model: 'T1RXXXXG2S',
            label: 'DRFO Code'
          },
          {
            model: 'signatureType',
            label: 'Signature type',
            value: val => this._.get(Object.values(this.signTypes).find(item => item.value === val), 'text', null)
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.xmlDoc = function (model) {
        return this.get(Model.$routes.employeeorder.xmlDoc(model.$id), { save: false })
      }
      configActions.taxRegister = function (model, payload) {
        return this.post(Model.$routes.employeeorder.taxRegister(model.$id), payload)
      }
      configActions.signTaxId = function (model, payload) {
        return this.put(Model.$routes.employeeorder.signTaxId(model.$id), payload)
      }
      configActions.validateReplacementKey = function (id, payload) {
        return this.put(Model.$routes.employeeorder.validateReplacementKey(id), payload)
      }
      configActions.modifyInTax = function (payload) {
        return this.post(Model.$routes.employeeorder.modifyInTax(), payload)
      }
      configActions.keyReplacement = function (payload) {
        return this.post(Model.$routes.employeeorder.keyReplacement(), payload)
      }
      configActions.getActive = function () {
        EmployeeOrder.deleteAll()
        return this.all()
      }
      configActions.depositSignRequest = function (id, payload) {
        return this.put(Model.$routes.employeeorder.depositSignRequest(id), payload, { save: false })
      }
      configActions.changeDsPassword = function (id, payload) {
        return this.put(Model.$routes.employeeorder.changeDsPassword(id), payload, { save: false })
      }
      configActions.depositSignRequestRead = function (id) {
        return this.get(Model.$routes.employeeorder.depositSignRequests(id), { save: false })
      }
      configActions.allowedDsRequest = function () {
        return this.get(Model.$routes.employeeorder.allowedDsRequest(), { save: false })
      }
      return configActions
    }
  }
}

export default EmployeeOrder
