import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import informationDialog from '~/mixins/dialogs/informationDialog'

export class Taxes extends ChainInheritance(Directory, [PersistAble]) {
  static entity = 'taxes'

  static fields () {
    return {
      id: this.attr(null),

      symbol: this.attr(null),
      label: this.attr(null),
      rate: this.attr(null),
      extraRate: this.attr(null),
      isDefault: this.attr(null),
      noVat: this.attr(null),
      extendedTaxCode: this.attr(null),
      code: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Tax ',
    multy: 'Taxes'
  }

  static ormHeaders = [
    { text: 'Letter', value: 'symbol', align: 'left', sortable: false, filterable: false },
    { text: 'Tax rate', value: 'rate', align: 'left', sortable: true, filterable: false },
    { text: 'Tax name', value: 'label' },
    { text: 'Extra rate', value: 'extraRate', align: 'left', filterable: true, sortable: true },
    { text: 'Tax rate code', value: 'code', align: 'left', filterable: true, sortable: true },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormMobileTitle = 'label'

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['code'],
      'sort-desc': [true]
    })
  }

  static ormActions = [
    {
      name: 'edit'
    },
    {
      name: 'delete'
    }
  ]

  static ormDialogs = {
    default: 'tax-rates-form-dialog',
    edit: 'tax-rates-form-dialog',
    delete: 'm-orm-delete-dialog'
  }

  static ormDialogsConfig = {
    default: {
      config: {
        context: 'create',
        hooks: {
          afterSave: async (data) => {
            if (data.instance.extraRate > 0) {
              await informationDialog.computed.informationDialog().open({ text: 'УВАГА! При використанні акцизного податку, обов\'язково необхідно оформити ліцензію на продаж алкоголю чи тютюну.', width: '600px' })
            }
          }
        }
      }
    },
    edit: {
      config: {
        context: 'update'
      }
    },
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.symbol }),
      notification: item => ({ type: this.ormTrans.single, name: item.symbol })
    }
  }
}

export default Taxes
