<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title mb-3") {{ $t(title) }}
    div(class="dialog__content")
      block-tap-to-phone-connecting-options(
        v-if="showTapToPhoneOptions"
        @showTapToPhoneSettingsForm="onShowTapToPhoneSettingsForm"
        @showBankSelectionForm="onShowBankSelectionForm"
      )
      block-tap-to-phone-settings-form(
        v-else-if="showTapToPhoneSettingsForm"
        :close-modal="closeModal"
        :is-editing="isEditing"
        :item="item"
      )
      block-tap-to-phone-bank-selection(
        v-else-if="showBankSelectionForm"
        @changeSelectedBank="onChangeSelectedBank"
        @showTapToPhoneUploadDocuments="onShowTapToPhoneUploadDocuments"
        :banks="banks"
      )
      block-tap-to-phone-upload-documents(
        v-else-if="showTapToPhoneUploadDocuments"
        @changeTitle="changeTitle"
        @documents="handleDocuments"
        @showTapToPhoneContactForm="onShowTapToPhoneContactForm"
      )
      block-tap-to-phone-contact-form(
        v-else-if="showTapToPhoneContactForm"
        :documents="documents"
        :tableRequest="tableRequest"
        :close-modal="closeModal"
        @showOschadbankAcquiringOrderInfo="onShowOschadbankAcquiringOrderInfo"
        @changeTitle="changeTitle"
      )
      block-oschadbank-acquiring-order-info(
        v-else-if="showOschadbankAcquiringOrderInfo"
        @changeDialogWidth="changeDialogWidth"
        @changeTitle="changeTitle"
      )
</template>

<script>
import BlockTapToPhoneConnectingOptions from '~/modules/acquiring/views/_old/tap-to-phone/block-tap-to-phone-connecting-options.vue'
import BlockTapToPhoneSettingsForm from '~/modules/acquiring/views/_old/tap-to-phone/block-tap-to-phone-settings-form.vue'
import BlockTapToPhoneBankSelection from '~/modules/acquiring/views/_old/tap-to-phone/block-tap-to-phone-bank-selection.vue'
import BlockTapToPhoneUploadDocuments from '~/modules/acquiring/views/_old/tap-to-phone/block-tap-to-phone-upload-documents.vue'
import BlockTapToPhoneContactForm from '~/modules/acquiring/views/_old/tap-to-phone/block-tap-to-phone-contact-form.vue'
import BlockOschadbankAcquiringOrderInfo from '~/modules/acquiring/views/_old/oschadbank/block-oschadbank-acquiring-order-info.vue'

export default {
  name: 'BlockTapToPhoneForm',
  components: {
    BlockOschadbankAcquiringOrderInfo,
    BlockTapToPhoneContactForm,
    BlockTapToPhoneUploadDocuments,
    BlockTapToPhoneBankSelection,
    BlockTapToPhoneSettingsForm,
    BlockTapToPhoneConnectingOptions
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    changeDialogWidth: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    title: 'Add Tap to Phone',
    documents: [],
    selectedBank: null,
    showTapToPhoneOptions: true,
    showTapToPhoneSettingsForm: false,
    showBankSelectionForm: false,
    showTapToPhoneUploadDocuments: false,
    showTapToPhoneContactForm: false,
    showOschadbankAcquiringOrderInfo: false
  }),
  computed: {
    banks () {
      return {
        oschad: 'oschad',
        concord: 'concord',
        raiffeisen: 'raiffeisen'
      }
    }
  },
  watch: {
    showOschadbankAcquiringOrderInfo (val) {
      if (val) {
        this.changeDialogWidth('560px')
      }
    }
  },
  created () {
    this.$gtm.push({
      event: 'open_add_tap_to_phone_dialog',
      organization: this._.get(this.$Organization, 'owner.email')
    })
  },
  methods: {
    changeTitle (title) {
      this.title = title
    },
    onShowTapToPhoneSettingsForm (val) {
      this.showTapToPhoneSettingsForm = val
      this.showTapToPhoneOptions = false
      this.showBankSelectionForm = false
      this.showTapToPhoneUploadDocuments = false
      this.showTapToPhoneContactForm = false
      this.showOschadbankAcquiringOrderInfo = false
    },
    onShowBankSelectionForm (val) {
      this.showBankSelectionForm = val
      this.showTapToPhoneOptions = false
      this.showTapToPhoneSettingsForm = false
      this.showTapToPhoneUploadDocuments = false
      this.showTapToPhoneContactForm = false
      this.showOschadbankAcquiringOrderInfo = false
    },
    onShowTapToPhoneUploadDocuments (val) {
      this.showTapToPhoneUploadDocuments = val
      this.showTapToPhoneOptions = false
      this.showTapToPhoneSettingsForm = false
      this.showBankSelectionForm = false
      this.showTapToPhoneContactForm = false
      this.showOschadbankAcquiringOrderInfo = false
    },
    onShowTapToPhoneContactForm (val) {
      this.showTapToPhoneContactForm = val
      this.showTapToPhoneOptions = false
      this.showTapToPhoneSettingsForm = false
      this.showBankSelectionForm = false
      this.showTapToPhoneUploadDocuments = false
      this.showOschadbankAcquiringOrderInfo = false
    },
    onShowOschadbankAcquiringOrderInfo (val) {
      this.showOschadbankAcquiringOrderInfo = val
      this.showTapToPhoneContactForm = false
      this.showTapToPhoneOptions = false
      this.showTapToPhoneSettingsForm = false
      this.showBankSelectionForm = false
      this.showTapToPhoneUploadDocuments = false
    },
    onChangeSelectedBank (val) {
      this.selectedBank = val
    },
    handleDocuments (documents) {
      this.documents = [...documents]
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 3px 0;
  }
}
</style>
