<template lang="pug">
  v-app(:key="key")
    drawer(v-if="showDrawer" @rerenderContent="rerenderContent")
    v-content()
      v-container(fluid fill-height class="default pa-0 background align-start")
        nuxt
    notification
    dialogs(ref="dialogs")
    e-new-version-informer
    sidebars
</template>

<script>
import drawer from './includes/_drawer'
import notification from './includes/_notification'
import dialogs from './includes/_dialogs'
import auth from '~/modules/auth/auth'
import authUser from '~/modules/auth/authUser'
import ENewVersionInformer from '~/components/elements/informers/e-new-version-informer'
import informationDialog from '~/mixins/dialogs/informationDialog'
import sidebars from '~/modules/sidebar/views/components/sidebars'

export default {
  components: {
    drawer,
    notification,
    dialogs,
    ENewVersionInformer,
    sidebars
  },
  mixins: [informationDialog],
  data () {
    return {
      key: 1
    }
  },
  computed: {
    showDrawer () {
      return auth.isAuthenticated() && authUser.get()
    }
  },
  methods: {
    rerenderContent () {
      this.key = Date.now()
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-width: 320px !important;
}
</style>
