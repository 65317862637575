<template lang="pug">
  tr(class="v-data-table__mobile-row__tr" :key="scopedItem.item.id || scopedItem.item.name")
    template(v-if="isPrependItem")
      td(
        class="v-data-table__mobile-row__td"
        :class="{ 'v-data-table__mobile-row__td--odd': itemBgColor }"
      )
        t-orm-wrapper(
          @click.native="itemActionFired(prependFieldIndex, scopedItem.item)"
          :item="scopedItem.item"
          :field="prependField"
          :request-params="requestParams"
        )
        template(v-if="intersectedActions.length")
          template(v-if="isEnabledActions")
            t-orm-menu-actions(:items="intersectedActions" :target="scopedItem.item")
    template(v-else)
      td(
        class="v-data-table__mobile-row__td"
        :class="{ 'v-data-table__mobile-row__td--odd': isOddItem }"
        @click="expand"
      )
        // TODO: add checkbox to select few rows in table
        div(class="d-flex align-center")
          div(v-if="model.mobileFieldIcons" class="pr-2")
            e-svg-icon(:name="model.mobileFieldIcons[scopedItem.item.type]")
          div(:class="statusClass")
            t-orm-wrapper(
              v-if="modelStatusComponent"
              :item="scopedItem.item"
              :field="modelStatusComponent"
              :request-params="requestParams"
            )
          div(
            :class="{ 'pb-2 pl-2': modelStatusComponent }"
          )
            div(class="v-data-table__mobile-row__subtitle") {{ handleValue(itemSubtitle) }}
            div(class="v-data-table__mobile-row__title" :class="{ 'v-data-table__mobile-row__title--active': isExpanded }") {{ handleValue(itemName) }}
        div(class="d-flex align-center")
          div(class="v-data-table__mobile-row__additional-text mr-2") {{ handleValue(additionalText) }}
          v-btn(
            icon
            class="v-data-table__mobile-row__expand-btn"
            :class="{ 'v-data-table__mobile-row__expand-btn--active': isExpanded }"
            @click.capture="expand"
          )
            e-svg-icon(size="sm" name="arrow-down")
</template>

<script>
import {
  Ripple
} from 'vuetify/lib'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import TOrmWrapper from '~/components/templates/orm/t-orm-wrapper'
import TOrmMenuActions from '~/components/templates/orm/t-orm-menu-actions'

export default {
  directives: {
    Ripple
  },
  components: {
    TOrmWrapper,
    ESvgIcon,
    TOrmMenuActions
  },
  props: {
    scopedItem: {
      type: Object,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    actions: {
      type: Object,
      default: () => {
      }
    },
    selected: {
      type: Array,
      default: () => []
    },
    expanded: {
      type: Array,
      default: () => []
    },
    itemSubtitle: {
      type: String,
      default: null
    },
    itemName: {
      type: String,
      default: 'name'
    },
    additionalText: {
      type: String,
      default: null
    },
    itemIndex: {
      type: Number,
      default: null
    },
    itemsLength: {
      type: Number,
      default: null
    },
    isPrependItem: {
      type: Boolean,
      default: false
    },
    requestParams: {
      type: Object,
      default: () => {
      }
    },
    context: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'orm-select-dialog'].includes(value)
      }
    },
    dialogModel: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      expandItem: false
    }
  },
  computed: {
    isExpanded () {
      const filtered = this._.filter(this.expanded, expanded => expanded.index === this._.get(this.scopedItem, 'index', null))
      return filtered && Boolean(filtered.length)
    },
    itemBgColor () {
      if (this.itemsLength % 2 === 0) {
        return this.itemIndex % 2 === 0
      }
      const index = this.itemIndex + 1
      return index % 2 === 0
    },
    isOddItem () {
      const copy = this.scopedItem.index
      return copy % 2 === 0
    },
    isEnabledActions () {
      if (Number.isFinite(+this.scopedItem.item.primaryVal)) {
        return this.scopedItem.item.primaryVal >= 0
      }

      return true
    },
    modelStatusComponent () {
      const statusObj = this._.get(this.model.ormColsComponents, 'status', null)
      return statusObj ? { ...statusObj, col: 'status' } : null
    },
    prependField () {
      return this.isPrependItem ? this.model.ormCols(this.config.short, this.scopedItem.headers).find(item => item.col === 'name') : null
    },
    prependFieldIndex () {
      return this.isPrependItem ? this.model.ormCols(this.config.short, this.scopedItem.headers).findIndex(item => item.col === 'name') : null
    },
    intersectedActions () {
      return this._.map(this.model.ormActions, (ormAction) => {
        return Object.assign({}, this._.find(this.config.actions, ['name', ormAction.name]), ormAction)
      })
    },
    disabledState () {
      return this._.isFunction(this.config.disabled) ? this.config.disabled(this.scopedItem) : false
    },
    statusClass () {
      return [
        this.disabledState ? 'disabled' : ''
      ]
    }
  },
  watch: {
    expandItem (val) {
      this.$emit('expand', this.scopedItem, val)
    }
  },
  methods: {
    expand () {
      this.expandItem = !this.expandItem
    },
    itemActionFired (index, item) {
      if (this._.isFunction(this._.get(this.actions, 'click', null))) {
        this.actions.click(item, this.requestParams)
        return
      }
      const header = this.scopedItem.headers[index]
      if (!this._.isEmpty(header.action)) {
        const actionItem = item[header.action.key]
        const action = this.intersectedActions.find(item => item.name === header.action.name)
        this.$emit('update:dialogModel', header.action.model)
        action.call(actionItem, true)
      }
    },
    handleValue (value) {
      return (typeof this.scopedItem.item[value] === 'number')
        ? this.scopedItem.item[value]
        : this.$t(this.scopedItem.item[value])
    }
  }
}
</script>

<style scoped lang="scss">

</style>
